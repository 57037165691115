<template>
  <div>
    <label class="switch">
      <input type="checkbox" id="togBtn" :checked="switchStatus" @click="toggleSwitch()">
      <div class="slider round">
        <span class="on">ON</span>
        <span class="off">OFF</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SilderToggleSwitch',
  props: {
    switchStatusValue: Boolean,
  },
  data() {
    return {
      switchStatus: this.switchStatusValue
    }
  },
  methods: {
    // toggle switch on and off
    toggleSwitch() {
      this.switchStatus = !this.switchStatus
      this.switchStatus ? this.$emit('toggle', 1) : this.$emit('toggle', 0)
    }
  }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--red);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--green);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 30%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 70%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
