<template>
  <div>
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: {
    id: String,
    graphData: Array,
    graphLabels: Array,
    interval: Object,
    subBotGraph: {
      type: Boolean,
      default: false
    },
    isBotGraph: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showLabels: false,
      chart: null
    }
  },
  watch: {
    graphData() {
      this.checkTimeInterval()
      this.generateGraph()
    }
  },
  mounted() {
    this.checkTimeInterval()
  },
  methods: {
    checkTimeInterval() {
      this.interval.value < 3 ? this.showLabels = true : this.showLabels = false
    },
    generateGraph() {
      const ChartDataLabels = require('chartjs-plugin-datalabels')

      if (this.chart) this.chart.destroy()
      let finalChartData = []
      let finalChartLabels = []
      let steps
      let dataJump
      if (this.showLabels) {
        dataJump = 7
        steps = this.graphData.length / dataJump
      } else {
        dataJump = 20
        steps = this.graphData.length / dataJump
      }
      let j = 0
      if (steps.toFixed(0) > 1) {
        for (let i = 0; i < dataJump; i++) {
          if (typeof this.graphData[j] !== 'undefined' && this.graphData[j] !== this.graphData[this.graphData.length - 1]) {
            finalChartData.push(this.graphData[j])
            finalChartLabels.push(this.graphLabels[j])
            j = j + Math.floor(steps.toFixed(0))
          }
        }
        finalChartData.push(this.graphData[this.graphData.length - 1])
        finalChartLabels.push(this.graphLabels[this.graphLabels.length - 1])
      } else {
        finalChartData = this.graphData
        finalChartLabels = this.graphLabels
      }

      if (finalChartData.length === 1) {
        finalChartData.unshift(0)
        finalChartLabels.unshift('')
      } else if (finalChartData.length < 1) {
        finalChartData.unshift(0, 0)
        finalChartLabels.unshift('', '')
      }

      const ctx = document.getElementById(this.id).getContext('2d')

      const gradient = ctx.createLinearGradient(0, 0, 0, 450)

      let bgColor = '#4aaf35'

      if (Math.sign(finalChartData[finalChartData.length - 1]) === -1) {
        bgColor = '#d56f7d'
        gradient.addColorStop(0, 'rgb(255,255,255)')
        gradient.addColorStop(0.7, 'rgb(211,80, 125)')
        gradient.addColorStop(1, 'rgb(221, 111, 125)')
      } else {
        gradient.addColorStop(0, 'rgb(96, 210, 72)')
        gradient.addColorStop(0.7, 'rgb(204,255,198)')
        gradient.addColorStop(1, 'rgb(255,255,255)')
      }

      let labelColor = '#2c3e50'
      let gridColor = 'rgb(193, 193, 193)'
      let botGridColor = '#4e5765'
      if (localStorage.boostingAlphaDarkTheme === 'true') {
        labelColor = '#fff'
        gridColor = '#333741'
        botGridColor = '#4e5765'
      }

      if (this.subBotGraph) botGridColor = gridColor

      const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;
      const up = (ctx, value) => ctx.p0.parsed.y < ctx.p1.parsed.y ? value : undefined;
      const same = (ctx, value) => ctx.p0.parsed.y === ctx.p1.parsed.y ? value : undefined;

      let tempYears = []

      const vm = this


      // eslint-disable-next-line no-unused-vars
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: finalChartLabels,
          datasets: [
            {
              lineTension: 0.3,
              borderJoinStyle: 'round',
              pointBackgroundColor: '#ffff',
              pointRadius: 3,
              pointBorderColor: '#2c3e50',
              fill: false,
              label: 'Return',
              borderWidth: 4,
              pointBorderWidth: 0.5,
              data: finalChartData,
              segment: {
                borderColor: ctx => same(ctx, '#64ba62') || up(ctx, '#64ba62') || down(ctx, '#d56f7d'),
              },
            }
          ]
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: vm.showLabels,
              align: 'top',
              anchor: 'end',
              visibility: 'auto',
              borderColor: 'white',
              backgroundColor: bgColor,
              borderRadius: 4,
              color: 'white',
              font: {
                weight: 'bold'
              },
              formatter(value) {
                if (vm.isBotGraph) return value.toFixed(2) + '%'
                return value.toFixed(2)
              },
              padding: 4
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  if (vm.isBotGraph) return 'Return: ' + context.raw
                  return 'Balance: ' + context.raw
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,

          tooltips: {
            enabled: !vm.showLabels,
            callbacks: {
              label: function (tooltipItem, data) {
                if (vm.isBotGraph) return tooltipItem.value + '%'
                return tooltipItem.value + ' USD'
              }
            }
          },
          aspectRatio: 5 / 4,
          layout: {
            padding: {
              top: 32,
              right: 16,
              bottom: 16,
              left: 8
            }
          },
          scales: {
            y: {
              grid: {
                color: vm.isBotGraph ? botGridColor : gridColor
              },
              suggestedMin: 0,
              stacked: true,
              position: 'right',
              ticks: {
                display: true,
                fontSize: 10,
                color: labelColor,
                callback(value) {
                  if (vm.isBotGraph) return value.toFixed(2) + '%'
                  return value.toFixed(2) + ' USD'
                }
              }
            },
            x: {
              grid: {
                color: vm.isBotGraph ? botGridColor : gridColor
              },
              ticks: {
                color: labelColor,
                display: true,
                callback(value) {
                  let temp = 0
                  if (finalChartLabels[value]) {
                    switch (finalChartLabels[value].split('-')[1]) {
                      case '01':
                        temp = 'Jan'
                        break
                      case '02':
                        temp = 'Feb'
                        break
                      case '03':
                        temp = 'Mar'
                        break
                      case '04':
                        temp = 'Apr'
                        break
                      case '05':
                        temp = 'May'
                        break
                      case '06':
                        temp = 'Jun'
                        break
                      case '07':
                        temp = 'Jul'
                        break
                      case '08':
                        temp = 'Aug'
                        break
                      case '09':
                        temp = 'Sep'
                        break
                      case '10':
                        temp = 'Oct'
                        break
                      case '11':
                        temp = 'Nov'
                        break
                      case '12':
                        temp = 'Dec'
                        break
                    }
                    let tempDay = 0
                    if (finalChartLabels[value].split('-')[2][0] === '0') {
                      tempDay = finalChartLabels[value].split('-')[2][1]
                    } else {
                      tempDay = finalChartLabels[value].split('-')[2]
                    }

                    return temp + ' ' + tempDay
                  }
                  return ' '
                }
              },
            },
            x1: {
              grid: {
                color: vm.isBotGraph ? botGridColor : gridColor
              },
              id: 'xAxis2',
              type: 'category',
              ticks: {
                color: labelColor,
                display: true,
                fontStyle: 'bold',
                callback(value) {
                  if (!tempYears.includes(finalChartLabels[value].split('-')[0])) {
                    tempYears.push(finalChartLabels[value].split('-')[0])
                    return finalChartLabels[value].split('-')[0]
                  }
                }
              },
            }
          }
        }
      }
      )

    }
  }
}
</script>

<style>
</style>