<template>
  <div class="section-settings">
    <span v-if="connectNewExchange" class="back" @click="closeNewExchange()">&larr; Back</span>
    <div class="exchange-title">
      <span v-if="!connectNewExchange" class="title">Connected Exchanges</span>
      <span v-else class="title">Connect new exchange</span>
      <span v-if="!connectNewExchange" class="button-p button-shadow exchange-b" @click="toggleConnectExchange()">+</span>
    </div>
    <div v-if="connectNewExchange">
      <span>Choose exchange:</span>
      <div class="choose-exchange">
        <exchange-selector :type='1' :selected="exchangeType" @selectExchange="setSelectedExchange($event)"/>
      </div>
      <connect-binance-account v-show="exchangeType === 1" @closeForm="closeForm()" @success="confirmAccountAdded()"/>
    </div>
    <v-data-table class="exchanges-table" v-else :headers="headers" :items="exchanges" no-data-text="No exchange accounts currently connected">
      <template v-slot:item.exchange="{ item }">
        <span small class="exchange">
          <img class="exchange-logo" src="@/assets/binance-logo.png" alt/>
          <span>{{ item.exchange[0].toUpperCase() + item.exchange.substr(1) }}</span>
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <span>
          <span>{{ isValidated(item.validated) }}</span>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span small class="mr-2" @click="editItem(item)">
          <img class="table-icon" height="50%" src="@/assets/edit.png" alt/>
        </span>
        <span small class="mr-2" @click="deleteItem(item)">
          <img class="table-icon" height="50%" src="@/assets/delete.png" alt/>
        </span>
      </template>
    </v-data-table>
    <span v-if="errorMsg" class="error">{{ errMsg }}</span>
    <span v-if="successMsg" class="success">{{ successMsg }}</span>
    <modal v-if="modifyExchangeAccount" :item="modifyAccount" :editExchangeAccount="editAccount" :deleteExchangeAccount="deleteAccount" :exchangeAccount="account" @click="closeModal()" @deleteConnection="confirmDeleteAccount($event)"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import NewExchangeAccount from '@/components/trading/NewExchangeAccount.vue'
import Modal from '@/components/shared/modals/Modal.vue'
import ExchangeSelector from '../../shared/exchanges/ExchangeSelector.vue'
import ConnectBinanceAccount from '@/components/trading/settings/exchanges/ConnectBinanceAccount.vue'


export default {
  name: 'ConnectedExchanges',
  computed: mapGetters(['connectedExchanges']),
  components: {
    NewExchangeAccount,
    Modal,
    ConnectBinanceAccount,
    ExchangeSelector
  },
  data() {
    return {
      modifyExchangeAccount: false,
      connectNewExchange: false,
      exchanges: [],
      headers: [
        {text: 'Name', sortable: false, value: 'connection_name'},
        {text: 'Exchange', value: 'exchange', sortable: false},
        {text: 'Status', value: 'status', sortable: false},
        {text: 'Connected bots', value: 'activeBots', sortable: false},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      modifyAccount: {
        title: '',
        content: ''
      },
      editAccount: false,
      deleteAccount: false,
      account: {},
      exchangeType: 0,
      successMsg: "",
      errorMsg: ""
    }
  },
  mounted() {
    this.getUserConnections()
  },
  methods: {
    ...mapActions(['loadExchangeConnections']),
    isValidated(item) {
      switch (item) {
        case 'True':
          return 'Validated'
        case 'False':
          return 'Not Validated'
      }
    },
    getUserConnections() {
      this.$api
          .get('/exchanges/get_connections', {
            params: {
              user_id: this.$session.get('uid')
            },
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.loadExchangeConnections(res.data.data)
              this.exchanges = res.data.data
            }
          })
          .catch((err) => {
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    },
    editItem(item) {
      this.account = item
      this.editAccount = true
      this.modifyExchangeAccount = true
      this.modifyAccount.title = 'Edit Exchange Account'
      this.modifyAccount.content = ''
    },
    // remove exchange account
    deleteItem(item) {
      this.editAccount = false
      this.account = item
      this.deleteAccount = true
      this.modifyExchangeAccount = true
      this.modifyAccount.title = 'Delete Exchange Account'
      this.modifyAccount.content = 'Are you sure you want to delete this exchange account?'
    },
    confirmDeleteAccount(item) {
      const body = {
        connection_id: item.connection_id,
        user_id: this.$session.get('uid')
      }
      this.$api
          .post('/exchanges/delete_connection', body, {
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.getUserConnections()
              this.successMsg = 'Account succesfuly removed'
            }
          })
          .catch((err) => {
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    },
    confirmAccountAdded() {
      this.getUserConnections()
      this.successMsg = 'Account succesfuly added'
    },
    // close new exchange form
    closeForm() {
      this.account = {}
      this.editAccount = false
      this.connectNewExchange = false
      this.deleteAccount = false
      this.loadExchangeConnections()
    },
    // open the connect new exchange form
    toggleConnectExchange() {
      this.connectNewExchange = true
    },
    closeModal() {
      this.modifyExchangeAccount = false
    },
    setSelectedExchange(type) {
      this.exchangeType = type
    },
    closeNewExchange() {
      this.connectNewExchange = false
      this.exchangeType = 0
    }
  }
}
</script>

<style scoped>
.section-settings {
  height: 80%;
  width: 65%;
}

.exchange-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.exchange-b {
  margin-left: 30px;
  background-color: #4aaf35;
  border-radius: 50%;
  height: 30px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
}

.exchanges-table {
  width: 100%;
}

.exchange {
  display: flex;
  align-items: center;
}

.exchange-logo {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.table-icon {
  height: 25px;
  cursor: pointer;
  margin-right: 20px;
}

.back {
  cursor: pointer;
  text-decoration: underline;
}

.choose-exchange {
  margin-top: 10px;
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }

  .exchange-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .title {
    font-size: 24px;
  }

  .exchange-b {
    width: 25px;
  }
}
</style>
