<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Boosting Alpha',
      link: [
        {rel: 'canonical', href: 'https://www.boostingalpha.com'}
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (to.params.title) {
          document.title = 'Boosting Alpha - ' + to.params.title;
          return
        }
        document.title = to.meta.title || 'Boosting Alpha';
      }
    }
  }

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');

:root {
  --dark: #333741;
  --white: #ffffff;
  --background: #f8f8f8;
  --card-background: var(--white);
  --primary-blue: #008ecb;
  --primary-blue-hover: #00538b;
  --header-color: #2c3e50;
  --header-color-hover: #4d5f72;
  --secondary-blue: #2e475c;
  --secondary-blue-hover: #7b91a2;
  --selected: #e9f8ff;
  --text-primary: var(--secondary-blue);
  --text-primary-hover: var(--primary-blue);
  --text-link: var(--primary-blue);
  --text-secondary: var(--white);
  --text-header: var(--secondary-blue);
  --border: #c7c7c7;
  --red: #e57388;
  --green: #53c664;
  --yellow: #ffca00;
  --yellow-hover: #dbaf00;
  --disabled: #8b8b8b;
  --binance: var(--yellow);
  --bitvavo: #5d89e9;
  --ftx: #66abb8;
  --okx: rgb(193, 193, 193);
  --bybit: #fee5b6;
  --bitget: #86d7e8;
  --kraken: #9086e7;
  --kucoin: #c7ffc3;
  --purple: #623ea9;
  --shadow: rgba(0, 0, 0, 0.2);
  --button-text: var(--white);
  --table-header: var(--secondary-blue);
  --widget-color: var(--secondary-blue);
}

[data-theme='dark'] {
  --dark: #333741;
  --white: #ffffff;
  --background: #333741;
  --card-background: #4e5765;
  --primary-blue: #008ecb;
  --primary-blue-hover: #00538b;
  --secondary-blue: #2e475c;
  --secondary-blue-hover: #7b91a2;
  --selected: rgb(193, 193, 193);
  --text-primary: var(--white);
  --text-primary-hover: var(--primary-blue);
  --text-link: var(--primary-blue);
  --text-secondary: var(--secondary-blue);
  --text-header: var(--white);
  --border: rgba(119, 119, 119);
  --red: #e57388;
  --green: #53c664;
  --yellow: #ffca00;
  --yellow-hover: #dbaf00;
  --disabled: #8b8b8b;
  --binance: var(--yellow);
  --bitvavo: #5d89e9;
  --ftx: #66abb8;
  --okx: rgb(193, 193, 193);
  --bybit: #fee5b6;
  --bitget: #86d7e8;
  --kraken: #9086e7;
  --purple: #623ea9;
  --kucoin: #c7ffc3;
  --shadow: rgba(0, 0, 0, 0.2);
  --button-text: var(--white);
  --table-header: var(--white);
  --widget-color: var(--card-background);
}

* {
  margin: 0;
}

#app {
  font-family: Dosis, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
  background: var(--background);
  overflow-x: hidden;
  user-select: none;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mail-link {
  color: var(--blue-primary);
}

.note {
  font-size: 16px;
  font-weight: normal;
  font-style: italic;
}

.title {
  height: 50px;
  font-size: 32px;
  font-weight: 500;
  color: var(--text-header);
  align-items: center;
  font-family: 'Roboto Condensed', sans-serif;
}

.button-p {
  background-color: var(--primary-blue);
  padding: 6px 15px;
  font-size: 20px;
  color: var(--button-text);
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  user-select: none;
}

.disabled-button {
  background-color: var(--disabled);
  padding: 6px 15px;
  font-size: 20px;
  color: var(--text-secondary);
  text-decoration: none;
  border-radius: 10px;
  user-select: none;
}

.button-p:hover {
  background-color: var(--primary-blue-hover);
}

.button-shadow {
  box-shadow: 0 4px 6px var(--shadow);
}

.button-shadow:active {
  box-shadow: 0 1px 3px var(--shadow);
}

.card {
  border-radius: 5px;
  box-shadow: 0 10px 20px var(--shadow), 0 3px 6px var(--shadow);
}

.note {
  font-style: italic;
}

.step-title {
  font-weight: 400;
}

.contact-input {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 16px;
  margin-top: 20px;
}

.form-input {
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  background: var(--card-background);
  color: var(--text-primary);
  font-weight: 400;
  border: 1px solid var(--border);
}

.form-button {
  background-color: var(--primary-blue);
  padding: 10px 60px;
  box-shadow: var(--shadow);
  border-radius: 10px;
  color: var(--button-text);
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-button:hover {
  background-color: var(--primary-blue);
}

.form-button-cancel {
  font-weight: 500;
  cursor: pointer;
  color: var(--text-secondary);
}

.form-button-cancel:hover {
  color: var(--primary-blue-hover);
  border-bottom: 2px solid var(--primary-blue-hover);
}

.confirmation-buttons {
  margin: 20px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 90%;
}

.form-textarea {
  min-height: 200px;
  padding: 10px;
  resize: none;
  outline: 0;
  border-radius: 10px;
  background: var(--background);
  color: var(--text-secondary);
  border: 1px solid var(--border);
  font-size: 18px;
  overflow-y: auto;
}

.divider {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--primary-blue);
}

.table {
  width: auto !important;
  box-shadow: 0 0 100px var(--shadow);
  border-radius: 5px;
}

.error, .err {
  font-weight: bold;
  font-size: 18px;
  color: var(--red);
}

.errorInput {
  border: 2px solid var(--red);
}

.success {
  font-weight: bold;
  font-size: 18px;
  color: var(--green);
}

.errorInput {
  border: 2px solid var(--red);
}

.theme--light.v-data-table {
  background-color: var(--card-background) !important;
  color: var(--text-primary);
}

th:first-child {
  border-top-left-radius: 5px;
}

th:last-child {
  border-top-right-radius: 5px;
}

tr {
  color: var(--text-primary) !important;
  cursor: pointer;
}

tr:hover {
  background-color: var(--card-background) !important;
  color: var(--text-primary) !important;
  cursor: pointer;
}

th {
  color: var(--text-secondary) !important;
  background: var(--table-header);
  text-align: left;
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: 'Roboto Condensed', sans-serif;
}

td {
  font-size: 16px !important;
}

.v-stepper,
.v-stepper__header {
  margin-top: 10px !important;
  height: auto !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.v-stepper__label {
  margin-left: 10px;
  color: var(--table-header) !important;
}

.v-stepper__step__step {
  background-color: var(--table-header) !important;
  color: var(--secondary-blue) !important;
}

.v-stepper__step {
  padding: 10px !important;
}

.v-icon {
  margin-right: 3px;
  font-size: 15px !important;
}

.v-stepper__step__step.primary {
  background-color: var(--primary-blue) !important;
}

.v-stepper__step--active {
  background-color: var(--card-background) !important;
  border-radius: 10px !important;
}

.v-data-footer__icons-before {
  color: var(--table-header) !important;
}

.v-data-footer__icons-after {
  color: var(--table-header) !important;
}

.v-data-footer__select,
.select__selection,
.v-select__selection--comma {
  display: none !important;
}

.v-data-footer__pagination {
  color: var(--table-header) !important;
}

.v-icon__svg {
  color: var(--text-secondary) !important;
}

.v-data-footer {
  display: flex;
  justify-content: flex-end;
}

.positive {
  font-weight: bold;
  color: var(--green);
}

.negative {
  color: var(--red);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--secondary-blue);
  color: var(--text-secondary);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--secondary-blue) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.negative {
  color: var(--red);
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.italic {
  font-style: italic;
}

.prev,
.next {
  color: var(--text-primary) !important;
}

.v-label,
.theme--light {
  color: var(--text-primary) !important;
}

@media (max-width: 850px) {
  .confirmation-buttons {
    width: 100%;
    justify-content: space-around;
  }

  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 0px !important;
  }

  .v-divider {
    border: none !important;
  }

  .title {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .form-button {
    width: 100%;
  }

  .prev,
  .next {
    color: var(--text-primary) !important;
  }
}
</style>
