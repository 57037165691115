<template>
  <div class='bots'>
    <div class='bots-title-section'>
      <span class='title'>Bots</span>
      <loading class='loading' :active='loading' />
      <div v-if="!loading" class='bots-navigation'>
        <h2 class='bot-navigation-item' :class='{ selected: !isActiveBots }' @click='toggleTab(0)'>Bots</h2>
        <h2 class='bot-navigation-item' :class='{ selected: isActiveBots }' @click='toggleTab(1)'>Connected</h2>
      </div>
      <div v-if="!loading && !isActiveBots" class="bots-navitagion-sub">
        <h2 class='bot-navigation-sub-item' :class='{ selected: subtab === 0 }' @click='toggleSubTab(0)'>All bots</h2>
        <h2 class='bot-navigation-sub-item' :class='{ selected: subtab === 1 }' @click='toggleSubTab(1,"index_fund")'>Index Funds</h2>
        <h2 class=' bot-navigation-sub-item' :class='{ selected: subtab === 2 }' @click='toggleSubTab(2,"rebalancer")'>Rebalancers</h2>
        <h2 class=' bot-navigation-sub-item' :class='{ selected: subtab === 3 }' @click='toggleSubTab(3,"base_coin_BTC")'>BTC Basecoin</h2>
      </div>
    </div>
    <div v-show="!loading" class='bots-content'>
      <div class='all-bots'>
        <div class='bot-cards'>
          <div class='bot-cards-content'>
            <div v-if='!isActiveBots' class='bot-cards-container'>
              <PromotedBots v-if="promotedBots.length > 0 && subtab === 0 && (filterQuery.length < 1 && exchangeQuery.length < 1)" :title='"New Bots"' :bots='promotedBots' />
              <PromotedBots v-if="topPerformers.length > 0 && subtab === 0 && (filterQuery.length < 1 && exchangeQuery.length < 1)" :title='"Top Performers"' :bots='topPerformers' :isTopPerformer="true" />
              <div class='bots-title-bar' :class='{ subscribed: isActiveBots }'>
                <div class='test'>
                  <div class='search-bar'>
                    <span class='password-visibility-toggle'>
                      <input class='search-input' type='text' v-model='botSearch' placeholder='Search for bot' />
                      <img v-if="isDarkTheme" class='password-toggle' src='@/assets/search-white.png' alt />
                      <img v-else class='password-toggle' src='@/assets/search.png' alt />
                    </span>
                  </div>
                </div>
                <div v-show="!loading" class='test right'>
                  <bot-filters class="filters" @updateFilter="updateFilter($event)" @clearFilter="resetFilter()" />
                  <markter-place-filters class="filters" @updateFilter="updateMarketFilter($event)" />
                </div>
              </div>
              <active-marketplace-filters v-if="selectedFilters.length > 0" :active-filters="selectedFilters" @clearFilters="resetMarketPlaceFilter()" @removeFilter="removeIndividualFilter($event)" />
              <div>
                <div class="test2">
                  <span class="section-title">{{ subTitleText }}</span>
                  <time-filter-dropdown class="filters" :activeFilter="timeframe" @updateTimeFrame="updateTimeFilter($event)" :marketplace="true" />
                </div>
                <loading class="loading" :active="botLoading" />
                <div v-if="!botLoading" class=" bot">
                  <div v-for='bot in marketPlace' :key='bot.bot_name'>
                    <bot-card class='bot-item' :bot='bot' :graphId="bot.bot_id + 'ALL'" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class='bot-cards-container'>
                <div class="bot">
                  <div v-for='bot in subscribedBots' :key='bot.id'>
                    <subscribed-bot-card class='bot-item' :bot='bot' :isActive='true' :graphId="bot.bot_id + 'SUB'" />
                  </div>
                </div>
              </div>
              <div class='no-bots-subs' v-if='!subscribedBots || subscribedBots.length < 1 && !loading'>
                <span class='no-bots-subs'>No bots currently connected</span>
              </div>
            </div>
            <Pagination v-if="!botLoading && !isActiveBots" :itemsPerPage='itemsPerPage' :totalItems="bots.length" :currentItems="marketPlace.length" :currentTab="this.subtab" @previous="previousPage()" @next="nextPage()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotCard from '@/components/trading/bots/BotCard'
import SubscribedBotCard from '@/components/trading/bots/SubscribedBotCard'
import { mapActions, mapGetters } from 'vuex'
import Filters from '@/components/trading/Filters.vue'
import Loading from '@/components/shared/Loading.vue'
import PaymentConfirmationModal from '@/components/shared/modals/PaymentConfirmationModal.vue'
import BotFilters from '@/components/trading/BotFilters.vue'
import MarkterPlaceFilters from "@/components/trading/MarkterPlaceFilters";
import ActiveMarketplaceFilters from "@/components/trading/ActiveMarketplaceFilters";
import TimeFilterDropdown from "@/components/trading/TimeFilterDropdown";
import PromotedBots from '@/components/trading/bots/PromotedBots.vue'
import Pagination from '@/components/trading/Pagination.vue'

export default {
  name: 'Bots',
  components: {
    BotCard,
    Filters,
    Loading,
    SubscribedBotCard,
    PaymentConfirmationModal,
    BotFilters,
    MarkterPlaceFilters,
    ActiveMarketplaceFilters,
    TimeFilterDropdown,
    PromotedBots,
    Pagination
  },
  data() {
    return {
      marketPlace: [],
      bots: [],
      marketplace: [],
      promotedBots: [],
      topPerformers: [],
      rerenderGraph: 0,
      loading: false,
      botLoading: true,
      isActiveBots: false,
      subtab: 0,
      selectedFilters: [],
      filterQuery: [],
      exchangeQuery: [],
      tabQuery: [],
      searchList: [],
      botSearch: '',
      activeFilters: false,
      orderByReturnHighToLow: false,
      subscribedBots: [],
      isFiltered: false,
      activePagination: true,
      currentPage: 0,
      showNextPageButton: true,
      itemsPerPage: 12,
      timeframe: { id: 6, name: 'ROI: All Time', time: '50 Years' },
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  computed: {
    ...mapGetters([
      'currentActiveFilters',
      'currentSelectedExchanges',
      'currentActiveTab',
      'selectedActiveFilters',
      'currentROIInterval',
      'darkTheme',
      'currentCachedBots'
    ]),
    subTitleText() {
      if (this.subtab === 0) return 'All Bots'
      if (this.subtab === 1) return 'Index Funds'
      if (this.subtab === 2) return 'Rebalancers'
      if (this.subtab === 3) return 'BTC Basecoin'
    },
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    },
    botSearch() {
      const vm = this
      this.setMarketPlace()
      this.searchList = []
      Object.keys(this.bots).forEach((key) => {
        if (vm.bots[key].bot_name.toLowerCase().includes(vm.botSearch.toLowerCase())) {
          if (vm.searchList.indexOf(vm.bots[key]) === -1) {
            vm.searchList.push(vm.bots[key])
          }
        }
      })
      if (this.botSearch !== '') {
        this.marketPlace = this.searchList
        this.activePagination = false
        return
      }
      this.currentPage = 0
      this.activePagination = true
      this.setMarketPlace()
    },
  },
  created() {
    window.addEventListener("resize", this.setMarketPlace);

    this.checkRoute()
    this.checkForActiveFilters()
    this.getMonthlyPerformers()
  },
  destroyed() {
    window.removeEventListener("resize", this.setMarketPlace);
  },
  methods: {
    ...mapActions([
      'loadSubscribedBots',
      'addNotification',
      'updateActiveFilters',
      'updateSelectedExchanges',
      'updateActiveTab',
      'updateSelectedActiveFilters',
      'updateROIInterval',
      'updateCachedBots'
    ]),
    orderByPnl(order) {
      switch (order) {
        // high to low
        case 0:
          this.bots.sort((a, b) => (a.total_pnl_pct < b.total_pnl_pct && 1) || -1)
          break;
        // low to high
        case 1:
          this.bots.sort((a, b) => (a.total_pnl_pct > b.total_pnl_pct && 1) || -1)
          break;
        default:
          break;
      }
      this.setMarketPlace()
    },
    updateMarketFilter(filter) {
      if (filter.exchange) {
        this.selectedFilters.push(filter)
        this.exchangeQuery.push(filter.item.value)

        this.updateSelectedActiveFilters(this.selectedFilters)
        this.updateSelectedExchanges(this.exchangeQuery)

        this.getAllBots(true)
        return
      }
      this.selectedFilters.push(filter)
      this.filterQuery.push(filter.value)

      this.updateSelectedActiveFilters(this.selectedFilters)
      this.updateActiveFilters(this.filterQuery)

      this.getAllBots(true)
    },
    resetMarketPlaceFilter() {
      this.selectedFilters = []
      this.filterQuery = []
      this.exchangeQuery = []

      this.updateSelectedActiveFilters(this.selectedFilters)
      this.updateSelectedExchanges(this.exchangeQuery)
      this.updateActiveFilters(this.filterQuery)

      this.getAllBots(false)
    },
    removeIndividualFilter(item) {
      if (item.type === 5) {
        this.selectedFilters.splice(this.selectedFilters.indexOf(item), 1)
        this.exchangeQuery.splice(this.exchangeQuery.indexOf(item.value), 1)

        this.updateSelectedActiveFilters(this.selectedFilters)
        this.updateSelectedExchanges(this.exchangeQuery)

      } else {
        this.selectedFilters.splice(this.selectedFilters.indexOf(item), 1)
        this.filterQuery.splice(this.filterQuery.indexOf(item.value), 1)

        this.updateSelectedActiveFilters(this.selectedFilters)
        this.updateActiveFilters(this.filterQuery)
      }
      if (this.selectedFilters.length === 0 && this.filterQuery.length === 0 && this.exchangeQuery.length === 0) {
        this.getAllBots(false)
        return
      }
      this.getAllBots(true)
    },
    updateFilter(filter) {
      this.selectedFilter = filter
      switch (filter.type) {
        case 1:
          filter.value.value ? this.orderByPnl(0) : this.orderByPnl(1)
          break;
      }
      this.isFiltered = true
      this.items = this.filteredItems
    },
    resetFilter() {
      this.selectedFilter = {}
      this.isFiltered = false
      this.getAllBots(true)
    },
    updateTimeFilter(timeframe) {
      this.timeframe = timeframe
      this.updateROIInterval(timeframe)
      if (this.subtab !== 0) {
        this.getAllBots(true)
        return
      }
      this.getAllBots()
    },
    toggleTab(page) {
      switch (page) {
        // All bots
        case 0:
          if (this.isActiveBots) {
            this.$router.push({ name: 'Bots', params: { page: 'marketplace' } })
            this.isActiveBots = false
            if (this.currentCachedBots.length > 0) {
              this.separateBots(this.currentCachedBots)
              return
            }
            this.getAllBots()
          }
          return
        // Subscribed bots
        case 1:
          if (!this.isActiveBots) {
            this.$router.push({ name: 'Bots', params: { page: 'subscribed' } })
            this.isActiveBots = true
            this.getSubscribedBots()
          }
          return
      }
    },
    toggleSubTab(subpage, type) {
      this.tabQuery = []
      if (subpage !== this.subtab) {
        this.subtab = subpage
        if (type) {
          this.tabQuery.push(type)
        } else {
          this.tabQuery = []
        }
        this.updateActiveTab(this.tabQuery)
        if (subpage > 0) {
          this.getAllBots(true)
          return
        }

        if (this.currentCachedBots.length > 0) {
          this.separateBots(this.currentCachedBots)
          return
        }
        this.getAllBots()
      }
    },
    getAllBots(withFilter) {
      const qs = require('qs');
      let params = {
        timezone: this.$session.get('timezone'),
        interval: this.timeframe.time
      }
      if (this.filterQuery.length > 0) {
        params.filters = this.filterQuery
        withFilter = true
      }
      if (this.exchangeQuery.length > 0) {
        params.exchanges = this.exchangeQuery
        withFilter = true
      }

      if (this.tabQuery.length > 0) {
        params.filters_sub = this.tabQuery
      }

      withFilter ? this.botLoading = true : this.loading = true

      this.$api
        .get('/bots/get_bots', {
          params: params,
          paramsSerializer: function paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.currentPage = 0
            if (!res.data || !res.data.data) {
              this.botLoading = false
              this.loading = false
              this.bots = []
              this.marketPlace = []
              return
            }

            if (!withFilter) {
              this.updateCachedBots(res.data.data)
              this.separateBots(res.data.data)
              return
            }
            this.promotedBots = []
            this.setFilteredBots(res.data.data)
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    getSubscribedBots() {
      this.loading = true
      this.$api
        .get('/bots/get_connected_bots', {
          params: {
            user_id: this.$session.get('uid'),
            timezone: this.$session.get('timezone')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.subscribedBots = res.data.data
            this.loadSubscribedBots(res.data.data)
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    getMonthlyPerformers() {
      this.$api.get('/bots/get_monthly_performers', {
        params: {
          timezone: this.$session.get('timezone')
        },
        headers: {
          'X-Authorization': this.$session.get('token')
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.topPerformers = res.data.data

          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    handleIdealFreeTrial(setupIntent) {
      let temp = {
        user_id: this.$session.get('uid'),
        subscription_id: this.$session.get('subscription_id'),
        setup_intent_id: setupIntent
      }
      this.$api
        .post('/subscriptions/stripe/attach_payment_method_ideal', temp, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$session.remove('subscription_id')
          }
        })
        .catch((err) => {
          this.loadingPayment = false
          if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
            this.errorMsg = 'You cannot allocate more than 2000 USDT across all bots'
          }
        })
    },
    setFilteredBots(bots) {
      this.bots = []
      this.bots = bots

      this.orderByPnl(0)
      this.setMarketPlace()
    },
    separateBots(bots) {
      this.promotedBots = []
      this.bots = []
      bots.forEach((bot) => {
        if (bot.promoted_new) {
          this.promotedBots.push(bot)
        } else {
          this.bots.push(bot)
        }
      })
      this.orderByPnl(0)
      this.setMarketPlace()
    },
    setMarketPlace() {
      this.showNextPageButton = true
      this.marketPlace = []

      if (window.innerWidth < 2030) this.itemsPerPage = 12
      if (window.innerWidth > 2030) this.itemsPerPage = 10
      if (window.innerWidth > 2410) this.itemsPerPage = 12
      if (window.innerWidth > 2800) this.itemsPerPage = 14
      if (window.innerWidth > 3180) this.itemsPerPage = 16

      for (let i = 0; i < this.itemsPerPage; i++) {
        if (this.bots[i]) this.marketPlace.push(this.bots[i])
        if (this.bots.length <= this.itemsPerPage) this.showNextPageButton = false
      }

      this.botLoading = false
      this.loading = false
    },
    previousPage() {
      if (this.currentPage !== 0) this.currentPage--
      this.showNextPageButton = true

      let lastValue = this.bots.indexOf(this.marketPlace[0])
      let firstValue = lastValue - this.itemsPerPage
      this.marketPlace = []


      for (let i = firstValue; i < lastValue; i++) {
        if (this.bots[i]) {
          this.marketPlace.push(this.bots[i])
        }
      }
    },
    nextPage() {
      this.currentPage++
      const first = this.bots.indexOf(this.marketPlace[this.marketPlace.length - 1]) + 1

      const last = first + (this.itemsPerPage)

      if (this.bots[this.bots.indexOf(this.marketPlace[this.marketPlace.length - 1]) + 1]) {
        this.marketPlace = []
        for (let i = first; i < last; i++) {
          if (!this.bots[i]) {
            this.showNextPageButton = false
            return
          }
          this.marketPlace.push(this.bots[i])
        }
      }
      const hasNextPage = this.bots.indexOf(this.marketPlace[this.marketPlace.length - 1]) + 1
      if (!this.bots[hasNextPage]) this.showNextPageButton = false

      if (this.marketPlace.length < this.itemsPerPage) this.showNextPageButton = false
    },
    checkRoute() {
      if (this.$route.params.page === 'activated-subscription' || this.$route.params.page === 'checkout') {
        if (this.$route.query.redirect_status === 'failed') {
          let notification = {
            status: 1,
            title: 'Something went wrong',
            text: 'Something went wrong in your payment process. Please try again or contact us'
          }
          this.addNotification(notification)
          if (this.$session.get('subscription_id')) {
            this.$session.remove('has_subscription')
            this.$session.remove('subscription_plan')
            this.$session.remove('subscription_id')
            this.$router.push({ name: 'Settings', params: { subscription: true } })
          } else {
            this.getAllBots(false)
          }
          return
        }
        if (this.$route.query.setup_intent && this.$session.get('subscription_id')) {
          this.handleIdealFreeTrial(this.$route.query.setup_intent)
        }
        let notification = {
          status: 0,
          title: 'Subscription Activated',
          text: 'Your subscription has succesfully been activated'
        }
        this.addNotification(notification)
        this.getAllBots(false)
        return
      }
      if (this.$route.params.page === 'modify') {
        this.$session.remove('subscription_id')
        if (this.$route.query.redirect_status === 'failed') {
          let notification = {
            status: 1,
            title: 'Something went wrong',
            text: 'Something went wrong in your payment process. Please try again or contact us'
          }
          this.addNotification(notification)
          this.$router.push({ name: 'Settings', params: { subscription: true } })
          return
        }
        let notification = {
          status: 0,
          title: 'Subscription updated',
          text: 'Your subscription has succesfully been updated'
        }
        this.addNotification(notification)
        this.$router.push({ name: 'Settings', params: { subscription: true } })
        return
      }
      if (this.$route.params.page === 'marketplace' && (this.currentActiveFilters.length === 0 && this.currentSelectedExchanges.length === 0 && this.currentActiveTab.length === 0 && Object.keys(this.currentROIInterval).length === 0)) {
        this.isActiveBots = false

        if (this.currentCachedBots && this.currentCachedBots.length > 0) {
          this.separateBots(this.currentCachedBots)
          return
        }

        this.getAllBots(false)
      }
      if (this.$route.params.page === 'subscribed') {
        this.isActiveBots = true
        this.getSubscribedBots()
      }
    },
    checkForActiveFilters() {
      if (this.currentActiveFilters.length > 0) {
        this.selectedFilters = this.selectedActiveFilters
        this.filterQuery = this.currentActiveFilters
      }
      if (this.currentSelectedExchanges.length > 0) {
        this.selectedFilters = this.selectedActiveFilters
        this.exchangeQuery = this.currentSelectedExchanges
      }
      if (this.currentROIInterval && Object.keys(this.currentROIInterval).length > 0) {
        this.timeframe = this.currentROIInterval
      }

      if (this.currentActiveTab.length > 0) {
        this.tabQuery = this.currentActiveTab
        switch (this.currentActiveTab[0]) {
          case 'index_fund':
            this.subtab = 1
            break
          case 'rebalancer':
            this.subtab = 2
            break
        }
      }

      if (this.currentActiveFilters.length > 0 || this.currentSelectedExchanges.length > 0 || this.currentActiveTab.length > 0) {
        this.getAllBots(true)
        return
      }
      if (Object.keys(this.currentROIInterval).length > 0) this.getAllBots(false)
    }
  }
}

</script>

<style scoped>
.test2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.bots-title-section {
  display: flex;
  flex-direction: column;
}

.bots-navigation {
  margin-top: 20px;
  width: 300px;
  display: flex;
  flex-direction: row;
  font-weight: 400;
}

.bot-navigation-item {
  padding: 15px;
  font-weight: 300;
  cursor: pointer;
  margin-right: 10px;
}

.bot-navigation-item:hover {
  background-color: var(--secondary-blue-hover);
  color: var(--text-secondary);
  border-radius: 10px;
}

.bots-navitagion-sub {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.bot-navigation-sub-item {
  padding: 5px 10px;
  font-weight: 300;
  cursor: pointer;
  font-size: 18px;
}

.bot-navigation-sub-item:hover {
  background-color: var(--secondary-blue-hover);
  color: var(--text-secondary);
  border-radius: 10px;
}

.selected {
  background-color: var(--widget-color);
  color: var(--white);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.05);
}

.selected:hover {
  background-color: var(--widget-color);
  color: var(--white);
}

.bots-title-bar {
  padding: 30px 0;
  height: 30px;
  font-size: 28px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.search-input {
  width: 400px;
  height: 40px;
  font-size: 18px;
  border-radius: 5px;
  background: var(--card-background);
  color: var(--text-primary);
  display: flex;
  align-items: center;
  padding: 5px 40px 5px 5px;
  border: 1px solid var(--border);
}

.search-input::placeholder {
  color: var(--text-primary);
}

.search-input:focus {
  outline: none;
}

.bots-content {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bot-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section-title {
  display: flex;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 500;
  font-family: 'Roboto Condensed', sans-serif;
}

.promoted-bots {
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--border);
}

.bot {
  margin: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

.bot-cards {
  height: auto;
  margin-bottom: 20px;
}

.bot-item {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

.bot-item-promoted {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

.no-bots-subs {
  width: 100%;
  text-align: center;
  margin: 20px auto;
  font-size: 28px;
}

.bot-cards-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.password-visibility-toggle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 10px;
  height: 20px;
  cursor: pointer;
}

.order-img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.order-by-return {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  transition: transform 0.2s;
  margin: 0 10px;
}

.order-by-return:hover {
  transform: scale(1.05);
}

.order-by-return-text {
  font-size: 12px;
  color: var(--text-primary);
}

.test {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.right {
  justify-content: flex-end;
  align-items: center;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filter-item {
  margin: 0 10px;
  padding: 5px 5px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--border);
  font-weight: bold;
  font-size: 16px;
}

.filter-remove {
  margin-left: 10px;
  height: 15px;
  width: 15px;
  background-color: var(--secondary-blue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  font-size: 20px;
  cursor: pointer;
}

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
}

/* .pagination {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
} */

@media (max-width: 1250px) {
  .bot {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 1150px) {
  .filters {
    margin: 0;
  }

  .order-by-return {
    margin-left: 10px;
  }

  .filter {
    width: 100%;
    margin: 5px 0px !important;
  }

  .test2 {
    flex-direction: column;
  }

  .test {
    width: 90%;
  }

  .right {
    flex-direction: column;
    justify-content: space-around;
  }

  .search-bar {
    margin-bottom: 10px;
    width: 100%;
  }

  .search-input {
    width: 100%;
  }

  .bots-title-bar {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }

  .bot-item-promoted,
  .bot-item {
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .bots-navitagion-sub {
    justify-content: center;
  }

  .bot-navigation-sub-item {
    font-size: 14px;
  }

  .bot-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bot-cards {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .bots-navigation {
    margin-top: 0;
    width: 100%;
    justify-content: center;
  }

  .pagination {
    justify-content: center;
  }
}
</style>
