<template>
  <div class="filter">
    <div id="filter" class="closed-filter" @click="toggleFilter()">
      <img class="filter-img" src="@/assets/filters.png" alt=""/>
    </div>
    <div id="openFilter" v-show="isOpen" class="open-filter card">
      <div v-click-outside="outside" class="filters" v-for="filter in options" :key="filter.filterName">
        <span class="filter-header">{{ filter.filterName }}</span>
        <div v-for="item in filter.filters" :key="item.value">
          <div class="filter-item multiselect-filter">
            <input style="cursor:pointer" type="checkbox" :id="item.value" @change="toggleCheckbox(item)"/>
            <label class="filter-item-title" :for="item.value">{{ item.value }}</label>
          </div>
        </div>
      </div>
      <span class="filter-clear" @click="clearFilter()">Clear filters</span>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Filters from '@/json/filters.json'

export default {
  data() {
    return {
      isOpen: false,
      selectedItem: false,
      options: Filters,
      selectedFilters: {
        risk: [],
        frequency: [],
        type: []
      }
    }
  },
  mounted() {
    this.popupItem = this.$el
  },
  directives: {
    ClickOutside
  },
  methods: {
    outside() {
      this.isOpen = false
    },
    toggleFilter() {
      this.isOpen = !this.isOpen
    },
    toggleCheckbox(item) {
      const isChecked = document.getElementById(item.value).checked

      if (isChecked) {
        this.addFilter(item)
      } else {
        this.removeFilter(item)
      }
    },
    addFilter(item) {
      switch (item.type) {
        case 0:
          this.selectedFilters.risk.push(item)
          break
        case 1:
          this.selectedFilters.frequency.push(item)
          break
        case 2:
          this.selectedFilters.type.push(item)
          break
      }

      this.$emit('updateFilter', this.selectedFilters)
    },
    removeFilter(item) {
      switch (item.type) {
        case 0:
          this.selectedFilters.risk.splice(
              this.selectedFilters.risk.indexOf(item),
              1
          )
          break
        case 1:
          this.selectedFilters.frequency.splice(
              this.selectedFilters.frequency.indexOf(item),
              1
          )
          break
        case 2:
          this.selectedFilters.type.splice(
              this.selectedFilters.type.indexOf(item),
              1
          )
          break
      }
      this.$emit('updateFilter', this.selectedFilters)
    },
    clearFilter() {
      this.selectedFilters.risk = []
      this.selectedFilters.type = []
      this.selectedFilters.frequency = []
      const vm = this
      Object.keys(this.options).forEach(key => {
        document.getElementById(vm.options[key].value).checked = false
      })

      this.$emit('updateFilter', this.selectedFilters)
    }
  }
}
</script>

<style scoped>
.filter {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--header-color);
}

.exchangeFilter {
  min-width: 220px;
}

.closed-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filter-img {
  height: 25px;
  width: 25px;
}

.selected {
  border: 2px solid #63ba62;
}

.filter-name {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.open-filter {
  min-width: 200px;
  right: 0;
  display: block;
  position: absolute;
  margin-top: 5px;
  background-color: #fff;
  padding: 5px 5px;
  z-index: 99999;
  border: 2px solid var(--header-color);
  border-radius: 5px;
}

.filter-item {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.single-select {
  border-radius: 5px;
  cursor: pointer;
}

.single-select:hover {
  background-color: #e8f6ff;
}

.filter-item-title {
  margin-left: 5px;
  text-align: left;
  cursor: pointer;
}

.filter-clear {
  font-size: 14px;
  text-decoration: underline;
  margin: 10px 0;
  color: var(--blue-primary);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.filter-header {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  width: 100%;
  color: var(--blue-primary);
}

.filters {
  border-bottom: 2px solid #e8f6ff;
}

@media (max-width: 850px) {
  .filter {
    margin: 5px;
  }
}
</style>
