<template>
  <div class="section-settings">
    <span class="title">Change Password</span>
    <span v-if="passwordUpdated" class="success">Password succesfully updated</span>
    <label class="contact-input">
      Current password:
      <span class="password-visibility-toggle">
        <input id="password" class="form-input" :class="{ errorInput: wrongPassword }" type="password" v-model="password" />
        <img v-if="isDarkTheme" class="password-toggle" src="@/assets/trading-platform/eye-white.png" alt @click="togglePasswordVisibility(0)" />
        <img v-else class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(0)" />
      </span>
    </label>
    <span v-if="wrongPassword" class="error">Wrong password</span>
    <label class="contact-input">
      New password:
      <span class="input-info">(At least 8 characters, one uppercase & one number)</span>
      <span class="password-visibility-toggle">
        <input id="new-password" class="form-input" :class="{ errorInput: wrongFormat }" type="password" v-model="newPassword" />
        <img v-if="isDarkTheme" class="password-toggle" src="@/assets/trading-platform/eye-white.png" alt @click="togglePasswordVisibility(1)" />
        <img v-else class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(1)" />
      </span>
    </label>
    <span v-if="wrongFormat" class="error">
      The password must contain at least 8 characters, one uppercase & one
      number
    </span>
    <label class="contact-input">
      Confirm new password:
      <span class="input-info">(At least 8 characters, one uppercase & one number)</span>
      <span class="password-visibility-toggle">
        <input id="confirm-password" class="form-input" :class="{ errorInput: notMatchPassword }" type="password" v-model="confirmPassword" />
        <img v-if="isDarkTheme" class="password-toggle" src="@/assets/trading-platform/eye-white.png" alt @click="togglePasswordVisibility(2)" />
        <img v-else class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(2)" />
      </span>
    </label>
    <span v-if="notMatchPassword" class="error">The passwords do not match</span>
    <div class="confirmation-buttons">
      <span class="form-button button-shadow" @click="changePassword()">SAVE</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChangePassword',
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      password: '',
      newPassword: '',
      confirmPassword: '',
      wrongPassword: false,
      wrongFormat: false,
      notMatchPassword: false,
      passwordVisible: false,
      passwordUpdated: false,
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  methods: {
    changePassword() {
      if (this.checkPassword()) {
        this.notMatchPassword = false
        this.wrongFormat = false
        if (this.newPassword === this.confirmPassword) {
          const body = {
            user_id: this.$session.get('uid'),
            current_password: this.password,
            new_password: this.newPassword
          }
          this.$api
            .post('/users/change_password', body, {
              headers: {
                'X-Authorization': this.$session.get('token')
              }
            })
            .then((res) => {
              if (res.status === 200) {
                this.passwordUpdated = true

                this.wrongPassword = false
                this.wrongFormat = false
                this.notMatchPassword = false

                this.password = ''
                this.newPassword = ''
                this.confirmPassword = ''
              }
            })
            .catch((err) => {
              if (err.response.data.error.type === 'Could not validate credentials') {
                this.$session.destroy()
                this.$router.push({ name: 'Login' })
              }
              if (err.response.data.error.type === "NOT_FOUND") {
                this.wrongPassword = true
              }
            })
        } else {
          this.notMatchPassword = true
          this.wrongFormat = false
        }
      } else {
        this.wrongFormat = true
      }
    },
    checkPassword() {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,128}$/
      return passwordRegex.test(this.newPassword)
    },
    togglePasswordVisibility(e) {
      this.passwordVisible = !this.passwordVisible
      switch (e) {
        case 0:
          if (document.getElementById('password').type === 'password') {
            document.getElementById('password').type = 'text'
          } else {
            document.getElementById('password').type = 'password'
          }
          break
        case 1:
          if (document.getElementById('new-password').type === 'password') {
            document.getElementById('new-password').type = 'text'
          } else {
            document.getElementById('new-password').type = 'password'
          }
          break
        case 2:
          if (document.getElementById('confirm-password').type === 'password') {
            document.getElementById('confirm-password').type = 'text'
          } else {
            document.getElementById('confirm-password').type = 'password'
          }
          break
      }
    }
  }
}
</script>

<style scoped>
.section-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.password-visibility-toggle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 30px;
  cursor: pointer;
}

.input-info {
  opacity: 0.7;
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }
}
</style>
