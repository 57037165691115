<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header-date">
                <div class="modal-header">
                  <div class="modal-title">
                    <span>Thank you for your purchase!</span>
                  </div>
                  <div class="subtext">
                  </div>
                </div>
                <div class="buttons">
                  <div class="buttons">
                    <span class="button-p button-shadow" @click="goToMarketPlace()">Go to Subscriptions</span>
                    <span class="to-dashboard" @click="goToDashboard()">Go to dashboard</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    goToMarketPlace() {
      this.$emit('click')
      //this.$router.push({ name: 'Bots', params: { page: 'subscriptions' } })
    },
    goToDashboard() {
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 34px;
  font-weight: bold;
}

.subtext {
  font-size: 24px;
}

.modal-container {
  width: 700px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 20px;
}

.modal-close {
  font-size: 50px;
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -10px;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.to-dashboard {
  color: var(--blue-primary);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: 300px;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }
}
</style>
