<template>
  <div>
    <loading class="loading" :active="loading" />
    <div v-show="!loading" class="dashboard">
      <div class="dashboard-content">
        <div class="top-content">
          <div class="left-content">
            <div class="dashboard-title">
              <span class="title errors">
                Dashboard
                <span v-if="activeErrors" class="active-errors">
                  <img
                    class="title-error-icon"
                    src="@/assets/notification/warning.png"
                    @mouseover="toggleErrorTooltip()"
                    @mouseleave="toggleErrorTooltip()"
                    alt="tooltip"
                  />
                </span>
              </span>
              <span v-if="showErrorTooltip" class="error-tooltip"
                >There are some errors with one of your bot(-s). Please check
                error log down below</span
              >
            </div>
            <div class="overview card">
              <div class="top-bar">
                <div class="left">
                  <div
                    v-if="subbedBots.length > 0"
                    class="section-title overview-title"
                    @click="toggleOverviewFilter()"
                  >
                    <span v-if="!selectedOverviewFilter.bot_name"
                      >Total Balance</span
                    >
                    <span v-else>{{ selectedOverviewFilter.bot_name }}</span>
                    <img
                      v-if="!isDarkTheme"
                      height="20"
                      src="@/assets/filterArrowOpen.png"
                      alt=""
                    />
                    <img
                      v-else
                      height="20"
                      src="@/assets/filterArrowOpen-white.png"
                      alt=""
                    />
                  </div>
                  <div v-else class="section-title overview-title">
                    <span>Total Balance</span>
                  </div>
                  <span v-if="!selectedOverviewFilter.bot_name">
                    <img
                      v-if="isDarkTheme"
                      class="refresh-icon"
                      src="@/assets/reset-white.png"
                      alt=""
                      @click="toggleResetPNLModal()"
                    />
                    <img
                      v-else
                      class="refresh-icon"
                      src="@/assets/refresh.png"
                      alt=""
                      @click="toggleResetPNLModal()"
                    />
                  </span>
                  <div
                    v-click-outside="outside"
                    v-if="overviewFilterOpen"
                    class="overview-filter card"
                  >
                    <div
                      class="overview-filter-bot"
                      :class="{
                        selectedFilter:
                          Object.keys(selectedOverviewFilter).length === 0
                      }"
                      @click="selectOverviewFilter(0)"
                    >
                      <span
                        v-if="Object.keys(selectedOverviewFilter).length === 0"
                        class="checkmark"
                        >✓</span
                      >
                      Total Balance
                    </div>
                    <div
                      class="overview-filter-bot"
                      :class="{
                        selectedFilter:
                          item.bot_name === selectedOverviewFilter.bot_name
                      }"
                      v-for="item in subbedBots"
                      :key="item.bot_id"
                      @click="selectOverviewFilter(item)"
                    >
                      <span
                        v-if="item.bot_name === selectedOverviewFilter.bot_name"
                        class="checkmark"
                        >✓</span
                      >
                      <span>{{ item.bot_name }}</span>
                    </div>
                  </div>
                </div>
                <time-filter-dropdown
                  class="dropdown"
                  :isBotGraph="botOverview"
                  :dashboard="true"
                  @updateTimeFrame="updateGraphTimeFrame($event)"
                />
              </div>
              <div class="overview-content">
                <div class="overview-content-graph">
                  <LineGraph
                    v-show="subbedBots.length > 0"
                    class="overview-content-graph"
                    ref="dashboardGraph"
                    :isBotGraph="botOverview"
                    :subBotGraph="true"
                    :interval="graphTimeframe"
                    :id="'dashboard-overview'"
                    :graphData="graphData"
                    :graphLabels="graphLabels"
                  />
                  <div
                    v-show="subbedBots.length < 1 || this.graphData.length < 1"
                    class="no-active-bots"
                  >
                    <span v-if="subbedBots.length < 1"
                      >No bots currently active</span
                    >
                    <span
                      v-if="subbedBots.length > 0 && this.graphData.length < 1"
                      >No data yet available</span
                    >
                    <br />
                    <span
                      v-if="subbedBots.length < 1"
                      class="button-p button-shadow"
                      @click="openNewBotModal()"
                      >Connect your first bot</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <div class="overview-positions">
              <span class="section-title overview-title"
                >Historical Positions</span
              >
              <bot-filter
                class="bot-filter"
                v-if="this.subbedBots.length > 0"
                :item-list="subbedBots"
                @selectItem="selectHistoricalPositionBot($event)"
              />
            </div>
            <historical-positions
              class="historical-positions"
              :bot="historicalPositionBot"
            />
          </div>
        </div>
        <div class="bottom-content">
          <div class="dashboard-nav">
            <span
              class="dashboard-nav-item"
              :class="{ selected: bottomNav === 0 }"
              @click="toggleBottomNav(0)"
              >Active Positions</span
            >
            <span
              class="dashboard-nav-item"
              :class="{ selected: bottomNav === 2 }"
              @click="toggleBottomNav(1)"
              >Balance Allocation</span
            >
            <span
              class="dashboard-nav-item"
              :class="{ selected: bottomNav === 3 }"
              @click="toggleBottomNav(2)"
              >Active Bots</span
            >
            <span
              class="dashboard-nav-item error-tab"
              :class="{ selected: bottomNav === 4 }"
              @click="toggleBottomNav(3)"
            >
              Errors
              <span v-if="activeErrors" class="active-errors">
                (<img
                  class="error-icon"
                  src="@/assets/notification/warning.png"
                  alt=""
                />{{ this.activeErrorsNr }})
              </span>
            </span>
          </div>
          <div class="dashboard-table card">
            <v-data-table
              ref="chart"
              :headers="headers"
              :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
              :items-per-page="10"
              :items="tableItems"
              :sort-by="['pnlPct']"
              :sort-desc="true"
              item-key="name"
              no-data-text="No data yet available"
              class="table"
            >
              <template v-slot:item.total_pnl_pct="{ item }">
                <span class="exchange">
                  <span
                    v-if="item.total_pnl_pct"
                    class="positive"
                    :class="{ negative: item.total_pnl_pct < 0 }"
                    >{{ item.total_pnl_pct.toFixed(2) }}%</span
                  >
                  <span v-else class="positive">0%</span>
                </span>
              </template>
              <template v-slot:item.exchange="{ item }">
                <span class="exchange" :title="item.connection_name">
                  <img
                    class="exchange-logo"
                    :src="loadExchangeIcon(item.exchange)"
                    alt
                  />
                  <span :title="item.connection_name">
                    {{
                      item.connection_name.length > 20
                        ? item.connection_name.substr(0, 15) + '...'
                        : item.connection_name
                    }}
                  </span>
                </span>
              </template>
              <template v-slot:item.qty="{ item }">
                <span class="exchange">
                  <span>{{ item.qty }}</span>
                </span>
              </template>
              <template v-slot:item.total_pct="{ item }">
                <span class="exchange">
                  <span>{{ item.total_pct.toFixed(2) }}%</span>
                </span>
              </template>
              <template v-slot:item.pnlPct="{ item }">
                <span class="exchange">
                  <span class="positive" :class="{ negative: item.pnlPct < 0 }"
                    >{{ item.pnlPct.toFixed(2) }}%</span
                  >
                </span>
              </template>
              <template v-slot:item.positionPct="{ item }">
                <span class="exchange">
                  <span>{{ item.positionPct.toFixed(2) }}%</span>
                </span>
              </template>
              <template v-slot:item.bot_name="{ item }">
                <span class="exchange">
                  <img
                    class="exchange-symbol"
                    src="@/assets/bots/bot_template.png"
                    alt=""
                  />
                  <span :title="item.bot_name">{{ item.bot_name }}</span>
                  <img
                    class="bot-link"
                    src="@/assets/trading-platform/bot-link.png"
                    alt
                    @click="goToSubbedBot(item)"
                  />
                </span>
              </template>
              <template v-slot:item.active="{ item }">
                <span class="exchange">
                  <span class="active-bot" v-if="item.active">Active</span>
                  <span class="paused-bot" v-else>Paused</span>
                </span>
              </template>
              <template v-slot:item.symbol="{ item }">
                <span class="exchange">
                  <img
                    class="exchange-symbol"
                    :src="loadCoinIcon(item.symbol)"
                    alt=""
                  />
                  <span class="exchange-symbol-text">{{ item.symbol }}</span>
                </span>
              </template>
              <template v-slot:item.error_actions="{ item }">
                <span class="exchange">
                  <span class="mark-read" @click="toggleErrorModal(item)"
                    >Clear</span
                  >
                </span>
              </template>
              <template v-slot:item.datetime="{ item }">
                <span class="exchange">
                  <span>{{
                    item.datetime.split('+')[0].substring(0, 16)
                  }}</span>
                </span>
              </template>
              <template v-slot:item.direction="{ item }">
                <span class="exchange">
                  <span>{{
                    item.direction.split('_')[0][0].toUpperCase() +
                      item.direction.split('_')[0].substr(1)
                  }}</span>
                </span>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
      <new-bot-modal v-if="newBotModal" @click="closeModal()"></new-bot-modal>
      <error-modal
        v-if="showErrorModal"
        :error="selectedError"
        @click="toggleErrorModal($event)"
      ></error-modal>
      <ResetPNLModal
        v-if="resetPNLModalActive"
        @click="toggleResetPNLModal()"
        @resetPNL="resetPNL()"
      />
      <PaypalPaymentModal
        v-if="fromPaypalPayment"
        :status="paypalPaymentStatus"
        @close="closePaypalModal()"
      />
    </div>
  </div>
</template>

<script>
import TimeFilterDropdown from '@/components/trading/TimeFilterDropdown'
import NewBotModal from '@/components/shared/modals/NewBotModal'
import StatsCard from '@/components/trading/statistics/StatsCard.vue'
import { mapActions, mapGetters } from 'vuex'
import ArrowHeadDown from '@/components/shared/ArrowHeadDown.vue'
import Loading from '@/components/shared/Loading.vue'
import HistoricalPositions from '@/components/trading/HistoricalPositions'
import ErrorModal from '@/components/shared/modals/ErrorModal'
import LineGraph from '@/components/shared/LineGraph'
import DashboardMetrics from '@/components/trading/DashboardMetrics.vue'
import BotFilter from '@/components/trading/BotFilter'
import ResetPNLModal from '@/components/shared/modals/ResetPNLModal'
import PaypalPaymentModal from '@/components/shared/modals/PaypalPaymentModal.vue'

export default {
  computed: mapGetters([
    'userBalanceAllocation',
    'selectedConnection',
    'darkTheme'
  ]),
  name: 'Dashboard',
  components: {
    BotFilter,
    TimeFilterDropdown,
    NewBotModal,
    StatsCard,
    ArrowHeadDown,
    Loading,
    HistoricalPositions,
    ErrorModal,
    LineGraph,
    DashboardMetrics,
    ResetPNLModal,
    PaypalPaymentModal
  },
  data() {
    return {
      isDarkTheme: localStorage.boostingAlphaDarkTheme,
      overviewFilterOpen: false,
      overviewFilter: [],
      overviewGraphLabels: [],
      overviewGraphReturnData: [],
      isBeta: false,
      returnGraph: true,
      bottomNav: 0,
      overviewData: [],
      overviewLabels: [],
      graphData: [],
      graphLabels: [],
      newBotModal: false,
      chosenBot: {},
      subscribedBots: [],
      subbedBots: [],
      tableOptionsSmallScreen: [
        { value: 0, name: 'Active Bots' },
        { value: 1, name: 'Balance Breakdown' },
        { value: 2, name: 'Transaction History' },
        { value: 2, name: 'Positions' }
      ],
      tableItems: [],
      headers: [],
      positionHeaders: [
        {
          text: 'Bot Name',
          align: 'start',
          sortable: false,
          width: '20%',
          value: 'botName'
        },
        { text: 'Symbol', value: 'symbol', sortable: false, width: '20%' },
        {
          text: 'P/L Percentage',
          value: 'pnlPct',
          sortable: true,
          width: '20%'
        },
        {
          text: 'Direction',
          value: 'direction',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Allocated Percentage',
          value: 'positionPct',
          sortable: false,
          width: '20%'
        }
      ],
      balanceHeaders: [
        { text: 'Symbol', sortable: false, value: 'symbol', width: '50%' },
        { text: 'Quantity', sortable: false, value: 'qty', width: '50%' }
      ],
      botsHeaders: [
        { text: 'Bot Name', value: 'bot_name', sortable: false, width: '25%' },
        {
          text: 'Allocated',
          value: 'allocated',
          sortable: false,
          width: '25%'
        },
        { text: 'Exchange', value: 'exchange', sortable: false, width: '25%' },
        { text: 'Status', value: 'active', sortable: false, width: '25%' }
      ],
      errorHeaders: [
        { text: 'Date', value: 'datetime', sortable: false, width: '25%' },
        { text: 'Bot Name', value: 'bot_name', sortable: false, width: '25%' },
        {
          text: 'Error',
          value: 'error_message',
          sortable: false,
          width: '25%'
        },
        {
          text: 'Actions',
          value: 'error_actions',
          sortable: false,
          width: '25%'
        }
      ],
      allocatedBalance: [],
      userPositions: [],
      graphTimeframe: {
        value: 6,
        name: 'All Time',
        time: '50 Years'
      },
      selectedOverviewFilter: {},
      chart: null,
      chartLabelFont: 14,
      loading: false,
      activeErrors: false,
      activeErrorsNr: 0,
      showErrorTooltip: false,
      showErrorModal: false,
      selectedError: {},
      userTotal: {
        total_allocated: 0,
        total_return_qty: 0,
        total_return_pct: 0
      },
      botOverview: false,
      historicalPositionBot: {},
      resetPNLModalActive: false,
      fromPaypalPayment: false,
      paypalPaymentStatus: ''
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
      this.loadCharts()
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'Settings') {
        window.location.reload()
      }
    })
  },
  mounted() {
    if (this.$route.query.payment) {
      this.loading = true
      this.fromPaypalPayment = true
      this.paypalPaymentStatus = this.$route.query.payment
    }
    if (this.$route.query.status) {
      this.fromPaypalPayment = true
      this.paypalPaymentStatus = this.$route.query.status
    }
    this.getDashboardData()
  },
  methods: {
    ...mapActions([
      'loadExchangeConnections',
      'loadSubscribedBots',
      'loadBalanceAllocation',
      'loadTotal'
    ]),
    selectHistoricalPositionBot(item) {
      this.historicalPositionBot = item
    },
    closePaypalModal() {
      this.fromPaypalPayment = false
    },
    toggleResetPNLModal() {
      this.resetPNLModalActive = !this.resetPNLModalActive
    },
    resetPNL() {
      this.getDashboardData()
    },
    outside() {
      this.overviewFilterOpen = false
    },
    toggleErrorTooltip() {
      this.showErrorTooltip = !this.showErrorTooltip
    },
    toggleErrorModal(item) {
      if (item === 1) {
        this.showErrorModal = !this.showErrorModal
        this.getDashboardData()
        return
      }
      this.selectedError = item
      this.showErrorModal = !this.showErrorModal
    },
    loadCoinIcon(coin) {
      let temp = coin
      if (coin.includes('-')) temp = coin.split('-')[0]
      if (coin.includes('USDT')) temp = coin.split('USDT')[0]
      try {
        return require('/node_modules/cryptocurrency-icons/32/color/' +
          temp.toLowerCase() +
          '.png')
      } catch (e) {
        return require('/node_modules/cryptocurrency-icons/32/color/generic.png')
      }
    },
    loadExchangeIcon(exchange) {
      try {
        return require('@/assets/' + exchange.toLowerCase() + '-logo.png')
      } catch (e) {}
    },
    loadBotIcon(botName) {
      // const bot_name = botName.split(' ').join('')
      return require('@/assets/bots/bot_template.png')
    },
    // updates the time filter for the dashboard page
    updateGraphTimeFrame(timeframe) {
      this.overviewData = []
      this.overviewLabels = []

      this.graphTimeframe = timeframe
      this.loadCharts()
    },
    loadCharts() {
      let botId
      this.selectedOverviewFilter.bot_id
        ? (botId = this.selectedOverviewFilter.bot_id)
        : (botId = 'Total Balance')

      this.$api
        .get('/bots/get_chart', {
          params: {
            user_id: this.$session.get('uid'),
            timezone: this.$session.get('timezone'),
            bot_id: botId,
            interval: this.graphTimeframe.time
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.populateGraphData(res.data.data)
          }
        })
        .catch(err => {
          if (
            err.response &&
            err.response.data.error.type === 'Could not validate credentials'
          ) {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    // populates the overview graph
    populateGraphData(res) {
      this.overviewData = []
      this.overviewLabels = []
      this.graphData = []
      this.graphLabels = []
      let counter = 0

      for (const key in res) {
        if (!this.overviewLabels.includes(key.split(' ')[0])) {
          this.overviewLabels.push(key.split(' ')[0])
          this.overviewData.push(res[key])
          counter++
        } else {
          this.overviewData.splice(counter - 1, 1)
          this.overviewLabels.splice(counter - 1, 1)
          this.overviewLabels.push(key.split(' ')[0])
          this.overviewData.push(res[key])
        }
      }

      this.graphData = this.overviewData
      this.graphLabels = this.overviewLabels
    },
    // api call to get dashboard data
    getDashboardData() {
      this.selectedError = {}
      this.loading = true
      this.allocatedBalance = []
      this.userPositions = []
      this.subbedBots = []
      this.tableItems = []
      this.activeErrors = false
      this.$api
        .get('/bots/get_dashboard', {
          params: {
            user_id: this.$session.get('uid'),
            interval: this.graphTimeframe.time,
            timezone: this.$session.get('timezone')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.loading = false

            this.userTotal.total_allocated =
              res.data.data.exchange_account.total_allocated
            this.userTotal.total_return =
              res.data.data.exchange_account.total_return_qty
            this.userTotal.total_return_pct =
              res.data.data.exchange_account.total_return_pct
            this.subbedBots = res.data.data.bots

            this.loadSubscribedBots(this.subbedBots)
            this.getBalanceAllocation(res.data.data.exchange_account.allocation)
            this.getUserPositions(res.data.data.bots)
            this.loadErrors(res.data.data.bots)

            this.loadCharts()
          }
        })
        .then(() => {
          this.toggleBottomNav(0)
          this.$refs.dashboardGraph.generateGraph()
        })
        .catch(err => {
          this.loading = false
          if (
            err.response &&
            err.response.data.error.type === 'Could not validate credentials'
          ) {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    getBalanceAllocation(allocated) {
      for (const key in allocated) {
        const temp = {
          symbol: key,
          qty: allocated[key].qty,
          total_pct: allocated[key].total_pct
        }
        this.allocatedBalance.push(temp)
      }
    },
    getUserPositions(bots) {
      Object.keys(bots).forEach(key => {
        const name = bots[key].bot_name
        const botPositions = bots[key].positions
        const positionSymbol = Object.getOwnPropertyNames(botPositions)
        let counter = 0
        Object.keys(botPositions).forEach(i => {
          const temp = {
            botName: name,
            symbol: positionSymbol[counter],
            avgPrice: botPositions[i].average_price,
            positionPct: botPositions[i].position_pct,
            pnlPct: botPositions[i].unrealized_pnl_pct,
            direction: botPositions[i].direction
          }
          this.userPositions.push(temp)
          counter++
        })
      })
    },
    loadErrors(bots) {
      this.errors = []
      for (const key in bots) {
        let temp = {}
        temp.bot_id = bots[key].bot_id
        temp.bot_name = bots[key].bot_name
        temp.connection_id = bots[key].connection_id

        for (const key2 in bots[key].errors) {
          temp.datetime = bots[key].errors[key2].datetime
          temp.error_message = bots[key].errors[key2].error_name
          if (temp.error_message) this.errors.push(temp)
        }
      }
      if (this.errors.length > 0) {
        this.activeErrors = true
        this.activeErrorsNr = this.errors.length
      }
    },
    openNewBotModal() {
      // this.newBotModal = true
      this.$router.push({ name: 'Bots', params: { page: 'marketplace' } })
    },
    closeModal() {
      this.newBotModal = false
      this.botModal = false
    },
    toggleBottomNav(e) {
      switch (e) {
        case 0:
          this.bottomNav = 0
          this.headers = this.positionHeaders
          this.tableItems = this.userPositions
          break
        case 1:
          this.bottomNav = 2
          this.headers = this.balanceHeaders
          this.tableItems = this.allocatedBalance
          break
        case 2:
          this.bottomNav = 3
          this.tableItems = this.subbedBots
          this.headers = this.botsHeaders
          break
        case 3:
          this.bottomNav = 4
          this.tableItems = this.errors
          this.headers = this.errorHeaders
          break
      }
    },
    loadBadge(item) {
      const path = `assets/${item}.png`
      return require(`@/${path}`)
    },
    toggleOverviewFilter() {
      this.overviewFilterOpen = !this.overviewFilterOpen
    },
    selectOverviewFilter(item) {
      this.overviewFilterOpen = !this.overviewFilterOpen
      this.selectedOverviewFilter = item

      this.botOverview = true

      if (item === 0) {
        this.botOverview = false
      }
      this.loadCharts()
    },
    goToSubbedBot(item) {
      this.$router.push({
        name: 'SubscribedBot',
        params: {
          name: item.bot_name,
          subBot: item
        }
      })
    }
  }
}
</script>

<style scoped>
.dashboard {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}

.title-error-icon {
  height: 30px;
}

.errors {
  display: flex;
  gap: 10px;
}

.error-tooltip {
  color: var(--header-color);
  padding: 5px;
  margin-top: 40px;
  margin-left: 30px;
  height: auto;
  width: 250px;
  background-color: var(--white);
  border: 1px solid var(--secondary-blue);
  position: absolute;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 300;
  z-index: 999;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
}

.top-content {
  height: 665px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.left-content {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overview {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: var(--card-background);
}

.right-content {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.historical-positions {
  margin-top: 20px;
}

.overview-positions {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.overview-content {
  height: 90%;
}

.section-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-header);
  font-family: 'Roboto Condensed', sans-serif;
}

.overview-content-graph {
  width: 100%;
  height: 100%;
}

.mark-read {
  color: var(--primary-blue);
  text-decoration: underline;
  cursor: pointer;
}

.overview-graph-toggle {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.overview-graph-toggle-title {
  padding: 0 10px;
  cursor: pointer;
}

.overview-graph-toggle-title:first-child {
  border-right: 1px solid var(--border);
}

.bottom-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboard-nav {
  margin: 20px 0;
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
}

.dashboard-nav-item {
  color: var(--text-primary);
  font-size: 22px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 30px;
}

.dashboard-nav-item:hover {
  color: var(--text-primary-hover);
}

.selected {
  color: var(--primary-blue);
}

.selectedGraph {
  font-weight: bold;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.overview-button {
  margin-left: 20px;
}

.border {
  border: 2px solid var(--border);
  background-color: var(--white);
}

.no-active-bots {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.dropdown-table {
  display: none;
}

.exchange {
  display: flex;
  align-items: center;
}

.exchange-logo {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.split {
  display: flex;
  flex-direction: column;
}

.overview-title {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.arrow-head {
  margin-left: 10px;
}

.overview-filter {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  background-color: var(--background);
  padding: 2px 2px;
  z-index: 99999;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 5px;
  max-height: 300px;
  overflow-y: auto;
  top: 250px;
}

.overview-filter-bot {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
}

.selectedFilter {
  background-color: var(--primary-blue);
  color: var(--button-text);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
}

.checkmark {
  color: var(--white);
  font-size: 16px;
}

.overview-filter-bot:hover {
  background-color: var(--selected);
  color: var(--text-secondary);
}

.selectedFilter:hover {
  background-color: var(--primary-blue);
  color: var(--button-text);
}

.overview-filter-bot-overview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-blue);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}

.bot-link {
  height: 15px;
  width: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.active-bot {
  width: 65px;
  display: flex;
  justify-content: center;
  padding: 2px 5px;
  color: var(--green);
  border-radius: 5px;
  font-weight: bold;
}

.paused-bot {
  width: 65px;
  display: flex;
  justify-content: center;
  padding: 2px 5px;
  color: var(--red);
  border-radius: 5px;
  font-weight: bold;
}

.exchange-symbol {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.exchange-symbol-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.error-tab {
  display: flex;
}

.error-icon {
  height: 20px;
}

.active-errors {
  display: flex;
  align-items: center;
}

.refresh-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

@media (max-width: 1635px) {
  .top-content {
    height: auto;
    flex-direction: column;
  }

  .left-content {
    width: 100%;
  }

  .right-content {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .dashboard-title {
    width: 100%;
    flex-direction: column;
    gap: 0px;
  }

  .dashboard-nav-item {
    font-size: 16px;
  }

  .top-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .overview-positions {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
