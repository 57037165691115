<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header-date">
                <div class="modal-header">
                  <span>Activate your FREE one month trial</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="checkout-input">
              <loading class="loading" :active="loading" />
              <div v-show="!loading && !trialActive">
                <span style="font-weight:bold">NOTE: You will not be charged for activating your trial</span>
                <br>
                <div class="user-form-input">
                  <span>Name on card</span>
                  <input class="form-input" :class="{ errorInput: false }" v-model="nameOnCard" type="text" placeholder="Name on card" />
                </div>
                <div class="credit-card" v-show="!loading">
                  <span>Credit Card information</span>
                </div>
                <div v-show="!loading && !trialActive" class="card-info">
                  <img style="height:50px" src="@/assets/payment-methods/credit-card.png" alt="">
                  <div class="form-input card-info-largecard-info-small" id="card-number"></div>
                  <div class="form-input card-info-small expiry" id="card-expiry"></div>
                  <div class="form-input card-info-small" id="card-cvc"></div>
                  <div id="card-errors"></div>
                </div>
                <span v-if="errMsg" class="error">{{ errMsg }}</span>
              </div>
              <span v-if="trialActive" class="success">A one month free trial has been activated for your next subscription</span>
            </div>
            <div v-show="!loading" class="payment">
              <div class="payment-buttons cart-pay">
                <div v-if="!trialActive" class="button-p button-shadow payment-b free-trial" @click="activateTrial()">
                  <span>Activate FREE one month trial</span>
                </div>
                <div v-else class="button-p button-shadow payment-b free-trial" @click="closeModal()">
                  <span>Close</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue'
import { mapActions } from "vuex";

export default {
  name: 'Modal',
  components: {
    Loading
  },
  data() {
    return {
      nameOnCard: '',
      loading: false,
      errMsg: false,
      userCard: null,
      stripe: null,
      elements: null,
      trialActive: false
    }
  },
  mounted() {
    this.loadStripe()
  },
  methods: {
    ...mapActions(['addNotification']),
    closeModal() {
      this.$emit('close')
    },
    activateTrial() {
      this.loading = true
      this.errorMsg = ''
      let body = {
        user_id: this.$session.get('uid')
      }
      this.$api
        .post('/subscriptions/stripe/setup_trial_period', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.cardIntentPaymentTrial(res.data.data.intent.client_secret)
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response.data.error.type === 'FORBIDDEN') {
            this.errMsg = 'You have already activated your trial period'
          }
        })
    },
    async cardIntentPaymentTrial(client_secret) {
      await this.stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: this.userCard
        },
      }).then((result) => {
        if (result.error) {
          this.loading = false
          this.errmsg = 'Enter correct credit card details to unlock you free trial'
        } else {
          // The payment has been processed!
          if (result.setupIntent.status === 'succeeded') {
            let body = {
              user_id: this.$session.get('uid'),
              payment_method_id: result.setupIntent.payment_method
            }
            this.$api.post('/subscriptions/stripe/activate_trial_period', body, {
              headers: {
                'X-Authorization': this.$session.get('token')
              }
            })
              .then((res) => {
                if (res.status === 200) {
                  this.loading = false
                  this.getUser()
                  const notification = {
                    status: 0,
                    title: 'Free trial activated',
                    text: 'A one month free trial has been activated for your next subscription'
                  }
                  this.addNotification(notification)
                  this.closeModal()
                }
              })
              .catch((err) => {
                this.loading = false
                if (err.response.data.error.type === 'FORBIDDEN') {
                  this.errMsg = err.response.data.error.body
                }
              })
          }
        }
      })
    },
    async loadStripe() {
      const Stripe = require('@stripe/stripe-js')
      this.stripe = await Stripe.loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
      this.elements = this.stripe.elements()
      this.createCardElement()
    },
    async createCardElement() {
      const style = {
        base: {
          color: '#2c3e50',
          fontWeight: 500,
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF'
          },
          ':-webkit-autofill': {
            color: '#e39f48'
          }
        },
        invalid: {
          color: '#E25950',
          '::placeholder': {
            color: '#FFCCA5'
          }
        }
      }

      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid'
      }

      this.userCard = this.elements.create('cardNumber', {
        style,
        classes: elementClasses
      })
      this.userCard.mount('#card-number')

      const cardExpiry = this.elements.create('cardExpiry', {
        style,
        classes: elementClasses
      })
      cardExpiry.mount('#card-expiry')

      const cardCvc = this.elements.create('cardCvc', {
        style,
        classes: elementClasses
      })
      cardCvc.mount('#card-cvc')
    },
    getUser() {
      this.$api
        .get('/users/get_user', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$session.set('username', res.data.data.username)
            this.$session.set('email', res.data.data.email)
            this.$session.set('timezone', res.data.data.timezone)
            this.$session.set('trial_period_activated', res.data.data.trial_period_activated)
            this.$session.set('trial_period_used', res.data.data.trial_period_used)
          }
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
    },
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.forgot-password {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  min-height: 200px;
  height: auto;
  width: 700px;
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.forgotPasswordContainer {
  width: 600px;
}

.modal-close {
  margin-top: -20px;
  font-size: 40px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 80%;
  font-size: 28px;
  margin-top: 0;
}

.modal-content {
  height: 100%;
  margin-top: 20px;
  font-size: 16px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.loading-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-info-small {
  width: 20% !important;
}

.expiry {
  margin-right: 5px;
  margin-left: 5px;
}

.card-info-large {
  width: 40% !important;
}

.free-trial {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment {
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .modal-container {
    width: 95%;
    height: auto;
    overflow-y: auto;
    padding: 15px;
  }

  .content {
    padding: 10px 0;
  }
}
</style>
