<template>
  <div class="new-exchange-connection">
    <span class="button-p button-shadow back" @click="closeNewExchange()">&larr; Back</span>
    <span class="choose-exchange-title">Choose exchange:</span>
    <div class="exchanges">
      <div class="exchanges-options">
        <div class="exchanges-section">
          <span class="exchanges-section-title">Spots</span>
          <div class="exchanges-section-content">
            <div v-for="exchange in exchanges.filter(item => item.type === 0)" :key="exchange.name"
                 class="choose-exchange">
              <exchange-selector :exchange='exchange' :selected="selectedExchange"
                                 @selectExchange="setSelectedExchange($event)"/>
            </div>
          </div>
        </div>
        <div class="exchanges-section">
          <span class="exchanges-section-title">Futures</span>
          <div class="exchanges-section-content">
            <div v-for="exchange in exchanges.filter(item => item.type === 1)" :key="exchange.name"
                 class="choose-exchange">
              <exchange-selector :exchange='exchange' :selected="selectedExchange"
                                 @selectExchange="setSelectedExchange($event)"/>
            </div>
          </div>
        </div>
      </div>

      <ConnectBinanceAccount v-show="selectedExchange.id=== 0" @closeForm="closeForm()"
                             @success="confirmAccountAdded()"/>
      <ConnectBitvavoAccount v-show="selectedExchange.id === 1" @closeForm="closeForm()"
                             @success="confirmAccountAdded()"/>
      <!-- <connect-ftx-account v-show="selectedExchange.id === 2" @closeForm="closeForm()" @success="confirmAccountAdded()" /> -->
      <ConnectOkxAccount v-show="selectedExchange.id === 3" @closeForm="closeForm()" @success="confirmAccountAdded()"/>
      <ConnectBybitAccount v-show="selectedExchange.id === 4" @closeForm="closeForm()"
                           @success="confirmAccountAdded()"/>
      <ConnectKrakenAccount v-show="selectedExchange.id === 5" @closeForm="closeForm()"
                            @success="confirmAccountAdded()"/>
      <ConnectBitgetAccount v-show="selectedExchange.id === 6" @closeForm="closeForm()"
                            @success="confirmAccountAdded()"/>
      <ConnectKucoinAccount v-show="selectedExchange.id === 7" @closeForm="closeForm()"
                            @success="confirmAccountAdded()"/>
    </div>
  </div>
</template>

<script>
import ExchangeSelector from '@/components/shared/exchanges/ExchangeSelector.vue'
import ConnectBinanceAccount from '@/components/trading/settings/exchanges/ConnectBinanceAccount.vue'
import ConnectFtxAccount from '@/components/trading/settings/exchanges/ConnectFtxAccount.vue'
import ConnectBitvavoAccount from '@/components/trading/settings/exchanges/ConnectBitvavoAccount.vue'
import ConnectOkxAccount from '@/components/trading/settings/exchanges/ConnectOkxAccount.vue'
import ConnectBybitAccount from '@/components/trading/settings/exchanges/ConnectBybitAccount.vue'
import ConnectKrakenAccount from "@/components/trading/settings/exchanges/ConnectKrakenAccount.vue";
import ConnectBitgetAccount from "@/components/trading/settings/exchanges/ConnectBitgetAccount.vue";
import ConnectKucoinAccount from "@/components/trading/settings/exchanges/ConnectKucoinAccount.vue";

export default {
  name: 'NewExchange',
  components: {
    ConnectKucoinAccount,
    ConnectBitgetAccount,
    ConnectKrakenAccount,
    ConnectBinanceAccount,
    ExchangeSelector,
    ConnectFtxAccount,
    ConnectBitvavoAccount,
    ConnectOkxAccount,
    ConnectBybitAccount
  },
  data() {
    return {
      selectedExchange: {},
      exchanges: [
        {
          id: 0,
          name: 'Binance',
          logo: 'assets/binance-logo.png',
          type: 0
        },
        {
          id: 1,
          name: 'Bitvavo',
          logo: 'assets/bitvavo.png',
          type: 0
        },
        // {
        //   id: 2,
        //   name: 'FTX',
        //   logo: 'assets/ftx.png'
        // },
        {
          id: 3,
          name: 'OKX',
          logo: 'assets/okx_futures-logo.png',
          type: 1
        },
        {
          id: 4,
          name: 'Bybit',
          logo: 'assets/bybit_futures-logo.png',
          type: 1
        },
        {
          id: 5,
          name: 'Kraken',
          logo: 'assets/kraken_futures-logo.png',
          type: 1
        },
        {
          id: 6,
          name: 'Bitget',
          logo: 'assets/bitget_futures-logo.png',
          type: 1
        },
        {
          id: 7,
          name: 'Kucoin',
          logo: 'assets/kucoin-logo.png',
          type: 0
        },
      ]
    }
  },
  methods: {
    setSelectedExchange(exchange) {
      this.selectedExchange = exchange
    },
    confirmAccountAdded() {
      this.$emit('confirmAccount')
    },
    closeNewExchange() {
      this.$emit('closeNewExchange')
    },
    closeForm() {
      this.$emit('closeNewExchange')
    }
  }
}
</script>

<style scoped>
.exchanges {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exchanges-options {
  display: flex;
  gap: 60px;
}


.new-exchange-connection {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.choose-exchange-title {
  font-size: 28px;
}

.back {
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  cursor: pointer;
}

.exchanges-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exchanges-section-title {
  font-size: 18px;
  font-weight: bold;
}

.exchanges-section-content {
  display: flex;
  gap: 10px;
}
</style>
