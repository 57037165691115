<template>
  <div class="duration-selector">
    <div class="subscription-duration-type">
      <div v-if="!modify" class="subscription-duration-container card" :class="{ selected: selectedMethod === 1 }" @click="toggleSelected(1)">
        <div class="subscription-duration-container-info">
          <img class="tooltip" src="@/assets/tooltip-white.png" alt @mouseover="showSubscriptionDescription()" @mouseleave="showSubscriptionDescription()" />
          <span class="title subscription-duration-container-title">Reocurring (monthly)</span>
        </div>
        <span v-if="subscriptionDescription" class="subscription-duration-container-description">You will be billed the decided upon amount every month</span>
      </div>
    </div>
    <div class="subscription-duration-type">
      <div class="subscription-duration-type">
        <div class="subscription-duration-container card" :class="{ selected: selectedMethod === 2 }" @click="toggleSelected(2)">
          <div class="subscription-duration-container-info">
            <img class="tooltip" src="@/assets/tooltip-white.png" alt @mouseover="showOneTimeDescription()" @mouseleave="showOneTimeDescription()" />
            <span v-if="!selectedName" class="title subscription-duration-container-title">Fixed duration</span>
            <span v-else class="title subscription-duration-container-title">{{selectedName}}</span>
          </div>
          <span v-if="oneTimeDescription" class="subscription-duration-container-description">
            At the end of the selected duration the bot will no longer run, you
            can extend the duration in the subscription overview
          </span>
        </div>
        <div v-if="openOneTimeOptions" class="one-time-durations">
          <div class="one-time-duration" :class="{ oneTimeSelected: selectedOneTime === 1 }" @click="selectOneTime(1)">
            <span>1 Month</span>
          </div>
          <div class="one-time-duration" :class="{ oneTimeSelected: selectedOneTime === 2 }" @click="selectOneTime(2)">
            <span>3 Months</span>
          </div>
          <div class="one-time-duration" :class="{ oneTimeSelected: selectedOneTime === 3 }" @click="selectOneTime(3)">
            <span>6 Months</span>
          </div>
          <div class="one-time-duration" :class="{ oneTimeSelected: selectedOneTime === 4 }" @click="selectOneTime(4)">
            <span>Custom</span>
          </div>
          <div v-if="modify" class="one-time-duration" :class="{ oneTimeSelected: selectedOneTime === 5 }" @click="selectOneTime(5)">
            <span>Dont extend</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedMethod: 0,
      subscriptionDescription: false,
      oneTimeDescription: false,
      selectedOneTime: 0,
      selectedName: '',
      openOneTimeOptions: false,
      isCustom: false
    }
  },
  methods: {
    showSubscriptionDescription() {
      this.subscriptionDescription = !this.subscriptionDescription
    },
    showOneTimeDescription() {
      this.oneTimeDescription = !this.oneTimeDescription
    },
    // update selector to display as selected
    toggleSelected(e) {
      this.openOneTimeOptions = false
      this.selectedName = ''
      this.selectedMethod = e
      if (this.selectedMethod === 1) {
        this.$emit('updateSelector', 0)
        this.selectedOneTime = 0
      }
      if (this.selectedMethod === 2) {
        this.openOneTimeOptions = !this.openOneTimeOptions
      }
    },
    selectOneTime(id) {
      this.isCustom = false
      switch (id) {
        case 1:
          this.selectedName = '1 Month'
          break
        case 2:
          this.selectedName = '3 Months'
          break
        case 3:
          this.selectedName = '6 Months'
          break
        case 4:
          this.selectedName = 'Custom'
          break
        case 5:
          this.selectedName = 'Dont extend'
          break
      }
      this.openOneTimeOptions = false
      this.selectedOneTime = id
      this.$emit('updateSelector', id)
    }
  }
}
</script>

<style scoped>
.duration-selector {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.subscription-duration-type {
  display: flex;
  flex-direction: column;
}

.subscription-duration-container {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  height: 55px;
  width: 300px;
  background-color: var(--header-color);
  cursor: pointer;
  padding: 2px 10px;
  color: #ffffff;
}

.subscription-duration-container-info {
  display: flex;
  align-items: center;
}

.subscription-duration-container-title {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 30px;
  margin-left: 10px;
}

.subscription-duration-container-description {
  padding: 5px;
  margin-top: 40px;
  width: 200px;
  background-color: var(--white);
  border: 1px solid var(--header-color);
  position: absolute;
  border-radius: 5px;
  box-shadow: 4px 4px 4px var(--shadow);
  font-size: 14px;
  font-weight: 300;
  color: var(--header-color);
}

.selected {
  background-color: #1cc07b;
}

.selectedBlue {
  background-color: var(--primary-blue);
}

.tooltip {
  height: 20px;
  width: 20px;
}

.one-time-durations {
  position: absolute;
  margin-top: 60px;
  padding: 10px 0;
  width: 300px;
  display: flex;
  background-color: var(--blue-primary);
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
}

.one-time-duration {
  cursor: pointer;
  color: #ffffff;
  padding: 10px;
}
.one-time-duration:hover {
  background-color: var(--header-color);
}

.oneTimeSelected {
  background-color: #1bc07b;
}
@media (max-width: 850px) {
  .duration-selector {
    flex-direction: column;
  }

  .subscription-duration-container {
    margin: 10px 0;
  }

  .title {
    margin-top: 0;
  }
}
</style>
