<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <span class="modal-title">Are you sure?</span>
              <div class="modal-header-date">
                <div class="modal-content">
                  <span>Are you sure you want to reset your profit and loss tracking? This will reset your historical performance tracking and new tracking will begin from today.</span>
                </div>
                <div class="buttons">
                  <span class="button-p button-shadow" @click="resetPNL()">Reset PNL</span>
                  <span class="close" @click="closeModal()">Close</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResetPNLModal',
  props: {
    enable: Boolean
  },
  data() {
    return {}
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    resetPNL() {
      const body = {
        user_id: this.$session.get('uid')
      }

      this.$api
          .post('/bots/reset_pnl_tracking', body, {
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.$emit('resetPNL')
              this.closeModal()
            }
          })
          .catch((err) => {
            if (err.response && err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })

    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
  font-weight: bold;
}

.modal-container {
  width: 600px;
  margin: 0 auto;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.modal-content {
  margin-top: 20px;
  font-size: 18px;
}

.modal-close {
  font-size: 30px;
  float: right;
  cursor: pointer;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.close {
  font-size: 18px;
  text-decoration: underline;
  color: var(--primary-blue);
  cursor: pointer;
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    padding: 5px;
  }
}
</style>
