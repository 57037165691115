<template>
  <div class="section-settings">
    <span class="title">Notifications preferences</span>
    <div v-if="!loading" class="notifications-settings">
      <div class="notifications-settings-item">
        <span class="notifications-settings-item-text">
          Trade notifications
        </span>
        <toggle-switch v-if="loadedValues" :switch-status-value="notifications.trade_notifications" @toggle="toggleSwitch(0)" />
      </div>
      <div class="notifications-settings-item">
        <span class="notifications-settings-item-text">
          System messages notifications
        </span>
        <toggle-switch v-if="loadedValues" :switch-status-value="notifications.system_notifications" @toggle="toggleSwitch(1)" />
      </div>
      <div class="notifications-settings-item">
        <span class="notifications-settings-item-text">News notifications</span>
        <toggle-switch v-if="loadedValues" :switch-status-value="notifications.news_notifications" @toggle="toggleSwitch(2)" />
      </div>
      <div class="notifications-settings-item">
        <span class="notifications-settings-item-text">
          Subscribe to newsletter
        </span>
        <toggle-switch v-if="loadedValues" :switch-status-value="notifications.newsletter_subscribed" @toggle="toggleSwitch(3)" />
      </div>
    </div>
    <loading class="loading" :active="loading" />
    <div class="confirmation-buttons">
      <span v-if="!loading" class="form-button button-shadow" @click="saveNotificationSettings()">SAVE</span>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '@/components/shared/ToggleSwitch'
import Loading from '@/components/shared/Loading'

export default {
  name: 'NotificationSettings',
  components: {
    ToggleSwitch,
    Loading
  },
  data() {
    return {
      notifications: {
        user_id: this.$session.get('uid'),
        trade_notifications: false,
        system_notifications: false,
        news_notifications: false,
        newsletter_subscribed: false
      },
      loading: false,
      loadedValues: false
    }
  },
  created() {
    this.getNotifications()
  },
  methods: {
    toggleSwitch(type) {
      switch (type) {
        case 0:
          this.notifications.trade_notifications = !this.notifications.trade_notifications
          break;
        case 1:
          this.notifications.system_notifications = !this.notifications.system_notifications
          break;
        case 2:
          this.notifications.news_notifications = !this.notifications.news_notifications
          break;
        case 3:
          this.notifications.newsletter_subscribed = !this.notifications.newsletter_subscribed
          break;
      }
    },
    getNotifications() {
      this.loading = true
      this.$api
        .get('/users/get_notifications', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.notifications.trade_notifications = res.data.data.trade_notifications
            this.notifications.system_notifications = res.data.data.system_notifications
            this.notifications.news_notifications = res.data.data.news_notifications
            this.notifications.newsletter_subscribed = res.data.data.newsletter_subscribed
            this.loadedValues = true
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    saveNotificationSettings() {
      this.loading = true
      this.$api
        .post('/users/edit_notifications', this.notifications, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    }
  }
}
</script>

<style scoped>
.section-settings {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.notifications-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notifications-settings-item {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notifications-settings-item-text {
  font-size: 20px;
}
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }

  .notifications-settings-item-text {
    font-size: 16px;
  }
}
</style>
