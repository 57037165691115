<template>
  <div class="section-settings">
    <h1 class="title">Profile</h1>
    <span v-if="msg" class="success">{{ msg }}</span>
    <span v-if="errMsg" class="error">{{ errMsg }}</span>

    <loading class="loading" :active="loading" />
    <div v-if="!loading" v-click-outside="outside" class="section">
      <br>
      <span class="section-title"> Timezone </span>
      <div class="closed-filter" @click="toggleDropdown()">
        <span class="filter-name">{{ selectedTimezone.label }}</span>
        <arrow-head-down />
      </div>
      <div v-if="isDropDownOpen" class="open-filter card" data-aos="fade-bottom">
        <div class="filter-item" v-for="item in timezones" :key="item.label" @click="updateTimezone(item)">
          <div class="single-select">
            <span class="filter-item-title">{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="section">
      <span class="section-title"> Billing Information </span>
      <billing-details class="billing-details" ref="billingDetails" :userData="billingDetails" @setBillingDetails="getBillingDetails($event)" />
    </div>
    <div v-if="!loading" class="confirmation-buttons">
      <span class="form-button button-shadow" @click="saveProfile()">SAVE</span>
    </div>
  </div>
</template>

<script>
import ArrowHeadDown from '@/components/shared/ArrowHeadDown.vue'
import BillingDetails from '../BillingDetails.vue'
import Loading from '../../shared/Loading.vue'

export default {
  name: 'Profile',
  components: {
    ArrowHeadDown,
    BillingDetails,
    Loading
  },
  props: {
    userData: Object,
    subscriptionId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timezones: [
        { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
        { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
        { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
        { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
        { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
        { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
        { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
        { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'America/Chihuahua' },
        { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
        { label: '(GMT-06:00) Central America', value: 'America/Managua' },
        { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
        { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'America/Mexico_City' },
        { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
        { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'America/Bogota' },
        { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
        { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
        { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
        { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
        { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
        { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
        { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
        { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
        { label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'America/Argentina/Buenos_Aires' },
        { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
        { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
        { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
        { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
        { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
        { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'Africa/Casablanca' },
        { label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', value: 'Etc/Greenwich' },
        { label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: 'Europe/Amsterdam' },
        { label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: 'Europe/Belgrade' },
        { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Europe/Brussels' },
        { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Europe/Sarajevo' },
        { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
        { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
        { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens' },
        { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
        { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
        { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
        { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'Europe/Helsinki' },
        { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
        { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
        { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
        { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
        { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'Europe/Moscow' },
        { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
        { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
        { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
        { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
        { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
        { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
        { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
        { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
        { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi' },
        { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'Asia/Calcutta' },
        { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
        { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
        { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
        { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
        { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
        { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
        { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
        { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'Asia/Hong_Kong' },
        { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur' },
        { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
        { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
        { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
        { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
        { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
        { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
        { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
        { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
        { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
        { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'Australia/Canberra' },
        { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
        { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
        { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
        { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'Asia/Magadan' },
        { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
        { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
        { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' }
      ],
      selectedTimezone: {},
      isDropDownOpen: false,
      msg: '',
      errMsg: '',
      billingDetails: {},
      loading: false
    }
  },
  watch: {
    userData() {
      this.billingDetails = this.userData
    }
  },
  mounted() {
    this.billingDetails = this.userData
    this.setUserSelectedTimezone()
  },
  methods: {
    outside() {
      this.isDropDownOpen = false
    },
    saveProfile() {
      this.$refs.billingDetails.setBillingData()
    },
    getBillingDetails(billing_details) {
      this.msg = ''
      this.errMsg = ''

      let missingField = false
      for (const item in billing_details) {
        if (!billing_details[item]) {
          missingField = true
          this.errMsg = 'Missing fields'
        }
      }
      if (missingField) return

      this.loading = true

      let body = {
        user_id: this.$session.get('uid'),
        subscription_id: this.subscriptionId,
        first_name: billing_details.firstName,
        last_name: billing_details.lastName,
        address: billing_details.street + ', ' + billing_details.postCode + ', ' + billing_details.region,
        country: billing_details.country,
        postal_code: billing_details.postCode,
        state: billing_details.region
      }

      this.$api
        .post('/subscriptions/modify_billing_details', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$emit('updateProfile')
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    setUserSelectedTimezone() {
      this.timezones.forEach((elem) => {
        if (elem.value === this.$session.get('timezone')) {
          this.selectedTimezone = elem
        }
      })
    },
    updateTimezone(timezone) {
      this.selectedTimezone = timezone
      this.isDropDownOpen = false
      this.changeTimezone()
    },
    toggleDropdown() {
      this.isDropDownOpen = !this.isDropDownOpen
    },
    changeTimezone() {
      this.msg = ''
      this.errMsg = ''
      const body = {
        user_id: this.$session.get('uid'),
        timezone: this.selectedTimezone.value
      }
      this.$api
        .post('/users/change_timezone', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.$session.set('timezone', this.selectedTimezone.value)
            this.msg = 'Succesfully updated profile'
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    }
  }
}
</script>

<style scoped>
.section-settings {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section {
  width: 90%;
  margin: 20px 0;
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.closed-filter {
  margin-top: 10px;
  width: 600px;
  height: 30px;
  padding: 2px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 5px;
}

.open-filter {
  left: 0;
  height: 500px;
  display: block;
  position: absolute;
  margin-top: 5px;
  background-color: var(--card-background);
  padding: 2px 2px;
  z-index: 99999;
  border: 1px solid var(--border);
  border-radius: 5px;
  overflow-y: auto;
}

.filter-item {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.filter-item:nth-child(odd) {
  background-color: var(--card-background);
  border-radius: 5px;
}

.single-select {
  border-radius: 5px;
}

.filter-item:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
}

.filter-item-title {
  margin-left: 5px;
  text-align: left;
  cursor: pointer;
}

.billing-information {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.billing-details {
  margin-top: 20px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }

  .closed-filter {
    width: 100%;
    height: auto;
  }
}
</style>
