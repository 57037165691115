<template>
  <div>
    <span v-if="!modify" class="select-subscription">Select your subscription</span>
    <span v-if="modify" class="select-subscription">Select your new subscription</span>
    <div v-if="modify === 0" class="duration-toggle">
      <span class="sub-duration" :class="{selectedDuration: monthly}"><span class="sub-duration-text">Monthly</span></span>
      <toggle-switch @toggle="toggleDuration($event)" />
      <span class="sub-duration" :class="{selectedDuration: !monthly}">
        <span class="sub-duration-text">6 Months</span>
      </span>
      <!-- <span class="discount">
        <div class="pointer"></div>
        Summer SALE
      </span> -->
    </div>
    <div class="duration-options">
      <div v-for="item in options" :key="item.id">
        <selector v-if="$session.get('subscription_plan') !== item.value" class="selector-card" :id="item.id" :item="item" :selectedItem="selectedItem" @updateSelector="updateSubscriptionSelector($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import selector from '@/components/shared/Selector'
import ToggleSwitch from '../../shared/ToggleSwitch.vue'

export default {
  components: {
    selector,
    ToggleSwitch
  },
  props: {
    modify: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: [],
      durationOptions: [
        {
          id: 0,
          title: 'Bronze',
          value: 'bronze',
          monthly: true,
          base_price: 10,
          per_month: 10.00,
          benefits: [
            'Copy-Trading',
            'Max 2k Capital',
            'Max 1 Active Bot',
            'Reinvesting of Profits',
            'Swapping of Bots',
            'Longs & Shorts'
          ],
          notIncludes: [
            'Shorts',
            'Unlimited Connections',
            '2 Weeks Early Access to New Bots',
            '"Gold only" Exclusive Bots',
            'Priority Customer Service'
          ]
        },
        {
          id: 1,
          title: 'Silver',
          value: 'silver',
          monthly: true,
          base_price: 49,
          per_month: 49.00,
          benefits: [
            'Copy-Trading',
            'Max 10k Capital',
            'Max 5 Active Bots',
            'Reinvestment of Profits',
            'Swapping of Bots',
            'Longs & Shorts',
          ],
          notIncludes: [
            'Unlimited Connections',
            '2 Weeks Early Access to New Bots',
            '"Gold only" Exclusive Bots',
            'Priority Customer Service'
          ]
        },
        {
          id: 2,
          title: 'Gold',
          value: 'gold',
          monthly: true,
          base_price: 99,
          per_month: 99.00,
          benefits: [
            'Copy-Trading',
            'Max 30k Capital',
            'Unlimited Active Bots',
            'Reinvestment of Profits',
            'Swapping of Bots',
            'Longs & Shorts',
            'Unlimited Connections',
            '2 Weeks Early Access to New Bots',
            '"Gold only" Exclusive Bots',
            'Priority Customer Service'
          ]
        }
      ],
      durationOptions6Months: [
        {
          id: 0,
          title: 'Bronze',
          value: 'bronze_6_mo',
          monthly: false,
          discount_price: 60 * 1,
          base_price: 10,
          per_month: 60.00,
          benefits: [
            'Copy-Trading',
            'Max 2k Capital',
            'Max 1 Active Bot',
            'Reinvesting of Profits',
            'Swapping of Bots',
            'Longs & Shorts'
          ],
        },
        {
          id: 1,
          title: 'Silver',
          value: 'silver_6_mo',
          monthly: false,
          discount_price: 234 * 1,
          base_price: 39,
          per_month: 234.00,
          benefits: [
            'Copy-Trading',
            'Max 10k Capital',
            'Max 5 Active Bots',
            'Reinvestment of Profits',
            'Swapping of Bots',
            'Longs & Shorts',
          ],
        },
        {
          id: 2,
          title: 'Gold',
          value: 'gold_6_mo',
          monthly: false,
          discount_price: 474 * 1,
          base_price: 79,
          per_month: 474.00,
          benefits: [
            'Copy-Trading',
            'Max 30k Capital',
            'Unlimited Active Bots',
            'Reinvestment of Profits',
            'Swapping of Bots',
            'Longs & Shorts',
            'Unlimited Connections',
            '2 Weeks Early Access to New Bots',
            '"Gold only" Exclusive Bots',
            'Priority Customer Service'
          ]
        }
      ],
      selectedItem: null,
      monthly: true
    }
  },
  watch: {
    modify() {
      this.selectOptions()
    }
  },
  mounted() {
    this.selectOptions()
  },
  methods: {
    selectOptions() {
      switch (this.modify) {
        case 1:
          this.options = this.durationOptions
          break;
        case 2:
          this.options = this.durationOptions6Months
          break;
        default:
          this.options = this.durationOptions
          break;
      }
    },
    // select subscription type
    updateSubscriptionSelector(e) {
      this.selectedItem = e
      this.$emit('selectedSubscription', e)
    },
    toggleDuration(duration) {
      this.options = []
      switch (duration) {
        case 0:
          this.monthly = true
          this.options = this.durationOptions
          break;
        case 1:
          this.monthly = false
          this.options = this.durationOptions6Months
          break;
      }
    }
  }
}
</script>

<style scoped>
.duration-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.select-subscription {
  font-size: 20px;
}

.selector {
  box-shadow: 0 2px 5px 2px rgba(164, 164, 164, 0.75);
  color: var(--header-color);
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.next-button {
  width: 100px;
  display: flex;
  justify-content: center;
}

.next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.duration-toggle {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-duration {
  padding: 0px 10px;
  font-weight: 700;
  color: var(--disabled);
}

.sub-duration-text {
  font-size: 18px;
  cursor: pointer;
}

.selectedDuration {
  color: var(--text-primary);
}

.monthly {
  border-right: 2px solid var(--header-color-hover);
}

.discount {
  position: absolute;
  margin-left: 420px;
  padding: 10px 15px;
  background-color: #ffc400;
  color: var(--header-color);
  border-radius: 5px;
  font-weight: bold;
}

.discount:after {
  position: absolute;
  content: '';
  border-top: 5px solid #ffc400;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 45%;
  right: 100%;
  transform: rotate(90deg);
  display: block;
}

@media (max-width: 1250px) {
  .duration-options {
    flex-wrap: wrap;
    justify-content: center;
  }

  .discount {
    display: none;
  }

  .next-step {
    margin-top: 50px;
    justify-content: center;
  }

  .next-button {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .discount {
    display: none;
  }
}
</style>
