<template>
  <div class="stat-card-graph">
    <canvas id="bar-chart" />
  </div>
</template>

<script>
export default {
  name: 'BarChart',
  props: {
    chartData: Object
  },
  mounted() {
    this.generateBarChart()
  },
  methods: {
    //generate Bar chart
    generateBarChart() {
      const Chart = require('chart.js')
      const ChartDataLabels = require('chartjs-plugin-datalabels')
      const ctx = document.getElementById('bar-chart').getContext('2d')

      const colours = this.chartData.datasets[0].data.map(value =>
        value < 0 ? '#FD6C7F' : '#60d248'
      )

      // eslint-disable-next-line no-unused-vars
      const chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.chartData.labels,
          datasets: [
            {
              lineTension: 0.3,
              borderJoinStyle: 'round',
              pointBackgroundColor: '#ffff',
              pointRadius: 6,
              stepped: true,
              fill: true,
              label: 'Return',
              backgroundColor: colours,
              borderWidth: 4,
              pointBorderWidth: 2,
              data: this.chartData.datasets[0].data
            }
          ]
        },
        plugins: [ChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'left',
            display: this.legend
          },
          tooltips: {
            enabled: false
          },
          plugins: {
            datalabels: {
              color: 'white',
              formatter: value => {
                return `${value}%`
              },
              font: {
                weight: 'bold'
              },
              padding: 6
            }
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                position: 'right',
                ticks: {
                  min: Math.min.apply(null, this.chartData.datasets[0].data),
                  max:
                    Math.max.apply(null, this.chartData.datasets[0].data) + 10,
                  stepSize: 20,
                  display: true,
                  fontSize: 16,
                  callback(value) {
                    return `${value}%` // convert it to percentage
                  }
                }
              }
            ],
            xAxes: [
              {
                ticks: {
                  display: true,
                  fontSize: 16
                }
              }
            ]
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.stat-card-graph {
  height: 90%;
  width: 100%;
}
</style>
