<template>
  <div class="marketplace-filters">
    <div v-for="item in activeFilters" :key="item.id" class="active-filters">
      <div v-if="item.type === 0" class="filter-group">
        <span>Coin:</span>
        <div class="active-filter-item">
          <span>{{ item.title }}</span>
          <span class="filter-remove" @click="removeFilter(item)"><span class="cross">&#10005;</span></span>
        </div>
      </div>
      <div v-if="item.type === 1" class="filter-group">
        <span>Base Coin:</span>
        <div class="active-filter-item">
          <span>{{ item.title }}</span>
          <span class="filter-remove" @click="removeFilter(item)"><span class="cross">&#10005;</span></span>
        </div>
      </div>
      <div v-if="item.type === 2" class="filter-group">
        <span>Risk Level:</span>
        <div class="active-filter-item">
          <span>{{ item.title }}</span>
          <span class="filter-remove" @click="removeFilter(item)"><span class="cross">&#10005;</span></span>
        </div>
      </div>
      <div v-if="item.type === 3" class="filter-group">
        <span>Extra:</span>
        <div class="active-filter-item">
          <span>{{ item.title }}</span>
          <span class="filter-remove" @click="removeFilter(item)"><span class="cross">&#10005;</span></span>
        </div>
      </div>
      <div v-if="item.type === 4" class="filter-group">
        <span>Exclusivity:</span>
        <div class="active-filter-item">
          <span>{{ item.title }}</span>
          <span class="filter-remove" @click="removeFilter(item)"><span class="cross">&#10005;</span></span>
        </div>
      </div>
      <div v-if="item.exchange" class="filter-group">
        <span>Exchange:</span>
        <div class="active-filter-item">
          <span>{{ item.item.title }}</span>
          <span class="filter-remove" @click="removeFilter(item.item)"><span class="cross">&#10005;</span></span>
        </div>
      </div>
    </div>
    <span class="clear-filter" @click="clearFilters()">Clear Filters</span>
  </div>
</template>

<script>
export default {
  name: "ActiveMarketplaceFilters",
  props: {
    activeFilters: Array
  },
  methods: {
    clearFilters() {
      this.$emit('clearFilters')
    },
    removeFilter(item) {
      this.$emit('removeFilter', item)
    }
  }
}
</script>

<style scoped>
.marketplace-filters {
  display: flex;
  align-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.active-filters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.active-filter-item {
  padding: 5px 10px;
  background-color: var(--border);
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-filter {
  padding: 10px 0;
  text-decoration: underline;
  color: var(--text-link);
  cursor: pointer;
}

.filter-remove {
  margin-left: 15px;
  height: 15px;
  width: 15px;
  background-color: var(--text-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-size: 20px;
  cursor: pointer;
}

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
}

@media (max-width: 850px) {
  .marketplace-filters {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>