<template>
  <div class="amount-allocate">
    <span class="step-title" v-if="!isUpdate">3. Allocated amount</span>
    <div class="allocation-type">
      <label class="allocation-type-label" for="pct-allocation">
        <input class="allocation-type-radio" type="radio" name="allocation-type" id="pct-allocation" @click="toggleAllocationType(0)">
        Percentage of balance
      </label>
      <label class="allocation-type-label" for="qty-allocation">
        <input class="allocation-type-radio" type="radio" name="allocation-type" id="qty-allocation" @click="toggleAllocationType(1)">
        Quantity
      </label>
    </div>
    <v-app class="amount-slider" id="inspire">
      <div class="slider">
        <v-slider v-if="pctAllocation" class="slider" v-model="selectedAmount" :label="sliderLabel" :step="stepSize" :thumb-color="sliderColor" :min="minAllocated" :max="maxAllocated" :inverse-label="true" @end="SliderStop()" />
        <span v-else-if="!pctAllocation && !isUpdate">Available amount: {{max}}</span>
        <div class="amount-allocation">
          <input class="allocated-amount" type="number" v-model="selectedAmountInput" min="0"/>
          <span v-if="!pctAllocation">{{ userInputLabel }}</span>
          <span v-else>% of your wallet</span>
        </div>
        <span class="err">{{ this.errMsg }}</span>
      </div>
    </v-app>
    <span class="note">* Re-investing of profits is only possible if you select percentage allocation</span>
    <div class="next-step" :class="{update: isUpdate}">
      <span v-show="!isUpdate" class="form-button button-shadow previous-button" @click="previousStep()">Previous Step</span>
      <span class="form-button button-shadow next-button" @click="nextStep()">Next Step</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    min: Number,
    market: String,
    max: Number,
    maxPct: Number,
    isUpdate: {
      type: Boolean,
      default: false
    },
    exchange: String
  },
  data() {
    return {
      pctAllocation: true,
      amountAllocated: 0,
      maxAllocated: 0,
      minAllocated: 0,
      selectedAmount: 0,
      selectedAmountInput: 0,
      selectedAmountSlider: 0,
      stepSize: 0,
      sliderColor: '#1b81c0',
      errMsg: '',
      sliderLabel: '',
      userInputLabel: '%'
    }
  },
  watch: {
    selectedAmountInput() {
      if (this.pctAllocation) {
        if (this.selectedAmountInput > 100) this.selectedAmountInput = 100
        if (typeof this.selectedAmountInput === 'string' && this.selectedAmountInput.includes('-')) this.selectedAmountInput = 1
      } else {
        // if (this.selectedAmountInput > this.max) this.selectedAmountInput = this.max
        // if (typeof this.selectedAmountInput === 'string' && this.selectedAmountInput.length >= this.min.toString().length && Number(this.selectedAmountInput) < this.min) this.selectedAmountInput = this.min
      }
      this.selectedAmount = Number(this.selectedAmountInput)
    },
  },
  mounted() {
    if (this.isUpdate) {
      switch (this.type) {
        case 'pct':
          document.getElementById('pct-allocation').checked = true
          this.toggleAllocationType(0)
          break
        case 'qty':
          document.getElementById('qty-allocation').checked = true
          this.toggleAllocationType(1)
          break
      }
      return
    }
    this.toggleAllocationType(0)
    document.getElementById('pct-allocation').checked = true
    this.sliderLabel = this.maxPct + '%'
  },
  methods: {
    SliderStop() {
      if (!this.pctAllocation) {
        if (this.market === 'BTC') {
          this.selectedAmountInput = this.selectedAmount.toFixed(8)
        } else {
          this.selectedAmountInput = this.selectedAmount.toFixed(2)
        }
      } else {
        this.selectedAmountInput = this.selectedAmount
      }
    },
    previousStep() {
      this.$emit('previousStep')
    },
    test() {
      if (!this.pctAllocation) {
        if (this.selectedAmountInput > this.max || this.selectedAmountInput < this.min) {
          this.max = this.min
          this.selectedAmountInput = this.min
        }
      }
      this.selectedAmountSlider = this.selectedAmountInput
      this.selectedAmount = Number(this.selectedAmountInput)
    },
    toggleAllocationType(type) {
      switch (type) {
        case 0:
          if (this.isUpdate) {
            this.pctAllocation = true
            this.maxAllocated = 100
            this.minAllocated = 1
            this.selectedAmount = 100
            this.selectedAmountInput = 100
            this.sliderLabel = this.maxPct * 100 + '%'
            this.stepSize = 1
            this.userInputLabel = '%'
            return
          }
          this.pctAllocation = true
          this.maxAllocated = 100
          this.minAllocated = 1
          this.selectedAmountInput = 100
          this.selectedAmount = 100
          this.sliderLabel = this.maxPct + '%'
          this.userInputLabel = '%'
          this.stepSize = 1
          break;
        case 1:
          if (this.isUpdate) {
            this.pctAllocation = false
            this.maxAllocated = 100000
            this.minAllocated = this.min
            this.selectedAmountInput = this.min
            this.selectedAmount = this.min
            this.sliderLabel = this.max + ' ' + this.market
            this.userInputLabel = this.market
            this.stepSize = ((this.max - this.min) / 100)
            return
          }
          this.pctAllocation = false
          this.maxAllocated = this.max
          this.minAllocated = this.min
          this.selectedAmountInput = this.min
          this.selectedAmount = this.minAllocated
          this.stepSize = ((this.max - this.min) / 100)
          this.sliderLabel = this.max + ' ' + this.market
          this.userInputLabel = this.market
          break;
      }
    },
    nextStep() {
      if(!this.pctAllocation){
        if(this.selectedAmountInput < this.min){
          this.errMsg = "Can't allocate less than the minimum amount (" + this.min + " " + this.userInputLabel + ")"
          return
        }
      }
      let temp = {
        amount: this.pctAllocation ? this.selectedAmountInput / 100 : this.selectedAmountInput,
        allocation_type: this.pctAllocation ? 'pct' : 'qty'
      }
      this.$emit('nextStep', temp)
    }
  }
}
</script>
<style scoped>
.allocated-amount {
  min-height: 100%;
  font-size: 18px;
  padding: 5px;
  display: inline-block;
  border-radius: 5px;
  color: var(--text-primary);
  border: 1px solid var(--border);
  margin-right: 5px;
}

.allocation-type {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.slider {
  background-color: var(--card-background);
  color: var(--text-primary);
}

.allocation-type-label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.amount-slider {
  margin: 10px 0;
}

.amount-allocated {
  color: var(--text-primary);
  font-weight: bold;
}

.next-button {
  background-color: var(--secondary-blue);
  padding: 10px 20px;
  width: auto;
  display: flex;
  justify-content: center;
}

.previous-button {
  background-color: var(--secondary-blue);
  width: auto;
  display: flex;
  justify-content: center;
}

.next-step {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.update {
  justify-content: flex-end;
}

@media (max-width: 850px) {
  .amount-allocation {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .next-step {
    margin-top: 50px;
    justify-content: center;
  }

  .next-button {
    width: 100%;
  }
}
</style>
