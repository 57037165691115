<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" @click.stop :class="{ forgotPasswordContainer: forgotPassword }">
        <span class="modal-close" @click="closeModal()">&#10799;</span>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header-date">
                <div class="modal-header">
                  <span>Forgot your password?</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <loading :active="loading"/>
            <div v-if="!newPassword && !loading && !emailSent" class="forgot-password">
              <span>Your email address:</span>
              <input class="form-input" type="text" v-model="email"/>
              <br/>
              <span v-if="errorMsg" class="error-messages error">{{ errorMsg }}</span>
              <button class="button-p card button-shadow" @click="forgotPassword()">
                <span>Reset password</span>
              </button>
            </div>
            <div v-if="newPassword && !loading && !emailSent" class="forgot-password">
              <span>New password:</span>
              <span class="password-visibility-toggle">
                <input id="forgot-user-password" class="form-input" v-model="newUserPassword" type="password"/>
                <img class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(0)"/>
              </span>
              <br/>
              <span>Confirm new password:</span>
              <span class="password-visibility-toggle">
                <input id="forgot-user-confirm-password" class="form-input" v-model="newUserPasswordConfirm" type="password"/>
                <img class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(1)"/>
              </span>
              <br/>
              <span v-if="errorMsg" class="error-messages error">{{ errorMsg }}</span>
              <button class="button-p card button-shadow" @click="resetPassword()">
                <span>Reset password</span>
              </button>
            </div>
            <div v-if="emailSent" class="forgot-password">
              <span>An email has been sent with a password reset link</span>
              <br>
              <button class="button-p card button-shadow" @click="closeModal()">
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue'

export default {
  name: 'Modal',
  props: {
    newPassword: Boolean,
    code: String,
    email: String
  },
  components: {
    Loading
  },
  data() {
    return {
      newUserPassword: '',
      newUserPasswordConfirm: '',
      errorMsg: false,
      validConfirmPassword: false,
      loading: false,
      passwordVisible: false,
      confirmPasswordVisible: false,
      emailSent: false
    }
  },
  mounted() {
    if (this.exchangeAccount) {
      this.newExchangeName = this.exchangeAccount.connection_name
    }
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    //toggle password visibility
    togglePasswordVisibility(e) {
      switch (e) {
        case 0:
          this.passwordVisible = !this.passwordVisible
          if (this.passwordVisible) {
            document.getElementById('forgot-user-password').type = 'text'
            return
          }
          document.getElementById('forgot-user-password').type = 'password'
          break
        case 1:
          this.confirmPasswordVisible = !this.confirmPasswordVisible
          if (this.confirmPasswordVisible) {
            document.getElementById('forgot-user-confirm-password').type = 'text'
            return
          }
          document.getElementById('forgot-user-confirm-password').type = 'password'
          break
      }
    },
    forgotPassword() {
      this.loading = true
      this.errorMsg = ''
      const body = {
        email: this.email
      }
      this.$api
          .post('/users/forgot_password', body, {
            headers: {
              'X-Authorization': this.$session.get('guest-token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.loading = false
              this.emailSent = true
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
            if (err.response.data.error.type === 'TOO_MANY_REQUESTS') {
              this.errorMsg = 'Please wait 5 minutes before you request another password change'
            }
            if (err.response.data.error.type === 'NOT_FOUND') {
              this.errorMsg = 'Email not found'
            }
          })
    },
    resetPassword() {
      this.errorMsg = ''
      if (this.newUserPassword !== this.newUserPasswordConfirm) {
        this.errorMsg = 'Passwords do not match'
        return
      }
      if (!this.checkPassword()) {
        this.errorMsg = 'The password must contain at least 8 characters, one uppercase & one number'
        return
      }

      const body = {
        email: this.email,
        new_password: this.newUserPassword,
        token: this.code
      }
      this.$api
          .post('/users/reset_password', body, {
            headers: {
              'X-Authorization': this.$session.get('guest-token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.$router.push({name: 'Login'})
              this.$emit('click', 'Succesfully updated your password')
            }
          })
          .catch((err) => {
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }

          })
    },
    checkPassword() {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,128}$/
      return passwordRegex.test(this.newUserPassword)
    },
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.forgot-password {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;

  margin: 0 auto;
  padding: 20px 30px;
  background-color: var(--background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 20px;
}

.forgotPasswordContainer {
  width: 600px;
}

.modal-close {
  margin-top: -25px;
  margin-right: -10px;
  position: relative;
  font-size: 40px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 80%;
  font-size: 28px;
  margin-top: 0;
}

.modal-date {
  font-size: 12px;
}

.modal-content {
  height: 100%;
  margin-top: 20px;
  font-size: 16px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.email {
  color: var(--primary-blue);
  font-size: 20px;
}

.register-account {
  bottom: 0;
}

.account-register-content {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-item-title {
  font-weight: 600;
}

.delete-button {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-button {
  margin-left: 50px;
  color: var(--primary-blue);
  text-decoration: underline;
  font-weight: 500;
  font-size: 20px;

  cursor: pointer;
}

.error-messages {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.password-visibility-toggle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 30px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .modal-container {
    width: 95%;
    height: auto;
    overflow-y: auto;
    padding: 15px;
  }

  .content {
    padding: 10px 0;
  }
}
</style>
