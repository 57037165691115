<template>
  <div class="dropdown">
    <div class='closed-dropdown' @click='toggleFilterOptions()'>
      <span v-if='!selectedItem'>Select a Bot</span>
      <span v-else>{{ selectedItem.bot_name }}</span>
      <img v-if="!isDarkTheme" height="20" src="@/assets/filterArrowOpen.png" alt="">
      <img v-else height="20" src="@/assets/filterArrowOpen-white.png" alt="">
    </div>
    <div v-click-outside="outside" v-if='isFilterOpen' class='open-dropdown card'>
      <div class='list-item' v-for='item in itemList' :key='item.bot_id' :class="{selected: item.bot_id === selectedItem.bot_id }" @click='selectListItem(item)'>
        <span v-if="item.bot_id === selectedItem.bot_id" class="checkmark">✓</span>
        <span>{{ item.bot_name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowHeadDown from '../shared/ArrowHeadDown.vue'
import { mapGetters } from 'vuex'
export default {
  name: "BotFilter",
  components: {
    ArrowHeadDown
  },
  computed: mapGetters(['darkTheme']),
  props: {
    itemList: Array,
  },
  data() {
    return {
      isDarkTheme: localStorage.boostingAlphaDarkTheme,
      isFilterOpen: false,
      selectedItem: {}
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    },
  },
  mounted() {
    if (this.itemList.length > 0) {
      this.selectedItem = this.itemList[0]
      this.$emit('selectItem', this.selectedItem)
    }
  },
  methods: {
    outside() {
      this.isFilterOpen = false
    },
    toggleFilterOptions() {
      this.isFilterOpen = !this.isFilterOpen
    },
    selectListItem(item) {
      if (this.selectedItem.bot_id !== item.bot_id) {
        this.selectedItem = item
        this.$emit('selectItem', this.selectedItem)
        this.toggleFilterOptions()
      }
    }
  }
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.closed-dropdown {
  min-width: 300px;
  width: auto;
  height: 30px;
  display: flex;
  padding: 5px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 5px;
}

.open-dropdown {
  top: 35px;
  width: 300px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  background-color: var(--background);
  z-index: 9;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 5px;
  overflow: auto;
}

.list-item {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  gap: 5px;
}

.list-item:hover {
  background-color: var(--selected);
  color: var(--text-secondary);
}

.selected {
  background-color: var(--primary-blue);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
}

.selected:hover {
  background-color: var(--primary-blue);
  color: var(--text-primary);
}
</style>