<template>
  <div v-if="showPagination" class="pagination card">
    <span class="page-navigation" @click="previousPage()">&lt;</span>
    <span v-if="currentPage !== 1" class=" pagination-item">{{ currentPage - 1 }}</span>
    <span class=" pagination-item active">{{ currentPage }}</span>
    <span v-if="currentPage !== lastPage" class=" pagination-item">{{ currentPage + 1 }}</span>
    <span v-if="lastPage - currentPage > 1">
      <span class=" pagination-item">...</span>
      <span class=" pagination-item">{{ lastPage }}</span>
    </span>
    <span v-if="showNextPageButton" class="page-navigation" @click="nextPage()">&gt;</span>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    itemsPerPage: { type: Number, default: 0 },
    currentItems: { type: Number, default: 0 },
    totalItems: { type: Number, default: 0 },
    currentTab: 0
  },
  data() {
    return {
      currentPage: 1,
      showNextPageButton: true,
      showPagination: true,
      lastPage: Number((this.totalItems / this.itemsPerPage).toFixed())
    }
  },
  mounted() {
    this.currentPage = 1
    if (this.currentPage === 1) {
      this.currentItems < this.itemsPerPage ? this.showPagination = false : this.showPagination = true
    }
  },
  watch: {
    currentItems() {
      this.lastPage = Number((this.totalItems / this.itemsPerPage).toFixed())
      if (this.currentPage === 1) {
        this.currentItems < this.itemsPerPage ? this.showPagination = false : this.showPagination = true
      }
    },
    currentPage() {
      this.showPagination = true
      this.currentPage === this.lastPage ? this.showNextPageButton = false : this.showNextPageButton = true
    }
  },
  methods: {
    previousPage() {
      if (this.currentPage === 1) return
      this.currentPage--
      this.$emit('previous')
    },
    nextPage() {
      if (this.currentPage === this.lastPage) return
      this.currentPage++
      this.$emit('next')
    }
  }
}
</script>

<style scoped>
.pagination {
  margin: 0 auto;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--card-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.pagination-item {
  padding: 5px 10px;
  border-radius: 10px;
  color: var(--text-primary);
}
.active {
  background-color: var(--primary-blue);
  color: var(--white);
}
.page-navigation {
  padding: 5px 10px;
  border-radius: 10px;
}
.page-navigation:hover {
  background-color: var(--disabled);
  cursor: pointer;
}
</style>