<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop :class="{ forgotPasswordContainer: forgotPassword }">
        <div class="modal-close" @click="closeModal()">X</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header-date">
                <div class="modal-header">
                  <span>Register for our beta</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="forgot-password">
              <span>First Name:</span>
              <input class="form-input" type="text" v-model="firstName" />
              <br />
              <span>Last Name:</span>
              <input class="form-input" type="text" v-model="lastName" />
              <br />
              <span>Your email address:</span>
              <input class="form-input" type="text" v-model="email" />
              <br />
              <button class="button-p card button-shadow" @click="betaRegister()">
                <span>Register for our beta!</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue'

export default {
  name: 'Modal',
  props: {
    newPassword: Boolean
  },
  components: {
    Loading
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      errorMsg: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    betaRegister() {
      this.closeModal
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.forgot-password {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 20px;
}

.forgotPasswordContainer {
  width: 600px;
}

.modal-close {
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 80%;
  font-size: 28px;
  margin-top: 0;
}

.modal-date {
  font-size: 12px;
}

.modal-content {
  height: 100%;
  margin-top: 20px;
  font-size: 16px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.email {
  color: var(--blue-primary);
  font-size: 20px;
}
.register-account {
  bottom: 0;
}

.account-register-content {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-item-title {
  font-weight: 600;
}
.delete-button {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-button {
  margin-left: 50px;
  color: var(--blue-primary);
  text-decoration: underline;
  font-weight: 500;
  font-size: 20px;

  cursor: pointer;
}
.error-messages {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
@media (max-width: 1200px) {
  .modal-container {
    width: 95%;
    height: auto;
    overflow-y: auto;
    padding: 15px;
  }

  .content {
    padding: 10px 0;
  }
}
</style>
