<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header-date">
                <div class="modal-header">
                  <span>Re-send verification code</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-content">
            <div class="forgot-password">
              <span>Your email address:</span>
              <input class="form-input" type="text" v-model="email"/>
              <br/>
              <button class="button-p card button-shadow" @click="resetPassword()">
                <span>Send Verification Code</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  data() {
    return {
      email: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    resetPassword() {
      this.$emit('sendVerification', this.email)
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.forgot-password {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: auto;
  width: 700px;
  margin: 0 auto;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 20px;
}

.forgotPasswordContainer {
  width: 600px;
}

.modal-close {
  margin-top: -20px;
  font-size: 40px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 80%;
  font-size: 28px;
  margin-top: 0;
}

.modal-date {
  font-size: 12px;
}

.modal-content {
  height: 100%;
  margin-top: 20px;
  font-size: 16px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.email {
  color: var(--primary-blue);
  font-size: 20px;
}

.register-account {
  margin-top: 20px;
  bottom: 0;
}

.account-register-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-item-title {
  font-weight: 600;
}

.delete-button {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-button {
  margin-left: 50px;
  color: var(--primary-blue);
  text-decoration: underline;
  font-weight: 500;
  font-size: 20px;

  cursor: pointer;
}

.button-save-edit {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-keys {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.change-keys-label {
  cursor: pointer;
  margin-left: 10px;
}

.change-keys-checkbox {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .modal-container {
    width: 95%;
    height: auto;
    overflow-y: auto;
    padding: 15px;
  }

  .content {
    padding: 10px 0;
  }
}
</style>
