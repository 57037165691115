<template>
  <div v-click-outside="outside" class="filter">
    <div class="closed-filter" @click="toggleOpenFilter()">
      <span class="close-filter-title">Filters</span>
      <img v-if="isDarkTheme" class="filter-img" src="@/assets/filters-white.png" alt="">
      <img v-else class="filter-img" src="@/assets/filters.png" alt="">
    </div>
    <div v-show="isFilterOpen" class="open-filter card">
      <div class="filter-section" @click="toggleBotNameFilter()">
        <span class="filter-title">
          <span>Bots</span>
          <img v-if="!botNameFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="botNameFilterOpen" class="filter-options">
          <span v-for="item in items.bots" :key="item.bot_id" class="filter-item" :class="{selected: botNameFilter.includes(item)}" @click="addItemToFilter(item, 0)">{{ item.bot_name }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleSymbolFilter()">
        <span class="filter-title">
          <span>Symbol</span>
          <img v-if="!symbolFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="symbolFilterOpen" class="filter-options">
          <span v-for="item in items.pairs" :key="item" class="filter-item" :class="{selected: symbolFilter.includes(item)}" @click="addItemToFilter(item, 1)">{{ item }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleSideFilter()">
        <span class="filter-title">
          <span>Side</span>
          <img v-if="!sideFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="sideFilterOpen" class="filter-options">
          <span v-for="item in items.side" :key="item" class="filter-item" :class="{selected: sideFilter.includes(item)}" @click="addItemToFilter(item, 2)">{{ item[0].toUpperCase() + item.substr(1) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ActivityFilters',
  props: {
    items: Object,
  },
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      isFilterOpen: false,
      botNameFilter: [],
      symbolFilter: [],
      sideFilter: [],
      botNameFilterOpen: false,
      symbolFilterOpen: false,
      sideFilterOpen: false,
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  methods: {
    outside() {
      this.isFilterOpen = false
    },
    toggleBotNameFilter() {
      this.botNameFilterOpen = !this.botNameFilterOpen
    },
    toggleSymbolFilter() {
      this.symbolFilterOpen = !this.symbolFilterOpen
    },
    toggleSideFilter() {
      this.sideFilterOpen = !this.sideFilterOpen
    },
    toggleOpenFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },
    clearFilters() {
      this.botNameFilter = []
      this.symbolFilter = []
      this.sideFilter = []
    },
    addItemToFilter(item, type) {
      switch (type) {
        case 0:
          if (this.botNameFilter.includes(item)) return
          this.botNameFilter.push(item)
          break
        case 1:
          if (this.symbolFilter.includes(item)) return
          this.symbolFilter.push(item)
          break
        case 2:
          if (this.sideFilter.includes(item)) return
          this.sideFilter.push(item)
          break
      }
      let temp = {
        item: item,
        type: type
      }

      this.$emit('updateFilter', temp)
    },
  }
}
</script>

<style scoped>
.filter {
  width: 250px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: var(--secondary-blue);
}

.closed-filter {
  position: relative;
  padding: 5px 10px;
  height: 100%;
  border: 1px solid var(--border);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--card-background);
  color: var(--text-primary);
  cursor: pointer;
  font-weight: bold;
}

.close-filter-title {
  font-weight: 500;
}

.filter-title {
  font-weight: 400;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  color: var(--text-primary);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.filter-title:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
}

.open-filter {
  padding: 5px 0;
  position: absolute;
  margin-top: 10px;
  min-width: 100%;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  z-index: 9999;
}

.filter-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter-options {
  max-height: 300px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.filter-item {
  font-weight: 400;
  width: 100%;
  padding: 10px 10px;
  cursor: pointer;
  color: var(--text-primary);
  border-radius: 5px;
}

.filter-item:hover {
  color: var(--white);
  background-color: var(--secondary-blue);
}

.filter-img {
  height: 20px;
  width: 20px;
}

.selected {
  background-color: var(--primary-blue);
  color: var(--text-secondary);
}

.selected:hover {
  background-color: var(--primary-blue);
  color: var(--text-secondary);
}

.filter-arrow-close {
  height: 20px;
  width: 20px;
}
</style>