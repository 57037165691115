<template>
  <div class="exchange-container card" :class="{selected: isSelected}" @click="selectExchange()">
    <img class="exchange-icon" :src="loadIcon(exchange.logo)" alt="">
    <span class=" exchange-text">{{ exchange.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    exchange: Object,
    selected: Object
  },
  data() {
    return {
      isSelected: false,
    }
  },
  watch: {
    selected() {
      if (this.selected === this.exchange) {
        this.isSelected = true
        return
      }
      this.isSelected = false
    }
  },
  methods: {
    loadIcon(path) {
      return require(`@/${path}`)
    },
    selectExchange() {
      this.$emit('selectExchange', this.exchange)
    }
  }
}
</script>

<style scoped>
.exchange-container {
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--card-background);
  transition: transform 0.2s;
}

.exchange-container:hover {
  transform: scale(1.1);
}

.selected {
  border: 2px solid var(--green);
}

.exchange-icon {
  height: 60%;
  width: 60%;
}

.exchange-text {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-primary);
}
</style>
