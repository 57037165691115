<template>
  <div class="cart-item card">
    <div class="item-info">
      <span class="item-title"> {{ item.bot_name }} </span>
      <div class="item-subscription-detail">
        <div class="item-detail">
          <img class="item-img" src="@/assets/binance-logo.png" alt="" />
          <span class="item-data-text">{{ item.exchange}}</span>
        </div>
        <div class="item-detail">
          <img class="item-img badge" :src="loadBadge(item.subscription_plan)" alt="" />
          <span class="item-data-text"> {{ item.subscription_plan [0].toUpperCase() + item.subscription_plan.substr(1) }}</span>
        </div>
        <div class="item-detail">
          <span class="amount-allocated item-data-text">
            Allocated: {{ item.allocated_balance }} USDT
          </span>
        </div>
        <div class="item-detail" v-if="item.extend_duration_months">
          <span class="amount-allocated item-data-text">
            {{ item.extend_duration_months}} month(s) extension
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  methods: {
    loadBadge(item) {
      const path = `assets/${item}.png`
      return require(`@/${path}`)
    },
  }
}
</script>

<style scoped>
.cart-item {
  width: 100%;
  padding: 20px;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-color);
  color: #fff;
  border-radius: 5px;
  cursor: default;
}

.item-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.item-title {
  font-size: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-duration {
  font-size: 24px;
  margin-left: 10px;
}

.item-detail {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
}

.subscription-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-price {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 24px;
}

.item-price-value {
  margin-right: 10px;
}

.item-duration {
  font-size: 16px;
}

.item-img {
  height: 30px;
}

.badge {
  height: 25px;
}

.delete-logo {
  cursor: pointer;
  height: 25px;
}

.item-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

.item-subscription-detail {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amount-allocated {
  font-size: 18px;
}

@media (max-width: 850px) {
  .cart-item {
    min-height: 70px;
    padding: 10px;
    margin: 10px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .item-title {
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .item-duration {
    font-size: 16px;
  }

  .item-price {
    font-size: 16px;
    width: 100%;
    justify-content: flex-end;
  }
  .item-data-text {
    font-size: 14px;
  }
  .item-img {
    height: 25px;
  }
  .item-subscription-detail {
    justify-content: center;
    align-items: center;
  }
}
</style>
