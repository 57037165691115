<template>
  <div class="overview-card card">
    <div class="overview-card-content">
      <span class="overview-card-image">
        <img
          class="overview-card-icon"
          :src="loadImage(overviewItem.icon)"
          alt=""
        />
      </span>

      <span
        v-if="activeBots"
        class="overview-card-value"
        :class="{ text: overviewItem.type === 1 }"
      >
        {{ overviewItem.value }}
      </span>
      <span v-else class="overview-card-value">n/d</span>
    </div>
    <span class="overview-card-title">{{ overviewItem.title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    overviewItem: Object,
    activeBots: Boolean
  },
  methods: {
    // load icon
    loadImage(path) {
      return require(`@/${path}`)
    }
  }
}
</script>

<style scoped>
.overview-card {
  padding: 10px;
  margin: 10px;
  height: 100px;
  width: 225px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.overview-card-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.overview-card-title {
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 15px;
  color: rgba(44, 62, 80, 0.69);
}

.overview-card-image {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
}

.overview-card-icon {
  width: 50px;
}

.overview-card-value {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 36px;
  font-weight: bold;
  color: #2c3e50;
  text-align: end;
}

.text {
  font-size: 22px !important;
}

@media (max-width: 850px) {
  .overview-card {
    width: 180px;
    padding: 5px;
    margin: 5px;
  }

  .overview-card-title {
    font-size: 12px;
  }

  .overview-card-value {
    font-size: 24px;
  }

  .text {
    font-size: 18px !important;
  }
}
</style>
