<template>
  <div>
    <div v-if="!loading" class="subscribed-bot">
      <span class="button-p button-shadow back" @click="goBack()">&larr; Back to subscriptions</span>
      <div class="content-container">
        <div class="sub-bot-info">
          <div class="subscription-title">
            <img class="bot-logo" src="@/assets/bots/bot_template.png" alt />
            <span class="title sub-bot-title">{{ subscribedBot.bot_name }}</span>
          </div>
          <div class="subscription-info-content">
            <div class="subscription-info-item">
              <div class="subscription-info-item-icon">
                <img class="item-icon large" :src="loadExchangeIcon()" alt />
              </div>
              <div class="subscription-info-item-text">
                <span v-if="exchange.connection_name" class="title item-value exchange_name desktop" :title="exchange.connection_name"> {{ exchange.connection_name.length > 15 ? exchange.connection_name.substr(0, 10) + '...' : exchange.connection_name }}</span>
                <span v-if="exchange.connection_name" class="title item-value exchange_name mobile" :title="exchange.connection_name"> {{ exchange.connection_name.length > 10 ? exchange.connection_name.substr(0, 10) + '...' : exchange.connection_name }}</span>
                <span class="title item-title">Exchange</span>
              </div>
            </div>
            <div class="subscription-info-item">
              <div class="subscription-info-item-icon">
                <img class="item-icon large" src="@/assets/trading-platform/invested.png" alt />
              </div>
              <div class="subscription-info-item-text">
                <span v-if="subscribedBot.balance_allocation_method === 'qty'" class="title item-value">
                  <span v-if="subscribedBot.market === 'BTC'">{{ Number(subscribedBot.allocated_balance).toFixed(8) }}</span>
                  <span v-else>{{ Number(subscribedBot.allocated_balance).toFixed(2) }}</span>
                  <span class="coin"> {{ subscribedBot.market }} </span>
                </span>
                <span v-else class="title item-value column">
                  <span>{{ (subscribedBot.allocated_balance * 100).toFixed(0) }}</span>
                  <span class="small-text">% of total balance</span>
                </span>
                <span class="title item-title">Allocated</span>
              </div>
            </div>
            <div class="subscription-info-item">
              <div class="subscription-info-item-icon">
                <img class="item-icon large" src="@/assets/trading-platform/status.png" alt />
              </div>
              <div class="subscription-info-item-text">
                <span v-if="subscribedBot.active" class="title item-value green">Trading</span>
                <span v-else class="title item-value red">Paused</span>
                <span class="title item-title">Status</span>
              </div>
            </div>
            <div v-if="subscribedBot.enable_shorts !== null" class="subscription-info-item">
              <div class="subscription-info-item-icon">
                <img class="item-icon large" src="@/assets/trading-platform/shorts.png" alt />
              </div>
              <div class="subscription-info-item-text">
                <span v-if="subscribedBot.enable_shorts" class="title item-value green">Enabled</span>
                <span v-else class="title item-value red">Disabled</span>
                <span class="title item-title">Shorts</span>
              </div>
            </div>
          </div>
        </div>
        <div class="edit-item-buttons">
          <span class="form-button save-bot-button" @click="toggleModifyBotModal()">Modify bot</span>
          <span class="form-button cancel-bot-button cancel" @click="toggleStopBotModal()">Stop bot</span>
        </div>
        <div class="sub-bot-graph card">
          <div class="sub-bot-graph-title">
            <span class="title sub-bot-graph-text">Performance</span>
            <time-filter-dropdown :isBotGraph="true" @updateTimeFrame="updateGraphTimeFrame($event)" />
          </div>
          <div class="graph">
            <LineGraph class="bot-graph" ref="subBotGraph" :isBotGraph="true" :subBotGraph="true" :interval="graphTimeframe" :id="subscribedBot.bot_name" :graphData="graphData" :graphLabels="graphLabels" />
            <canvas id="lineChart" />
          </div>
        </div>
      </div>
      <stop-bot-modal v-if="showStopBotModal" @click="toggleStopBotModal()" @stopBot="stopBot($event)" />
      <update-subscription-modal v-if="showModifyBotModal" :subscribedBot="subscribedBot" :selectedConnection="subscribedBot.connection_id" @click="toggleModifyBotModal()" @updateBot="updatedBot($event)" />
    </div>
    <loading v-else class="loading" :active="loading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StopBotModal from '@/components/shared/modals/StopBotModal'
import UpdateSubscriptionModal from '@/components/shared/modals/UpdateSubscriptionModal'
import Notification from '@/components/shared/Notification'
import TimeFilterDropdown from '@/components/trading/TimeFilterDropdown'
import Loading from '@/components/shared/Loading.vue'
import LineGraph from '@/components/shared/LineGraph'

export default {
  name: 'Subscribedbot',
  components: {
    UpdateSubscriptionModal,
    Notification,
    StopBotModal,
    TimeFilterDropdown,
    Loading,
    LineGraph
  },
  computed: mapGetters(['userSubscribedBots', 'darkTheme']),
  data() {
    return {
      loading: false,
      subscribedBot: {},
      subBot: {},
      exchange: {},
      expiryDate: 0,
      subPlan: '',
      confirmMessage: false,
      showStopBotModal: false,
      showModifyBotModal: false,
      botStatus: false,
      graphData: [],
      graphLabels: [],
      returnData: true,
      graphTimeframe: {
        value: 6,
        name: 'All Time',
        time: '50 Years'
      }
    }
  },
  watch: {
    darkTheme() {
      this.loadCharts()
    }
  },
  created() {
    if (this.$route.params.subBot) {
      this.$session.set('lastSubBot', this.$route.params.subBot)
    }
    this.subBot = this.$session.get('lastSubBot')
    this.getSubscribedBot()
  },
  methods: {
    ...mapActions(['loadSubscribedBots', 'addNotification']),
    goBack() {
      this.$router.go(-1)
    },
    loadExchangeIcon() {
      try {
        return require('@/assets/' + this.subscribedBot.exchange.toLowerCase() + '-logo.png')
      } catch (e) {
      }
    },
    loadCharts() {
      this.$api
        .get('/bots/get_chart', {
          params: {
            user_id: this.$session.get('uid'),
            timezone: this.$session.get('timezone'),
            bot_id: this.subscribedBot.bot_id,
            interval: this.graphTimeframe.time
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.populateGraphData(res.data.data)
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    populateGraphData(res) {
      this.graphData = []
      this.graphLabels = []
      let counter = 0
      for (const key in res) {
        if (!this.graphLabels.includes(key.split(' ')[0])) {
          this.graphLabels.push(key.split(' ')[0])
          this.graphData.push(res[key])
          counter++
        } else {
          this.graphData.splice(counter - 1, 1)
          this.graphLabels.splice(counter - 1, 1)

          this.graphLabels.push(key.split(' ')[0])
          this.graphData.push(res[key])
        }
      }
    },
    updateGraphTimeFrame(timeframe) {
      this.graphTimeframe = timeframe
      this.loadCharts()
    },
    toggleStopBotModal() {
      this.showStopBotModal = !this.showStopBotModal
    },
    toggleModifyBotModal() {
      this.showModifyBotModal = !this.showModifyBotModal
    },
    getSubscribedBot() {
      this.loading = true
      this.$api
        .get('/bots/get_connected_bot', {
          params: {
            user_id: this.$session.get('uid'),
            bot_id: this.subBot.bot_id,
            connection_id: this.subBot.connection_id,
            timezone: this.$session.get('timezone'),
            interval: this.graphTimeframe.time
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.subscribedBot = res.data.data
            if (this.subscribedBot.expiry_date) this.expiryDate = this.subscribedBot.expiry_date.split(' ')[0]
            this.loadCharts()
            this.getExchangeAccount()
          }
        })
        .then(() => {
          this.$refs.subBotGraph.generateGraph()
        })
        .catch((err) => {
          this.loading = false
          if (err.reponse && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    getExchangeAccount() {

      this.$api
        .get('/exchanges/get_connection', {
          params: {
            user_id: this.$session.get('uid'),
            connection_id: this.subscribedBot.connection_id
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.exchange = res.data.data[0]
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    updatedBot(updatedBot) {
      this.loading = true
      this.$api
        .post('/bots/modify_bot', updatedBot, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.getSubscribedBot()
          }
        })
        .then(() => {
          const notification = {
            status: 0,
            title: 'Updated bot',
            text: 'Your ' + this.subscribedBot.bot_name + ' connection has been updated'
          }
          this.addNotification(notification)
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    stopBot(closePositions) {
      this.loading = true
      let temp = {
        user_id: this.$session.get('uid'),
        bot_id: this.subscribedBot.bot_id,
        connection_id: this.subscribedBot.connection_id,
        exchange: this.subscribedBot.exchange,
        close_positions: closePositions
      }
      this.$api
        .post('/bots/stop_bot', temp, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            const notification = {
              status: 0,
              title: 'Bot stopped',
              text: 'Your ' + this.subscribedBot.bot_name + ' connection has been stopped'
            }
            this.addNotification(notification)
            this.$router.push({ name: 'Bots', params: { page: 'marketplace' } })
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
  }
}
</script>
<style scoped>
.back {
  font-size: 16px;
  font-weight: 500;
  width: 225px;
  cursor: pointer;
}

.subscribed-bot {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-bot-info {
  margin-top: 30px;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.subscription-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sub-bot-title {
  height: 100px;
  display: flex;
  align-items: center;
}

.subscription-info-content {
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.subscription-info-item {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.subscription-info-item-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
}

.subscription-info-item-text {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.coin {
  font-size: 14px;
}

.item-icon {
  width: 100%;
}

.item-value {
  height: auto;
  font-size: 32px;
  color: var(--text-primary);
}

.payment-status {
  font-size: 28px;
}

.item-title {
  height: auto;
  font-size: 20px;
  color: var(--text-primary);
  opacity: 0.7;
}

.edit-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 50px;
  margin-bottom: 50px;
  gap: 100px;
}

.small-text {
  font-size: 16px;
}

.cancel-bot-button {
  width: 300px;
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-bot-button:hover {
  background-color: var(--red);
}

.res {
  background-color: var(--green);
}

.res:hover {
  background-color: var(--green);
}

.cancel {
  background-color: var(--red);
}

.cancel:hover {
  background-color: var(--red);
}

.save-bot-button {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-bot-graph {
  background-color: var(--card-background);
  height: 450px;
  width: 100%;
}

.sub-bot-graph-title {
  padding: 5px;
  height: 50px;
  width: 100%;
  background-color: var(--text-primary);
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-bot-graph-text {
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 26px;
}

.bot-logo {
  height: 125px;
  border-radius: 50%;
  border: 1px solid var(--secondary-blue);
}

.graph {
  padding: 0 10px;
  margin-bottom: 10px;
  height: 90%;
  width: 100%;
  z-index: 1;
}

.red {
  color: var(--red);
}

.green {
  color: var(--green);
}

.exchange_name {
  font-size: 22px;
}

.mobile {
  display: none;
}

.no-data-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.waiting-for-payment {
  font-size: 28px;
}

.overview-graph-toggle {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.overview-graph-toggle-title {
  color: var(--text-secondary);
  padding: 0 10px;
  cursor: pointer;
}

.overview-graph-toggle-title:first-child {
  border-right: 1px solid var(--border);
}

.selectedGraph {
  font-weight: bold;
}

.loading {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bot-graph {
  width: 100%;
  height: 100%;
}

@media (max-width: 700px) {
  .edit-item-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .bot-logo {
    height: 80px;
  }
}
</style>
