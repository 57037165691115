<template>
  <div class="subscription-step">
    <div class="bot-selector">
      <span class="step-title">1. Choose a bot</span>
      <span v-if="!this.selectedBot" class="button-p button-shadow select-bot-button" @click="openBotMarket()">
        Select Bot
      </span>
      <div v-else class="selected-bot">
        <div class="selected-bot-item card">
          <div class="selected-bot-item-name">
            <img class="bot-img-icon" src="@/assets/bots/bot_template.png" alt=""/>
            <span v-if="this.selectedBot.bot_name">{{ this.selectedBot.bot_name }}</span>
          </div>
        </div>
        <span class="button-p button-shadow bot-selected" @click="openBotMarket()">
          <img style="width:20px" src="@/assets/edit-white.png" alt=""/>
        </span>
      </div>
      <div v-if="selectedBot" class="next-step">
        <span class="form-button button-shadow next-button" @click="nextStep()">Next</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    selectedBot: Object,
  },
  methods: {
    // take user to marketplace
    openBotMarket() {
      this.$router.push({name: 'Bots', params: {page: 'marketplace'}})
    },
    nextStep() {
      this.$emit('nextStep', this.selectedBot)
    }
  }
}
</script>

<style scoped>
.subscription-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bot-selector {
  display: flex;
  flex-direction: column;
}

.selected-bot {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-bot-button {
  margin: 10px 0;
  width: 130px;
}

.selected-bot-item {
  height: 60px;
  width: 400px;
  padding: 10px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 2px 5px 2px var(--shadow);
}

.selected-bot-item-name {
  display: flex;
  align-items: center;
}

.bot-selected {
  margin-left: 15px;
  height: 50%;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.remove-bot-button {
  margin-left: 20px;
  cursor: pointer;
}

.next-button {
  background-color: var(--secondary-blue);
  padding: 10px 20px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bot-img-icon {
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

@media (max-width: 850px) {
  .selected-bot {
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .selected-bot-item {
    width: 100%;
  }

  .next-step {
    margin-top: 50px;
    justify-content: center;
  }

  .next-button {
    width: 100%;
  }
}
</style>
