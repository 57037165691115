<template>
  <div>
    <div class="page-back" @click="closeModal()">&larr; Back</div>
    <br>
    <div class="modal-title">Checkout</div>
    <div class="modal-content">
      <div class="section">
        <div class="cart">
          <div class="cart-items">
            <div class="payment-form">
              <div class="checkout-input">
                <div class="no-payment-button" v-if="!updatedSubscription.extend_duration_months || paymentType !== 'all_at_once'">
                  <span class="button-p button-shadow payment-b" @click="createSubscription()">Update Subscription</span>
                </div>
                <div v-if="updatedSubscription.extend_duration_months > 0 && paymentType !== 'recurring'" class="payment-methods">
                  <div class="payment-types" v-for="method in availablePaymentMethods" :key="method.id">
                    <div class="payment-types-item" :class="{ selectedMethod: selectedPaymentMethod === method.id }" @click="selectPaymentMethod(method)">
                      <img class="payment-types-item-image" :src="loadImage(method.imgSrc)" alt />
                    </div>
                  </div>
                </div>
                <loading class="loading-icon" :active="loadingPayment" />
                <div v-show="selectedPaymentMethod === 1 && !loadingPayment" class="user-form-input">
                  <span>Name on card</span>
                  <input class="form-input" :class="{ errorInput: false }" v-model="nameOnCard" type="text" placeholder="Name on card" />
                </div>
                <span v-show="selectedPaymentMethod === 1 && !loadingPayment">Card information</span>
                <div v-show="selectedPaymentMethod === 1 && !loadingPayment" class="card-info">
                  <div class="form-input card-info-largecard-info-small" id="card-number"></div>
                  <div class="form-input card-info-small expiry" id="card-expiry"></div>
                  <div class="form-input card-info-small" id="card-cvc"></div>
                  <div id="card-errors"></div>
                </div>
                <div v-show="selectedPaymentMethod === 2 && !loadingPayment">
                  <span>iDeal</span>
                  <div id="ideal-bank-element"></div>
                </div>
                <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
              </div>
            </div>
            <div v-show="!loadingPayment && selectedPaymentMethod > 0" class=" payment">
              <span class="total">Total: ${{ this.subscriptionPrice }}</span>
              <div class="payment-buttons cart-pay">
                <div v-show="selectedPaymentMethod === 2">
                  <div class="paypal-button" ref="paypal"></div>
                </div>
                <span class="button-p button-shadow payment-b" @click="createSubscription()">
                  <div class="checkout-type" v-show="selectedPaymentMethod === 1">
                    <img class="checkout-type-img" src="@/assets/payment-methods/credit-card.png" alt="">
                    <span>Pay with card</span>
                  </div>
                  <div class="checkout-type" v-show="selectedPaymentMethod === 2">
                    <img class="checkout-type-img" src="@/assets/payment-methods/ideal.png" alt="">
                    <span>Pay with iDeal</span>
                  </div>
                  <div class="checkout-type" v-show="selectedPaymentMethod === 3">
                    <img class="checkout-type-img" src="@/assets/payment-methods/bancontact.png" alt="">
                    <span>Pay with Bancontact</span>
                  </div>
                </span>
                <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AllocateAmount from '@/components/trading/subscribeBot/AllocateAmount.vue'
import ConnectExchange from '@/components/trading/subscribeBot/ConnectExchange.vue'
import NewExchangeAccount from '@/components/trading/NewExchangeAccount.vue'
import SubscriptionPlan from '@/components/trading/subscribeBot/SubscriptionPlan.vue'
import UpdateExchangeDropdown from '@/components/trading/UpdateExchangeDropdown.vue'
import Loading from '@/components/shared/Loading.vue'
import { client, paypalCheckout } from 'braintree-web';

export default {
  name: 'PaymentModal',
  components: {
    AllocateAmount,
    ConnectExchange,
    NewExchangeAccount,
    SubscriptionPlan,
    UpdateExchangeDropdown,
    Loading
  },
  computed: mapGetters(['userCart']),
  props: {
    updatedSubscription: Object,
    selectedConnection: Object,
    subscriptionPrice: Number,
    paymentType: String
  },
  data() {
    return {
      nameOnCard: '',
      userCards: [],
      purchaseModal: false,
      purchaseLoading: false,
      address: '',
      postCode: '',
      region: '',
      country: '',
      email: '',
      userCard: null,
      stripeCard: null,
      stripe: null,
      errorMsg: '',
      selectedPaymentMethod: 0,
      availablePaymentMethods: [
        {
          id: 1,
          name: 'card',
          imgSrc: 'assets/payment-methods/credit-card.png'
        },
        {
          id: 2,
          name: 'ideal',
          imgSrc: 'assets/payment-methods/ideal.png'
        },
        // {
        //   id: 3,
        //   name: 'bancontact',
        //   imgSrc: 'assets/payment-methods/bancontact.png'
        // },
        // {
        //   id: 6,
        //   method: 'paypal',
        //   imgSrc: 'assets/payment-methods/paypal.png'
        // }
      ],
      transactionId: '',
      hostedFieldInstance: false,
      nonce: "",
      error: "",
      clientToken: "",
      addNewCard: false,
      loadingPayment: false,
    }
  },
  mounted() {
    this.loadStripe()
    this.updatedSubscription.payment_method = 'card'
  },
  methods: {
    ...mapActions(['addNotification']),
    getClientToken() {
      this.$api
        .get('/subscriptions/braintree/generate_client_token', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.clientToken = res.data.data
          }
        })
        .then(() => {
          this.initBraintree()
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.credentialsError = true
          }
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    initBraintree() {
      client.create({
        authorization: this.clientToken
      })
        .then(clientInstance => {
          let promises = [];
          promises.push(paypalCheckout.create({ client: clientInstance }));
          return Promise.all(promises);
        })
        .then(instances => {
          const paypalInstance = instances[0];
          return paypal.Button.render({
            env: 'sandbox',
            style: {
              label: 'paypal',
              size: 'responsive',
              shape: 'pill',
              height: 40
            },
            payment: () => {
              return paypalInstance.createPayment({
                flow: 'vault',
              })
            },
            onAuthorize: (data, options) => {
              return paypalInstance.tokenizePayment(options).then(payload => {
                this.error = "";
                //this.nonce = payload.nonce;
                this.nonce = 'fake-paypal-billing-agreement-nonce'
              })
            },
            onCancel: (data) => {

            },
            onError: (err) => {
              console.error(err);
              this.error = "An error occurred while processing the paypal payment.";
            }
          }, this.$refs.paypal)
        })
        .catch(err => {
          console.error(err);
          this.error = "An error occurred while creating the payment form.";
        })
    },
    closeModal() {
      this.$emit('click')
    },
    addNewStripeCard() {
      this.addNewCard = !this.addNewCard
    },
    selectStripeCard(card) {
      this.updatedSubscription.first_name = card.billing_details.name.split(' ')[0]
      this.updatedSubscription.last_name = card.billing_details.name.split(' ')[1]
      this.updatedSubscription.email = card.billing_details.email
      this.updatedSubscription.country = card.billing_details.address.country
      this.updatedSubscription.address = card.billing_details.address.line1 + ', ' + card.billing_details.address.postal_code + ', ' + card.billing_details.address.state

      this.stripeCard = card
    },
    selectPaymentMethod(method) {
      this.selectedPaymentMethod = method.id
      this.updatedSubscription.payment_method = method.name
      switch (method) {
        case 1:
          this.getUserStripeCards()
          this.updatedSubscription.payment_method = 'card'
          break;
        case 2:
          this.updatedSubscription.payment_method = 'ideal'
          break
      }
    },
    loadImage(path) {
      return require(`@/${path}`)
    },
    loadCardIcon(type) {
      return require('@/assets/' + type + '.png')
    },
    async loadStripe() {
      const Stripe = require('@stripe/stripe-js')
      this.stripe = await Stripe.loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
      this.elements = this.stripe.elements()
      this.createCardElement()
      this.createIdealElement()
    },
    async createCardElement() {
      const elements = this.stripe.elements()

      const style = {
        base: {
          color: '#2c3e50',
          fontWeight: 500,
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF'
          },
          ':-webkit-autofill': {
            color: '#e39f48'
          }
        },
        invalid: {
          color: '#E25950',
          '::placeholder': {
            color: '#FFCCA5'
          }
        }
      }

      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid'
      }

      this.userCard = elements.create('cardNumber', {
        style,
        classes: elementClasses
      })
      this.userCard.mount('#card-number')

      const cardExpiry = elements.create('cardExpiry', {
        style,
        classes: elementClasses
      })
      cardExpiry.mount('#card-expiry')

      const cardCvc = elements.create('cardCvc', {
        style,
        classes: elementClasses
      })
      cardCvc.mount('#card-cvc')
    },
    async createIdealElement() {

      const options = {
        // Custom styling can be passed to options when creating an Element
        style: {
          base: {
            padding: '10px 12px',
            cursor: 'pointer',
            color: '#32325d',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            },
          },
        },
      };

      // Create an instance of the idealBank Element
      this.idealBank = this.elements.create('idealBank', options);

      // Add an instance of the idealBank Element into
      // the `ideal-bank-element` <div>
      this.idealBank.mount('#ideal-bank-element');
    },
    getUserStripeCards() {
      this.$api
        .get('/subscriptions/get_saved_payment_details', {
          params: {
            user_id: this.$session.get('uid'),
            payment_platform: 'stripe',
            payment_method: 'card'
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.userCards = res.data.data
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    createSubscription() {
      this.loadingPayment = true
      this.errorMsg = ''

      if (this.errorMsg !== '') {
        return false
      }
      if (this.address && this.postCode && this.region) {
        this.updatedSubscription.address = this.address + ', ' + this.postCode + ', ' + this.region
      }
      this.$api
        .post('/bots/modify_subscription', this.updatedSubscription, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(async (res) => {
          if (res.status === 200 || res.status === 'success') {
            if (res.data.data.surcharge) {
              if (this.updatedSubscription.payment_method === 'card') {
                this.cardPayment(res.data.data.surcharge.client_secret)
              } else if (this.updatedSubscription.payment_method === 'ideal') {
                this.idealPayment(res.data.data.surcharge.client_secret)
              }
              return
            }

            let subscriptionNotification = {
              status: 0,
              title: 'Subscription updated',
              text: 'Your subscription has succesfully been updated'
            }
            this.addNotification(subscriptionNotification)
            this.loadingPayment = false
            this.$emit('closeModal')
          }
        })
        .catch((err) => {
          this.loadingPayment = false
          // if (err.response.data.error.type === 'Could not validate credentials') {
          //   this.$session.destroy()
          //   this.$router.push({ name: 'Login' })
          // }
          if (err.response.data.error.type === 'EXCHANGE_ERROR') {
            this.errorMsg = 'You cannot allocate more than 1000 USDT accross all bots'
          }
        })
    },
    async cardPayment(client_secret) {
      let paymentCard = {
        card: this.userCard,
        billing_details: {
          name: this.nameOnCard,
          email: this.$session.get('email')
        }
      }
      await this.stripe
        .confirmCardPayment(client_secret, {
          payment_method: paymentCard
        })
        .then((result) => {
          if (result.error) {
            this.loadingPayment = false
            this.errorMsg = 'Something went wrong please try again later'
            // Show error to your customer (e.g., insufficient funds)
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              let subscriptionNotification = {
                status: 0,
                title: 'Subscription updated',
                text: 'Your subscription has succesfully been updated'
              }
              this.addNotification(subscriptionNotification)
              this.loadingPayment = false
              this.$emit('closeModal')
            } else {
              vm.purchase.content = 'There has been an error processing your payment'
            }
          }
        })
    },
    async idealPayment(client_secret) {
      await this.stripe.confirmIdealPayment(client_secret,
        {
          payment_method: {
            ideal: this.idealBank,
            billing_details: {
              email: this.$session.get('email')
            }
          },
          return_url: process.env.VUE_APP_MODIFY_URL
        }
      )
    },
    async bancontactPayment(client_secret) {
      await this.stripe.confirmBancontactPayment(client_secret,
        {
          payment_method: {
            billing_details: {
              address: {
                city: null,
                country: this.subscribedBot.country,
                line1: this.address,
                line2: null,
                postal_code: this.postCode,
                state: this.region
              },
              name: this.subscribedBot.first_name + ' ' + this.subscribedBot.last_name,
              email: this.subscribedBot.email
            }
          },
          return_url: process.env.VUE_APP_MODIFY_URL
        }
      ).then((result) => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            this.loadingPayment = false

          } else {
            this.purchase.content = 'There has been an error processing your payment'
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.content {
  padding: 10px;
}

.modal-title {
  font-size: 28px;
}

.modal-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  transition: all 0.3s ease;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-close {
  font-size: 40px;
  margin-top: -20px;
  float: right;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
  font-size: 18px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.cart-item {
  width: 100%;
  margin: 10px 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.proceed-button {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.add-to-cart {
  margin-top: 30px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.total-price {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}

::v-deep .v-application--wrap {
  min-height: 1vh !important;
}

.modal-item {
  display: flex;
}

.red-bg {
  background-color: #d56f7d;
}

.my-cart {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.cart-content {
  margin: 20px auto 0;
  width: 90%;
  display: flex;
  justify-content: center;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: initial;
}

.stepper {
  margin: 0 auto;
  width: 90%;
}

.cart {
  padding: 10px;
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
}

.cart-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-logo {
  margin-right: 10px;
}

.empty-cart {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.payment {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.payment-form {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-input {
  padding: 5px;
  font-size: 16px;
  background-color: var(--white);
}

.checkout-input {
  width: 100%;
}

.country {
  width: 60%;
}

.form-input {
  background-color: #fff;
}

.payment-header {
  width: 70%;
  text-align: left;
  font-size: 30px;
  font-weight: 400;
}

.total {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
}

.total-text {
  font-size: 28px;
  display: flex;
  justify-content: flex-end;
}

.payment-b {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  text-align: center;
  font-weight: bold;
}

.payment-b-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  background: #ccd0d2;
  cursor: default;
}

.card-element {
  height: 50%;
}

.page-back {
  text-decoration: underline;
  cursor: pointer;
}

.payment-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-separator {
  margin: 10px 0;
}

.paypal-checkout {
  z-index: 0;
}

.street {
  width: 50%;
}

.post-code {
  width: 20%;
  margin: 0 5px;
}

.region {
  width: 30%;
}

.user-form-input {
  width: 100%;
}

.first-name {
  margin-right: 5px;
}

.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ccd0d2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  color: #fa755a;
}

.payment-methods {
  display: flex;
  flex-direction: row;
}

.payment-types {
  height: 100px;
  padding: 10px 0;
  margin-right: 20px;
}

.payment-types-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
}

.payment-types-item-image {
  height: 80%;
}

.selectedMethod {
  border: 4px solid #63ba62;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.05);
}

.user-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-info-small {
  width: 20% !important;
}

.expiry {
  margin-right: 5px;
  margin-left: 5px;
}

.card-info-large {
  width: 40% !important;
}

.country-selector {
  width: 100%;
  cursor: pointer;
}

content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 34px;
  font-weight: bold;
}

.subtext {
  font-size: 24px;
}

.modal-close {
  font-size: 50px;
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -10px;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.loading-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.existing-payment-method {
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 400px;
  height: 50px;
  border: 2px solid var(--blue-primary);
  border-radius: 5px;
  justify-content: space-between;
  cursor: pointer;
}

.existing-card-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.existing-card-number {
  margin: 0 10px;
  cursor: pointer;
}

.existing-card-icon {
  height: 100%;
  width: 40px;
}

.add-new-card {
  cursor: pointer;
}

.new-card-b {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  margin: 20px 0;
  background-color: var(--header-color);
}

.checkout-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkout-type-img {
  margin-right: 5px;
  height: 30px;
}

.no-payment-button {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 850px) {
  .modal-container {
    padding: 20px 15px;
    width: 95%;
    height: 600px;
  }

  .modal-content {
    width: 100%;
  }

  .content {
    padding: 0;
  }

  .proceed-button {
    justify-content: center;
    margin-bottom: 40px;
  }

  .total-price {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }

  .payment-buttons {
    width: 100%;
  }

  .payment-b {
    width: 100%;
  }
}
</style>
