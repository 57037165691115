<template>
  <div class="bot-item-card-container">
    <div class="closed-bot-item" @click="toggleTableItem()">
      <div class="closed-exchange-item card" :class="name">
        <img class="exchange-logo" :src="loadExchangeIcon()" alt="">
        <span>{{ getExchangeName() }}</span>
      </div>
    </div>

  </div>

</template>

<script>
import ArrowHeadDown from '../shared/ArrowHeadDown.vue'

export default {
  components: {ArrowHeadDown},
  props: {
    name: String,
    exchange: Object,
    selectedExchange: String
  },
  data() {
    return {
      open: false,
    }
  },
  watch: {
    selectedExchange() {
      if (this.selectedExchange !== this.name) this.open = false
    }
  },
  methods: {
    loadExchangeIcon() {
      return require('@/assets/' + this.name.toLowerCase() + '-logo.png')
    },
    getExchangeName() {
      switch (this.name) {
        case 'bybit_futures':
          return 'Bybit'
        case 'okx_futures':
          return 'OKX'
        case 'bitget_futures':
          return 'Bitget'
        case 'kraken_futures':
          return 'Kraken'
        default:
          return this.name[0].toUpperCase() + this.name.substr(1)
      }
    },
    toggleTableItem() {
      this.open = !this.open
      let temp = {
        name: this.name,
        exchangeData: this.exchange
      }
      if (this.open) this.$emit('setSelectedExchange', temp)
    }
  }
}
</script>

<style scoped>
.bot-item-card-container {
  position: relative;
}

.closed-bot-item {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.closed-exchange-item {
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  gap: 5px;
  cursor: pointer;
  color: var(--secondary-blue);
}

.bot-table-items {
  width: auto;
  margin-top: 5px;
  padding: 5px;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  cursor: pointer;
  transition: 0.5s;
}

.table-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  font-weight: 300;
  font-size: 16px;
}

.table-item-content {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 5px;
  font-weight: 500;
  transition: 0.5s;
  color: var(--secondary-blue);
}

.table-item-title {
  color: var(--secondary-blue);
}

.exchange-logo {
  height: 25px;
}

.exchange-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.binance {
  background-color: var(--binance);
}

.bitvavo {
  background-color: var(--bitvavo);
}

.okx_futures {
  background-color: var(--okx);
}

.bybit_futures {
  background-color: var(--bybit);
}

.bitget_futures {
  background-color: var(--bitget);
}

.kraken_futures {
  background-color: var(--kraken);
}

.ftx {
  background-color: var(--ftx);
}

.kucoin {
  background-color: var(--kucoin);
}

.disabled {
  background-color: var(--disabled);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  box-shadow: none;
}

.selected {
  height: 65px;
  width: 65px;
  border: 2px solid var(--secondary-blue);
}

.green {
  color: #00ff26;
}

.red {
  color: #ff0044;
}

@media (max-width: 800px) {
  .bot-table-items {
    padding: 5px;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
  }
}
</style>
