<template>
  <div class="subscription-modal">
    <loading class="loading" :active="loadingPayment" />
    <div v-show="!loadingPayment" class="payment-form">
      <span class="section-title">Payment details:</span>
      <div class="payment-methods">
        <div class="payment-types" v-for="method in availablePaymentMethods" :key="method.id">
          <div class="payment-types-item" :class="{ selectedMethod: selectedPaymentMethod === method.id }" @click="selectPaymentMethod(method)">
            <img class="payment-types-item-image" :src="loadImage(method.imgSrc)" alt />
          </div>
        </div>
      </div>
      <div class="payment-form-items">
        <div v-show="selectedPaymentMethod === 0">
          <div class="user-form-input">
            <span>Name on card</span>
            <input class="form-input" :class="{ errorInput: false }" v-model="nameOnCard" type="text" placeholder="Name on card" />
          </div>
          <span>Card information</span>
          <div class="card-info">
            <div class="form-input card-number" id="card-number" />
            <div class="form-input card-expiry" id="card-expiry" />
            <div class="form-input card-cvc" id="card-cvc" />
            <div id="card-errors" />
          </div>
        </div>
        <div v-show="selectedPaymentMethod === 1">
          <span>iDeal</span>
          <div class="form-input" id="ideal-element" />
        </div>
        <div v-show="selectedPaymentMethod === 3">
          <span>Paypal:</span>
          <span class="paypal-button" @click="modifyPaypalSubscription()"><img class="paypal-img" src="@/assets/paypal-logo.png" alt=""></span>
        </div>
      </div>
      <div class="payment">
        <div class="payment-row">
          <span v-show="!loadingPayment" class="new-subscription">
            Upgrade to
            <span v-if="!newSubscription.new_subscription_plan.split('_')[1]" class="sub-type">{{ newSubscription.new_subscription_plan [0].toUpperCase() + newSubscription.new_subscription_plan.substr(1) }}</span>
            <span v-if="newSubscription.new_subscription_plan.split('_')[1] && newSubscription.new_subscription_plan.split('_')[0] === 'gold'" class="sub-type"> 6 month Gold </span>
            <span v-else-if="newSubscription.new_subscription_plan.split('_')[1] && newSubscription.new_subscription_plan.split('_')[0] === 'silver'" class="sub-type"> 6 month Silver </span>
            <span v-else class="sub-type"> 6 month Bronze </span>
            subscription:
          </span>
          <div class="breakdown">
            <div class="price-breakdown">
              <div class="prices">
                <span class="new-amount">€{{ newPrice }}</span>
                <span class="new-amount">
                  <span v-if="surchargeInfo.remaining_credits.toString().length === 3">{{ surchargeInfo.remaining_credits.toString()[0] + '.' + surchargeInfo.remaining_credits.toString()[1] + surchargeInfo.remaining_credits.toString()[2] }}</span>
                  <span v-if="surchargeInfo.remaining_credits.toString().length === 4">{{ surchargeInfo.remaining_credits.toString()[0] + surchargeInfo.remaining_credits.toString()[1] + '.' + surchargeInfo.remaining_credits.toString()[2] + surchargeInfo.remaining_credits.toString()[3] }}</span>
                  <span v-if="surchargeInfo.remaining_credits.toString().length === 5">{{ surchargeInfo.remaining_credits.toString()[0] + surchargeInfo.remaining_credits.toString()[1] + surchargeInfo.remaining_credits.toString()[2] + '.' + surchargeInfo.remaining_credits.toString()[3] + surchargeInfo.remaining_credits.toString()[4] }}</span>
                </span>
              </div>
              <div class="prices-description">
                <span>Price</span>
                <span>Remaining Credits</span>
              </div>
            </div>
            <div class="one-time-breakdown">
              <div class="payment-row">
                <span v-if="surchargeInfo.new_price.toString().length === 3" class="one-time-price">€{{ surchargeInfo.new_price.toString()[0] + '.' + surchargeInfo.new_price.toString()[1] + surchargeInfo.new_price.toString()[2] }}</span>
                <span v-if="surchargeInfo.new_price.toString().length === 4" class="one-time-price">€{{ surchargeInfo.new_price.toString()[0] + surchargeInfo.new_price.toString()[1] + '.' + surchargeInfo.new_price.toString()[2] + surchargeInfo.new_price.toString()[3] }}</span>
                <span v-if="surchargeInfo.new_price.toString().length === 5" class="one-time-price">€{{ surchargeInfo.new_price.toString()[0] + surchargeInfo.new_price.toString()[1] + surchargeInfo.new_price.toString()[2] + '.' + surchargeInfo.new_price.toString()[3] + surchargeInfo.new_price.toString()[4] }}</span>
              </div>
              <span class="one-time">One-Time Payment</span>
            </div>
          </div>
        </div>
        <div v-if="selectedPaymentMethod !== 3" class="payment-buttons">
          <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
          <span class="button-p button-shadow payment-b" @click="modifySubscription()">
            <span>Modify Subscription</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue'
import BillingDetails from '../trading/BillingDetails.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PaymentForm',
  components: {
    Loading,
    BillingDetails,
  },
  props: {
    newSubscription: Object,
    newPrice: String,
    monthly: Boolean,
    surchargeInfo: Object
  },
  data() {
    return {
      loadingPayment: false,
      nameOnCard: '',
      userCard: null,
      idealBank: null,
      stripe: null,
      elements: null,
      errorMsg: '',
      selectedPaymentMethod: 0,
      availablePaymentMethods: [
        {
          id: 0,
          name: 'card',
          imgSrc: 'assets/payment-methods/credit-card.png'
        },
        {
          id: 1,
          name: 'ideal',
          imgSrc: 'assets/payment-methods/ideal.png'
        },
        // {
        //   id: 3,
        //   name: 'paypal',
        //   imgSrc: 'assets/payment-methods/paypal.png'
        // },
      ],
    }
  },
  mounted() {
    this.newSubscription.test_mode = false
    this.selectPaymentMethod(this.availablePaymentMethods[0])
    this.loadStripe()
    if (this.$session.get('trial_period_activated')) {
      this.freeTrial = this.$session.get('trial_period_activated')
    }
    if (this.$session.get('trial_period_used')) {
      this.freeTrialUsed = this.$session.get('trial_period_used')
      this.freeTrial = false
    }
  },
  methods: {
    ...mapActions(['addNotification']),
    async loadStripe() {
      const Stripe = require('@stripe/stripe-js')
      this.stripe = await Stripe.loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
      this.elements = this.stripe.elements()

      this.createCardElement()
      this.createIdealElement()
    },
    selectPaymentMethod(method) {
      this.selectedPaymentMethod = method.id
      this.newSubscription.payment_method = method.name
    },
    loadImage(path) {
      return require(`@/${path}`)
    },
    modifyPaypalSubscription() {
      this.$api
        .post('/subscriptions/paypal/modify_subscription', this.newSubscription, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(async (res) => {
          this.loadingPayment = false
          window.open(res.data.data)
        })
        .catch((err) => {
          this.loadingPayment = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    modifySubscription() {
      this.loadingPayment = true
      this.errorMsg = ''
      this.$api
        .post('/subscriptions/stripe/modify_subscription', this.newSubscription, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(async (res) => {
          if (res.status === 200) {
            switch (this.selectedPaymentMethod) {
              case 0:
                await this.cardPayment(res.data.data.client_secret)
                return
              case 1:
                await this.idealPayment(res.data.data.client_secret)
                return
            }
            this.loadingPayment = false
            this.$emit('paymentComplete')
          }
        })
        .catch((err) => {
          this.loadingPayment = false
          if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
            this.errorMsg = 'You cannot allocate more than 2000 USDT accross all bots'
          }
        })
    },
    async createCardElement() {
      const style = {
        base: {
          color: '#2c3e50',
          fontWeight: 500,
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF'
          },
          ':-webkit-autofill': {
            color: '#e39f48'
          }
        },
        invalid: {
          color: '#E25950',
          '::placeholder': {
            color: '#FFCCA5'
          }
        }
      }

      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid'
      }

      this.userCard = this.elements.create('cardNumber', {
        style,
        classes: elementClasses
      })
      this.userCard.mount('#card-number')

      const cardExpiry = this.elements.create('cardExpiry', {
        style,
        classes: elementClasses
      })
      cardExpiry.mount('#card-expiry')

      const cardCvc = this.elements.create('cardCvc', {
        style,
        classes: elementClasses
      })
      cardCvc.mount('#card-cvc')
    },
    async createIdealElement() {
      const options = {
        style: {
          base: {
            padding: '10px 12px',
            cursor: 'pointer',
            color: '#32325d',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            },
          },
        },
      };
      this.idealBank = this.elements.create('idealBank', options);
      this.idealBank.mount('#ideal-element');
    },
    async cardPayment(client_secret) {
      let paymentCard
      paymentCard = {
        card: this.userCard,
        billing_details: {
          // address: {
          //   country: this.subscription.country,
          //   line1: this.street,
          //   postal_code: this.postCode,
          //   state: this.region
          // },
          name: this.nameOnCard,
          email: this.$session.get('email')
        }
      }
      await this.stripe
        .confirmCardPayment(client_secret, {
          payment_method: paymentCard
        })
        .then((result) => {
          if (result.error) {
            this.loadingPayment = false
            this.errorMsg = result.error.message
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              this.loadingPayment = false
              this.$emit('paymentComplete')
            } else {
              this.purchase.content = 'There has been an error processing your payment'
            }
          }
        })
    },
    async idealPayment(client_secret) {
      this.stripe.confirmIdealPayment(client_secret,
        {
          payment_method: {
            ideal: this.idealBank,
            billing_details: {
              email: this.$session.get('email')
            }
          },
          return_url: process.env.VUE_APP_MODIFY_URL
        }
      )
    },
    getUser() {
      this.$api
        .get('/users/get_user', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$session.set('username', res.data.data.username)
            this.$session.set('email', res.data.data.email)
            this.$session.set('timezone', res.data.data.timezone)
            this.$session.set('trial_period_activated', res.data.data.trial_period_activated)
            this.$session.set('trial_period_used', res.data.data.trial_period_used)
          }
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
    },
  }
}
</script>

<style scoped>
.subscription-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 400;
}

.payment-methods {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.payment-types {
  height: 100px;
  padding: 10px 0;
  margin-right: 20px;
}

.payment-types-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
}

.selectedMethod {
  border: 4px solid #63ba62;
  border-radius: 5px;
  box-shadow: 0 10px 20px var(--shadow), 0 3px 6px var(--shadow);
}

.payment-form-items {
  width: 100%;
}

.payment-types-item-image {
  height: 80%;
}

.user-form-input {
  width: 100%;
}

.card-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-number {
  flex: 3;
}

.card-expiry {
  margin-left: 5px;
  flex: 1;
}

.card-cvc {
  margin-left: 5px;
  flex: 1;
}

.expiry {
  margin: 0 5px;
}

#card-errors {
  color: #fa755a;
}

.payment-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.buttons {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.checkout-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkout-type-img {
  margin-right: 5px;
  height: 30px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.payment-row {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.new-amount {
  max-width: 70px;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 400;
}

.billing-date {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 400;
}

.payment {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.payment-row-new-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.paypal-button {
  padding: 20px 100px;
  border-radius: 5px;
  height: 50px;
  background-color: #ffc53a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.paypal-button:hover {
  background-color: #f3bb37;
}

.paypal-img {
  height: 50px;
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0px 5px;
  border-right: 2px solid var(--border);
  gap: 5px;
}

.prices-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 5px;
  gap: 5px;
  font-size: 18px;
  font-weight: 300;
}

.one-time {
  padding: 0 5px;
  font-size: 16px;
  font-weight: bold;
}

.breakdown {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.price-breakdown {
  display: flex;
  width: 245px;
}

.one-time-breakdown {
  width: 245px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 5px;
  border-top: 2px solid var(--border);
  display: flex;
  padding: 5px 5px 0px;
}

.one-time-price {
  padding: 0 5px;
  max-width: 70px;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: bold;
}

.new-subscription {
  font-size: 18px;
}

.sub-type {
  font-weight: bold;
}
</style>
