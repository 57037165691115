<template>
  <div class="subscription-modal">
    <loading class="loading" :active="loadingPayment" />
    <div v-show="!loadingPayment" class="payment-form">
      <span class="section-title">Billing information:</span>
      <billing-details ref="billingDetails" @setBillingDetails='setBillingDetails($event)' />
      <span class="section-title">Payment details:</span>
      <div class="payment-methods">
        <div class="payment-types" v-for="method in availablePaymentMethods" :key="method.id">
          <div class="payment-types-item" :class="{ selectedMethod: selectedPaymentMethod === method.id }" @click="selectPaymentMethod(method)">
            <img class="payment-types-item-image" :src="loadImage(method.imgSrc)" alt />
          </div>
        </div>
      </div>
      <div class="payment-form-items" v-show=" selectedPaymentMethod > 0">
        <div v-show="selectedPaymentMethod === 1">
          <div class="user-form-input">
            <span>Name on card</span>
            <input class="form-input" :class="{ errorInput: false }" v-model="nameOnCard" type="text" placeholder="Name on card" />
          </div>
          <span>Card information</span>
          <div class="card-info">
            <div class="form-input card-number" id="card-number" />
            <div class="form-input card-expiry" id="card-expiry" />
            <div class="form-input card-cvc" id="card-cvc" />
            <div id="card-errors" />
          </div>
        </div>
        <div v-show="selectedPaymentMethod === 2">
          <span>iDeal</span>
          <div class="form-input" id="ideal-element" />
        </div>
        <div v-show="selectedPaymentMethod === 3">
          <span>Paypal</span>
          <span class="paypal-button" @click="handlePayment()"><img class="paypal-img" src="@/assets/paypal-logo.png" alt=""></span>
        </div>
      </div>
    </div>
    <div class="payment">
      <div class="payment-total">
        <span class="total-amount">Total:</span>
        <span v-if="this.$session.get('free_trial') && subscriptionPlan.monthly" class="total-amount-text">First month FREE then </span>
        <span v-if="subscriptionPlan.monthly" class="total-amount">
          <span v-if="subscriptionPlan.discount_price">€{{ subscriptionPlan.discount_price }}.00/mo</span>
          <span v-else>€{{ subscriptionPlan.base_price }}.00/mo</span>
        </span>
        <span v-else class="total-amount">
          <span v-if="subscriptionPlan.discount_price">€{{ subscriptionPlan.discount_price.toFixed(2) }} every 6 months</span>
          <span v-else>€{{ subscriptionPlan.base_price }}.00/mo</span>
        </span>
      </div>
      <div v-if="selectedPaymentMethod !== 3" class="payment-buttons">
        <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
        <span v-if="!loadingPayment" class="button-p button-shadow payment-b" @click="handlePayment()">
          <span>Purchase Subscription</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue'
import BillingDetails from '../trading/BillingDetails.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PaymentForm',
  components: {
    Loading,
    BillingDetails,
  },
  props: {
    subscriptionPlan: Object
  },
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      isDarkTheme: JSON.parse(localStorage.boostingAlphaDarkTheme),
      loadingPayment: false,
      nameOnCard: '',
      freeTrial: false,
      freeTrialUsed: false,
      street: '',
      postCode: '',
      region: '',
      subscription: {
        user_id: this.$session.get('uid'),
        subscription_plan: '',
        payment_method: '',
        first_name: '',
        last_name: '',
        address: '',
        country: '',
        timezone: this.$session.get('timezone')
      },
      userCard: null,
      idealBank: null,
      stripe: null,
      elements: null,
      errorMsg: '',
      selectedPaymentMethod: 0,
      availablePaymentMethods: [
        { id: 1, name: 'card', imgSrc: 'assets/payment-methods/credit-card.png' },
        { id: 2, name: 'ideal', imgSrc: 'assets/payment-methods/ideal.png' },
        { id: 3, name: 'paypal', imgSrc: 'assets/payment-methods/paypal.png' },
      ],
      paypalSelectedPlan: '',
      paypalPlansMonthly: {
        bronze_1_month_trial: 'P-8NM26235N7135154EMJ7J47Q',
        bronze_1_month: 'P-2U107102J9718950PMJ7JUKY',
        silver_1_month_trial: 'P-1S477618L6037083TMJ7KG5Y',
        silver_1_month: 'P-53J909274A9175526MJ7J57Y',
        gold_1_month_trial: 'P-5HG204553C530950EMJ7J7XA',
        gold_1_month: 'P-6F258256YN7717153MJ7J7LA',
      },
      paypalPlans6Month: {
        bronze_6_months_trial: 'P-2X519552CR2281923MJ7KB5Y',
        bronze_6_months: 'P-89M91420BY090840HMJ7KA3Q',
        silver_6_months_trial: 'P-2X519552CR2281923MJ7KB5Y',
        silver_6_months: 'P-2FB310329K8530931MJ7KCOA',
        gold_6_months_trial: 'P-6WW730150X393090LMJ7KDPA',
        gold_6_months: 'P-74W31035X29773100MJ7KFFY',
      }
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  mounted() {
    this.subscription.subscription_plan = this.subscriptionPlan.value
    this.loadStripe()
  },
  methods: {
    ...mapActions(['addNotification']),
    async loadStripe() {
      const Stripe = require('@stripe/stripe-js')
      this.stripe = await Stripe.loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
      this.elements = this.stripe.elements()

      this.createCardElement()
      this.createIdealElement()
    },
    loadImage(path) {
      return require(`@/${path}`)
    },
    selectPaymentMethod(method) {
      this.subscription.payment_method = method.name
      this.selectedPaymentMethod = method.id
      if (method.name === 'paypal') {
        switch (this.subscription.subscription_plan) {
          case "bronze":
            this.paypalSelectedPlan = this.paypalPlansMonthly.bronze_1_month
            if (this.$session.get('free_trial')) this.paypalSelectedPlan = this.paypalPlansMonthly.bronze_1_month_trial
            break
          case "silver":
            this.paypalSelectedPlan = this.paypalPlansMonthly.silver_1_month
            if (this.$session.get('free_trial')) this.paypalSelectedPlan = this.paypalPlansMonthly.silver_1_month_trial
            break
          case "gold":
            this.paypalSelectedPlan = this.paypalPlansMonthly.gold_1_month
            if (this.$session.get('free_trial')) this.paypalSelectedPlan = this.paypalPlansMonthly.gold_1_month_trial
            break
          case "bronze_6_mo":
            this.paypalSelectedPlan = this.paypalPlans6Month.bronze_6_months
            if (this.$session.get('free_trial')) this.paypalSelectedPlan = this.paypalPlans6Month.bronze_6_months_trial
            break
          case "silver_6_mo":
            this.paypalSelectedPlan = this.paypalPlans6Month.silver_6_months
            if (this.$session.get('free_trial')) this.paypalSelectedPlan = this.paypalPlans6Month.silver_6_months_trial
            break
          case "gold_6_mo":
            this.paypalSelectedPlan = this.paypalPlans6Month.gold_6_months
            if (this.$session.get('free_trial')) this.paypalSelectedPlan = this.paypalPlans6Month.gold_6_months_trial
            break
        }
      }
    },
    handlePayment() {
      this.$refs.billingDetails.setBillingData()
    },
    setBillingDetails(billing_details) {
      this.errorMsg = ''
      for (const key in billing_details) {
        if (billing_details[key] === '') {
          this.errorMsg = 'Missing field(s)'
          return
        }
      }
      this.subscription.address = billing_details.street + ', ' + billing_details.postCode + ', ' + billing_details.region
      this.subscription.first_name = billing_details.firstName
      this.subscription.last_name = billing_details.lastName
      this.subscription.country = billing_details.country
      this.subscription.state = billing_details.region
      this.subscription.postal_code = billing_details.postCode

      switch (this.subscription.payment_method) {
        case 'card':
          if (!this.nameOnCard) {
            this.errorMsg = "Please add the your name on the credit card"
            return
          }
          this.getCreditCardIntent()
          return
        case 'ideal':
          this.createStripeSubscription()
          return
        case 'paypal':
          this.createPaypalSubscription()
          break
        default:
          this.errorMsg = "Please select a payment method"
          break;
      }
    },
    createPaypalSubscription() {
      this.loadingPayment = true
      this.$api
        .post('/subscriptions/paypal/create_subscription', this.subscription, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(async (res) => {
          window.open(res.data.data)
          this.loadingPayment = false
        })
        .catch((err) => {
          this.loadingPayment = false
          if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
            this.errorMsg = 'You cannot allocate more than 2000 USDT accross all bots'
          }
        })
    },
    createStripeSubscription(payment_method) {
      this.loadingPayment = true
      this.errorMsg = ''
      if (payment_method) {
        this.subscription.payment_method_id = payment_method
      }
      this.$api
        .post('/subscriptions/stripe/create_subscription', this.subscription, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then(async (res) => {
          if (res.status === 200 || res.status === 'success') {
            this.$session.set('subscription_id', res.data.data.subscription_id)
            if (this.subscription.payment_method === 'ideal') {
              if (res.data.data.payment_obj) {
                await this.idealSetupIntent(res.data.data.surcharge_obj.client_secret)
                return
              }
              await this.idealPayment(res.data.data.surcharge_obj.client_secret)
              return
            }
            this.loadingPayment = false
            this.$emit('paymentComplete')
          }
        })
        .catch((err) => {
          this.loadingPayment = false
          if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
            this.errorMsg = 'You cannot allocate more than 2000 USDT accross all bots'
          }
        })
    },
    getCreditCardIntent() {
      this.loadingPayment = true
      let body = {
        user_id: this.$session.get('uid'),
        payment_method: "card"
      }
      this.$api.post('/subscriptions/stripe/setup_intent', body, {
        headers: {
          'X-Authorization': this.$session.get('token')
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.cardIntentPayment(res.data.data.intent.client_secret)
          }
        })
        .catch((err) => {
          error.log(err)
        })
    },
    async cardIntentPayment(client_secret) {
      await this.stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: this.userCard,
          billing_details: {
            address: {
              country: this.subscription.country,
              line1: this.street,
              postal_code: this.postCode,
              state: this.region
            },
            name: this.nameOnCard,
            email: this.$session.get('email')
          }
        },
      }).then((result) => {
        if (result.error) {
          this.loadingPayment = false
          this.errorMsg = result.error.message
        } else {
          if (result.setupIntent.status === 'succeeded') {
            let body = {
              user_id: this.$session.get('uid'),
              payment_method: "card",
              setup_intent_id: result.setupIntent.id
            }
            this.$api.post('/subscriptions/stripe/attach_payment_method', body, {
              headers: {
                'X-Authorization': this.$session.get('token')
              }
            })
              .then((res) => {
                if (res.status === 200) {
                  this.createStripeSubscription(res.data.data.payment_method_id)
                }
              })
              .catch((err) => {
                this.loadingPayment = false
                if (err.response && err.response.data.error.type === 'Could not validate credentials') {
                  this.$session.destroy()
                  this.$router.push({ name: 'Login' })
                }
              })
          }
        }
      })
    },
    async idealPayment(client_secret) {
      this.stripe.confirmIdealPayment(client_secret,
        {
          payment_method: {
            ideal: this.idealBank,
            billing_details: {
              address: {
                country: this.subscription.country,
                line1: this.street,
                postal_code: this.postCode,
                state: this.region
              },
              name: this.subscription.first_name + this.subscription.last_name,
              email: this.$session.get('email')
            }
          },
          return_url: process.env.VUE_APP_CHECKOUT_URL
        }
      ).then((result) => {
        if (result.error) {
          //  this.cancelSubscription()
          // Show error to your customer (e.g., insufficient funds)
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            this.loadingPayment = false
          } else {
            this.purchase.content = 'There has been an error processing your payment'
          }
        }
      })
    },
    async idealSetupIntent(client_secret) {
      this.stripe.confirmIdealSetup(client_secret,
        {
          payment_method: {
            ideal: this.idealBank,
            billing_details: {
              address: {
                country: this.subscription.country,
                line1: this.street,
                postal_code: this.postCode,
                state: this.region
              },
              name: this.subscription.first_name + this.subscription.last_name,
              email: this.$session.get('email')
            }
          },
          return_url: process.env.VUE_APP_CHECKOUT_URL
        }
      ).then((result) => {
        if (result.error) {
          // this.cancelSubscription()
          // Show error to your customer (e.g., insufficient funds)
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            this.loadingPayment = false
          } else {
            this.purchase.content = 'There has been an error processing your payment'
          }
        }
      })
    },
    async createIdealElement() {
      let textColor = '#fff'
      let backgroundColor = '#4e5765'
      if (!this.isDarkTheme) {
        textColor = '#2c3e50'
        backgroundColor = '#fff'
      }

      const options = {
        theme: 'stripe',
        style: {
          base: {
            padding: '10px 12px',
            cursor: 'pointer',
            color: textColor,
            fontSize: '16px',
            backgroundColor: backgroundColor,
            ':focus': {
              color: textColor,
            }
          },
        },
      };
      this.idealBank = this.elements.create('idealBank', options);
      this.idealBank.mount('#ideal-element');
    },
    async createCardElement() {
      const style = {
        base: {
          color: '#2c3e50',
          fontWeight: 500,
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          '::placeholder': {
            color: '#CFD7DF'
          },
          ':-webkit-autofill': {
            color: '#e39f48'
          }
        },
        invalid: {
          color: '#E25950',
          '::placeholder': {
            color: '#FFCCA5'
          }
        }
      }
      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid'
      }

      this.userCard = this.elements.create('cardNumber', {
        style,
        classes: elementClasses
      })
      this.userCard.mount('#card-number')

      const cardExpiry = this.elements.create('cardExpiry', {
        style,
        classes: elementClasses
      })
      cardExpiry.mount('#card-expiry')

      const cardCvc = this.elements.create('cardCvc', {
        style,
        classes: elementClasses
      })
      cardCvc.mount('#card-cvc')
    },
  }
}
</script>

<style scoped>
.subscription-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 400;
}

.payment-methods {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.payment-types {
  height: 100px;
  padding: 10px 0;
  margin-right: 20px;
}

.payment-types-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 5px;
  cursor: pointer;
}

.selectedMethod {
  border: 4px solid #63ba62;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.05);
}

.payment-form-items {
  width: 100%;
}

.payment-types-item-image {
  height: 80%;
}

.user-form-input {
  width: 100%;
}

.card-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-number {
  flex: 3;
}

.card-expiry {
  margin-left: 5px;
  flex: 1;
}

.card-cvc {
  margin-left: 5px;
  flex: 1;
}

.expiry {
  margin: 0 5px;
}

#card-errors {
  color: #fa755a;
}

.total-amount {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 500;
}

.payment-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.payment-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.total-amount-text {
  font-size: 16px;
  font-weight: 500;
}

.checkout-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkout-type-img {
  margin-right: 5px;
  height: 30px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paypal-button {
  padding: 20px 100px;
  border-radius: 5px;
  height: 50px;
  background-color: #ffc53a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.paypal-button:hover {
  background-color: #f3bb37;
}

.paypal-img {
  height: 50px;
}
</style>
 