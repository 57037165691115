<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" @click.stop>
        <span class="modal-close" @click="closeModal()">&#10799;</span>
        <div class="content">
          <div class="modal-title">Connect your bot</div>
          <div class="stepper">
            <v-stepper v-if="!isLoading" :value="step" alt-labels>
              <v-stepper-header>
                <v-stepper-step step="1" :complete="currentStep > 1" complete-icon="✓" class="stepper-step">Select your Bot</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2" :complete="currentStep > 2" complete-icon="✓" class="stepper-step">Exchange account</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" :complete="currentStep > 3" complete-icon="✓" class="stepper-step">Allocate an amount</v-stepper-step>
                <v-divider v-if="bot.shorts_enabled && shortAvailableExchanges.includes(exchange)"></v-divider>
                <v-stepper-step v-if=" bot.shorts_enabled && shortAvailableExchanges.includes(exchange)" step=" 4" :complete="currentStep > 4" complete-icon="✓" class="stepper-step">Shorts</v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </div>
          <div class="modal-content">
            <select-bot v-show="currentStep === 1" :selectedBot="bot" @clearBot="clearSelectedBot()" @previousStep="previousStep()" @nextStep="setSelectedBot($event)" />
            <connect-exchange v-if="currentStep === 2" :bot="bot" :exchange="exchange" @previousStep="previousStep()" @nextStep="setSelectedExchange($event)" />
            <allocate-amount v-if="currentStep === 3" :exchange="newBotConnection.exchange" :min="minInvestment" :max="maxInvestment" :maxPct="maxInvestmentPct" :market="bot.market" @previousStep="previousStep()" @nextStep="setSelectedAmount($event)" />
            <shorts-toggle v-if="currentStep === 4" @previousStep="previousStep()" @nextStep="setShortsSelection($event)" />
            <bot-overview v-if="currentStep === 5" :bot="bot" :connectionInfo="newBotConnection" :connectionName="selectedExchangeName" :exchange="newBotConnection.exchange" />
          </div>
          <loading class="loading" :active="isLoading" />
          <br>
          <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
          <div v-if="currentStep === 5 && !isLoading" class="payment-buttons">
            <span class="button-p button-shadow previous-button" @click="previousStep()">Previous Step</span>
            <span class="button-p button-shadow activate-b" @click="activateBotConnection()">Activate Bot</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectExchange from '@/components/trading/subscribeBot/ConnectExchange.vue'
import AllocateAmount from '@/components/trading/subscribeBot/AllocateAmount.vue'
import SelectBot from '@/components/trading/subscribeBot/SelectBot.vue'
import Loading from '@/components/shared/Loading.vue'
import NewExchange from '@/components/shared/exchanges/NewExchange.vue'
import { mapGetters } from 'vuex'
import BotOverview from '@/components/trading/BotOverview.vue'
import ShortsToggle from "@/components/trading/subscribeBot/ShortsToggle";

export default {
  name: 'Modal',
  components: {
    ConnectExchange,
    AllocateAmount,
    SelectBot,
    Loading,
    NewExchange,
    BotOverview,
    ShortsToggle
  },
  computed: mapGetters(['userSubscribedBots']),
  props: {
    bot: Object,
    exchange: String
  },
  data() {
    return {
      minInvestment: 0,
      maxInvestment: 0,
      maxInvestmentPct: 100,
      isLoading: false,
      currentStep: 1,
      step: 1,
      newBotConnection: {
        user_id: this.$session.get('uid'),
        connection_id: "",
        bot_id: "",
        exchange: "",
        balance_allocation_method: "",
        enable_shorts: false,
      },
      shortAvailableExchanges: ['ftx', 'bybit_futures', 'okx_futures'],
      errorMsg: '',
      successMsg: '',
      selectedExchangeName: ''
    }
  },
  methods: {
    // close connect new bot modal
    closeModal() {
      this.$emit('click')
    },
    previousStep() {
      if (this.currentStep === 5 && this.step === 5) {
        if (this.bot.shorts_enabled && this.exchange === 'ftx' && this.$session.get('subscription_plan') !== 'bronze') {
          this.step--
          this.currentStep--
        } else {
          this.step = 3
          this.currentStep = 3
        }
        return
      }
      if (this.step !== 1 && this.currentStep !== 1) {
        this.step--
        this.currentStep--
      }
    },
    setSelectedBot(bot) {
      this.newBotConnection.bot_id = bot.bot_id
      this.minInvestment = bot.minimum_investment
      this.market = bot.market
      this.step = 2
      this.currentStep = 2
    },
    setSelectedExchange(exchange) {
      this.selectedExchangeName = exchange.connection.connection_name
      this.newBotConnection.connection_id = exchange.connection.connection_id
      this.newBotConnection.exchange = exchange.connection.exchange
      this.minInvestment = exchange.min
      this.maxInvestment = exchange.max
      this.maxInvestmentPct = exchange.maxPct

      if (exchange.connection.exchange === 'ftx') {
        this.newBotConnection.enable_shorts = true
      }

      this.step = 3
      this.currentStep = 3
    },
    setSelectedAmount(allocation) {
      this.newBotConnection.balance_allocation_method = allocation.allocation_type
      switch (allocation.allocation_type) {
        case 'pct':
          if (this.newBotConnection.allocated_balance_qty) delete this.newBotConnection.allocated_balance_qty
          this.newBotConnection.allocated_balance_pct = allocation.amount
          break;
        case 'qty':
          if (this.newBotConnection.allocated_balance_pct) delete this.newBotConnection.allocated_balance_pct
          this.newBotConnection.allocated_balance_qty = allocation.amount
          break;
      }

      this.step = 5
      this.currentStep = 5
      if (this.bot.shorts_enabled && this.exchange === 'ftx') {
        this.step = 4
        this.currentStep = 4
      }
    },
    setShortsSelection(shorts) {
      shorts ? this.newBotConnection.enable_shorts = true : this.newBotConnection.enable_shorts = false
      this.step = 5
      this.currentStep = 5
    },
    activateBotConnection() {
      this.isLoading = true
      this.$api
        .post('/bots/connect_bot', this.newBotConnection, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false
            this.$router.push({ name: 'Bots', params: { page: 'subscribed' } })
          }
        })
        .catch((err) => {
          this.isLoading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
          if (err.response && err.response.data.error.type === 'BOT_UNAVAILABLE') {
            this.errorMsg = 'This bot is only available to Gold Subscriptions'
          }
          if (err.response && err.response.data.error.type === 'BOT_UNAVAILABLE_TEMP') {
            this.errorMsg = 'This bot is only available to Gold Subscriptions'
          }
          if (err.response && err.response.data.error.type === 'MAX_BOTS') {
            this.errorMsg = err.response.data.error.body
          }
          if (err.response && err.response.data.error.type === 'COOLDOWN') {
            this.errorMsg = err.response.data.error.body
          }
          if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
            this.errorMsg = "This exchange account already has an existing bot connection. Please create a new subaccount and try again."
          }
          if (err.response && err.response.data.error.type === 'ALREADY_CONNECTED') {
            this.errorMsg = err.response.data.error.body
          }
        })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 10px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
}

.modal-container {
  width: 1200px;
  max-height: 95vh;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--card-background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px;
  overflow: auto;
}

.modal-close {
  margin-top: -25px;
  margin-right: 0;
  position: relative;
  font-size: 40px;
  float: right;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
  font-size: 18px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.proceed-button {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.add-to-cart {
  display: flex;
  flex-direction: column;
}

.total-price {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}

::v-deep .v-application--wrap {
  min-height: 1vh !important;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.stepper-step {
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.step-back {
  text-decoration: underline;
  cursor: pointer;
}

.payment-buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.activate-b {
  padding: 15px 30px;
}

.previous-button {
  background-color: var(--secondary-blue);
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1250px) {
  .modal-container {
    padding: 20px 10px;
    width: 95vw;
    height: auto;
  }
}

@media (max-width: 850px) {
  .stepper-step {
    justify-content: flex-start;
    text-align: left;
  }

  .content {
    padding: 0;
  }

  .proceed-button {
    justify-content: center;
    margin-bottom: 40px;
  }

  .total-price {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }

  .connect-exchange {
    width: 100%;
  }
}
</style>
