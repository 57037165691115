<template>
  <div class="side-bar">
    <div class="navigation-items">
      <div class="logo">
        <div class="logo-icon-text title">
          <router-link class="side-bar-item logo-img" :to="{ name: 'Dashboard' }">
            <img class="logo-icon" src="@/assets/footer-logo.png" alt="" @click="changeCurrentPage(0)"/>
            <span class="side-bar-item-name mleft">Boosting Alpha</span>
          </router-link>
          <span class="logo-title">Boosting Alpha</span>
        </div>
      </div>
      <div class="platform">
        <router-link :to="{ name: 'Dashboard' }" class="side-bar-item title" :class="{ selected: currentRoute === 0 }" v-on:click.native="updateCurrentPage(0)">
          <img v-if="currentRoute === 0 || isDarkTheme" class="side-bar-item-icon" src="@/assets/platformSideBar/selected/dashboard-selected.png" alt=""/>
          <img v-else class="side-bar-item-icon" src="@/assets/platformSideBar/dashboard.png" alt=""/>
          <span class="side-bar-item-name" :class="{ selectedText: currentRoute === 0 }">Dashboard</span>
        </router-link>
        <router-link :to="{ name: 'Bots', params: { page: 'marketplace' } }" class="side-bar-item" :class="{ selected: currentRoute === 1 }" v-on:click.native="updateCurrentPage(1)">
          <img v-if="currentRoute === 1 || isDarkTheme" class="side-bar-item-icon" src="@/assets/platformSideBar/selected/robot-selected.png" alt=""/>
          <img v-else class="side-bar-item-icon" src="@/assets/platformSideBar/robot.png" alt=""/>
          <span class="side-bar-item-name" :class="{ selectedText: currentRoute === 1 }">Bots</span>
        </router-link>
        <router-link :to="{ name: 'ExchangeAccounts' }" class="side-bar-item" :class="{ selected: currentRoute === 6 }" v-on:click.native="updateCurrentPage(6)">
          <img v-if="currentRoute === 6 || isDarkTheme" class="side-bar-item-icon" src="@/assets/platformSideBar/selected/exchange-selected.png" alt=""/>
          <img v-else class="side-bar-item-icon" src="@/assets/platformSideBar/exchange.png" alt=""/>
          <span class="side-bar-item-name" :class="{ selectedText: currentRoute === 6 }">Connections</span>
        </router-link>
        <router-link :to="{ name: 'Activity' }" class="side-bar-item" :class="{ selected: currentRoute === 3 }" v-on:click.native="updateCurrentPage(3)">
          <img v-if="currentRoute === 3 || isDarkTheme" class="side-bar-item-icon" src="@/assets/platformSideBar/selected/history-selected.png" alt=""/>
          <img v-else class="side-bar-item-icon" src="@/assets/platformSideBar/history.png" alt=""/>
          <span class="side-bar-item-name" :class="{ selectedText: currentRoute === 3 }">Activity</span>
        </router-link>
        <router-link :to="{ name: 'Settings' }" class="side-bar-item" :class="{ selected: currentRoute === 4 }" v-on:click.native="updateCurrentPage(4)">
          <img v-if="currentRoute === 4 || isDarkTheme" class="side-bar-item-icon" src="@/assets/platformSideBar/selected/gear-selected.png" alt=""/>
          <img v-else class="side-bar-item-icon" src="@/assets/platformSideBar/gear.png" alt=""/>
          <span class="side-bar-item-name" :class="{ selectedText: currentRoute === 4 }">Preferences</span>
        </router-link>
        <a href="mailto:support@boostingalpha.com" class="side-bar-item">
          <img v-if="isDarkTheme" class="side-bar-item-icon" src="@/assets/platformSideBar/selected/information-selected.png" alt=""/>
          <img v-else class="side-bar-item-icon" src="@/assets/tooltip.png" alt=""/>
          <span class="side-bar-item-name">Contact Us</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'sidebar',
  computed: mapGetters(['selectedPage', 'darkTheme']),
  data() {
    return {
      currentRoute: 0,
      isDarkTheme: false
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    },
    $route() {
      this.checkCurrentPage()
    },
    selectedPage() {
      this.currentRoute = this.selectedPage
    }
  },
  mounted() {
    this.isDarkTheme = JSON.parse(localStorage.boostingAlphaDarkTheme)
    this.checkCurrentPage()
  },
  methods: {
    ...mapActions(['updateCurrentPage']),
    // update the icon for the page the user is currently on
    checkCurrentPage() {
      const current = this.$route.name
      switch (current) {
        case 'Dashboard':
          this.updateCurrentPage(0)
          break
        case 'Bots':
          this.updateCurrentPage(1)
          break
        case 'Statistics':
          this.updateCurrentPage(2)
          break
        case 'Activity':
          this.updateCurrentPage(3)
          break
        case 'Notifications':
          this.updateCurrentPage(4)
          break
        case 'Settings':
          this.updateCurrentPage(5)
          break
        case 'ExchangeAccounts':
          this.updateCurrentPage(6)
          break
        default:
          this.updateCurrentPage(10)
      }
    }
  }
}
</script>

<style scoped>
.side-bar {
  position: fixed;
  height: 100vh;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  transition: opacity 1s linear;
  padding: 5px;
  box-shadow: 0 4px 4px var(--shadow);
  background-color: var(--card-background);
  z-index: 2;
}

.side-bar:hover {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  width: 200px;
}

.side-bar:hover .side-bar-item-name {
  -webkit-transition: visibility 0.2s ease-in-out;
  -moz-transition: visibility 0.2s ease-in-out;
  -o-transition: visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
  visibility: visible;
  opacity: 1;

  margin-left: 40px;
  font-size: 20px;
  text-decoration: none;
  font-weight: 400;
}

.side-bar:hover .side-bar-item {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;

  padding: 0 10px;
  width: 180px;
  justify-content: flex-start;
}

.side-bar:hover .selected {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;

  padding: 0 10px;
  width: 180px;
  color: var(--text-secondary);
}

.side-bar:hover .side-bar-item:hover {
  padding: 0 10px;
}

.side-bar:hover .logo-title {
  transition-delay: 0.5s;
  display: none;
  transition: 0.5s;
}

.logo {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.platform {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.user {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-item {
  margin-top: 10px;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo-icon {
  height: 40px;
  width: 40px;
}

.navigation-items {
  height: 100%;
}

.logo-icon-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.side-bar-item {
  margin: 20px auto;
  width: 45px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
}

.side-bar-item:hover {
  background-color: var(--selected);
  border-radius: 10px;
  cursor: pointer;
  color: var(--secondary-blue) !important;
}

.selectedText {
  color: var(--button-text) !important;
}

.selected {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: var(--primary-blue);
  box-shadow: 0 10px 20px var(--shadow), 0 3px 6px var(--shadow);
}

.selected:hover {
  border-radius: 10px;
  background-color: var(--primary-blue);
}

.side-bar-item-icon {
  width: 25px;
  height: 25px;
}

.side-bar-item-name {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  margin-left: 10px;
  font-size: 18px;
  text-decoration: none;
  transition: 0s;
}

.mleft {
  margin-left: 50px !important;
}

.logo-title {
  margin-top: -15px;
  font-size: 14px;
  text-align: center;
  transition: 0.5s;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.logo-img:hover {
  background-color: var(--card-background);
}

@media (max-width: 850px) {
  .side-bar {
    display: none;
  }
}
</style>
