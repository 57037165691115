<template>
  <div class="modal-content">
    <span class="modal-content-text"
      >To start using our trading bots on binance you need to integrate your api
      key</span
    >
    <label class="contact-input">
      Connection Name:
      <input class="form-input" type="text" />
    </label>
    <label class="contact-input">
      Account API Key:
      <input class="form-input" type="text" />
    </label>
    <label class="contact-input">
      Account API Secret:
      <input class="form-input" type="text" />
    </label>
    <span
      @click="openHints()"
      class="open-hints"
      style="color: var(--blue-primary)"
    >
      How to connect an exchange account?
    </span>
    <div class="connection-hints" v-if="connectionHints">
      <span class="connection-hint-items">
        1. Open
        <a href="https://accounts.binance.com" target="_blank">
          https://accounts.binance.com
        </a>
      </span>
      <span class="connection-hint-items">
        2. Log in to your account
      </span>
      <span class="connection-hint-items">
        3. Hover over your profile and click ”API Management”
      </span>
      <span class="connection-hint-items">
        4. GIve the API key a label at the top of the screen
      </span>
      <span class="connection-hint-items">5. Click create</span>
      <span class="connection-hint-items">
        6. Follow the authentication instructions
      </span>
      <span class="connection-hint-items">
        7. When done, copy the API keys of the newly created API back into the
        corresponding input fields above
      </span>
      <span class="connection-hint-items">8. Now you are done!</span>
    </div>
    <div class="proceed-button">
      <div>
        <span class="form-button button-shadow" @click="connectExchange()"
          >Connect</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
