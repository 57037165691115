<template>
  <div class="arrow-head">
    <svg
      width="10"
      height="6"
      viewBox="-2.5 -5 75 60"
      preserveAspectRatio="none"
    >
      <path
        d="M0,0 l35,50 l35,-50"
        fill="none"
        stroke="black"
        stroke-linecap="round"
        stroke-width="5"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.arrow-head {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--header-color);
}
</style>
