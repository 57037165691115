<template>
  <div class="bot-badges">
    <span class="badge" :class="badgeStyle" :title="badgeText">
      {{ badgeText.length > 8 ? badgeText.substr(0, 6) + '...' : badgeText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BotBadge',
  props: {
    badgeText: String,
    badgeStyle: String
  },
}
</script>

<style scoped>
.badge {
  width: 100px;
  border-radius: 100px;
  color: #ffffff;
  padding: 5px 10px;
  margin: 5px 5px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AI {
  background-color: #951bc0;
  color: #ffffff;
}

.HighRisk {
  background-color: #f2555a;
  color: #ffffff;
}

.MediumRisk {
  background-color: #f09000;
  color: #ffffff;
}

.LowRisk {
  background-color: #245c22;
  color: #ffffff;
}

.Multicoin {
  background-color: #077e8b;
  color: #ffffff;
}

.Conservative {
  background-color: #f79400;
  color: #ffffff;
}

.BTCBase {
  background-color: #D7A619;
  color: #ffffff;
}

.HFT {
  background-color: #5e7c8d;
  color: #ffffff;
}

.Index {
  background-color: #83c1a6;
  color: #ffffff;
}

.Innovative {
  background-color: #3b3b3b;
  color: #ffffff;
}

.Rebalancer {
  background-color: #f09000;
  color: #ffffff;
}

.Exclusive {
  background-color: #0093c9;
  color: #ffffff;
}


.SmallCoins {
  background-color: #43b9a7;
  color: #ffffff;
}

.LargeCoins {
  background-color: #60d248;;
  color: #ffffff;
}

.Popular {
  background-color: #80bdc4;
  color: #ffffff;
}

.Shorts {
  background-color: #26b400;
  color: #ffffff;
}

.Bitcoin {
  background-color: #d9af2d;;
  color: #ffffff;
}

.Ethereum {
  background-color: #747A9B;
  color: #ffffff;
}

.Cardano {
  background-color: #0133B2;
  color: #ffffff;
}

@media (max-width: 1250px) {
  .badge {
    width: 80px;
    font-size: 12px;
  }
}
</style>
