<template>
  <div class="bot-table-items card" :class="selectedExchange.name">
    <div class="table-item">
      <span class="table-item-title">
        Exchange:
      </span>
      <div class="table-item-content">
        <div class="exchange-item">
          <img class="exchange-logo" :src="loadExchangeIcon()" alt="">
          <span>{{ getExchangeName() }}</span>
        </div>
      </div>
    </div>
    <div class="table-item">
      <span class="table-item-title">
        Market:
      </span>
      <div class="table-item-content">
        {{ selectedExchange.exchangeData.base_currency }}
      </div>
    </div>
    <div class="table-item">
      <span class="table-item-title">
        Min. investment:
      </span>
      <div class="table-item-content">
        {{ selectedExchange.exchangeData.minimum_investment + ' ' + selectedExchange.exchangeData.base_currency }}
      </div>
    </div>
    <div class="table-item last">
      <span class="table-item-title">
        Shorts:
      </span>
      <div class="table-item-content">
        <!--        <span  class="red">✕</span>-->
        <img height="25" width="25" v-if="!selectedExchange.exchangeData.shorts" src="@/assets/notification/cross.png"
             alt="">
        <img height="25" width="25" v-else src="@/assets/notification/checked.png" alt="">
        <!--        <span v-else class="green">✓</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedExchange: Object
  },
  methods: {
    loadExchangeIcon() {
      return require('@/assets/' + this.selectedExchange.name.toLowerCase() + '-logo.png')
    },
    getExchangeName() {
      switch (this.selectedExchange.name) {
        case 'bybit_futures':
          return 'Bybit'
        case 'okx_futures':
          return 'OKX'
        case 'bitget_futures':
          return 'Bitget'
        case 'kraken_futures':
          return 'Kraken'
        default:
          return this.selectedExchange.name[0].toUpperCase() + this.selectedExchange.name.substr(1)
      }
    },
  }
}
</script>

<style scoped>
.bot-table-items {
  width: auto;
  margin-top: 5px;
  padding: 5px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.table-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  font-weight: 300;
  font-size: 16px;
}

.table-item-content {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 5px;
  font-weight: 500;
  transition: 0.5s;
  color: var(--secondary-blue);
}

.table-item-title {
  color: var(--secondary-blue);
}

.exchange-logo {
  height: 25px;
}

.exchange-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.binance {
  background-color: var(--binance);
}

.bitvavo {
  background-color: var(--bitvavo);
}

.okx_futures {
  background-color: var(--okx);
}

.bybit_futures {
  background-color: var(--bybit);
}

.bitget_futures {
  background-color: var(--bitget);
}

.kraken_futures {
  background-color: var(--kraken);
}

.ftx {
  background-color: var(--ftx);
}

.kucoin {
  background-color: var(--kucoin);
}

.disabled {
  background-color: var(--disabled);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  box-shadow: none;
}

.selected {
  height: 65px;
  width: 65px;
  border: 2px solid var(--secondary-blue);
}

.green {
  color: #1b802a;
}

.red {
  color: #ff0044;
}

@media (max-width: 800px) {
  .bot-table-items {
    padding: 5px;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
  }
}
</style>
