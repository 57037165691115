<template>
  <div>
    <span class="step-title">3. Enable shorts</span>
    <div class="enable-shorts">
      <span>Shorts:</span>
      <shorts-toggle-switch :switch-status-value="enabledShorts" @toggle="toggleShorts($event)" />
    </div>
    <div class="next-step">
      <span class="form-button button-shadow previous-button" @click="previousStep()">Previous Step</span>
      <span class="form-button button-shadow next-button" @click="nextStep()">Next Step</span>
    </div>
  </div>
</template>

<script>
import ShortsToggleSwitch from '@/components/shared/ShortsToggleSwitch'

export default {
  name: "ShortsToggle",
  components: {
    ShortsToggleSwitch
  },
  data() {
    return {
      enabledShorts: true
    }
  },
  methods: {
    toggleShorts(type) {
      this.enabledShorts = type
    },
    previousStep() {
      this.$emit('previousStep')
    },
    nextStep() {
      this.$emit('nextStep', this.enabledShorts)
    },
  }
}
</script>

<style scoped>
.enable-shorts {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.next-button {
  background-color: var(--secondary-blue);
  padding: 10px 20px;
  width: auto;
  display: flex;
  justify-content: center;
}

.previous-button {
  background-color: var(--secondary-blue);
  width: auto;
  display: flex;
  justify-content: center;
}

.next-step {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>