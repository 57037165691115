<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div v-if="status === 'success'" class="firework"></div>
          <div class="top-information">
            <div class="top-information-text">
              <span v-if="status === 'success'" class="modal-title">Thank you for your purchase</span>
              <span v-else-if="status === 'failed'" class="modal-title">Something went wrong with the purchase</span>
              <span v-else class="modal-title">We are sorry to see you go</span>
              <loading class="loading" :active="loading"/>
              <div class="modal-header-date">
                <div class="modal-content">
                  <span v-if="status === 'success'">Now you are ready to enjoy are products, head to the marketplace to see what is available for you!</span>
                  <span v-else-if="status === 'failed'"> Please try again later or contact us at <a href="mailto:support@boostingalpha.com">support@boostingalpha.com</a></span>
                  <span v-else>You have succesfully cancelled your subscription.</span>
                </div>
                <div v-if="!loading" class="buttons">
                  <span v-if="status === 'success'" class="button-p button-shadow green" @click="toMarketplace()">Marketplace</span>
                  <span v-else class="button-p button-shadow green" @click="closeModal()">Close</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading'

export default {
  name: 'OTPModal',
  components: {
    Loading,
  },
  props: {
    status: String
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    toMarketplace() {
      this.$router.push({name: 'Bots', params: {page: 'marketplace'}})
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
  font-weight: bold;
}

.modal-subtext {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.modal-container {
  width: 500px;
  margin: 0 auto;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.modal-close {
  font-size: 30px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.remove {
  margin-bottom: 20px;
}

.modal-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  z-index: 99;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  align-items: center;
}

.button-p {
  width: 200px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.to-cart {
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: 300px;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }
}

body {
  background: #234;
}

.green {
  background-color: var(--green);
}

@keyframes firework {
  0% {
    transform: translate(-50%, 60vh);
    width: 0.5vmin;
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  100% {
    width: 45vmin;
    opacity: 0;
  }
}

.firework,
.firework::before,
.firework::after {
  z-index: -1;
  --top: 60vh;
  content: '';
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background: /* random backgrounds */ radial-gradient(
      circle,
      #ff0 0.2vmin,
      #0000 0
  ) 50% 00%,
  radial-gradient(circle, rgb(184, 77, 15) 0.3vmin, #0000 0) 00% 50%,
  radial-gradient(circle, rgb(255, 0, 0) 0.5vmin, #0000 0) 50% 99%,
  radial-gradient(circle, #ff0 0.5vmin, #0000 0) 50% 99%,
  radial-gradient(circle, rgb(184, 77, 15) 0.2vmin, #0000 0) 99% 50%,
  radial-gradient(circle, rgb(255, 0, 0) 0.5vmin, #0000 0) 50% 99%,
  radial-gradient(circle, #ff0 0.3vmin, #0000 0) 80% 90%,
  radial-gradient(circle, rgb(184, 77, 15) 0.5vmin, #0000 0) 95% 90%,
  radial-gradient(circle, rgb(255, 0, 0) 0.5vmin, #0000 0) 50% 99%,
  radial-gradient(circle, #ff0 0.5vmin, #0000 0) 10% 60%,
  radial-gradient(circle, rgb(184, 77, 15) 0.2vmin, #0000 0) 31% 80%,
  radial-gradient(circle, rgb(255, 0, 0) 0.5vmin, #0000 0) 50% 99%,
  radial-gradient(circle, #ff0 0.3vmin, #0000 0) 80% 10%,
  radial-gradient(circle, rgb(184, 77, 15) 0.2vmin, #0000 0) 90% 23%,
  radial-gradient(circle, rgb(255, 0, 0) 0.5vmin, #0000 0) 50% 99%,
  radial-gradient(circle, #ff0 0.3vmin, #0000 0) 45% 20%,
  radial-gradient(circle, rgb(184, 77, 15) 0.5vmin, #0000 0) 13% 24%;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: firework 2s infinite;
}

.firework::before {
  transform: translate(-50%, -50%) rotate(25deg) !important;
}

.firework::after {
  transform: translate(-50%, -50%) rotate(-37deg) !important;
}
</style>
