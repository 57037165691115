import Vue from "vue";

const state = {
    cart: {},
};

const getters = {
    userCart: (state) => state.cart
};

const actions = {
    async addItem({commit}, item) {
        commit('newItem', item)
    },
    async deleteItem({commit}, id) {
        commit('removeItem', id);
    },
    async clearCart({commit}) {
        commit('clearCart');
    },
    async setCart({commit, cart}) {
        commit('setCart', cart);
    },

};

const mutations = {
    newItem(state, item) {
        state.cart = item
        Vue.prototype.$session.set("cart", state.cart);
    },
    removeItem(state, id) {
        const index = state.cart.findIndex((item) => item.id === id);
        state.cart.splice(index, 1);
        Vue.prototype.$session.set("cart", state.cart);
    },
    clearCart(state) {
        state.cart = []
        Vue.prototype.$session.set("cart", state.cart);
    },
    setCart(state) {
        state.cart = Vue.prototype.$session.get("cart");
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
