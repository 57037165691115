<template>
  <div class="top-bar">
    <div class="hamburger">
      <div class="user-icon">
        <img style="width: 25px; height: 25px; margin:10px" src="@/assets/hamburger-white.png" alt @click="showSmallNav()" />
        <div class="top-bar-user-info">
          <span class="hamburger-username" @click="goToProfile()">{{ username }}</span>
          <img v-if="hasActiveSubscription && subscriptionBadge" style="width: 30px; height: 100%" :src="loadSubscriptionBadge()" alt />
        </div>
      </div>
    </div>
    <div class="balance">
      <balance-overview ref="widget" class="user-balance-overview" />
    </div>
    <div class="user">
      <div class="free-trial-box">
        <span v-if="!hasActiveSubscription" class="button-p button-shadow payment-b free-trial" @click="toSubscriptionPage()">Start Trading</span>
      </div>
      <div v-click-outside="outside" class="user-icon card">
        <div class="user-icon-name" @click="toggleUserOptions()">
          <img v-if="!hasActiveSubscription && !subscriptionBadge" style="width: 25px; height: 100%" src="@/assets/user.png" alt />
          <img v-else style="width: 25px; height: 100%" :src="loadSubscriptionBadge()" alt />
          <span class="user-name">{{ username }}</span>
          <img v-if="!isDarkTheme" class="hamburger-user" style="width: 15px; height: 100%" src="@/assets/hamburger.png" alt="" />
          <img v-else class="hamburger-user" style="width: 15px; height: 100%" src="@/assets/hamburger-white.png" alt="" />
        </div>
        <div v-show="userOptions" class="user-options card">
          <router-link class="user-option-item" :to="{ name: 'Settings' }" v-on:click.native="toggleUserOptions()">
            <img v-if="!isDarkTheme" style="height: 15px" src="@/assets/platformSideBar/gear.png" alt />
            <img v-else style="height: 15px" src="@/assets/platformSideBar/selected/gear-selected.png" alt />
            <span class="user-options-item-name">Preferences</span>
          </router-link>
          <router-link class="user-option-item" :to="{ name: 'Login' }" v-on:click.native="toggleUserOptions()">
            <img v-if="!isDarkTheme" style="height: 15px" src="@/assets/platformSideBar/logout.png" alt />
            <img v-else style="height: 15px" src="@/assets/platformSideBar/selected/logout.png" alt />
            <span class="user-options-item-name" @click="logout()">Log out</span>
          </router-link>
          <ThemeToggle class="theme-toggle" />
        </div>
      </div>
    </div>
    <div v-if="isSmallNav" class="small-nav" id="smallNav" data-aos="fade-right">
      <div class="hamburger-open" @click="showSmallNav()">
        <img style="width: 25px; height: 25px" src="@/assets/hamburger-white.png" alt />
      </div>
      <div class="top-bar-logo">
        <img src="@/assets/footer-logo.png" alt />
        <span class="top-bar-logo-text">Boosting Alpha</span>
      </div>
      <router-link :to="{ name: 'Dashboard' }" class="top-bar-item" v-on:click.native="showSmallNav()">
        <span class="top-bar-item-name">Dashboard</span>
      </router-link>
      <router-link :to="{ name: 'Bots', params: { page: 'marketplace' } }" class="top-bar-item" v-on:click.native="showSmallNav()">
        <span class="top-bar-item-name">Bots</span>
      </router-link>
      <router-link :to="{ name: 'ExchangeAccounts' }" class="top-bar-item" v-on:click.native="showSmallNav()">
        <span class="top-bar-item-name">Exchanges</span>
      </router-link>
      <router-link :to="{ name: 'Activity' }" class="top-bar-item" v-on:click.native="showSmallNav()">
        <span class="top-bar-item-name">Activity</span>
      </router-link>
      <router-link :to="{ name: 'Settings' }" class="top-bar-item" v-on:click.native="showSmallNav()">
        <span class="top-bar-item-name">Preferences</span>
      </router-link>
      <a href="mailto:support@boostingalpha.com" class="top-bar-item">
        <span class="top-bar-item-name">Contact Us</span>
      </a>
      <router-link class="top-bar-item" :to="{ name: 'Login' }">
        <span class="top-bar-item-name" @click="logout()">Log out</span>
      </router-link>
      <theme-toggle class="theme-toggle" />
    </div>
  </div>
</template>

<script>
import BalanceOverview from '@/components/trading/BalanceOverview'
import Dropdown from '@/components/trading/Dropdown'
import { mapActions, mapGetters } from 'vuex'
import ToggleSwitch from '../shared/ToggleSwitch.vue'
import ThemeToggle from "@/components/trading/settings/ThemeToggle";

export default {
  computed: mapGetters(['connectedExchanges', 'userSubscription', 'darkTheme']),
  name: 'TopBar',
  components: {
    BalanceOverview,
    Dropdown,
    ToggleSwitch,
    ThemeToggle
  },
  data() {
    return {
      userOptions: false,
      isSmallNav: false,
      username: '',
      selectedExchange: {},
      hasActiveSubscription: true,
      subscriptionBadge: null,
      isDarkTheme: 0
    }
  },
  watch: {
    userSubscription() {
      this.getSubscriptionInfo()
    },
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  created() {
    this.isDarkTheme = JSON.parse(localStorage.boostingAlphaDarkTheme)
  },
  mounted() {
    this.getUser()
    this.getSubscriptionInfo()
    this.username = this.$session.get('username')
  },
  methods: {
    ...mapActions(['loadSubscribedBots', 'loadExchangeConnections', 'updateUserDarkTheme']),
    outside() {
      this.userOptions = false
    },
    loadSubscriptionBadge() {
      try {
        return require('@/assets/' + this.$session.get('subscription_plan').split('_')[0] + '.png')
      } catch (e) {
      }
    },
    logout() {
      this.$session.destroy()
    },
    // toggle mobile navigation bar
    showSmallNav() {
      this.isSmallNav = !this.isSmallNav
    },
    updateWidget(e) {
      this.$refs.widget.getWidgetData()
    },
    // toggle user options dropdown
    toggleUserOptions() {
      this.userOptions = !this.userOptions
    },
    updateSelectedConnection(connection) {
      this.selectedExchange = connection
    },
    goToConnectExchange() {
      this.$router.push({ name: 'ExchangeAccounts', params: { newAccount: true } })
    },
    toSubscriptionPage() {
      this.$router.push({ name: 'Settings', query: { page: 'subscription' } })
    },
    goToProfile() {
      this.$router.push({ name: 'Settings' })
    },
    getUser() {
      this.$api
        .get('/users/get_user', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$session.set('username', res.data.data.username)
            this.$session.set('email', res.data.data.email)
            this.$session.set('timezone', res.data.data.timezone)
            this.$session.set('free_trial', res.data.data.trial_period_activated)

          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    getSubscriptionInfo() {
      this.$api
        .get('/subscriptions/get_subscription_info', {
          params: {
            user_id: this.$session.get('uid'),
            timezone: this.$session.get('timezone')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            if (!res.data.data) {
              this.hasActiveSubscription = false
              return
            }
            this.hasActiveSubscription = res.data.data.active_subscription
            this.$session.set('has_subscription', res.data.data.active_subscription)
            this.$session.set('subscription_plan', res.data.data.subscription_plan)
            this.subscriptionBadge = res.data.data.subscription_plan
          }
        })
        .catch((err) => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>

.top-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.balance {
  height: 100%;
  width: 1150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dropdowns {
  padding: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user {
  display: flex;
  flex-direction: row;
}

.small-nav {
  padding: 10px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100%;
  width: 200px;
  background-color: var(--primary-blue);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border-bottom-right-radius: 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.top-bar-logo {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-bar-logo-text {
  color: var(--white);
  font-size: 24px;
}

.top-bar-item {
  width: 100%;
  height: 50px;
  text-decoration: none;
  border-top: 1px solid #63b9ee;
  display: flex;
  align-items: center;
}

.top-bar-item-name {
  font-size: 20px;
  color: var(--white);
  text-decoration: none;
}

.hamburger-open {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.notification-badge {
  position: absolute;
  margin-top: -20px;
  margin-left: 20px;
  height: 20px;
  width: 20px;
  background-color: var(--red);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--text-secondary);
}

.free-trial-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.free-trial {
  width: 165px;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
  color: var(--secondary-blue);
}

.user-icon {
  width: 170px;
  padding: 0 5px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  background-color: var(--card-background);
  border: 1px solid var(--border);
}

.user-options {
  top: 40px;
  left: 0;
  width: 170px;
  padding: 10px 5px;
  height: auto;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  position: absolute;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 9999999;
}

.user-option-item {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--text-primary);
}

.user-option-item:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
  border-radius: 20px;
}

.theme-toggle {
  margin: 20px 0 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon-name {
  width: 100%;
  padding: 3px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.userDropDownOpen {
  background-color: var(--selected);
  color: var(--text-secondary);
  border-radius: 20px;
}

.user-options-item-name {
  margin-left: 10px;
}

.hamburger {
  display: none;
}

.small-nav-dropdown {
  display: none;
}

.new-exchange-button {
  display: flex;
  align-items: center;
}

.button-add-exchange {
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
}

.button-add-exchange:hover {
  background-color: var(--yellow-hover);
}

.subscription_badge {
  margin-left: 10px;
  padding: 10px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: var(--secondary-blue);
}

.user-name {
  width: 100%;
}

.dark-theme {
  display: flex;
  align-items: center;
}

@media (max-width: 850px) {
  .hamburger-user {
    display: none;
  }

  .hamburger {
    height: 50px;
    width: 100%;
    position: fixed;
    background-color: var(--primary-blue);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 0;
  }

  .user-icon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--primary-blue);
    border: none;
  }

  .userDropDownOpen {
    background-color: var(--primary-blue);
  }

  .user-icon:hover {
    background-color: var(--primary-blue);
  }

  .top-bar-user-info {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    font-weight: 500;
    padding: 0 5px;
  }

  .hamburger-username {
    color: var(--white);
  }

  .top-bar {
    padding: 5px;
    flex-direction: column;
  }

  .balance {
    margin-top: 50px;
    z-index: 0;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .dropdowns {
    margin: 0;
    display: none;
  }

  .user {
    display: none;
  }

  .user-balance-overview {
    width: 100%;
  }

  .filter {
    width: 60%;
  }

  .notification-item-side {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .notification-badge-sidebar {
    height: 20px;
    width: 20px;
    background-color: var(--red);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--white);
  }

  .notification-badge-hamburger {
    position: absolute;
    margin-top: -10px;
    margin-left: 10px;
    height: 20px;
    width: 20px;
    background-color: var(--red);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--white);
  }

  .small-nav-dropdown {
    margin: 2px 0;
    display: block;
    width: 100%;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
