<template>
  <div>
    <div class="bot-card card" :class="{ gold: bot.gold_only }" @click="goToBot()">
      <div class="top-area">
        <div class="bot-data">
          <span class="bot-icon">
            <img class="bot-icon-src" src="@/assets/bots/bot_template.png" alt="icon" />
          </span>
          <div class="name-price">
            <h3 class="bot-name">{{ bot.bot_name }}</h3>
          </div>
          <bot-exchange :exchanges="bot.exchanges" :gold="bot.gold_only" />
        </div>
        <div class="bot-badges">
          <div class="bot-badge-item">
            <bot-badge v-if="bot.badge_1" :badgeStyle="bot.badge_1.replace(' ', '')" :badgeText="bot.badge_1" />
            <bot-badge v-if="bot.badge_2" :badgeStyle="bot.badge_2.replace(' ', '')" :badgeText="bot.badge_2" />
            <bot-badge v-if="bot.badge_3" :badgeStyle="bot.badge_3.replace(' ', '')" :badgeText="bot.badge_3" />
          </div>
        </div>
      </div>
      <div class="bottom-area" :class="{ notActiveBottom: !bot.enabled  }">
        <div class="bot-graph">
          <bot-card-graph ref="botCardGraph" :data="botGraphData" :labels="botGraphLabels" :name="bot.bot_id" :positive="isPositive" />
        </div>
        <div class="bot-graph-data">
          <span v-if="bot.total_pnl_pct" class="bot-graph-data-text success">{{ bot.total_pnl_pct.toFixed(2) }}%</span>
          <span v-else class="bot-graph-data-text success">0%</span>
          <span class="sub-button add" @click="goToBot()">Details</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotBadge from '@/components/trading/bots/BotBadge.vue'
import Dropdown from '@/components/trading/Dropdown'
import BotCardGraph from '@/components/trading/bots/BotCardGraph'
import BotExchange from '@/components/trading/bots/BotExchange.vue'

export default {
  name: 'BotCard',
  components: {
    BotBadge,
    Dropdown,
    BotCardGraph,
    BotExchange
  },
  props: {
    bot: Object,
    isActive: Boolean,
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editMode: false,
      botModal: false,
      botGraphData: [],
      botGraphLabels: [],
      isPositive: true,
    }
  },
  watch: {
    bot() {
      this.bot.total_pnl_pct < 0 ? this.isPositive = false : this.isPositive = true
      this.populateGraphData()
    }
  },
  created() {
    this.bot.total_pnl_pct < 0 ? this.isPositive = false : this.isPositive = true
  },
  mounted() {
    this.populateGraphData()
  },
  methods: {
    populateGraphData() {
      this.botGraphData = []
      this.botGraphLabels = []

      let counter = 0
      for (const key in this.bot.historical_performance) {
        if (!this.botGraphLabels.includes(this.bot.historical_performance[key].datetime.split(' ')[0])) {
          this.botGraphLabels.push(this.bot.historical_performance[key].datetime.split(' ')[0])
          this.botGraphData.push(this.bot.historical_performance[key].pnl_pct
          )
          counter++
        } else {
          this.botGraphData.splice(counter - 1, 1)
          this.botGraphLabels.splice(counter - 1, 1)

          this.botGraphLabels.push(this.bot.historical_performance[key].datetime.split(' ')[0])
          this.botGraphData.push(this.bot.historical_performance[key].pnl_pct)
        }
      }
    },
    // take user to individual bot page
    goToBot() {
      this.$router.push({
        name: 'Bot',
        params: { name: this.bot.bot_name.replace(/ /g, ""), title: this.bot.bot_name, bot_id: this.bot.bot_id },
      })
    },
    // leave bot edit mode
    goBack() {
      this.editMode = false
    },
    isBadgeActive(badge) {
      if (badge === 'True') {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.bot-card {
  position: relative;
  cursor: pointer;
  padding: 10px;
  height: auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  transition: transform 0.2s;
  border-radius: 15px !important;
}

.bot-card:hover {
  transform: scale(1.025);
}

.top-area {
  height: auto;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bot-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bot-data {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.bot-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-top: 15px;
}

.bot-icon-src {
  height: 100%;
  margin-top: 1px;
  margin-left: -2px;
  border-radius: 50%;
}

.bot-name {
  margin-top: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 22px;
  font-weight: 400;
}

.longs-shorts {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.longs-shorts-text {
  height: 20px;
  border-radius: 100px;
  padding: 0px 10px;
  background-color: var(--header-color);
  color: #ffffff;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.check-mark {
  color: var(--green);
  font-weight: bolder;
}

.cross-mark {
  font-weight: bolder;
  color: var(--red);
}

.bot-badges {
  display: flex;
  justify-content: center;
  text-align: left;
}

.bottom-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 180px;
  background: linear-gradient(112.03deg, #ffffff 35.78%, #1b81c0 273.82%),
    #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
}

.notActiveBottom {
  background: linear-gradient(112.03deg, #ffffff 35.78%, #7d7d7d 273.82%),
    #ffffff;
}

.bot-graph {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 2px;
  height: 60%;
  width: 100%;
  border-radius: 10px;
}

.bot-graph-data {
  padding-right: 10px;
  padding-left: 10px;
  height: 70%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bot-stats-graph {
  height: 100%;
}

.bot-graph-data-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: var(--blue-primary);
  /*color: var(--header-color);*/
}

.sub-button {
  background-color: var(--blue-primary);
  padding: 6px 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: white;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.add {
  border-radius: 50px;
  height: 35px;
  background-color: var(--header-color);
}

.transaction-description {
  padding: 5px;
  margin-left: 160px;
  margin-top: 65px;
  height: 100px;
  width: 150px;
  background-color: var(--white);
  border: 1px solid #a1a1a1;
  position: absolute;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.active-bot-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.active-bot-info-item {
  font-family: 'Roboto Condensed', sans-serif;
  padding: 2px;
  margin: 2px 2px;
  width: 100px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.active-bot-info-item-value {
  font-size: 18px;
  color: #0ef147;
}

.subscribed {
  color: #1b81c0;
}

.blue {
  background-color: var(--header-color);
  color: #fff;
}

.connected-exchange {
  display: flex;
  justify-content: flex-end;
}

.connected-exchange-item {
  width: 96%;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: 400;
  border-radius: 5px;
  white-space: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active-bot-info-item-value-date {
  color: #0ec4f1;
  font-size: 18px;
}

.name-price {
  width: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.connected-exchange-item-img {
  height: 25px;
  margin-right: 10px;
}

.edit-item {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.edit-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-title {
  width: 50%;
}

.edit-i {
  height: 35px;
  width: 100%;
}

.cancel-bot-button {
  width: 40%;
  background-color: #eb7c8b;
  display: flex;
  justify-content: center;
}

.cancel-bot-button:hover {
  background-color: #d56f7d;
}

.save-bot-button {
  width: 40%;
  display: flex;
  justify-content: center;
}

.notActive {
  background: #f2f2f2;
  color: #a5a5a5;
}

.notActiveProfit {
  color: #a5a5a5;
}

.bot-badge-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.sub-status {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paused {
  background-color: #e7c60a;
}

.sub-status-icon {
  height: 100%;
  width: 100%;
}

.bot-status {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.gold {
  background: linear-gradient(45deg, #ffffff 50%, #ffe6a0 100%);
}
</style>
