<template>
  <div class="bot" :class="{gold: selectedBot.gold_only}">
    <span class="button-p button-shadow back" @click="backToMarket()">&larr; Back to marketplace</span>
    <div v-if="!loading" class="top-content">
      <div class="bot-info">
        <div class="bot-information">
          <div class="bot-info-top">
            <div class="bot-info-icon">
              <img class="bot-icon-src" src="@/assets/bots/bot_template.png" alt="icon" />
              <div class="bot-overview">
                <span class="bot-info-name">{{ selectedBot.bot_name }}</span>
                <div class="bot-info-table">
                  <div class="bot-details">
                    <span>Published: <span v-if="selectedBot.published" class="published">{{ selectedBot.published.split(' ')[0] }}</span> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="showLargeGraph" class="bot-graph large-graph">
            <div class="bot-graph-title">
              <div class="section-title">
                <span class="title section">Performance</span>
                <div class="value-tooltip">
                  <img style="height: 20px; cursor:pointer; margin-left: 5px" src="@/assets/tooltip.png" alt @mouseover="showLiveDescription()" @mouseleave="showLiveDescription()" />
                  <span v-if="liveDescription" class='metric-description'>
                    <p class=" italic">Performance is based on closed positions.</p>
                    <p class="italic">The position record has a delay of 2 weeks. the current one is not disclosed. If you subscribed to the bot, the position record is instant.</p>
                    <p class="italic">When you start with one bot, the open positions are not copied. </p>
                  </span>
                </div>
              </div>
              <time-filter-dropdown class="time-filter" :isBotGraph="true" @updateTimeFrame="updateGraphTimeFrame($event)" />
            </div>
            <LineGraph v-if="selectedBot.bot_name" class="bot-chart" ref="botGraph" :isBotGraph="true" :interval="interval" :id="selectedBot.bot_name + '2'" :graphData="graphData" :graphLabels="graphLabels" />
          </div>
          <div class="connect-bot-title">
            <img v-if="!isDarkTheme" class="connect-bot-img" src="@/assets/platformSideBar/robot.png" alt="">
            <img v-else class="connect-bot-img" src="@/assets/platformSideBar/selected/robot-selected.png" alt="">
            <span class="title">Connect bot</span>
          </div>
          <div class="exchange-info-container">
            <span class="select-exchange-text">Select your Exchange</span>
            <div class="exchange-info">
              <div class="exchange-items">
                <bot-table-item v-for="(item, index) in selectedBot.exchanges" :key="index" :name="index" :exchange="item" :selectedExchange="selectedExchange.name" @setSelectedExchange="setSelectedExchange($event)" />
              </div>
              <botSelectedExchange v-if="selectedExchange" :selectedExchange="selectedExchange" />
            </div>
          </div>
          <div class="bottom-content-right">
            <div v-if="this.selectedExchange !== ''" class="confirmation-buttons sub-button">
              <span v-if="!selectedBot.enabled" class="not-available">This bot is currently not available</span>
              <span v-else-if="selectedBot.full_bot" class="not-available">This bot is currently full</span>
              <span v-else-if="!selectedBot.gold_only && this.$session.get('subscription_plan') && this.$session.get('subscription_plan').split('_')[0] !== 'gold' && availableDateDays < 15 && availableDateDays > 0" class="disabled-button disabled-button-sub">
                Available in {{ availableDateDays }} days for non gold subscribers
              </span>
              <span v-else-if="selectedBot.gold_only && this.$session.get('subscription_plan') && this.$session.get('subscription_plan').split('_')[0] === 'gold'" class="form-button button-sub button-shadow" @click="subscribe()">Start Bot</span>
              <span v-else-if="selectedBot.gold_only && this.$session.get('subscription_plan') && this.$session.get('subscription_plan').split('_')[0] !== 'gold'" class="disabled-button disabled-button-sub">
                Only available for gold subscriptions
              </span>
              <span v-else class="form-button button-sub button-shadow" @click="subscribe()">Start Bot</span>
            </div>
            <span class="title-med">Historical Performance</span>
            <div class="bot-stats">
              <div v-for="item in historicItems" :key="item.id">
                <metrics class="metric-item" :text="item.text" :value="item.value" :description="item.description" :img="item.img" />
              </div>
            </div>
            <span v-if="isHistoricalPerformance" class="performance-since">Tracked since: {{ selectedBot.backtest_start_date }}</span>
            <span v-else class="performance-since">Tracked since: {{ selectedBot.backtest_start_date }}</span>
            <div>
              <span class="title-med">Traded Pairs</span>
              <div class="bot-tickers">
                <div v-for="ticker in selectedBot.tickers" :key="ticker">
                  <span :title="ticker">
                    <img class="ticker-icon" :src="loadIcon(ticker)" alt="">
                  </span>
                </div>
              </div>
            </div>
            <div class="bot-info-bottom">
              <span class="title-med">Description</span>
              <span class="bot-info-description" v-html="selectedBot.description"></span>
              <br>
              <br>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!showLargeGraph" class="bot-graph">
        <div class="bot-graph-title">
          <div class="section-title">
            <span class="title section">Performance</span>
            <div class="value-tooltip">
              <img style="height: 20px; cursor:pointer; margin-left: 5px" src="@/assets/tooltip.png" alt @mouseover="showLiveDescription()" @mouseleave="showLiveDescription()" />
              <span v-if="liveDescription" class='metric-description'>
                <span class=" italic">Performance is based on closed positions.</span>
                <br><br>
                <span class="italic">The position record has a delay of 2 weeks. the current one is not disclosed. If you subscribed to the bot, the position record is instant.</span>
                <br><br>
                <span class="italic">When you start with one bot, the open positions are not copied. </span>
              </span>
            </div>
          </div>
          <time-filter-dropdown class="time-filter" :isBotGraph="true" @updateTimeFrame="updateGraphTimeFrame($event)" />
        </div>
        <LineGraph v-if="selectedBot.bot_name" class="bot-chart" ref="botGraph" :isBotGraph="true" :interval="interval" :id="selectedBot.bot_name" :graphData="graphData" :graphLabels="graphLabels" />
      </div>
    </div>
    <Loading class="loading" v-if="loading" :active="loading" />
    <new-bot-modal v-if="newBotModal" :bot="selectedBot" :exchange="selectedExchange.name" @click="closeModal()" />
  </div>
</template>

<script>
import NewBotModal from '@/components/shared/modals/NewBotModal'
import Descriptions from '@/json/descriptions.json'
import Metrics from '@/components/shared/Metrics.vue'
import BotTableItem from '@/components/shared/BotTableItem.vue'
import botSelectedExchange from '@/components/shared/exchanges/botSelectedExchange.vue'
import TimeFilterDropdown from '@/components/trading/TimeFilterDropdown'
import { mapActions, mapGetters } from 'vuex'
import LineGraph from '@/components/shared/LineGraph'
import Loading from '@/components/shared/Loading.vue'

export default {
  components: {
    NewBotModal,
    Metrics,
    BotTableItem,
    TimeFilterDropdown,
    LineGraph,
    Loading,
    botSelectedExchange
  },
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      isDarkTheme: localStorage.boostingAlphaDarkTheme,
      loading: false,
      headers: [
        { text: 'Open date', align: 'date', sortable: true, value: 'date' },
        { text: 'Pair', value: 'symbol', sortable: false },
        { text: 'Current Price', value: 'amount', sortable: false },
        { text: 'P/L #', value: 'side', sortable: false },
        { text: 'P/L %', value: 'price', sortable: false },
        { text: 'Invested', value: 'price', sortable: false }
      ],
      items: [],
      isHistoricalPerformance: true,
      liveDescription: false,
      historicalDescription: true,
      newBotModal: false,
      descriptions: Descriptions,
      selectedBot: {},
      isFull: false,
      isEnabled: true,
      historicItems: [],
      chartLabelFont: 14,
      interval: {
        value: 6,
        name: 'All Time',
        time: '50 Years'
      },
      graphData: [],
      graphLabels: [],
      availableDateDays: 0,
      selectedExchange: '',
      windowWidth: window.innerWidth,
      showLargeGraph: false
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
      // this.loadCharts()
    },
    newBotModal() {
      if (this.newBotModal) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
    windowWidth() {
      this.windowWidth < 1400 ? this.showLargeGraph = true : this.showLargeGraph = false
    }
  },
  created() {
    if (this.$route.params.bot_id) {
      this.$session.set('lastBotId', this.$route.params.bot_id)
    }
    this.getBot()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.windowWidth < 1400 ? this.showLargeGraph = true : this.showLargeGraph = false
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    ...mapActions(['addNewBotToProcess']),
    onResize() {
      this.windowWidth = window.innerWidth
    },
    setSelectedExchange(e) {
      this.selectedExchange = e
    },
    getDate() {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mm = String(today.getMonth() + 1).padStart(2, '0');//January is 0!
      let yyyy = today.getFullYear()

      today = yyyy + '-' + mm + '-' + dd

      this.getBotAvailableDate(today)
    },
    getBotAvailableDate(today) {
      let d1 = new Date(today)
      let d2 = new Date(this.selectedBot.published.split(' ')[0])

      let time_difference = d1.getTime() - d2.getTime();
      let days_difference = time_difference / (1000 * 60 * 60 * 24);
      this.availableDateDays = 14 - days_difference
    },
    updateGraphTimeFrame(timeframe) {
      this.graphData = []
      this.graphLabels = []

      this.interval = timeframe
      this.loadCharts()
    },
    loadCharts() {
      this.$api
        .get('/bots/get_public_chart', {
          params: {
            user_id: this.$session.get('uid'),
            timezone: this.$session.get('timezone'),
            bot_id: this.selectedBot.bot_id,
            interval: this.interval.time
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.populateGraphData(res.data.data)
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    populateGraphData(res) {
      this.overviewData = []
      this.overviewLabels = []
      this.graphData = []
      this.graphLabels = []
      let counter = 0
      for (const key in res) {
        if (!this.graphLabels.includes(key.split(' ')[0])) {
          this.graphLabels.push(key.split(' ')[0])
          this.graphData.push(res[key])
          counter++
        } else {
          this.graphData.splice(counter - 1, 1)
          this.graphLabels.splice(counter - 1, 1)

          this.graphLabels.push(key.split(' ')[0])
          this.graphData.push(res[key])
        }
      }
    },
    getBot() {
      this.loading = true
      this.$api
        .get('/bots/get_bot', {
          params: {
            bot_id: this.$session.get('lastBotId'),
            timezone: this.$session.get('timezone'),
            interval: this.interval.time
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.selectedBot = res.data.data
            this.loadCharts()
            this.getDate()
          }
        })
        .then(() => {
          this.historicItems = [
            {
              id: 0,
              text: 'Cumulative Return',
              value: `${this.selectedBot.cumulative_return.toString()}%`,
              description: this.descriptions.cumulativeReturn,
              img: 'assets/percentage-return.png'
            },
            {
              id: 1,
              text: 'Yearly Return',
              value: `${this.selectedBot.yearly_return.toString()}%`,
              description: this.descriptions.yearly_return,
              img: 'assets/return.png'
            },
            {
              id: 2,
              text: 'Risk Level',
              value: this.selectedBot.risk_level.toString(),
              description: this.descriptions.risk_level,
              img: 'assets/risk-level.png'
            },
            {
              id: 3,
              text: 'Trading Freq.',
              value: this.selectedBot.trading_frequency + '/week'.toString(),
              description: this.descriptions.drawdown,
              img: 'assets/trade-frequency.png'
            }
          ]
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    // return to market
    backToMarket() {
      this.$router.push({ name: 'Bots', params: { page: 'marketplace' } })
    },
    // load bot icon
    loadImage(path) {
      return require(`@/${path}`)
    },
    loadIcon(coin) {
      try {
        return require('/node_modules/cryptocurrency-icons/32/color/' + coin.toLowerCase() + '.png')
      } catch (e) {
        return require('/node_modules/cryptocurrency-icons/32/color/generic.png')
      }
    },
    // open subscribe to bot modal
    subscribe() {
      if (!this.$session.get('has_subscription')) {
        this.$router.push({ name: 'Settings', query: { page: 'subscription' } })
        return
      }
      this.newBotModal = true
    },
    // close subscribe to bot modal
    closeModal() {
      this.newBotModal = false
      this.botModal = false
    },
    showLiveDescription() {
      this.liveDescription = !this.liveDescription
    },
    showHistoricalDescription() {
      this.historicalDescription = !this.historicalDescription
    },
  }
}
</script>

<style scoped>
.bot {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  user-select: none;
}

.back {
  font-size: 16px;
  font-weight: 500;
  width: 220px;
  cursor: pointer;
}

.top-content {
  margin-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.bot-info {
  width: 50%;
}

.select-exchange-text {
  font-size: 18px;
}

.bot-info-top {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bot-info-bottom {
  padding: 20px 0;
  width: 100%;
  font-size: 20px;
  opacity: 0.8;
}

.bot-price {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.bot-info-name {
  font-size: 45px;
}

.title-med {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.title {
  display: flex;
  align-items: center;
}

.bot-info-icon {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.bot-icon-src {
  border-radius: 50%;
  width: 120px;
}

.published {
  font-weight: 500;
}

.bot-info-description {
  padding: 10px 0;
  text-align: justify;
  display: block;
  white-space: unset;
  width: 100%;
}

.bot-graph {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  width: 45%;
  height: 500px;
}

.bot-chart {
  width: 100%;
  height: 100%;
}

.bottom-content-right {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bot-stats {
  padding: 0 5px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: var(--widget-color);
  color: var(--text-secondary);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.05);
  gap: 10px;
}

.performance-since {
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-style: italic;
}

.bot-info-data {
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.bot-item-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.not-available {
  margin: 50px auto 0;
  font-size: 24px;
}

.bot-specifics {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 25px;
}

.bot-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bot-info-table {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bot-info {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.bot-information {
  width: 100%;
}

.bot-details {
  padding: 0 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  cursor: default;
  border-right: 2px solid var(--border);
}

.bot-details:last-child {
  border: none;
}

.button-sub {
  margin: -10px 0 10px;
  height: 50px;
  background-color: var(--primary-blue);
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.button-sub:hover {
  transform: scale(1.05);
  background-color: var(--primary-blue-hover);
}

.disabled-button-sub {
  margin: -10px 0 10px;
  height: 50px;
  background-color: var(--disabled);
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.sub-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0px !important;
}

.section {
  font-size: 28px;
  display: flex;
  align-items: center;
}

.bot-graph-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.metric-description {
  padding: 5px;
  margin-top: 5px;
  width: 150px;
  background-color: var(--text-secondary);
  border: 1px solid var(--secondary-blue);
  position: absolute;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 300;
}

.value-tooltip {
  margin-top: 5px;
}

.realtime {
  border-right: 2px solid var(--border);
  padding-right: 10px;
}

.no-data-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.loading {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchange-info-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 120px;
}

.exchange-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.exchange-items {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.connect-bot-title {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.connect-bot-img {
  height: 30px;
  width: 30px;
}

.bot-tickers {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.ticker-icon {
  height: 25px;
  width: 25px;
}

.checkmark {
  color: var(--green);
  font-weight: 500;
  font-size: 20px;
}

@media (max-width: 1400px) {
  .large-graph {
    width: 100%;
  }

  .bot-info {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .bot {
    padding: 0;
  }

  .bot-info-icon {
    flex-direction: column;
  }

  .bot-icon-src {
    width: 60px;
  }

  .bot-info-table {
    flex-wrap: wrap;
    justify-content: center;
  }

  .bot-info-name {
    font-size: 25px;
    text-align: center;
  }

  .top-content {
    flex-direction: column;
    justify-content: space-between;
  }

  .bot-info {
    width: 100%;
    height: auto;
    border-right: none;
  }

  .bot-graph {
    width: 100%;
  }

  .bottom-content-right {
    margin-top: 0;
    width: 100%;
  }

  .section {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin: 0;
  }

  .bot-details {
    padding: 0 5px;
  }

  .bot-item-data {
    width: 50%;
    justify-content: flex-start;
  }

  .bot-chart[data-v-21e70b0f] {
    width: 100%;
  }

  .bot-graph {
    padding: 10px 5px;
  }

  .sub-button {
    width: 100%;
  }

  .button-sub {
    width: 100%;
  }

  .exchange-info-container {
    height: 200px;
  }
}
</style>
