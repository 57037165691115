<template>
  <div>
    <div class="bot-card card" :class="{ pausedSub: !bot.active }" @click="editBot()">
      <div class="top-area">
        <div v-if="!bot.active" class="bot-status">
          <span class="sub-status-text paused-text">Paused</span>
          <span class="sub-status paused" title="Paused">
            <img class="sub-status-icon" src="@/assets/pause.png" alt/>
          </span>
        </div>
        <div v-if="bot.active" class="bot-status">
          <span class="sub-status-text play-text">Active</span>
          <span class="sub-status play" :title="'Active'">
            <img class="sub-status-icon" src="@/assets/play.png" alt/>
          </span>
        </div>
        <div class="bot-data">
          <span class="bot-icon">
            <img class="bot-icon-src" src="@/assets/bots/bot_template.png" alt/>
          </span>
          <div class="name-price">
            <span class="bot-name">{{ bot.bot_name }}</span>
            <div class="sub-connection">
              <span class="connected-exchange card" :class="bot.exchange" :title="bot.connection_name">
                <img class="exchange-icon" :src="loadExchangeIcon(bot.exchange)" alt/>
                <span>{{
                    bot.connection_name.length > 20 ? bot.connection_name.substr(0, 15) + '...' : bot.connection_name
                  }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-area">
        <div class="bot-graph">
          <bot-card-graph ref="botCardGraph" :labels="botGraphLabels" :data="botGraphData"
                          :name="bot.bot_id + bot.connection_id" :is-stats="false" :positive="isPositive"/>
        </div>
        <div class="bot-graph-data">
          <span v-if="bot.total_pnl_pct" class="bot-graph-data-text">{{ bot.total_pnl_pct.toFixed(2) }}%</span>
          <span v-else class="bot-graph-data-text">0.00%</span>
          <span class="sub-button add">Details</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotCardGraph from '@/components/trading/bots/BotCardGraph'

export default {
  name: 'BotCard',
  components: {
    BotCardGraph
  },
  props: {
    bot: Object,
    isActive: Boolean
  },
  data() {
    return {
      isEnabled: true,
      isFull: false,
      editMode: false,
      botModal: false,
      confirmMessage: false,
      botGraphData: [],
      botGraphLabels: [],
      isPositive: true
    }
  },
  mounted() {
    console.log(this.bot.exchange)
    if (this.bot.historical_performance.length > 0 && this.bot.historical_performance[this.bot.historical_performance.length - 1].pnl_pct < 0.0) {
      this.isPositive = false
    } else {
      if (this.bot.total_pnl_pct < 0) {
        this.isPositive = false
      }
    }
    this.generateBotGraphs()
  },
  methods: {
    // toggle bot edit
    loadExchangeIcon(exchange) {
      try {
        return require('@/assets/' + exchange.toLowerCase() + '-logo.png')
      } catch (e) {
      }
    },
    editBot() {
      this.$router.push({
        name: 'SubscribedBot',
        params: {
          name: this.bot.bot_name.replace(/ /g, ""),
          title: this.bot.bot_name,
          subBot: this.bot
        }
      })
    },
    generateBotGraphs() {
      this.botGraphData = []
      let counter = 0
      for (const key in this.bot.historical_performance) {
        if (!this.botGraphLabels.includes(key.split(' ')[0])) {
          this.botGraphLabels.push(key.split(' ')[0])
          this.botGraphData.push(this.bot.historical_performance[key])
          counter++
        } else {
          this.botGraphData.splice(counter - 1, 1)
          this.botGraphLabels.splice(counter - 1, 1)

          this.botGraphLabels.push(key.split(' ')[0])
          this.botGraphData.push(this.bot.historical_performance[key])
        }
      }
    }
  }
}
</script>

<style scoped>
.bot-card {
  cursor: pointer;
  padding: 10px;
  height: auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
      112.03deg,
      var(--card-background) 35.78%,
      var(--green) 273.82%
  ),
  var(--card-background);
  box-sizing: border-box;
  transition: transform 0.2s;
  border-radius: 15px !important;
}

.bot-card:hover {
  transform: scale(1.1);
}

.top-area {
  height: auto;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.exchange-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.bot-data {
  margin-top: -15px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.bot-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.bot-icon-src {
  height: 100%;
  margin-top: 1px;
  margin-left: -2px;
  border-radius: 50%;
}

.bot-name {
  max-width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 22px;
  font-weight: 400;
  margin-left: 5px;
}

.no-data-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.bottom-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 180px;
  background: linear-gradient(
      112.03deg,
      var(--card-background) 35.78%,
      var(--primary-blue) 273.82%
  ),
  var(--card-background);
  border: 1px solid var(--border);
  box-sizing: border-box;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
}

.pausedSub {
  background: linear-gradient(
      112.03deg,
      var(--card-background) 35.78%,
      #e7c60a 273.82%
  ),
  var(--card-background);
}

.bot-graph {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 2px;
  height: 60%;
  width: 100%;
  border-radius: 10px;
}

.bot-graph-data {
  padding-right: 10px;
  padding-left: 10px;
  height: 30%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bot-graph-data-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: var(--primary-blue);
}

.negative {
  -webkit-text-fill-color: var(--red);
  -webkit-text-stroke-color: var(--red);
  -webkit-text-stroke-width: 0.2px;
}

.sub-button {
  background-color: var(--primary-blue);
  padding: 6px 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: var(--text-secondary);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add {
  border-radius: 50px;
  height: 35px;
  background-color: var(--secondary-blue);
  color: var(--button-text);
}

.active-bot-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.name-price {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sub-connection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.connected-exchange {
  margin: 10px 0;
  padding: 3px 10px;
  display: flex;
  color: var(--secondary-blue);
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  align-items: center;
  border-radius: 100px;
  font-weight: bold;
}

.binance {
  background-color: var(--binance);
}

.bitvavo {
  background-color: var(--bitvavo);
}

.ftx {
  background-color: var(--ftx);
}

.okx_futures {
  background-color: var(--okx);
}

.bybit_futures {
  background-color: var(--bybit);
}

.bitget_futures {
  background-color: var(--bitget);
}

.kraken_futures {
  background-color: var(--kraken);
  color: var(--white);
}

.kucoin {
  background-color: var(--kucoin);
  color: #318068;
}

.sub-status {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stopped {
  background-color: var(--red);
  color: var(--text-secondary);
}

.paused {
  background-color: var(--yellow);
}

.paused-text {
  color: var(--yellow);
}

.play {
  background-color: var(--green);
}

.play-text {
  color: var(--green);
}

.sub-status-icon {
  margin-left: 1px;
  height: 10px;
  width: 10px;
}

.sub-status-text {
  font-size: 1px;
  font-weight: bold;
  margin-right: 5px;
}

.bot-status {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
