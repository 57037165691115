<template>
  <div class="notifications">
    <h1 class="title">Notifications</h1>
    <div class="notifications-content card">
      <div class="notifications-left">
        <div class="notifications-nav">
          <div class="user-notifications">
            <div
              class="notifications-nav-item"
              :class="{ selected: selectedNotificationType === 0 }"
            >
              <img
                v-if="selectedNotificationType === 0"
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/selected/bell-selected.png"
                alt=""
              />
              <img
                v-else
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/bell.png"
                alt=""
              />
              <span class="notifications-nav-item-text">All</span>
            </div>
            <div
              class="notifications-nav-item"
              :class="{ selected: selectedNotificationType === 1 }"
              @click="selectNotificationType(1)"
            >
              <img
                v-if="selectedNotificationType === 1"
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/selected/chart-selected.png"
                alt=""
              />
              <img
                v-else
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/chart.png"
                alt=""
              />
              <span class="notifications-nav-item-text">Trades</span>
            </div>
            <div
              class="notifications-nav-item"
              :class="{ selected: selectedNotificationType === 2 }"
              @click="selectNotificationType(2)"
            >
              <img
                v-if="selectedNotificationType === 2"
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/selected/system-selected.png"
                alt=""
              />
              <img
                v-else
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/system.png"
                alt=""
              />
              <span class="notifications-nav-item-text">System messages</span>
            </div>
            <div
              class="notifications-nav-item"
              :class="{ selected: selectedNotificationType === 3 }"
              @click="selectNotificationType(3)"
            >
              <img
                v-if="selectedNotificationType === 3"
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/selected/newspaper-selected.png"
                alt=""
              />
              <img
                v-else
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/newspaper.png"
                alt=""
              />
              <span class="notifications-nav-item-text">News</span>
            </div>
            <div
              class="notifications-nav-item"
              :class="{ selected: selectedNotificationType === 4 }"
              @click="selectNotificationType(4)"
            >
              <img
                v-if="selectedNotificationType === 4"
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/selected/graph-selected.png"
                alt=""
              />
              <img
                v-else
                class="notifications-nav-item-icon"
                src="@/assets/notificationIcons/graph.png"
                alt=""
              />
              <span class="notifications-nav-item-text">Activity</span>
            </div>
          </div>
        </div>
        <router-link
          :to="{ name: 'Settings', params: { type: 1 } }"
          class="notifications-settings"
        >
          <img
            class="notifications-nav-item-icon"
            src="@/assets/notificationIcons/gear.png"
            alt=""
          />
          <span class="notifications-nav-item-text"
            >Notifications settings</span
          >
        </router-link>
      </div>
      <div class="section-notification" id="notification">
        <div class="top-notification-section">
          <h2>{{ notificationType }}</h2>
          <img
            class="more-options"
            src="@/assets/dots.png"
            alt=""
            @click="showMoreOptions()"
          />
          <div v-if="moreOptions" class="notification-options">
            <span class="notification-option-item" @click="markAllAsRead()">
              Mark all as read
            </span>
          </div>
        </div>
        <div v-if="notificationsFiltered.length < 1" class="notification-item">
          <span>There are no notifications</span>
        </div>
        <div
          v-for="item in notificationsFiltered"
          :key="item.title"
          class="notification-item"
          :class="{ unread: !item.isRead }"
          @click="openNotification(item)"
        >
          <div class="notification-item-title">
            <span
              class="notification-item-read"
              :class="{ notRead: !item.isRead }"
            ></span>
            <span>{{ item.title }}</span>
          </div>
          <div v-if="!smallScreen" class="notification-item-type">
            <span>{{ getNotificationType(item.type) }}</span>
          </div>
          <div v-if="!smallScreen" class="notification-item-content">
            <span>{{ getContentSneakPeak(item.content) }}...</span>
          </div>
          <div class="notification-item-date">{{ item.date }}</div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showModal"
      :item="selectedNotification"
      @click="closeModal()"
    />
  </div>
</template>

<script>
import Modal from '@/components/shared/modals/Modal'

export default {
  name: 'notifications',
  components: {
    Modal
  },
  data() {
    return {
      moreOptions: false,
      smallScreen: false,
      selectedNotificationType: 0,
      notificationType: null,
      notifications: [
        {
          title: 'New message',
          type: 1,
          content: 'test notification',
          date: '01-01-2021 00:00',
          isRead: false
        },
        {
          title: 'Second message',
          type: 4,
          content: 'test notification version two',
          date: '01-01-2021 00:00',
          isRead: true
        }
      ],
      notificationsFiltered: [],
      selectedNotification: null,
      showModal: false
    }
  },
  watch: {
    // toggle notification filter
    notificationType() {
      if (this.selectedNotificationType > 0) {
        this.notificationsFiltered = this.notifications.filter(
          notification => notification.type === this.selectedNotificationType
        )
      } else {
        this.notificationsFiltered = this.notifications
      }
    }
  },
  mounted() {
    if (window.innerWidth < 850) {
      this.smallScreen = true
    }
    this.notificationType = this.setNotificationTypeName(
      this.selectedNotificationType
    )
    this.notificationsFiltered = this.notifications
  },
  methods: {
    // select type of notifications user wants to see
    selectNotificationType(type) {
      this.selectedNotificationType = type
      this.notificationType = this.setNotificationTypeName(
        this.selectedNotificationType
      )
      const element = document.getElementById('notification')
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    },
    // create short text for notification content
    getContentSneakPeak(str) {
      return str.substring(0, 50)
    },
    // set notification as read once it is opened
    openNotification(item) {
      item.isRead = true
      this.selectedNotification = item
      this.showModal = true
    },
    // close notification content modal
    closeModal() {
      this.showModal = false
      this.selectedNotification = []
    },
    // change title name
    setNotificationTypeName(type) {
      switch (type) {
        case 0:
          return 'All notifications'
        case 1:
          return 'Trade notifications'
        case 2:
          return 'System notifications'
        case 3:
          return 'News notifications'
        case 4:
          return 'Activity notifications'
      }
    },
    // get type of notification
    getNotificationType(type) {
      switch (type) {
        case 1:
          return 'Trades'
        case 2:
          return 'System'
        case 3:
          return 'News'
        case 4:
          return 'Activity'
      }
    },
    // toggle more options button
    showMoreOptions() {
      this.moreOptions = !this.moreOptions
    },
    // mark all notifications as read
    markAllAsRead() {
      this.moreOptions = false
    }
  }
}
</script>

<style scoped>
.section-notification {
  padding: 20px;
  height: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.notifications {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notifications-settings {
  width: 80%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: var(--header-color);
  border-radius: 10px !important;
}

.notifications-settings:hover {
  background-color: #e8f6ff;
}

.notifications-content {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
}

.notifications-nav {
  padding: 20px;
  width: 100%;
  margin-bottom: 100px;
}

.notifications-left {
  width: 25%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--header-color);
}

.user-notifications {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.notifications-nav-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.notifications-nav-item:hover {
  background-color: #e8f6ff;
  border-radius: 10px;
}

.notifications-nav-item-text {
  cursor: pointer;
  font-size: 20px;
}

.selected {
  color: #1b81c0;
  background-color: #e8f6ff;
  border-radius: 10px;
}

.notifications-nav-item-icon {
  height: 100%;
  width: 25px;
  margin-right: 30px;
}

.top-notification-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.notification-item {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  align-items: center;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #e8f6ff;
  border-radius: 5px;
}

.notification-item-read {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.notRead {
  background-color: #1b81c0;
}

.notification-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.notification-item-type {
  width: 10%;
}

.notification-item-content {
  width: 55%;
}

.notification-item-date {
  width: 15%;
}

.unread {
  font-weight: bold;
}

.more-options {
  height: 25px;
  cursor: pointer;
}

.notification-options {
  padding: 5px;
  height: auto;
  margin-top: 25px;
  margin-right: 90px;
  right: 0;
  width: 150px;
  background-color: var(--white);
  border: 1px solid var(--header-color);
  position: absolute;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.notification-option-item {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.notification-option-item:hover {
  background-color: #e8f6ff;
  border-radius: 5px;
}

@media (max-width: 850px) {
  .notifications-content {
    flex-direction: column;
  }

  .notifications-left {
    border: none;
    width: 100%;
  }

  .notifications-nav {
    margin-bottom: 0;
    padding: 0;
  }

  .notifications-nav-item-text {
    font-size: 14px;
  }

  .user-notifications {
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .notifications-nav-item {
    padding: 2px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-notification {
    margin-top: 20px;
    padding: 0;
    width: 100%;
  }

  .notification-item-title {
    width: 50%;
  }

  .notification-item-date {
    width: 50%;
  }

  .notifications-nav-item-icon {
    display: none;
  }

  .notifications-settings {
    width: 100%;
    justify-content: center;
  }

  .notification-options {
    margin-right: 30px;
  }
}
</style>
