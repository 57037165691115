<template>
  <div>
    <svg width="10" height="10" viewBox="-2.5 -5 75 60" preserveAspectRatio="none">
      <path d="M0,0 l35,50 l35,-50" stroke-linecap="round" stroke-width="5" />
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
