<template>
  <div>
    <div class='investments' @click='goToSubbedBot()'>
      <div class='investment-bot-info top'>
        <div class='investment-info top'>
          <span class='investment-icon'>
            <img class='investment-icon-img' src='@/assets/bots/bot_template.png' alt />
          </span>
          <span class='investment-title' :title='item.bot_name'>{{ item.bot_name.length > 25 ? item.bot_name.substr(0, 25) + '...' : item.bot_name }}</span>
        </div>
        <span class='exchange-account' :title='item.connection_name'>
          <img class='exchange-icon' src='@/assets/binance-logo.png' alt />
          <span class='exchange-account-name'>{{ item.connection_name.length > 20 ? item.connection_name.substr(0, 15) + '...' : item.connection_name }}</span>
        </span>
      </div>
      <div class="revenue-info">
        <div class="bot-revenue">
          <div class="investment-profit">
            <span class="investment-profit-title">PnL:</span>
            <span v-if="item.total_pnl_pct" class="investment-profit-value" :class="{negative: !isPositive}">{{ item.total_pnl_pct.toFixed(2)  }} %</span>
            <span v-else class="investment-profit-value" :class="{negative: !isPositive}">0 %</span>
          </div>
          <div class='investment-profit'>
            <span class='investment-profit-title'>Allocated:</span>
            <span class='investment-profit-value blue'>{{ item.allocated }} USDT</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotCardGraph from '../bots/BotCardGraph'
import { mapGetters } from 'vuex'

export default {
  components: { BotCardGraph },
  computed: mapGetters(['userSubscribedBots', 'connectedExchanges']),
  props: {
    item: Object,
  },
  data() {
    return {
      isPositive: true,
    }
  },
  mounted() {
    this.item.total_pnl_pct < 0 ? this.isPositive = false : this.isPositive = true
  },
  methods: {
    goToSubbedBot() {
      this.$router.push({
        name: 'SubscribedBot',
        params: {
          name: this.item.bot_name,
          subscriptionId: this.item.subscription_id
        }
      })
    }
  }
}
</script>

<style scoped>
.investments {
  margin-bottom: 10px;
  padding: 5px;
  background: linear-gradient(
      112.03deg,
      #f7f7f7 35.78%,
      var(--blue-primary) 273.82%
    ),
    #ffffff;
  border: 2px solid #bed8e9;
  min-height: 75px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  user-select: none;
  transition: transform 0.1s;
  cursor: pointer;
}

.investments:hover {
  transform: scale(1.01);
}

.investment-bot-info {
  min-height: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.investment-info {
  min-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.revenue-info {
  padding: 0 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.top {
  justify-content: normal;
}

.investment-title {
  font-size: 95%;
  font-weight: 500;
  color: var(--header-color);
  margin-left: 5px;
}

.investment-profit {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.investment-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.investment-icon-img {
  height: 100%;
  border-radius: 50%;
}

.investment-profit-value {
  color: #09983f;
  font-weight: 500;
}

.negative {
  color: var(--red);
}

.exchange-account {
  margin-left: 3px;
  width: 60%;
  display: flex;
  align-items: center;
}

.exchange-icon {
  height: 25px;
  margin-left: 5px;
}

.exchange-account-name {
  width: 100%;
  font-size: 90%;
  font-weight: 400;
  word-wrap: break-word;
}

.item-graph {
  height: 40px;
  width: 200px;
}

.bot-revenue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.investment-revenue {
  font-size: 18px;
  font-weight: 500;
  color: var(--header-color);
}

.investment-profit-allocated {
  font-weight: 400;
}

.investment-profit-title {
  margin-right: 5px;
}

.manage-subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.manage-subscriptions-text {
  text-decoration: underline;
  color: var(--blue-primary);
  font-size: 18px;
  cursor: pointer;
}

.no-active-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
}

.blue {
  color: var(--header-color);
}

.no-data-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
}

@media (max-width: 1450px) {
  .investments {
    flex-direction: column;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .investment-bot-info {
    width: 100%;
  }

  .investment-info {
    width: 100%;
    font-size: 100%;
  }

  .item-graph {
    width: 100%;
  }

  .investment-title {
    width: 100%;
  }

  .investment-profit {
    font-size: 20px;
  }

  .bot-revenue {
    width: 100%;
  }

  .revenue-info {
    flex-direction: column;
    width: 100%;
    font-size: 20px;
  }
}
</style>
