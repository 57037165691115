<template>
  <div class="section-settings">
    <h1 class="title">Referral Code</h1>
    <Loading class="loading" :active="loading" />
    <div v-if="!loading" class="section">
      <span class="section-title">Your referral code</span>
      <div class="referral-code">
        <span class="referral" @click="copyReferral()">
          <input class="form-input referral-code-input" type="text" v-model="url" disabled />
          <img class="copy-img" src="@/assets/copy.png" alt="">
        </span>
        <span class="button-p button-shadow referral-code-button" @click="copyReferral()"> Copy My Link</span>
        <span v-if="copiedCode" class="link-copied">Copied!</span>
      </div>
    </div>

  </div>
</template>

<script>
import Loading from "@/components/shared/Loading";

export default {
  name: 'ReferralCode',
  components: {
    Loading
  },
  props: {
    referralCode: String
  },
  data() {
    return {
      loading: false,
      copiedCode: false,
      url: ''
    }
  },
  mounted() {
    this.url = process.env.VUE_APP_URL + '?page=register&referral=' + this.referralCode
  },
  methods: {
    copyReferral() {
      navigator.clipboard.writeText(this.url)
      this.copiedCode = true
      const vm = this
      setTimeout(function () {
        vm.copiedCode = false
      }, 5000);
    }
  }
}
</script>

<style scoped>
.section-settings {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.section-title {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.referral-code-input {
  cursor: pointer;
  background-color: var(--background);
  border: none;
  color: var(--disabled);
}

.copy-img {
  height: 20px;
  position: absolute;
  top: 25%;
  right: 10px;
}

.referral-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.referral-code-button {
  padding: 10px 100px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.1s;
  font-weight: 500;
  background-color: var(--purple);
}

.referral-code-button:active {
  transform: scale(0.99);
}

.link-copied {
  opacity: 1;
  animation: fade 2s linear;
  color: var(--disabled);
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
