const state = {
  selectedActiveFilters: [],
  activeFilters: [],
  activeTab: [],
  selectedExchanges: [],
  ROIInterval: [],
  cachedBots: []
}

const getters = {
  currentActiveFilters: state => state.activeFilters,
  selectedActiveFilters: state => state.selectedActiveFilters,
  currentSelectedExchanges: state => state.selectedExchanges,
  currentROIInterval: state => state.ROIInterval,
  currentActiveTab: state => state.activeTab,
  currentCachedBots: state => state.cachedBots
}

const actions = {
  async updateSelectedActiveFilters({ commit }, filters) {
    commit('setSelectedActiveFilters', filters)
  },
  async updateActiveFilters({ commit }, filters) {
    commit('setActiveFilters', filters)
  },
  async updateSelectedExchanges({ commit }, exchanges) {
    commit('setSelectedExchanges', exchanges)
  },
  async updateROIInterval({ commit }, interval) {
    commit('setROIInterval', interval)
  },
  async updateActiveTab({ commit }, tab) {
    commit('setActiveTab', tab)
  },
  async updateCachedBots({ commit }, bots) {
    commit('setCachedBots', bots)
  }
}

const mutations = {
  setSelectedActiveFilters: (state, filters) =>
    (state.selectedActiveFilters = filters),
  setActiveFilters: (state, filters) => (state.activeFilters = filters),
  setSelectedExchanges: (state, exchanges) =>
    (state.selectedExchanges = exchanges),
  setROIInterval: (state, interval) => (state.ROIInterval = interval),
  setActiveTab: (state, tab) => (state.activeTab = tab),
  setCachedBots: (state, bots) => (state.cachedBots = bots)
}

export default {
  state,
  getters,
  actions,
  mutations
}
