<template>
  <div class="login-component">
    <div class="left-container">
      <span class="button-p button-shadow back" @click="toLandingPage()">&larr; Back to landing page</span>
    </div>
    <div class="right-container">
      <div class="login-info">
        <span class="login-title">
          <span class="bold">Boosting</span> Alpha
        </span>
        <span class="subtitle">Copy trading portal</span>
      </div>
      <div v-if="!loading" class="login-form card">
        <div class="login">
          <span v-if="!isRegister" class="form-header">Login</span>
          <span v-else class="form-header">Create Account</span>
          <div class="form-inputs">
            <span v-if="credentialsError" class="error">{{ loginErrorMsg }}</span>
            <br>
            <span v-if="notVerified" class="error verification-code" @click="toggleVerificationCodeModal()">re-send verification code</span>
            <label class="login-input">
              <span v-if="!isRegister">Email or username:</span>
              <span v-else>Email:</span>
              <input class="form-input" :class="{ errorInput: !validEmail }" v-model="email" type="email" />
              <span v-if="!validEmail" class="error">invalid email</span>
            </label>
            <label v-if="isRegister" class="login-input">
              <div>
                <span>Username:</span>
                <span class="input-info">(More than 4 characters)</span>
              </div>
              <input class="form-input" :class="{ errorInput: !validUsername }" v-model="username" type="text" />
              <span v-if="!validUsername" class="error">{{ usernameErrorMsg }}</span>
            </label>
            <label v-if="isRegister" class="login-input">
              <span>Country:</span>
              <country-select class="form-input country-selector" v-model="country" :country="country" placeholder="Select Country" />
            </label>
            <label class="login-input">
              <div>
                <span> Password:</span>
                <span v-if="isRegister" class="input-info">(At least 8 characters, one uppercase & one number)</span>
              </div>
              <span class="password-visibility-toggle">
                <input id="user-password" class="form-input" :class="{ errorInput: !validPassword }" v-model="password" type="password" />
                <img v-if="!isDarkTheme" class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(0)" />
                <img v-else class="password-toggle" src="@/assets/trading-platform/eye-white.png" alt @click="togglePasswordVisibility(0)" />
              </span>
              <span v-if="!validPassword" class="error">invalid password</span>
            </label>
            <div v-if="!isRegister" class="password-reset">
              <span>Forgot password?</span>
              <span class="forgot-password" @click="forgotPassword()">click here!</span>
            </div>
            <label v-if="isRegister" class="login-input">
              <span>Confirm Password:</span>
              <span class="password-visibility-toggle">
                <input id="user-confirm-password" class="form-input" :class="{ errorInput: !validConfirmPassword }" v-model="confirmPassword" type="password" />
                <img v-if="!isDarkTheme" class="password-toggle" src="@/assets/trading-platform/eye.png" alt @click="togglePasswordVisibility(1)" />
                <img v-else class="password-toggle" src="@/assets/trading-platform/eye-white.png" alt @click="togglePasswordVisibility(1)" />
              </span>
              <span v-if="!validConfirmPassword" class="error">Passwords do not match</span>
            </label>
            <!--            <label v-if="isRegister" class="login-input">-->
            <!--              <span>Referral Code:</span>-->
            <!--              <span class="password-visibility-toggle">-->
            <!--                            <input id="referral-code" class="form-input" v-model="referralCode" type="text"/>-->
            <!--                          </span>-->
            <!--              <span v-if="!validReferral" class="error">Referral code is not correct</span>-->
            <!--            </label>-->
          </div>
          <div v-if="isRegister" class="create-account-route">
            <input id="terms-checkbox" class="terms-checkbox" type="checkbox" @change="toggleTermsCheckbox()" />
            <span>I agree with the</span>
            <router-link :to="{name: 'PrivacyPolicy'}" class="sign-up-here" target="_blank">Privacy policy
            </router-link>
            <span>and</span>
            <router-link :to="{name: 'TermsConditions'}" class="sign-up-here" target="_blank">Terms & Conditions
            </router-link>
          </div>
          <div v-if="!isRegister" class="create-account-route">
            <span>Don't have an account yet?</span>
            <span class="sign-up-here" @click="toggleRegister()">Sign up here</span>
          </div>
          <span v-else class="create-account-route">
            <span>Already have an account?</span>
            <span class="sign-up-here" @click="toggleRegister()">Log in here</span>
          </span>
          <br />
          <span v-if="loginError" class="error">{{ errorMessage }}</span>
          <button v-if="!isRegister" class="button-p button-shadow button-login" @click="logInUser()">
            <span>Login</span>
          </button>
          <button v-else class="button-p button-shadow button-login" @click="registerAccount()">
            <span>Register Account</span>
          </button>
        </div>
      </div>
    </div>
    <forgot-password-modal v-if="passwordReset" :code="forgotPasswordCode" :newPassword="newPasswordLink" :email="email" @click="closeModal($event)" />
    <modal v-if="accountRegistered" :item="registerAccountDetails" :accountRegister="true" :email="email" @click="closeModal()" />
    <modal v-if="accountVerified" :item="verifyAccountDetails" :accountVerified="true" @click="closeModal()" />
    <loading class="loading" :active='loading' :login='true' />
    <OTPModal v-if="showOtpModal" @click="toggleOtpModal()" @confirmOtpCode='confirmOtpCode($event)' />
    <VerificationCodeModal v-if="openVerificationCodeModal" @click="toggleVerificationCodeModal()" @sendVerification="sendVerificationCode($event)" />
  </div>
</template>

<script>
import ForgotPasswordModal from '@/components/shared/modals/ForgotPasswordModal.vue'
import Modal from '@/components/shared/modals/Modal.vue'
import Loading from '@/components/shared/Loading.vue'
import OTPModal from '@/components/shared/modals/OTPModal.vue'
import VerificationCodeModal from "@/components/shared/modals/VerificationCodeModal";

export default {
  name: 'Login',
  components: {
    Modal,
    ForgotPasswordModal,
    Loading,
    OTPModal,
    VerificationCodeModal
  },
  data() {
    return {
      loading: false,
      newPasswordLink: true,
      autoLogin: false,
      loginError: false,
      isRegister: false,
      username: '',
      email: '',
      country: '',
      dob: '',
      password: '',
      confirmPassword: '',
      termsPrivacyChecked: false,
      passwordReset: false,
      resetPassword: {
        title: 'Forgot your password?'
      },
      accountRegistered: false,
      registerAccountDetails: {
        title: 'Email confirmation has been sent'
      },
      accountVerified: false,
      verifyAccountDetails: {
        title: 'Account Verified'
      },
      credentialsError: false,
      passwordVisible: false,
      confirmPasswordVisible: false,
      validEmail: true,
      validUsername: true,
      validPassword: true,
      validConfirmPassword: true,
      validReferral: true,
      errorMessage: '',
      usernameErrorMsg: '',
      loginErrorMsg: '',
      verifyMsg: '',
      forgotPasswordCode: '',
      validDob: true,
      showOtpModal: false,
      notVerified: false,
      landingURL: process.env.VUE_APP_LANDING_URL,
      openVerificationCodeModal: false,
      referralCode: '',
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  created() {
    if (typeof localStorage.boostingAlphaDarkTheme === 'undefined') {
      localStorage.setItem('boostingAlphaDarkTheme', JSON.stringify(false));
    }

    if (localStorage.boostingAlphaDarkTheme === 'true') {
      document.documentElement.setAttribute('data-theme', 'dark');
    }
    if (localStorage.boostingAlphaDarkTheme === 'false') {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  },
  mounted() {
    this.$session.destroy()
    if (this.$route.query.referral){
      this.referralCode = this.$route.query.referral
    }

    if (this.$route.query.referral || this.$route.query.page === 'register') {
      this.isRegister = true
      return
    }
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.type === 'forgot_password') {
        this.newPasswordLink = true
        this.passwordReset = true
        this.forgotPasswordCode = this.$route.query.code
        this.email = this.$route.query.address
      } else {
        const body = {
          email: this.$route.query.address,
          verification_code: this.$route.query.code
        }
        this.$api
          .post('/users/verify_user', body, {
            headers: {
              'X-Authorization': this.$session.get('guest-token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.accountVerified = true
              this.verifyMsg = 'Your account is verified'
            }
          })
          .catch((err) => {
            if (err.response.data.error.type === 'ALREADY_VERIFIED') {
              this.accountVerified = true
              this.verifyMsg = 'Your account is verified'
            }
          })
      }
    }
  },
  methods: {
    toggleVerificationCodeModal() {
      this.openVerificationCodeModal = !this.openVerificationCodeModal
    },
    sendVerificationCode(email) {
      this.email = email
      let body = {
        email: email
      }
      this.$api.post('/users/resend_verification_code', body)
        .then((res) => {
          if (res.status === 200) {
            this.toggleVerificationCodeModal()
            this.accountRegistered = true
          }
        })
    },
    // Clear all inputs
    clearInputs() {
      this.username = ''
      this.email = ''
      this.password = ''
      this.confirmPassword = ''
    },
    toLandingPage() {
      this.referralCode ?
          window.location = this.landingURL + '/?referral=' + this.referralCode
          : window.location = this.landingURL
    },
    // Toggle between login and register
    toggleRegister() {
      this.accountVerified = false
      this.credentialsError = false
      this.loginError = false
      this.clearInputs()
      this.isRegister = !this.isRegister
    },
    toggleTermsCheckbox() {
      const checkBox = document.getElementById('terms-checkbox')
      if (checkBox.checked) {
        this.termsPrivacyChecked = true
      } else {
        this.termsPrivacyChecked = false
      }
    },
    toggleOtpModal() {
      this.showOtpModal = !this.showOtpModal
    },
    logInUser() {
      this.loginErrorMsg = ''
      this.loading = true
      this.accountVerified = false
      let body = {}
      if (this.email && this.password) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isEmail = emailRegex.test(this.email)
        if (isEmail) {
          body = {
            email: this.email,
            password: this.password
          }
        } else {
          body = {
            username: this.email,
            password: this.password
          }
        }
        this.$api
          .post('/users/authenticate_user', body, {
            headers: {
              'X-Authorization': this.$session.get('guest-token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.$session.set('uid', res.data.data.user_id)
              this.$session.set('username', res.data.data.username)
              if (res.data.data.otp_enabled) {
                this.loading = false
                this.toggleOtpModal()
                return
              }
              this.getToken()
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response && err.response.data.error.type === 'NOT_VERIFIED') {
              this.notVerified = true
              this.credentialsError = true
              this.loginErrorMsg = 'This account is not yet verified, check your verification email.'
            }
            if (err.response && err.response.data.error.type === 'NOT_FOUND') {
              this.credentialsError = true
              this.loginErrorMsg = 'Wrong username/email or password'
            }
            if (err.response && err.response.data.error.type === 'DUPLICATE_VALUE') {
              this.loginErrorMsg = 'This user already exists'
            }
          })
      } else {
        this.loading = false
        this.loginErrorMsg = 'Wrong username/email or password'
        this.credentialsError = true
      }
    },
    // Handle user registration
    registerAccount() {
      this.checkEmail()
      this.checkUsername()
      this.checkPassword()
      this.checkReferralCode()

      if (this.referralCode !== '' && !this.validReferral) return

      if (this.validEmail && this.validUsername && this.validPassword && this.validConfirmPassword && this.termsPrivacyChecked) {
        const body = {
          username: this.username,
          email: this.email,
          password: this.password,
          country: this.country,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        if (this.validReferral) body.referral_id = this.referralCode

        this.$api
          .post('/users/add_user', body, {
            headers: {
              'X-Authorization': this.$session.get('guest-token')
            }
          })
          .then(() => {
            this.accountRegistered = true
          })
          .catch((err) => {
            this.loginError = true
            this.errorMessage = err.response.data.error.body
          })
      } else {
        this.errorMessage = 'you have to accept the terms of service and privacy policy'
      }
    },
    checkReferralCode() {
      this.validReferral = true
      if (this.referralCode.length !== 8) return
      const referralRegex = /^[a-zA-Z0-9_.-]*$/
      this.validReferral = referralRegex.test(this.referralCode)
    },
    checkEmail() {
      this.validUsername = true
      if (this.email.length < 254) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.validEmail = emailRegex.test(this.email)
      }
    },
    checkUsername() {
      this.validUsername = true
      if (this.username.length > 4 && this.username.length < 36) {
        const nameRegex = /^[\w.-]+$/
        this.validUsername = nameRegex.test(this.username)
        return
      }
      this.validUsername = false
      this.usernameErrorMsg = 'invalid username'
    },
    checkPassword() {
      this.validPassword = true
      this.validConfirmPassword = true
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,128}$/
      this.validPassword = passwordRegex.test(this.password)
      if (this.password !== this.confirmPassword) {
        this.validConfirmPassword = false
      }
    },
    //toggle password visibility
    togglePasswordVisibility(e) {
      switch (e) {
        case 0:
          this.passwordVisible = !this.passwordVisible
          if (this.passwordVisible) {
            document.getElementById('user-password').type = 'text'
            return
          }
          document.getElementById('user-password').type = 'password'
          break
        case 1:
          this.confirmPasswordVisible = !this.confirmPasswordVisible
          if (this.confirmPasswordVisible) {
            document.getElementById('user-confirm-password').type = 'text'
            return
          }
          document.getElementById('user-confirm-password').type = 'password'
          break
      }
    },
    // Opens forgot password modal
    forgotPassword() {
      this.newPasswordLink = false
      this.passwordReset = true
    },
    // Closes forgot password modal
    closeModal(msg) {
      this.accountVerified = false
      this.verifyMsg = msg
      this.passwordReset = false
      this.accountRegistered = false
    },
    getUser() {
      this.$api
        .get('/users/get_user', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$session.set('email', res.data.data.email)
            this.$session.set('timezone', res.data.data.timezone)
            if (res.data.data.active_subscription) {
              this.$router.push({ name: 'Dashboard' })
              this.loading = false
              return
            }

            this.$router.push({ name: 'Bots', params: { page: 'marketplace' } })
            this.loading = false
          }
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
    },
    getToken() {
      this.loading = true
      const bodyFormData = new FormData()
      bodyFormData.append('username', this.$session.get('username'))
      bodyFormData.append('password', this.password)

      if (!this.$session.get('token')) {
        this.$api
          .post('/token', bodyFormData, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((res) => {
            this.$session.set('token', 'Bearer ' + res.data.data.access_token)
            this.getUser()
          })
          .catch((err) => {
            this.loading = false
            if (err.response.data.error.type === 'NOT_VERIFIED') {
              this.notVerified = true
              this.credentialsError = true
              this.loginErrorMsg = 'This account is not yet verified, check your verification email or'
            }
            if (err.response.data.error.type === 'Incorrect username or password') {
              this.credentialsError = true
              this.loginErrorMsg = 'Incorrect username or password'
            }
            if (err.response.data.error.type === 'Incorrect 2FA Code') {
              this.credentialsError = true
              this.loginErrorMsg = 'Incorrect 2-factor authentication token'
            }
            if (err.response.status === 404) {
              this.credentialsError = true
            }
          })
      }
    },
    confirmOtpCode(code) {
      this.password = this.password + code
      this.getToken()
    }
  }
}
</script>

<style scoped>
.login-component {
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  justify-content: space-between;
  background-image: url('../../assets/trading-platform/register-bg.png');
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto Condensed', sans-serif;
  overflow: auto;
}

.left-container {
  width: 50%;
  margin-top: 20px;
}

.right-container {
  margin-top: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.landing-page {
  color: var(--text-primary);
  font-size: 18px;
  margin: 20px;
  text-decoration: underline;
}

.login-info {
  margin: 0 auto;
  width: 550px;
  display: flex;
  flex-direction: column;
}

.login-title {
  font-family: 'Roboto', sans-serif;
  color: var(--white);
  font-size: 72px;
  font-weight: 500;
}

.beta-title {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: 'Roboto', sans-serif;
  color: var(--text-secondary);
  font-size: 32px;
  font-weight: 500;
  margin-bottom: -20px;
}

.bold {
  font-weight: 400;
}

.error {
  font-size: 16px;
  color: var(--red);
}

.success {
  font-size: 16px;
  color: var(--green);
}

.errorInput {
  border: 2px solid var(--red);
}

.subtitle {
  font-weight: lighter;
  color: var(--white);
  font-size: 32px;
}

.login-form {
  margin: 20px auto 0;
  height: auto;
  width: 550px;
  background-color: var(--background);
}

.login {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.form-header {
  font-size: 28px;
  color: var(--text-link);
  font-weight: bold;
  text-align: left;
}

.login-input {
  margin-top: 10px;
  text-align: left;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.create-account-route {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-weight: 600;
}

.sign-up-here {
  margin: 0 5px;
  color: var(--text-link);
}

.sign-up-here:hover {
  cursor: pointer;
  color: var(--text-link);
}

.terms-checkbox {
  margin-right: 10px;
}

.password-reset {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.forgot-password {
  color: var(--text-link);
  cursor: pointer;
  margin-left: 5px;
}

.button-login {
  margin: 0 auto;
  width: 50%;
}

.password-visibility-toggle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 30px;
  cursor: pointer;
}

.input-info {
  margin-left: 10px;
  font-size: 16px;
  color: var(--disabled);
}

.country-selector {
  width: 100%;
  cursor: pointer;
}

.loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.verification-code {
  color: var(--text-link);
  text-decoration: underline;
  cursor: pointer;
}

.back {
  margin: 10px;
  background-color: var(--white);
  color: var(--primary-blue);
  font-size: 16px;
  font-weight: bold;
  width: 225px;
  cursor: pointer;
}

@media (max-width: 1370px) {
  .right-container {
    width: 90%;
    margin: 50px auto;
  }
}

@media (max-width: 850px) {
  .login-component {
    flex-direction: column;
    justify-content: normal;
  }

  .left-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .login-info {
    width: auto;
    justify-content: center;
    align-content: center;
  }

  .login-title {
    font-size: 55px;
    text-align: center;
  }

  .subtitle {
    text-align: center;
  }

  .login-form {
    width: 100%;
  }
}
</style>
