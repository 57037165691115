const state = {
  activeBots: [],
  balanceAllocation: [],
  userTotal: [],
  positions: [],
  overview_qty: [],
  overview_pct: []
}

const getters = {
  userSubscribedBots: state => state.activeBots,
  userBalanceAllocation: state => state.balanceAllocation,
  userPositions: state => state.positions,
  userTotal: state => state.userTotal,
  userStats: state => state.statistics,
  pctOverview: state => state.overview_pct,
  qtyOverview: state => state.overview_qty
}

const actions = {
  async loadSubscribedBots({ commit }, bots) {
    commit('loadUserBots', bots)
  },
  async loadBalanceAllocation({ commit }, allocation) {
    commit('loadUserBalanceAllocation', allocation)
  },
  async loadPositions({ commit }, positions) {
    commit('loadUserPositions', positions)
  },
  async loadTotal({ commit }, userTotal) {
    commit('loadUserTotal', userTotal)
  },
  async loadPctOverview({ commit }, pct) {
    commit('loadUserOverviewPct', pct)
  },
  async loadQtyOverview({ commit }, qty) {
    commit('loadUserOverviewQty', qty)
  }
}

const mutations = {
  loadUserBots(state, bots) {
    state.activeBots = bots
  },
  loadUserBalanceAllocation(state, allocation) {
    state.balanceAllocation = allocation
  },
  loadUserPositions(state, positions) {
    state.positions = positions
  },
  loadUserTotal(state, userTotal) {
    state.userTotal = userTotal
  },
  loadUserOverviewPct(state, pct) {
    state.overview_pct = pct
  },
  loadUserOverviewQty(state, qty) {
    state.overview_qty = qty
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
