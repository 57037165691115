<template>
  <div v-click-outside="outside" class="filter">
    <div id="filter" class="closed-filter" :class="{filterOpen: isOpen, dashboard: dashboard}" @click="toggleFilter()">
      <span class="filter-name">{{ selectedItem.name }}</span>
      <img v-if="!isDarkTheme" height="20" src="@/assets/filterArrowOpen.png" alt="">
      <img v-else height="20" src="@/assets/filterArrowOpen-white.png" alt="">
    </div>
    <div v-show="isOpen" id="openFilter" class="open-filter card" :class="{dashboard: dashboard}" data-aos="fade-bottom">
      <div v-for="item in selectedTimeFilter" :key="item.title">
        <div class="filter-item single-select" :class="{selected: item.id === selectedItem.id}" @click="selectItem(item)">
          <span v-if="item.id === selectedItem.id" class="checkmark">✓</span>
          <span class="filter-item-title">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowHeadDown from '../shared/ArrowHeadDown.vue'
import { mapGetters } from 'vuex'

export default {
  components: { ArrowHeadDown },
  computed: mapGetters(['darkTheme']),
  props: {
    dashboard: {
      type: Boolean,
      default: false
    },
    marketplace: {
      type: Boolean,
      default: false
    },
    isBotGraph: {
      type: Boolean,
      default: false
    },
    activeFilter: Object
  },
  data() {
    return {
      isOpen: false,
      selectedItem: '',
      selectedTimeFilter: [],
      isDarkTheme: localStorage.boostingAlphaDarkTheme,
      timeFilter: [
        { id: 0, name: '7 days', time: '7 days' },
        { id: 1, name: '2 weeks', time: '14 days' },
        { id: 2, name: '1 month', time: '1 month' },
        { id: 3, name: '3 months', time: '3 months' },
        { id: 4, name: '6 months', time: '6 months' },
        { id: 5, name: 'YTD', time: 'YTD' },
        { id: 6, name: '1 Year', time: '1 year' },
        { id: 7, name: 'All Time', time: '50 Years' }
      ],
      timeFilterMarketPlace: [
        { id: 0, name: 'ROI: 1 week', time: '7 days' },
        { id: 1, name: 'ROI: 2 weeks', time: '14 days' },
        { id: 2, name: 'ROI: 1 month', time: '1 month' },
        { id: 3, name: 'ROI: 3 months', time: '3 months' },
        { id: 4, name: 'ROI: 6 months', time: '6 months' },
        { id: 5, name: 'ROI: YTD', time: 'YTD' },
        { id: 6, name: 'ROI: 1 year', time: '1 year' },
        { id: 7, name: 'ROI: All Time', time: '50 Years' }
      ],
      timeFilterBot: [
        { id: 0, name: '2 weeks', time: '14 days' },
        { id: 1, name: '1 month', time: '1 month' },
        { id: 2, name: '3 months', time: '3 months' },
        { id: 3, name: '6 months', time: '6 months' },
        { id: 5, name: 'YTD', time: 'YTD' },
        { id: 6, name: '1 Year', time: '1 year' },
        { id: 7, name: 'All Time', time: '50 Years' }
      ],
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    },
    isBotGraph() {
      if (this.isBotGraph) {
        this.selectedTimeFilter = this.timeFilter
        this.selectedItem = this.selectedTimeFilter[this.selectedTimeFilter.length - 1]
        return
      }
      if (!this.isBotGraph) {
        this.selectedTimeFilter = this.timeFilter
        this.selectedItem = this.selectedTimeFilter[this.selectedTimeFilter.length - 1]
      }
    },
  },
  mounted() {
    if (this.marketplace) {
      this.selectedTimeFilter = this.timeFilterMarketPlace
      this.selectedItem = this.selectedTimeFilter[this.activeFilter.id]
      return
    }
    if (this.isBotGraph) {
      this.selectedTimeFilter = this.timeFilter
      this.selectedItem = this.selectedTimeFilter[this.selectedTimeFilter.length - 1]
      return
    }
    this.selectedTimeFilter = this.timeFilter
    this.selectedItem = this.selectedTimeFilter[this.selectedTimeFilter.length - 1]
  },
  methods: {
    outside() {
      this.isOpen = false
    },
    toggleFilter() {
      this.isOpen = !this.isOpen
    },
    // select item from dropdown
    selectItem(item) {
      this.selectedItem = item
      this.isOpen = false
      this.$emit('updateTimeFrame', item)
    }
  }
}
</script>

<style scoped>
.filter {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.closed-filter {
  min-width: 200px;
  height: 30px;
  display: flex;
  padding: 5px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 5px;
}

.selected {
  background-color: var(--primary-blue);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
}

.filter-name {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.open-filter {
  top: 35px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  background-color: var(--card-background);
  z-index: 9;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 5px;
  box-shadow: var(--shadow);
}

.filter-item {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.single-select {
  border-radius: 5px;
  cursor: pointer;
}

.single-select:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
}

.selected:hover {
  background-color: var(--primary-blue);
  color: var(--white);
}

.filter-item-title {
  margin-left: 5px;
  text-align: left;
  cursor: pointer;
}

.filter-clear {
  font-size: 14px;
  text-decoration: underline;
  margin: 10px 0;
  color: var(--primary-blue);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.exchange-icon {
  height: 25px;
  margin-right: 5px;
  margin-top: 1px;
}

.exchange-dropdown-options {
  display: flex;
  align-items: center;
}

.dashboard {
  background-color: var(--background);
}

@media (max-width: 850px) {
  .filter {
    margin: 5px;
  }
}
</style>
