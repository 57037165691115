const state = {
  currentPage: 0,
  subscription: '',
  darkTheme: ''
}

const getters = {
  selectedPage: state => state.currentPage,
  userSubscription: state => state.subscription,
  darkTheme: state => state.darkTheme
}

const actions = {
  async updateCurrentPage({ commit }, id) {
    commit('setCurrentPage', id)
  },
  async updateUserSubscription({ commit }, plan) {
    commit('setUserSubscription', plan)
  },
  async updateUserDarkTheme({ commit }, theme) {
    commit('setUserDarkTheme', theme)
  }
}

const mutations = {
  setCurrentPage: (state, id) => (state.currentPage = id),
  setUserSubscription: (state, plan) => (state.subscription = plan),
  setUserDarkTheme: (state, theme) => (state.darkTheme = theme)
}

export default {
  state,
  getters,
  actions,
  mutations
}
