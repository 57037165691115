<template>
  <div>
    <loading class="loading-icon" :active="loading"/>
    <div v-if="!loading" class="connect-exchange">
      <br>
      <span class="referal-code">
        To start using our trading bots on Kraken you need to integrate its API
        connection
      </span>
      <div class="note-message-container">
        <img class="warning-message" src="@/assets/warning.png" alt="warning"/>
        <span>
         Note: If you wish to connect to multiple bots, please create additional subaccount for each of the bot connection. Using the same account for every bot will yield negative trading performance and consequences.
        </span>
      </div>
      <div class="tutorials">
        <h4>FAQ:</h4>
        <router-link class="tips-title" :to="{name: 'ExchangeTutorial', query: {exchange: 'kraken',tutorial: 0}}"
                     target="_blank">
          API Key Permissions and Settings
        </router-link>
        <h5 class="note">If you have troubles connecting your exchange account, please contact us at <a
            class="mail-link" href="mailto:support@boostingalpha.com">support@boostingalpha.com</a></h5>
      </div>
      <span v-if="errMsg" class="error">{{ errMsg }}</span>
      <br/>
      <div class="exchange-form">
        <div class="form-item">
          <span class="form-title">Connection Name <span class="title-criteria">(Up to 20 characters, No symbols)</span></span>
          <input class="form-input" type="text" v-model="exchangeName"/>
        </div>
        <div class="form-item">
          <span class="form-title">API key</span>
          <input class="form-input" type="text" v-model="api"/>
        </div>
        <div class="form-item">
          <span class="form-title">Secret key</span>
          <input class="form-input" type="text" v-model="secret"/>
        </div>
        <div v-if="isSubAccount" class="form-item">
          <span class="form-title">Sub-account Name</span>
          <input class="form-input" type="text" v-model="subAccountName"/>
        </div>
      </div>
      <div class="bottom-content">
        <div class="tips">
          <div class="bottom-items">
            <div class="confirmation-buttons ">
              <span class="form-button button-shadow" @click="connectAccount()">
                Connect
              </span>
            </div>
          </div>
          <!-- <div v-if="tooltips" class="exchange-tips">
            <span class="tool-item">1. Open
              <a href="https://accounts.binance.com" target="_blank">https://accounts.binance.com</a></span>
            <span class="tool-item">2. Log in to your account</span>
            <span class="tool-item">3. Hover over your profile and click "API Management</span>
            <span class="tool-item">4. Give the API key a label at the top of the screen</span>
            <span class="tool-item">5. Click create</span>
            <span class="tool-item">6. Follow the authentication instructions</span>
            <span class="tool-item column">
              7. Enable the following:
              <span class="column-items">- Enable Reading</span>
              <span class="column-items">- Enable Spot & Margin Trading</span>
              <span class="column-items">- Unrestricted (Less Secure)</span>
            </span>
            <span class="tool-item">8. Copy the API keys into the corresponding input fields above</span>
            <span class="tool-item">9. Now you are done</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions} from 'vuex'
import Loading from '@/components/shared/Loading.vue'


export default {
  components: {Loading},
  data() {
    return {
      tooltips: false,
      exchangeName: '',
      api: '',
      secret: '',
      errMsg: '',
      loading: false,
      validConnectionName: true,
      subAccountName: '',
      isSubAccount: false
    }
  },
  methods: {
    ...mapActions(['addNewExchange', 'loadExchangeConnections']),
    // toggle tips on how to connect api
    toggleTips(tutorialType) {
      this.$router.push({
        name: 'ExchangeTutorial',
        params: {
          exchange: 'kraken',
          tutorial: tutorialType
        }
      })
      // this.tooltips = !this.tooltips
    },
    // add new exchange account
    connectAccount() {
      this.validConnectionName = true
      this.loading = true
      this.errMsg = ''
      this.checkConnectionName()
      if (!this.validConnectionName) {
        this.loading = false
        this.errMsg = 'Connection name does not match criteria'
        return
      }
      const tempExchange = {
        connection_name: this.exchangeName,
        exchange: 'kraken_futures',
        api_key: this.api,
        api_secret: this.secret,
        user_id: this.$session.get('uid')
      }

      this.$api
          .post('/exchanges/add_connection', tempExchange, {
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.addNewExchange(tempExchange)
              this.loadExchangeConnections()
              this.$emit('closeForm')
              this.$emit('success')
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
            if (err.response.data.error.type === "value_error") {
              this.errMsg = 'API secret must contain at least 3 characters'
            }
            if (err.response.data.error.type === "CONNECTION_ERROR") {
              this.errMsg = err.response.data.error.body
            }
            if (err.response.data.error.type === "DUPLICATE_VALUE") {
              this.errMsg = err.response.data.error.body
            }
            if (err.response.data.error.type === "EXCHANGE_ERROR") {
              this.errMsg = err.response.data.error.body
            }
          })
    },
    // closenew exchange account form
    goBack() {
      this.$emit('closeForm')
    },
    checkConnectionName() {
      if (this.exchangeName.length < 20) {
        const connectionNameRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
        this.validConnectionName = connectionNameRegex.test(this.exchangeName)
        return
      }
      this.validConnectionName = false
    }
  }
}
</script>

<style scoped>
.connect-exchange {
  display: flex;
  flex-direction: column;
}

.exchange-form {
  display: flex;
  flex-direction: column;
}

.form-item {
  margin: 10px 0px;
  width: 100%;
}

.form-title {
  font-size: 20px;
}

.bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tips {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.loading-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.exchange-b {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchange-tips {
  display: flex;
  flex-direction: column;
}

.tips-title {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: 18px;
  color: var(--blue-primary);
  text-decoration: underline;
}

.tool-item {
  margin: 10px 0;
}

.title-criteria {
  font-size: 16px;
}

.bottom-items {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.back-button {
  text-decoration: underline;
  cursor: pointer;
}

.referal-code {
  font-size: 18px;
  text-decoration: none;
}

.referal-code-link {
  color: var(--blue-primary);
  font-size: 18px;
  text-decoration: underline;
}

.column {
  display: flex;
  flex-direction: column;
}

.column-items {
  margin-left: 20px;
  font-weight: bold;
}

.tutorials {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.note-message-container {
  margin-top: 20px;
  width: 700px;
  padding: 10px;
  background-color: #FEF9EC;
  border-radius: 10px;
  color: var(--dark);
  display: flex;
  gap: 10px;
}

.warning-message {
  height: 30px;
}

@media (max-width: 850px) {
  .bottom-items {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
