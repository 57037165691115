<template>
  <div class="section-settings">
    <h1 class="title">Two Factor Authentication</h1>
    <loading class="loading" :active="loading"/>
    <div v-if="!loading" class="section">
      <span v-if="errorMsg" class="success">{{ errorMsg }}</span>
      <span v-if="successMsg" class="error">{{ successMsg }}</span>
      <span class="section-title">Setup your two factor authentication:</span>
      <div class="section-content">
        <span>
          <span v-if="!otpEnabled">Enable</span>
          <span v-else>Disable</span>
          two-factor authentication</span>
        <toggle-switch :switchStatusValue="otpEnabled" @toggle="toggleEnableOtp($event)"/>
      </div>
    </div>
    <QRModal v-if="showQrModal" :enable="!otpEnabled" @click="closeQrModal()"/>
  </div>
</template>

<script>
import Loading from "@/components/shared/Loading.vue"
import QRModal from '@/components/shared/modals/QRModal.vue'
import ToggleSwitch from '@/components/shared/ToggleSwitch.vue'

export default {
  name: 'TwoFactor',
  components: {
    Loading,
    ToggleSwitch,
    QRModal
  },
  data() {
    return {
      loading: false,
      errorMsg: '',
      successMsg: '',
      otpEnabled: false,
      showQrModal: false
    }
  },
  mounted() {
    this.getOtpStatus()
  },
  methods: {
    closeQrModal() {
      this.otpEnabled = false
      this.showQrModal = false
      this.getOtpStatus()
    },
    toggleEnableOtp(value) {
      switch (value) {
        case 0:
          this.otpEnabled = false
          this.showQrModal = true
          break;
        case 1:
          this.otpEnabled = true
          this.showQrModal = true
          break;
      }
    },
    getOtpStatus() {
      this.loading = true
      this.$api
          .get('/users/get_otp_status', {
            params: {
              user_id: this.$session.get('uid')
            },
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.loading = false
              this.otpEnabled = res.data.data
            }
          })
    }
  }
}
</script>

<style scoped>
.section-settings {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section {
  width: 90%;
  margin: 20px 0;
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.section-title {
  font-weight: bold;
}

.section-content {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
