<template>
  <div>
    <input type="checkbox" :checked="isDarkTheme" class="checkbox" id="checkbox" @click="toggleSwitch(true)">
    <label for="checkbox" class="label">
      <img v-if="!isDarkTheme" class="light-theme" height="20" src="@/assets/light.png" alt/>
      <img v-else height="20" src="@/assets/dark.png" alt/>
      <span class='ball'></span>
    </label>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'ToggleSwitch',
  props: {
    switchStatusValue: Boolean,
  },
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      switchStatus: this.switchStatusValue,
      isDarkTheme: JSON.parse(localStorage.boostingAlphaDarkTheme) ? JSON.parse(localStorage.boostingAlphaDarkTheme) : false
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  mounted() {
    this.isDarkTheme = JSON.parse(localStorage.boostingAlphaDarkTheme)
    this.toggleSwitch()
  },
  methods: {
    ...mapActions(['updateUserDarkTheme']),
    // toggle switch on and off
    toggleSwitch(toggle) {
      if (toggle) {
        this.isDarkTheme = !this.isDarkTheme
      }
      switch (this.isDarkTheme) {
        case true:
          document.documentElement.setAttribute('data-theme', 'dark');
          break
        case false:
          document.documentElement.setAttribute('data-theme', 'light');
          break
      }
      localStorage.setItem('boostingAlphaDarkTheme', JSON.stringify(this.isDarkTheme));
      this.updateUserDarkTheme(this.isDarkTheme)
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  transition: background 0.2s linear;
}

body.dark {
  background-color: #292c35;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.label {
  width: 60px;
  height: 26px;
  background-color: #79c7ea;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
}

.ball {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  padding: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the element after the label*/
.checkbox:checked + .label .ball {
  transform: translateX(32px);
}

.checkbox:checked + .label {
  background-color: #252525;
}

.light-theme {
  position: absolute;
  right: 2px;
}
</style>
