<template>
  <div class="promoted-bots card">
    <span class="promoted-title">{{title}}</span>
    <div class="bot">
      <div v-for="bot in bots" :key="bot.bot_name">
        <BotCard class='bot-item-promoted' :bot='bot' :isNew="true" :isTopPerformer=" isTopPerformer" :graphId="bot.bot_id + 'TOP'" />
      </div>
    </div>
  </div>
</template>

<script>
import BotCard from './BotCard.vue';
export default {
  name: 'PromotedBots',
  components: { BotCard },
  props: {
    bots: Array,
    title: String,
    isTopPerformer: {
      type: Boolean,
      default: false
    },
    graphId: String
  }
}
</script>

<style scoped>
.promoted-bots {
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid var(--border);
}
.promoted-title {
  display: flex;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 500;
  font-family: 'Roboto Condensed', sans-serif;
}
.bot {
  margin: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}
.bot-item-promoted {
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 800px) {
  .bot-item-promoted {
    justify-content: center;
  }
  .promoted-bots {
    width: 95%;
  }
}
</style>