import Vue from 'vue'
import Router from 'vue-router'

import ExchangeTutorial from '@/components/trading/settings/exchanges/tutorials/exchangeTutorial'
import Activity from '@/views/trading/Activity'
import Bot from '@/views/trading/Bot'
import CopyTrading from '@/views/trading/CopyTrading'
import Dashboard from '@/views/trading/Dashboard'
import ExchangeAccounts from '@/views/trading/ExchangeAccounts'
import Login from '@/views/trading/Login'
import Bots from '@/views/trading/Marketplace'
import Settings from '@/views/trading/Settings'
import SubscribedBot from '@/views/trading/SubscribedBot'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Boosting Alpha - Copy trading'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Boosting Alpha - Copy trading'
      }
    },
    {
      path: '/login/:page',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Boosting Alpha - Copy trading'
      }
    },
    {
      path: '/copy-trading/',
      name: 'CopyTrading',
      component: CopyTrading,
      meta: {
        title: 'Boosting Alpha - Copy Trading'
      },
      beforeEnter: (to, from, next) => {
        if (Vue.prototype.$session.get('token')) {
          next()
        } else {
          next({ path: '/login' })
        }
      },
      children: [
        {
          path: '/copy-trading/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            title: 'Boosting Alpha - Dashboard'
          }
        },
        {
          path: '/copy-trading/bots/:page',
          name: 'Bots',
          component: Bots,
          meta: {
            title: 'Boosting Alpha - Bots'
          }
        },
        {
          path: '/copy-trading/bot/:name',
          name: 'Bot',
          component: Bot,
          meta: {
            title: 'Boosting Alpha - '
          }
        },
        {
          path: '/copy-trading/activity',
          name: 'Activity',
          component: Activity,
          meta: {
            title: 'Boosting Alpha - Activity'
          }
        },
        {
          path: '/copy-trading/exchanges',
          name: 'ExchangeAccounts',
          component: ExchangeAccounts,
          meta: {
            title: 'Boosting Alpha - Exchange Accounts'
          }
        },
        {
          path: '/copy-trading/exchanges/tutorial/',
          name: 'ExchangeTutorial',
          component: ExchangeTutorial,
          meta: {
            title: 'Boosting Alpha - Tutorial'
          }
        },
        {
          path: '/copy-trading/settings',
          name: 'Settings',
          component: Settings,
          meta: {
            title: 'Boosting Alpha - Settings'
          }
        },
        {
          path: '/copy-trading/subscribed/:name',
          name: 'SubscribedBot',
          component: SubscribedBot,
          meta: {
            title: 'Boosting Alpha - Subscription'
          }
        }
      ]
    }
  ]
})
