<template>
  <div class="settings">
    <h1 class="title">Preferences</h1>
    <div class="settings-content card">
      <div class="settings-nav">
        <div class="user-settings">
          <div class="settings-nav-item" :class="{ selected: selectedSettingType === 0 }" @click="selectSettingType(0)">
            <span v-if="!isDarkTheme">
              <img v-if="selectedSettingType === 0" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/user-selected.png" alt/>
              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/user.png" alt/>
            </span>
            <span v-else>
               <img v-if="selectedSettingType === 0" class="settings-nav-item-icon" src="@/assets/settingsIcons/user.png" alt/>
               <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/white/user-selected-white.png" alt/>
             </span>
            <span class="settings-nav-item-text">Profile</span>
          </div>
          <div class="settings-nav-item" :class="{ selected: selectedSettingType === 1 }" @click="selectSettingType(1)">
            <span v-if="!isDarkTheme">
              <img v-if="selectedSettingType === 1" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/email-selected.png" alt/>
              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/email.png" alt/>
            </span>
            <span v-else>
               <img v-if="selectedSettingType === 1" class="settings-nav-item-icon" src="@/assets/settingsIcons/email.png" alt/>
               <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/white/email-selected-white.png" alt/>
              </span>
            <span class="settings-nav-item-text">Change Email</span>
          </div>
          <div class="settings-nav-item" :class="{ selected: selectedSettingType === 2 }" @click="selectSettingType(2)">
            <span v-if="!isDarkTheme">
              <img v-if="selectedSettingType === 2" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/padlock-selected.png" alt/>
              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/padlock.png" alt/>
            </span>
            <span v-else>
               <img v-if="selectedSettingType === 2" class="settings-nav-item-icon" src="@/assets/settingsIcons/padlock.png" alt/>
               <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/white/padlock-selected-white.png" alt/>
             </span>
            <span class="settings-nav-item-text">Change Password</span>
          </div>
          <!--          <div class="settings-nav-item" :class="{ selected: selectedSettingType === 4 }" @click="selectSettingType(4)">-->
          <!--            <img v-if="selectedSettingType === 4" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/bell-selected.png" alt/>-->
          <!--            <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/bell.png" alt/>-->
          <!--            <span class="settings-nav-item-text">Notifications</span>-->
          <!--          </div>-->
          <div class="settings-nav-item" :class="{ selected: selectedSettingType === 5}" @click="selectSettingType(5)">
            <span v-if="!isDarkTheme">
              <img v-if="selectedSettingType === 5" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/authentication-selected.png" alt/>
              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/authentication.png" alt/>
            </span>
            <span v-else>
             <img v-if="selectedSettingType === 5" class="settings-nav-item-icon" src="@/assets/settingsIcons/authentication.png" alt/>
             <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/white/authentication-selected-white.png" alt/>
             </span>
            <span class="settings-nav-item-text">Two Factor Authentication</span>
          </div>
          <!--          <div class="settings-nav-item" :class="{ selected: selectedSettingType === 6 }" @click="selectSettingType(6)">-->
          <!--            <span v-if="!isDarkTheme">-->
          <!--              <img v-if="selectedSettingType === 6" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/referral-selected.png" alt/>-->
          <!--              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/referral.png" alt/>-->
          <!--            </span>-->
          <!--            <span v-else>-->
          <!--              <img v-if="selectedSettingType === 6" class="settings-nav-item-icon" src="@/assets/settingsIcons/referral.png" alt/>-->
          <!--              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/white/referral-selected-white.png" alt/>-->
          <!--            </span>-->
          <!--            <span class="settings-nav-item-text">Referral Code</span>-->
          <!--          </div>-->
          <div class="settings-nav-item subscription-text" :class="{ selected: selectedSettingType === 3 }" @click="selectSettingType(3)">
            <span v-if="!isDarkTheme">
              <img v-if="selectedSettingType === 3" class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/subscription-selected.png" alt/>
              <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/subscription.png" alt/>
            </span>
            <span v-else>
             <img v-if="selectedSettingType === 3" class="settings-nav-item-icon" src="@/assets/settingsIcons/subscription.png" alt/>
             <img v-else class="settings-nav-item-icon" src="@/assets/settingsIcons/selected/white/subscription-selected-white.png" alt/>
             </span>
            <span class="settings-nav-item-text">Subscription</span>
          </div>
        </div>
      </div>
      <div class="setting-content">
        <profile id="setting" :userData="subscriptionInfo" :subscriptionId="subId" v-if="selectedSettingType === 0" @updateProfile="getSubscriptionInfo()"/>
        <change-email id="setting" :email="userData.email" v-if="selectedSettingType === 1"/>
        <change-password id="setting" v-if="selectedSettingType === 2"/>
        <two-factor id="setting" v-if="selectedSettingType === 5"/>
        <subscriptions id="setting" v-if="selectedSettingType === 3" @handleSubscription="reloadSubscriptionData()" :subscriptionInfo="subscriptionInfo"/>
        <notification-settings id="setting" v-if="selectedSettingType === 4"/>
        <ReferralCode v-if="selectedSettingType === 6" :referralCode="userData.referral_id"/>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from '@/components/trading/settings/Profile'
import TwoFactor from '@/components/trading/settings/TwoFactor'
import ChangeEmail from '@/components/trading/settings/ChangeEmail'
import ChangePassword from '@/components/trading/settings/ChangePassword'
import NotificationSettings from '@/components/trading/settings/NotificationSettings'
import PaymentMethods from '@/components/trading/settings/PaymentMethods'
import Subscriptions from '@/components/trading/settings/Subscriptions'
import ReferralCode from "@/components/trading/settings/ReferralCode";
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Settings',
  components: {
    Profile,
    ChangeEmail,
    ChangePassword,
    NotificationSettings,
    PaymentMethods,
    Subscriptions,
    TwoFactor,
    ReferralCode
  },
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      selectedSettingType: null,
      userData: {},
      subscriptionInfo: {},
      subId: '',
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  created() {
    this.getSubscriptionInfo()
    this.getUser()
  },
  mounted() {
    if (this.$route.query.payment_intent) {
      this.selectSettingType(3)
      return
    }
    if (this.$route.query.page === 'subscription') {
      this.selectSettingType(3)
      return
    }
    this.selectSettingType(0)
  },
  methods: {
    ...mapActions(['updateUserSubscription']),
    reloadSubscriptionData() {
      this.getSubscriptionInfo()
      this.getUser()
    },
    selectSettingType(type) {
      this.selectedSettingType = type
    },
    getUser() {
      this.$api
          .get('/users/get_user', {
            params: {
              user_id: this.$session.get('uid')
            },
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.$session.set('username', res.data.data.username)
              this.$session.set('email', res.data.data.email)
              this.$session.set('timezone', res.data.data.timezone)
              this.$session.set('free_trial', res.data.data.trial_period_activated)
              this.$session.set('has_subscription', res.data.data.active_subscription)

              this.userData = res.data.data
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response && err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    },
    getSubscriptionInfo() {
      this.loading = true
      this.$api
          .get('/subscriptions/get_subscription_info', {
            params: {
              user_id: this.$session.get('uid'),
              timezone: this.$session.get('timezone')
            },
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.subscriptionInfo = res.data.data
              this.subId = this.subscriptionInfo.subscription_id
              this.loading = false
              this.updateUserSubscription(res.data.data.subscription_plan)
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response && err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    },
  }
}
</script>

<style scoped>
.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.settings-content {
  padding: 40px 10px;
  margin: 20px auto 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--card-background);
}

.settings-nav {
  padding: 20px 10px;
  width: 350px;
  border-right: 1px solid var(--border);
}

.setting-content {
  padding: 0 20px;
  width: 75%;
}

.user-settings {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.settings-nav-item {
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
}

.settings-nav-item:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
  border-radius: 10px;
}

.subscription-text {
  background-color: rgb(217, 190, 45, 0.4);
  border-radius: 10px;
}

.subscription-text:hover {
  background-color: rgb(217, 190, 45, 0.8);
  border-radius: 10px;
}

.settings-nav-item-text {
  cursor: pointer;
  font-size: 20px;
}

.selected {
  color: var(--secondary-blue);
  background-color: var(--selected);
  border-radius: 10px;
}

.settings-nav-item-icon {
  height: 100%;
  width: 25px;
  margin-right: 30px;
}

@media (max-width: 850px) {
  .settings-content {
    margin: 5px 0;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .setting-content {
    width: 100%;
  }

  .user-settings {
    padding: 2px;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .settings-nav {
    padding: 0;
    border: none;
    width: 100%;
  }

  .settings-nav-item {
    padding: 5px;
  }

  .settings-nav-item-text {
    font-size: 14px;
  }

  .settings-nav-item-icon {
    display: none;
  }

  .selected {
    text-decoration: underline;
  }
}
</style>
