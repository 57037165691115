import vuetify from '@/plugins/vuetify'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Carousel3d from 'vue-carousel-3d'
import vueCountryRegionSelect from 'vue-country-region-select'
import VueExpandableImage from 'vue-expandable-image'
import VueMeta from 'vue-meta'
import LoadScript from 'vue-plugin-load-script'
import VueSession from 'vue-session'

Vue.use(VueMeta)

Vue.config.productionTip = false
axios.defaults.headers.post['Content-Type'] = 'application/json'

Vue.use(VueSession, {persist: true})
Vue.use(LoadScript)
Vue.use(vueCountryRegionSelect)
Vue.use(VueAxios, axios)
Vue.use(Carousel3d)
Vue.use(VueExpandableImage)

let api
if (process.env.NODE_ENV === 'staging') {
    api = axios.create({
        baseURL: process.env.VUE_APP_API_EXTERNAL,
        auth: {
            username: process.env.VUE_APP_STAGING_USER,
            password: process.env.VUE_APP_STAGING_PASSWORD
        }
    })
} else if (process.env.NODE_ENV === 'production') {
    api = axios.create({
        baseURL: process.env.VUE_APP_API_EXTERNAL
    })
} else {
    api = axios.create({
        baseURL: process.env.VUE_APP_API_LOCAL
    })
}

api.defaults.headers.common['X-Authorization'] = Vue.prototype.$session.get(
    'token'
)

let medium = axios.create({
    baseURL:
        'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@boostingalpha'
})

Vue.prototype.$api = api
Vue.prototype.$med = medium

new Vue({
    store,
    created() {
        AOS.init()
    },
    render: h => h(App),
    vuetify,
    router
}).$mount('#app')
