<template>
  <div class="stat-card card">
    <div class="stat-card-title">
      <span class="stat-card-title-text">{{ title }}</span>
    </div>
    <div v-if="chartType" class="stat-card-content">
      <PieChart v-if="chartType === 1" :chartData="chartData"/>
      <BarChart v-if="chartType === 2" :chartData="chartData"/>
    </div>
    <div v-else class="stats-active-bots">
      <div v-if="bots.length > 0">
        <div v-for="item in bots" :key="item.id">
          <bot-stats :item="item"/>
        </div>
      </div>
      <div v-else class="no-bots">
        <span>No bots currently active</span>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/trading/charts/PieChart.vue'
import BarChart from '@/components/trading/charts/BarChart.vue'
import BotStats from '@/components/trading/statistics/BotStats'
import Dropdown from '@/components/trading/Dropdown'

export default {
  name: 'StatsCard',
  components: {
    PieChart,
    BarChart,
    BotStats,
    Dropdown
  },
  props: {
    size: String,
    chartData: Object,
    title: String,
    chartType: Number,
    bots: Array
  },
  data() {
    return {
      containsBots: false,
      returnData: true
    }
  },
  created() {
    this.bots.sort((a, b) => b.total_pnl_pct - a.total_pnl_pct)
  },
  methods: {
    toggleDataType(type) {
      if (type === 0) {
        this.returnData = false
      } else {
        this.returnData = true
      }
    }
  }
}
</script>

<style scoped>
.stat-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: auto;
}

.stats-card-dropdown {
  width: 150px;
}

.stat-card-title {
  text-align: center;
  background-color: #2c3e50;
  border-radius: 5px 5px 0 0;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.stat-card-title-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  font-family: 'Roboto Condensed', sans-serif;
}

.stats-active-bots {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.stat-card-content {
  height: 90%;
  display: flex;
  align-items: center;
  padding: 10px;
}

.stat-card-graph {
  height: 90%;
  width: 100%;
}

.small {
  width: 33%;
  height: 600px;
}

.medium {
  width: 65%;
  height: 600px;
}

.large {
  width: 1200px;
  height: 600px;
}

.no-bots {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.overview-graph-toggle {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.overview-graph-toggle-title {
  padding: 0 10px;
  cursor: pointer;
}

.overview-graph-toggle-title:first-child {
  border-right: 1px solid #e2e2e2;
}

.selectedGraph {
  font-weight: bold;
}

@media (max-width: 850px) {
  .small {
    width: 100%;
  }

  .medium {
    width: 100%;
  }
}
</style>
