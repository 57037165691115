<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <span class="modal-title">Please enter your 2FA code</span>
              <loading class="loading" :active="loading"/>
              <div class="modal-header-date">
                <div class="modal-content">
                  <label>
                    6-Digit 2FA Code:
                    <input class="form-input" type="text" v-model="otpSecret" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code">
                  </label>
                </div>
                <span class="error" v-if="errorMsg">{{ errorMsg }}</span>
                <div v-if="!loading" class="buttons">
                  <span class="button-p button-shadow" @click="confirmCode()">Confirm code</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading'

export default {
  name: 'OTPModal',
  components: {
    Loading,
  },
  props: {
    enable: Boolean
  },
  data() {
    return {
      loading: false,
      otpSecret: '',
      errorMsg: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    confirmCode() {
      this.errorMsg = ''
      let isnum = /^\d+$/.test(this.otpSecret);
      if (isnum) {
        if (this.otpSecret.length === 6) {
          this.$emit('confirmOtpCode', this.otpSecret)
          this.closeModal()
          return
        }
        this.errorMsg = 'The verification code has to consist of 6 digits'
        return
      }
      this.errorMsg = 'The verification code can only contain numbers'
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
  font-weight: bold;
}

.modal-subtext {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.modal-container {
  width: 500px;
  margin: 0 auto;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.modal-close {
  font-size: 30px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.remove {
  margin-bottom: 20px;
}

.modal-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  align-items: center;
}

.to-cart {
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: 300px;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }
}
</style>
