<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <span class="modal-title">Set up two-factor authentication</span>
              <loading class="loading" :active="loading" />
              <div class="modal-header-date">
                <div v-if="!enable" class="modal-subtext">
                  <span class="subtext"><span style="font-weight:500">Step 1: </span>Visit the App Store to get an authenticator app like Google Authenticator or Authy, then follow the app’s instructions to set up an account with them.</span>
                  <span class="subtext"><span style="font-weight:500">Step 2: </span>Use your authenticator app to scan the barcode below or
                    <span class="toggle-code" @click="toggleQRSecret()">get a token to enter manually</span> instead.
                  </span>
                </div>
                <div class="modal-content">
                  <qrcode-vue class="qr-code" v-if="!enable" :value="otpValue" :size="qrSize" level="H" />
                  <span v-if="!enable && manualSetup" class="manual-setup">{{ otp }} <img class="copy-img" src="@/assets/copy.png" @click="copySecret()"></span>
                  <span v-if="enable" class="remove">Are you sure you want to disable your two-factor authentication?</span>
                  <input class="form-input special-input" id="2fa" type="text" v-model="otpSecret" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" placeholder="Two-factor authentication code">
                </div>
                <div v-if="!loading" class="buttons">
                  <span v-if="!enable" class="button-p button-shadow" @click="confirmOtp()">Complete setup</span>
                  <span v-else class="button-p button-shadow" @click="removeOtp()">Remove two-factor</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'QRModal',
  components: {
    Loading,
    QrcodeVue
  },
  props: {
    enable: Boolean
  },
  data() {
    return {
      loading: false,
      otpValue: '',
      otpSecret: '',
      otp: '',
      qrSize: 200,
      manualSetup: false
    }
  },
  mounted() {
    document.getElementById('2fa').select()
    this.generateQR()
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    toggleQRSecret() {
      this.manualSetup = !this.manualSetup
    },
    copySecret() {
      navigator.clipboard.writeText(this.otp);
    },
    generateQR() {
      this.loading = true
      let body = {
        user_id: this.$session.get('uid'),
        email: this.$session.get('email')
      }
      this.$api
        .post('/users/generate_otp', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.otpValue = res.data.data
            this.otp = res.data.data.split('secret=')[1].split('&')[0]
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    confirmOtp() {
      this.loading = true
      let body = {
        user_id: this.$session.get('uid'),
        otp: this.otpSecret,
        otp_secret: this.otp
      }
      this.$api
        .post('/users/confirm_otp', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.closeModal()
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    removeOtp() {
      this.loading = true
      let body = {
        user_id: this.$session.get('uid'),
        otp: this.otpSecret,
      }
      this.$api
        .post('/users/remove_otp', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.closeModal()
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },

  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
  font-weight: bold;
}

.modal-subtext {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.modal-container {
  width: 600px;
  margin: 0 auto;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.modal-close {
  font-size: 30px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.remove {
  margin-bottom: 20px;
}

.modal-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code {
  padding: 10px;
  border: 2px solid var(--border);
  border-radius: 10px;
  margin-bottom: 20px;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.to-cart {
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.special-input {
  width: 450px;
  padding: 15px 10px;
}

.toggle-code {
  color: var(--primary-blue);
  cursor: pointer;
  font-weight: 400;
}

.manual-setup {
  margin: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
}

.copy-img {
  height: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.copy-img:active {
  transform: scale(1.5);
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    padding: 5px;
  }

  .special-input {
    width: 100%;
    padding: 15px 10px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: left;
  }
}
</style>
