<template>
  <div class="notification-container card fadeIn">
    <div class="notification-content">
      <div class="notification-status">
        <div v-if="notification.status === 0" class="status success"></div>
        <div v-if="notification.status === 1" class="status error"></div>
      </div>
      <div class="notification-image">
        <img v-if="notification.status === 0" class="notification-image-item" src="@/assets/notification/checked.png" alt="">
        <img v-if="notification.status === 1" class="notification-image-item" src="@/assets/notification/cross.png" alt="">
      </div>
      <div class="notification-value">
        <span class="notification-title">{{ notification.title }}</span>
        <span class="notification-text">{{ notification.text }}</span>
      </div>
      <div class="close-notification" @click="closeNotification()">
        <span class="close-item">&#10799;</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "Notification",
  computed: mapGetters(['notification']),
  methods: {
    ...mapActions(['clearNotification']),
    closeNotification() {
      this.clearNotification()
    }
  }
}
</script>

<style scoped>
.notification-container {
  background-color: var(--card-background);
  width: 500px;
  min-height: 70px;
  height: auto;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding: 5px;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.close-notification {
  display: flex;
}

.close-item {
  color: #acadb1;
  margin-top: 7px;
  font-size: 30px;
  height: 25px;
  width: auto;
  cursor: pointer;
}

.notification-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.notification-image {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-image-item {
  height: 40px;
}

.notification-value {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.notification-title {
  font-size: 25px;
  font-weight: bold;
}

.notification-text {
  font-size: 14px;
  font-weight: bold;
  color: #acadb1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-status {
  height: 60px;
  width: 5px;
}

.status {
  height: 100%;
  width: 100%;
  border-radius: 50px;
}

.success {
  background-color: #32ba7c;
}

.error {
  background-color: #e21b1b;
}

.warning {
}

.info {
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@media (max-width: 850px) {
  .notification-container {
    width: 100%;
    justify-content: center;
  }
}
</style>