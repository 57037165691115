<template>
  <div class='overview-values'>
    <div class='overview-values-item'>
      <div class='overview-item-icon'>
        <img class='overview-item-icon-image bg-b' src='@/assets/trading-platform/total-return.png' alt/>
      </div>
      <div class='overview-item-content'>
        <span v-if="userTotal.total_return" class='item-value positive' :class='{negative: userTotal.total_return < 0}'>
          {{ userTotal.total_return }} USD
        </span>
        <span v-else class='item-value positive'>0 USD</span>
        <span class='item-title'>Total return</span>
      </div>
    </div>
    <div class='overview-values-item'>
      <div class='overview-item-icon'>
        <img class='overview-item-icon-image bg-pi' src='@/assets/trading-platform/percentage-return.png' alt/>
      </div>
      <div class='overview-item-content'>
        <span v-if="userTotal.total_return_pct" class='item-value positive' :class='{negative: userTotal.total_return_pct < 0}'>
          {{ userTotal.total_return_pct }}%
        </span>
        <span v-else class='item-value positive'>0%</span>
        <span class='item-title'>Total return %</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    userTotal: Object
  },
}
</script>

<style scoped>
.overview-values {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.overview-values-item {
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--header-color);
  font-size: 12px;
}

.overview-item-icon {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.overview-item-icon-image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.overview-item-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-value {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: bolder;
}

.item-title {
  font-size: 14px;
}

.refresh-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

@media (max-width: 850px) {
  .overview-values {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>