<template>
  <div class="bot-status">
    <span v-if="exchanges.includes('binance')" class="sub-status" title="Binance">
      <img class="sub-status-icon" src="@/assets/binance-logo.png" alt/>
    </span>
    <!-- <span v-if="exchanges.includes('ftx')" class="sub-status" title="FTX">
      <img class="sub-status-icon" src="@/assets/ftx-logo.png" alt/>
    </span> -->
    <span v-if="exchanges.includes('bitvavo')" class="sub-status" title="Bitvavo">
      <img class="sub-status-icon" src="@/assets/bitvavo-logo.png" alt/>
    </span>
    <span v-if="exchanges.includes('okx_futures')" class="sub-status" title="OKX">
      <img class="sub-status-icon" src="@/assets/okx_futures-logo.png" alt/>
    </span>
    <span v-if="exchanges.includes('bybit_futures')" class="sub-status" title="Bybit">
      <img class="sub-status-icon" src="@/assets/bybit_futures-logo.png" alt/>
    </span>
    <span v-if="exchanges.includes('kraken_futures')" class="sub-status" title="Bybit">
      <img class="sub-status-icon" src="../../../assets/kraken_futures-logo.png" alt/>
    </span>
    <span v-if="exchanges.includes('bitget_futures')" class="sub-status" title="Bybit">
      <img class="sub-status-icon" src="@/assets/bitget_futures-logo.png" alt/>
    </span>
    <span v-if="exchanges.includes('kucoin')" class="sub-status" title="Kucoin">
      <img class="sub-status-icon" src="@/assets/kucoin-logo.png" alt/>
    </span>
    <span v-if="gold" class="sub-status" title="Gold subscriptions only">
      <img class="sub-status-icon" src="@/assets/gold.png" alt/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BotBadge',
  props: {
    exchanges: Array,
    gold: Boolean
  },
}
</script>

<style scoped>
.bot-status {
  width: 100%;
  display: flex;
  top: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.sub-status {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-status-icon {
  height: 100%;
  width: 100%;
}
</style>
