<template>
  <div class="user-balance-overview">
    <div v-for="data in widgetData" :key="data.id" class="item card">
      <span class="item-title">{{ data.title }}</span>
      <div class="item-content">
        <span class="item-value usd">USD: {{ data.usd }}</span>
        <span class="item-value btc">BTC: {{ data.btc.toFixed(7) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalanceOverview',
  data() {
    return {
      widgetData: [
        {
          id: 0,
          title: 'Total Balance',
          usd: 0,
          btc: 0
        },
        {
          id: 1,
          title: 'In Positions',
          usd: 0,
          btc: 0
        },
      ],
    }
  },
  mounted() {
    this.getWidgetData()
  },
  methods: {
    getWidgetData() {
      this.$api
        .get('/bots/get_widget', {
          params: {
            user_id: this.$session.get('uid'),
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.widgetData[0].usd = res.data.data.allocated.USD
            this.widgetData[0].btc = res.data.data.allocated.BTC

            this.widgetData[1].usd = res.data.data.invested.USD
            this.widgetData[1].btc = res.data.data.invested.BTC

            this.widgetData[2].usd = res.data.data.pnl.USD
            this.widgetData[2].btc = res.data.data.pnl.BTC

          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    }
  }
}
</script>

<style scoped>
.user-balance-overview {
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 5px 0;
  border-radius: 5px;
}

.item {
  width: 200px;
  min-height: 40px;
  border-radius: 5px;
  background-color: var(--widget-color);
  padding: 2px 10px 8px 2px;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  justify-content: center;
  text-align: end;
}

.item:last-child {
  margin-right: 0;
}

.item-title {
  color: var(--white);
  font-size: 20px;
}

.item-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.item-content {
  display: flex;
  flex-direction: column;
}

.btc {
  color: var(--yellow);
}

.usd {
  color: var(--green);
}

@media (max-width: 850px) {
  .item-value {
    font-size: 12px;
  }

  .user-balance-overview {
    padding: 5px 0;
  }

  .item {
    width: 300px;
    padding: 2px 5px;
  }
}
</style>
