var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"historical-positions card"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.activeItems,"no-data-text":"No data yet available","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"cell"},[_c('span',[_vm._v(_vm._s(item.datetime.split(' ')[0] + ' ' + item.datetime.split(' ')[1].split(':')[0] + ':' + item.datetime.split(' ')[1].split(':')[1]))])]):_vm._e()]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"cell"},[_c('span',[_vm._v(_vm._s(item.direction.split('_')[0][0].toUpperCase() + item.direction.split('_')[0].substr(1)))])]):_vm._e()]}},{key:"item.bot_name",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"cell"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/bots/bot_template.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(item.bot_name))])]):_vm._e()]}},{key:"item.pnl_pct",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"cell"},[(item.pnl_pct > 0)?_c('span',{staticClass:"pnl positive"},[_vm._v(_vm._s(item.pnl_pct.toFixed(2))+"%")]):_c('span',{staticClass:"pnl negative"},[_vm._v(_vm._s(item.pnl_pct.toFixed(2))+"%")])]):_vm._e()]}}])}),(_vm.activeItems.length > 0)?_c('div',{staticClass:"pagination"},[(_vm.pages !== 0)?_c('span',{staticClass:"button-p button-shadow footer-button",on:{"click":function($event){return _vm.previousPage()}}},[_vm._v("<")]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNextPage),expression:"showNextPage"}],staticClass:"button-p button-shadow footer-button",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(">")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }