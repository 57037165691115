<template>
  <div class="bot-item-data-value">
    <img class="metric-icon" :src="loadImage(img)" alt @mouseover="showMetricDescription()" @mouseleave="showMetricDescription()" />
    <div class="metric-info">
      <span class="metric-title">{{ value }}</span>
      <span class="bot-item-data-text" :class="{small: text === 'Cumulative Return'}">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    text: String,
    description: String,
    color: String,
    img: String
  },
  data() {
    return {
      itemDescription: false
    }
  },
  methods: {
    // load image
    loadImage(path) {
      return require(`@/${path}`)
    },
    // show description of item on mouse hover
    showMetricDescription() {
      this.itemDescription = !this.itemDescription
    }
  }
}
</script>

<style scoped>
.bot-item-data-value {
  width: 150px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  margin: 10px 0;
}

.bot-item-data-text {
  display: flex;
  font-size: 12px;
}

.small {
  font-size: 10px;
}

.metric-title {
  font-size: 20px;
}

.metric-info {
  display: flex;
  flex-direction: column;
  font-size: 30px;
}

.metric-icon {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

@media (max-width: 850px) {
  .bot-item-data-value {
    margin: 10px 0;
  }

  .metric-title {
    font-size: 20px;
  }

  .metric-description {
    margin-top: 40px;
  }

  .bot-item-data-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
  }
}
</style>
