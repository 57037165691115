<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header-date">
                <div class="modal-header">
                  <span class="modal-title">
                    <span v-if="type === SUBSCRIPTION_STATUS_CONFIRM || type === CONFIRM_SUBSCRIPTION_UPDATE || type === CANCEL_SUBSCRIPTION_CONFIRM">Are you sure?</span>
                    <span v-if="type === ADD_CART_ITEM">Thank you!</span>
                  </span>
                  <br>
                  <span class="subtext">
                    <span v-if="type === SUBSCRIPTION_STATUS_CONFIRM">
                      <span v-if="isActive">Your subscription will be paused</span>
                      <span v-else>Your subscription will be resumed</span>
                    </span>
                    <span v-if="type === ADD_CART_ITEM">Your item has been added to your cart</span>
                    <span v-if="type === CONFIRM_SUBSCRIPTION_UPDATE" class="updated-values">
                      <span>Are you sure you want to update your subscription with the following changes?</span>
                      <br>
                      <span v-if="updatedSubscription.amountSelected">
                        - New Amount:
                        <span class="updated-value">{{ updatedSubscription.amountSelected }} USDT</span>
                      </span>
                      <span v-if="updatedSubscription.newSelectedPlan">
                        - New subscription plan:
                        <span class="updated-value">{{ updatedSubscription.newSelectedPlan.subscription }}</span>
                      </span>
                      <br>
                      <span>
                        Your subscription price will remain
                        <span class="updated-value">€{{ updatedSubscription.newSelectedPlan.price.toFixed(0) }}.</span>
                        The changes will be applied immediately
                      </span>
                    </span>
                    <div v-if="type === CANCEL_SUBSCRIPTION_CONFIRM">
                      <span>You subscription
                        <span v-if="expiry==='9999-12-30'">is recurring</span>
                        <span v-else>will end on {{ expiry }}</span>
                        do you want to cancel your subscription now or wait until your time runs out?</span>
                      <div class=" cancel-options">
                        <div class="cancel-radio">
                          <input name="cancel-type" class="cancel-radio-radio" type="radio" id="now" checked @click="toggleCancelType(0)">
                          <label class="cancel-radio-text" for="now">Cancel now</label>
                        </div>
                        <div class="cancel-radio">
                          <input name="cancel-type" class="cancel-radio-radio" type="radio" id="subscription" @click="toggleCancelType(1)">
                          <label class="cancel-radio-text" for="subscription">Cancel when subscription runs out</label>
                        </div>
                      </div>
                      <span>Are you sure you want to cancel your subscription? this action cannot be undone</span>
                    </div>
                  </span>
                </div>
                <div v-if="type === SUBSCRIPTION_STATUS_CONFIRM" class="buttons">
                  <span class="button-p button-shadow cancel-bot-button" :class="{ res: !isActive }" @click="toggleSubscription()">
                    <span v-if="isActive">Pause</span>
                    <span v-else>Resume</span>
                  </span>
                  <span class="to-cart" @click="closeModal()">Cancel</span>
                </div>
                <div v-if="type === ADD_CART_ITEM" class="buttons">
                  <span class="button-p button-shadow" @click="goToMarketPlace()">Go back to marketplace</span>
                  <span class="to-cart" @click="goToCart()">Go to my cart</span>
                </div>
                <div v-if="type === CONFIRM_SUBSCRIPTION_UPDATE" class="buttons">
                  <span class="button-p button-shadow" @click="updateSubscription()">Update Subscription</span>
                  <span class="to-cart" @click="closeModal()">cancel</span>
                </div>
                <div v-if="type === CANCEL_SUBSCRIPTION_CONFIRM" class="buttons">
                  <span class="button-p button-shadow" @click="cancelSubscription()">Cancel subscription</span>
                  <span class="to-cart" @click="closeModal()">cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    type: Number,
    isActive: Boolean,
    updatedSubscription: Object,
    expiry: String,
  },
  data() {
    return {
      SUBSCRIPTION_STATUS_CONFIRM: 0,
      ADD_CART_ITEM: 1,
      CONFIRM_SUBSCRIPTION_UPDATE: 2,
      CANCEL_SUBSCRIPTION_CONFIRM: 3,
      cancelType: false,
    }
  },
  methods: {
    toggleSubscription() {
      this.$emit('toggleSubscriptionStatus')
      this.$emit('closeAlert')
    },
    cancelSubscription() {
      this.$emit('cancelSubscription', this.cancelType)
    },
    updateSubscription() {
      this.$emit('handleUpdateSubscription')
    },
    closeModal() {
      this.$emit('closeAlert', 1)
    },
    goToMarketPlace() {
      this.$emit('click')
      this.$router.push({ name: 'Bots' })
    },
    goToCart() {
      this.$emit('click')
      this.$router.push({ name: 'Cart' })
    },
    toggleCancelType(e) {
      switch (e) {
        case 0:
          this.cancelType = false
          break;
        case 1:
          this.cancelType = true
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 34px;
  font-weight: bold;
}

.subtext {
  font-size: 24px;
}

.modal-container {
  width: 50%;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 20px;
}

.modal-close {
  font-size: 50px;
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -10px;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.to-cart {
  color: var(--blue-primary);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.cancel-bot-button {
  width: 40%;
  background-color: #eb7c8b;
  display: flex;
  justify-content: center;
}

.cancel-bot-button:hover {
  background-color: #d56f7d;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.res {
  background-color: #64ba62;
}

.res:hover {
  background-color: #64ba62;
}

.cancel-options {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cancel-radio {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancel-radio-radio {
  margin-right: 5px;
  cursor: pointer;
}

.cancel-radio-text {
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: 300px;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }
}
</style>
