<template>
  <div>
    <span v-if="!modify" class="step-title">5. Select Duration</span>
    <span v-else class="step-title">Extend Duration</span>
    <duration-selector :modify="modify" @updateSelector="updateDurationSelector($event)" />
    <div class="custom-duration-input" v-if="isCustom">
      <input class="form-input custom-duration" type="number" v-model="customDuration">
      <label for="">months</label>
    </div>
    <span v-if="errMsg" class="error">{{errMsg}}</span>
    <div v-if="this.selectedDuration && this.selectedDuration.type === 'fixed' && !modify">
      <br>
      <span class="section-title">6. Payment type</span>
      <div class="payment-types">
        <div class="payment-type">
          <input class="type-checkbox" type="checkbox" id="recurring-checkbox" v-model="recurringType" @change="togglePaymentType(1)">
          <label class="type-label" for="recurring-checkbox">Recurring</label>
        </div>
        <div class="payment-type">
          <input class="type-checkbox" id="all-checkbox" type="checkbox" v-model="allAtOnceType" @change="togglePaymentType(0)">
          <label class="type-label" for="all-checkbox">All at once</label>
        </div>
      </div>
    </div>
    <div v-if="nextEnabled" class="next-step">
      <span class="form-button button-shadow next-button" @click="nextStep()">Next</span>
    </div>
  </div>
</template>

<script>
import DurationSelector from '@/components/shared/DurationSelector'

export default {
  components: {
    DurationSelector
  },
  props: {
    price: Number,
    nextEnabled: Boolean,
    modify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCustom: false,
      errMsg: '',
      durationOptions: [
        {
          id: 0,
          title: 'Monthly',
          recurring: true,
          paymentType: 'recurring',
          type: 'recurring',
          duration: 1
        },
        {
          id: 1,
          title: '1 Month',
          recurring: true,
          paymentType: 'recurring',
          type: 'fixed',
          duration: 1
        },
        {
          id: 2,
          title: '3 Months',
          recurring: true,
          paymentType: 'recurring',
          type: 'fixed',
          duration: 3
        },
        {
          id: 3,
          title: '6 Months',
          recurring: true,
          paymentType: 'recurring',
          type: 'fixed',
          duration: 6
        },
        {
          id: 4,
          title: 'Custom',
          recurring: true,
          paymentType: 'recurring',
          type: 'fixed',
          duration: 1
        }
      ],
      selectedDuration: null,
      recurringType: true,
      allAtOnceType: false,
      subscriptionType: '',
      customDuration: 1
    }
  },
  watch: {
    customDuration() {
      this.errMsg = ''
      if (this.customDuration < 1 || this.customDuration > 24) {
        this.errMsg = 'Custom value should be between 1 and 24 months'
      }
      this.$emit('selectedDuration', Number(this.customDuration))
    }
  },
  methods: {
    // select subscription type
    updateDurationSelector(id) {
      this.errMsg = ''
      this.isCustom = false
      if (id === 4) {
        this.isCustom = true
      }

      this.selectedDuration = this.durationOptions[id]

      this.recurringType = true
      this.allAtOnceType = false
      this.$emit('selectedDuration', this.durationOptions[id])
    },
    togglePaymentType(e) {
      switch (e) {
        case 0:
          if (!this.allAtOnceType) {
            this.recurringType = true
            return
          }
          this.recurringType = false
          break
        case 1:
          if (!this.recurringType) {
            this.allAtOnceType = true
            return
          }
          this.allAtOnceType = false
          break
      }

      this.recurringType ? this.selectedDuration.paymentType = 'recurring' : this.selectedDuration.paymentType = 'all_at_once'

      this.$emit('selectedDuration', this.selectedDuration)
    },
    nextStep() {
      this.$emit('nextStep')
    }
  }
}
</script>

<style scoped>
.subscription-duration {
  display: flex;
  flex-direction: row;
}

.duration-options {
  margin-left: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.duration-items {
  display: flex;
  flex-direction: row;
}

.selector {
  box-shadow: 0 2px 5px 2px rgba(164, 164, 164, 0.75);
  color: var(--header-color);
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.section-title {
  font-weight: 400;
  margin-top: 20px;
}
.payment-types {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.payment-type {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.type-label {
  cursor: pointer;
  margin-left: 5px;
}
.type-checkbox {
  cursor: pointer;
}
.next-button {
  width: 100px;
  display: flex;
  justify-content: center;
}
.next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.custom-duration-input {
  margin-top: 10px;
}
.custom-duration {
  margin: 0 5px;
  width: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 850px) {
  .duration-options {
    flex-wrap: wrap;
  }
  .next-step {
    margin-top: 50px;
    justify-content: center;
  }
  .next-button {
    width: 100%;
  }
}
</style>
