const state = {
	newBot: {},
};

const getters = {
  newBotProcess: (state) => state.newBot,
};

const actions = {
  async addNewBotToProcess({ commit }, bot) {
    commit("newBot", bot);
  },
  async addNewExchangeToProcess({ commit }, exchange) {
    commit("newExchangeToProcess", exchange);
  },
  async addNewAmountToProcess({ commit }, amount) {
    commit("newAmmountAllocatedToProcess", amount);
  },
  async addNewSubscriptionToProcess({ commit }, subscription) {
    commit("newSubscriptionToProcess", subscription);
  },
  async addNewSubscriptionDurationToProcess({ commit }, duration) {
    commit("addNewSubscriptionDurationToProcess", duration);
  },
  async deleteBot({ commit }, id) {
    commit("removeNewBot", id);
  },
  async clearProcess({ commit }) {
    commit("clearProcess");
  },
};

const mutations = {
  newBot(state, bot) {
    state.newBot.bot = bot;
  },
  newExchangeToProcess(state, exchange) {
    state.newBot.exchange = exchange;
  },
  newAmmountAllocatedToProcess(state, amount) {
    state.newBot.allocated = amount;
  },
  newSubscriptionToProcess(state, subscription) {
    state.newBot.subscription = subscription;
  },
	addNewSubscriptionDurationToProcess(state, duration) {

		state.newBot.duration = duration;

  },
  removeNewBot(state) {
    state.newBot.bot = {};
  },
  clearProcess(state) {
    state.newBot = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
