<template>
  <div class="section-settings">
    <span class="title">Subscription</span>
    <div v-if="subscriptionInfo && !loading" class="active-subscription">
      <span v-if="subscriptionInfo.subscription_plan" class="active-subscription-line">Current Plan:
        <span class="current-subscription">{{ subscriptionInfo.subscription_plan.split('_')[0][0].toUpperCase() + subscriptionInfo.subscription_plan.split('_')[0].substr(1) }}</span>
      </span>
      <span class="active-subscription-line">
        <span>Status: </span>
        <span v-if="subscriptionInfo.active_subscription" class="success">Active</span>
        <span v-else-if="subscriptionInfo.status_payment ==='Waiting for payment'" class="current-subscription">Waiting for payment</span>
        <span v-else-if="subscriptionInfo.cancels_at_period_end" class="error">Cancels after expiry date</span>
      </span>
      <span v-if="!subscriptionInfo.cancels_at_period_end && subscriptionInfo.next_payment_date" class="active-subscription-line">Next billing date: <span class="current-subscription">{{ subscriptionInfo.next_payment_date.split(' ')[0] }}</span></span>
      <span v-else class="active-subscription-line">Ends on <span v-if="subscriptionInfo.next_payment_date" class="current-subscription">{{ subscriptionInfo.next_payment_date.split(' ')[0] }}</span></span>
      <div class="subscription-buttons">
        <span v-if="!subscriptionInfo.cancels_at_period_end && subscriptionInfo.status_payment === 'Paid' && subscriptionInfo.payment_platform !== 'paypal'" class="button-p button-shadow change-b" @click="toggleModifySubscriptionModal()">Modify Subscription</span>
        <span v-if="!subscriptionInfo.cancels_at_period_end" class="button-p button-shadow cancel-b" @click="toggleCancelSubscriptionModal()">Cancel Subscription</span>
        <span v-if="subscriptionInfo.cancels_at_period_end" class="button-p button-shadow change-b" @click="toggleResumeSubscriptionModal()">Resume Subscription</span>
      </div>
    </div>
    <div v-if="!subscriptionInfo && !loading">
      <subscription-plan @selectedSubscription="setSelectedSubscription($event)"/>
      <span v-if="errMsg" class="error">{{ errMsg }}</span>
      <div class="subscription-buttons">
        <span class="button-p button-shadow change-b" @click="toggleSubscriptionModal()">Proceed</span>
      </div>
    </div>
    <loading class="loading" :active="loading"/>
    <subscription-modal v-if="subscriptionModalOpen" :subscription="selectedSubscription" @paymentComplete="handleSubscription($event)" @click="toggleSubscriptionModal()"/>
    <modify-subscription-modal v-if="modifySubscription" :subscriptionInfo="subscriptionInfo" :subscriptionId="subscriptionInfo.subscription_id" @click="toggleModifySubscriptionModal()" @paymentComplete="handleSubscription($event)"/>
    <cancel-subscription-modal v-if="cancelSubscription" :subscriptionInfo="subscriptionInfo" :subscriptionId="subscriptionInfo.subscription_id" :expiry="subscriptionInfo.next_payment_date" @cancelSubscription="handleSubscription($event)" @click="toggleCancelSubscriptionModal()"/>
    <resume-subscription-modal v-if="showResumeSubscriptionModal" @click="toggleResumeSubscriptionModal()" @resume="resumeSubscription()"/>
  </div>
</template>

<script>
import SubscriptionPlan from '@/components/trading/subscribeBot/SubscriptionPlan.vue'
import SubscriptionModal from '@/components/shared/modals/SubscriptionModal'
import CancelSubscriptionModal from '@/components/shared/modals/CancelSubscriptionModal.vue'
import ResumeSubscriptionModal from '@/components/shared/modals/ResumeSubscriptionModal.vue'
import ModifySubscriptionModal from '@/components/shared/modals/ModifySubscriptionModal.vue'
import Loading from '@/components/shared/Loading.vue'

export default {
  name: 'Subscriptions',
  components: {
    SubscriptionPlan,
    SubscriptionModal,
    CancelSubscriptionModal,
    ModifySubscriptionModal,
    ResumeSubscriptionModal,
    Loading
  },
  props: {
    subscriptionInfo: Object
  },
  data() {
    return {
      hasSubscription: false,
      subscriptionModalOpen: false,
      selectedSubscription: {},
      cancelSubscription: false,
      modifySubscription: false,
      showResumeSubscriptionModal: false,
      errMsg: '',
      loading: false,
    }
  },
  methods: {
    handleSubscription(type) {
      switch (type) {
        case 0:
          this.$router.push({name: 'Bots', params: {page: 'activated-subscription'}})
          break;
        default:
          break;
      }
      this.$emit('handleSubscription')
      this.subscriptionModalOpen = false
      this.cancelSubscription = false
    },
    setSelectedSubscription(subscription) {
      this.errMsg = ''
      this.selectedSubscription = subscription
    },
    toggleSubscriptionModal() {
      if (Object.keys(this.selectedSubscription).length < 1) {
        this.errMsg = 'Please select a subscription to proceed'
        return
      }
      this.subscriptionModalOpen = !this.subscriptionModalOpen
    },
    toggleCancelSubscriptionModal() {
      this.cancelSubscription = !this.cancelSubscription
    },
    toggleModifySubscriptionModal() {
      this.modifySubscription = !this.modifySubscription
    },
    toggleResumeSubscriptionModal() {
      this.showResumeSubscriptionModal = !this.showResumeSubscriptionModal
    },
    resumeSubscription() {
      this.loading = true
      let body = {
        user_id: this.$session.get('uid'),
        subscription_id: this.subscriptionInfo.subscription_id
      }
      this.$api
          .post('/subscriptions/stripe/resume_subscription', body, {
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.handleSubscription()
              this.loading = false
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response && err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    }
  }
}
</script>

<style scoped>
.section-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.active-subscription {
  display: flex;
  flex-direction: column;
}

.active-subscription-line {
  margin: 10px 0;
}

.current-subscription {
  font-weight: bold;
  font-size: 18px;
}

.subscription-buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.change-b {
  text-align: center;
  padding: 10px 20px;
}

.cancel-b {
  text-align: center;
  background-color: var(--red);
  padding: 10px 20px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }
}
</style>
