<template>
  <div class="exchanges">
    <loading v-if="loading" class="loading" :active="loading" />
    <div v-if="!connectNewExchange" class="title-section">
      <span class="title">Connected Exchanges</span>
      <span class="button-p button-shadow exchange-b" @click="toggleConnectExchange()">+ Add new exchange</span>
    </div>
    <span class="title" v-else>Connect new exchange account</span>
    <new-exchange v-if="connectNewExchange" @confirmAccount="confirmAccountAdded()" @closeNewExchange="closeNewExchange()" />
    <span v-if="errorMsg" class="error">{{ errMsg }}</span>
    <span v-if="successMsg" class="success">{{ successMsg }}</span>
    <v-data-table v-if="!connectNewExchange" class="exchanges-table card" :headers="headers" :items="exchanges" no-data-text="No exchange accounts currently connected">
      <template v-slot:item.exchange="{ item }">
        <span small class="exchange">
          <img class="exchange-logo" :src="loadExchangeIcon(item.exchange)" alt />
          <span>{{ formatExchangeName(item.exchange) }}</span>
        </span>
      </template>
      <template v-slot:item.validated="{ item }">
        <span small>
          <span v-if="item.validated" class="success">Validated</span>
          <span v-else class="error">Not validated</span>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span small class="mr-2" @click="editItem(item)">
          <img class="table-icon" height="50%" src="@/assets/edit.png" alt />
        </span>
        <span small class="mr-2" @click="deleteItem(item)">
          <img class="table-icon" height="50%" src="@/assets/delete.png" alt />
        </span>
      </template>
    </v-data-table>
    <modal v-if="modifyExchangeAccount" :exchange="account.exchange" :item="modifyAccount" :editExchangeAccount="editAccount" :deleteExchangeAccount="deleteAccount" :exchangeAccount="account" @click="closeModal()" @deleteConnection="confirmDeleteAccount($event)" @edit="editExchangeAccount()" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewExchangeAccount from '@/components/trading/NewExchangeAccount.vue'
import Modal from '@/components/shared/modals/Modal.vue'
import Loading from '@/components/shared/Loading.vue'
import NewExchange from '@/components/shared/exchanges/NewExchange.vue'

export default {
  name: 'ConnectedExchanges',
  computed: mapGetters(['connectedExchanges']),
  components: {
    NewExchangeAccount,
    Modal,
    Loading,
    NewExchange
  },
  data() {
    return {
      modifyExchangeAccount: false,
      connectNewExchange: false,
      exchanges: [],
      headers: [
        { text: 'Name', sortable: false, value: 'connection_name' },
        { text: 'Exchange', value: 'exchange', sortable: false },
        { text: 'Status', value: 'validated', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      modifyAccount: {
        title: '',
        content: ''
      },
      editAccount: false,
      deleteAccount: false,
      account: {},
      exchangeType: 0,
      successMsg: "",
      errorMsg: "",
      loading: false
    }
  },
  mounted() {
    if (this.$route.params.newAccount) {
      this.connectNewExchange = true
    }
    this.getUserConnections()
  },
  methods: {
    ...mapActions(['loadExchangeConnections']),
    loadExchangeIcon(exchange) {
      try {
        return require('@/assets/' + exchange.toLowerCase() + '-logo.png')
      } catch (e) {
      }
    },
    formatExchangeName(name) {
      if (name === 'okx_futures') return 'OKX Futures'
      if (name === 'bybit_futures') return 'Bybit Futures'

      return name[0].toUpperCase() + name.substr(1)
    },
    getUserConnections() {
      this.loading = true
      this.$api
        .get('/exchanges/get_connections', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.loadExchangeConnections(res.data.data)
            this.exchanges = res.data.data
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    editExchangeAccount() {
      window.location.reload()
      this.getUserConnections()
      this.successMsg = 'Succesfully edited account'
    },
    editItem(item) {
      this.account = item
      this.deleteAccount = false
      this.editAccount = true
      this.modifyExchangeAccount = true
      this.modifyAccount.title = 'Edit Exchange Account'
      this.modifyAccount.content = ''
    },
    // remove exchange account
    deleteItem(item) {
      this.editAccount = false
      this.account = item
      this.deleteAccount = true
      this.modifyExchangeAccount = true
      this.modifyAccount.title = 'Delete Exchange Account'
      this.modifyAccount.content = 'Are you sure you want to delete this exchange account?'
    },
    confirmDeleteAccount(item) {
      const body = {
        connection_id: item.connection_id,
        user_id: this.$session.get('uid')
      }
      this.$api
        .post('/exchanges/delete_connection', body, {
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            window.location.reload()
            this.getUserConnections()
            this.successMsg = 'Account succesfully removed'
          }
        })
        .catch((err) => {
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
          if (err.response.data.error.type === 'CONNECTION_ERROR') {
            this.errorMsg = true
            this.errMsg = err.response.data.error.body
          }

        })
    },
    confirmAccountAdded() {
      window.location.reload()
      this.getUserConnections()
      this.successMsg = 'Account succesfully added'
    },
    // close new exchange form
    closeForm() {
      this.account = {}
      this.editAccount = false
      this.connectNewExchange = false
      this.deleteAccount = false
      this.loadExchangeConnections()
    },
    // open the connect new exchange form
    toggleConnectExchange() {
      this.errorMsg = false
      this.connectNewExchange = true
      this.successMsg = ""
      this.errorMsg = ""
    },
    closeModal() {
      this.modifyExchangeAccount = false
    },

    closeNewExchange() {
      this.connectNewExchange = false
      this.exchangeType = 0
    }
  }
}
</script>

<style scoped>
.exchanges {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}

.title-section {
  display: flex;
  flex-direction: row;
}

.exchange-b {
  margin-top: 5px;
  margin-left: 30px;
  background-color: #4aaf35;
  border-radius: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchanges-table {
  margin-top: 20px;
  width: 100%;
}

.exchange {
  display: flex;
  align-items: center;
}

.exchange-logo {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.table-icon {
  height: 25px;
  cursor: pointer;
  margin-right: 20px;
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }

  .exchange-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .title-section {
    flex-direction: column;
  }

  .title {
    font-size: 24px;
  }
}
</style>
