<template>
  <div class="stat-card-graph">
    <canvas id="pie-chart" />
  </div>
</template>

<script>
export default {
  name: 'PieChart.vue',
  props: {
    chartData: Object
  },
  mounted() {
    this.generatePie()
  },
  methods: {
    //generate pie graph
    generatePie() {
      const Chart = require('chart.js')
      const ChartDataLabels = require('chartjs-plugin-datalabels')
      const data = [
        {
          data: this.chartData.datasets[0].data,
          backgroundColor: this.chartData.datasets[0].backgroundColor,
          borderColor: '#2c3e50'
        }
      ]

      const options = {
        borderWidth: '10px',
        hoverBackgroundColor: 'red',
        hoverBorderWidth: '10px',
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom'
        },
        plugins: {
          datalabels: {
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            formatter: (value, ctx) => {
              let sum = 0
              const dataArr = ctx.chart.data.datasets[0].data
              dataArr.map(data => {
                sum += data
              })
              return `${((value * 100) / sum).toFixed(2)}%`
            },
            font: {
              weight: 'bold'
            },
            padding: 6
          }
        }
      }
      const ctx = document.getElementById('pie-chart').getContext('2d')
      // eslint-disable-next-line no-unused-vars
      const myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          datasets: data,
          labels: this.chartData.labels
        },
        plugins: [ChartDataLabels],
        options
      })
    }
  }
}
</script>

<style scoped>
.stat-card-graph {
  height: 90%;
  width: 100%;
}
</style>
