<template>
  <div>
    <div class="bot-card card" :class="{ gold: bot.gold_only }" @click="goToBot()">
      <div class="top-area">
        <div class="bot-data">
          <bot-exchange :exchanges="bot.exchanges" :gold="bot.gold_only" />
          <div class="bot-name-icon">
            <img class="bot-icon-src" src="@/assets/bots/bot_template.png" alt="icon" />
            <div class="name-price">
              <!-- <h3 class="bot-name" :title="bot.bot_name">{{ bot.bot_name.length < 30 ? bot.bot_name : bot.bot_name.slice(0,27) + '...' }}</h3> -->
              <h3 class="bot-name" :title="bot.bot_name">{{ bot.bot_name }}</h3>
              <div class="risk-level" :class="'risk' + bot.risk_level" title="Risk levels are measured from 1 (lowest risk) to 5 (highest risk)">
                <p class="risk-text">Risk:</p>
                <p>{{ bot.risk_level }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bot-badges">
          <div class="bot-badge-item">
            <bot-badge v-if="bot.badge_1" :badgeStyle="bot.badge_1.replace(' ', '')" :badgeText="bot.badge_1" />
            <bot-badge v-if="bot.badge_2" :badgeStyle="bot.badge_2.replace(' ', '')" :badgeText="bot.badge_2" />
            <bot-badge v-if="bot.badge_3" :badgeStyle="bot.badge_3.replace(' ', '')" :badgeText="bot.badge_3" />
          </div>
        </div>
      </div>
      <div class="bottom-area" :class="{ notActiveBottom: !bot.enabled  }">
        <div class="bot-graph">
          <bot-card-graph ref="botCardGraph" :data="botGraphData" :labels="botGraphLabels" :name="graphId" :positive=" isPositive" />
        </div>
        <div class="bot-graph-data">
          <span v-if="bot.total_pnl_pct" class="bot-graph-data-text">
            <p v-if="!isTopPerformer">{{ bot.total_pnl_pct.toFixed(2) }}%</p>
            <span v-else class="top-performer-return-text top-performing ">
              <div class="top-performer-value">
                <img v-if="isPositive" height="20px" src="@/assets/arrow-up.png" alt="">
                <img v-else src="@/assets/arrow-down.png" height="20px" alt="">
                <p class="positive" :class="{negative: !isPositive}">{{ bot.total_pnl_pct.toFixed(2) }}%</p>
              </div>
              <p>Return (30D)</p>
            </span>
          </span>
          <span v-else class="bot-graph-data-text">
            <p v-if="!isTopPerformer">0%</p>
            <span v-else class="top-performer-return-text top-performing">
              <p>0%</p>
              <p>Return (30D)</p>
            </span>
          </span>
          <span class="sub-button add" @click="goToBot()">Details</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BotBadge from '@/components/trading/bots/BotBadge.vue'
import Dropdown from '@/components/trading/Dropdown'
import BotCardGraph from '@/components/trading/bots/BotCardGraph'
import BotExchange from '@/components/trading/bots/BotExchange.vue'

export default {
  name: 'BotCard',
  components: {
    BotBadge,
    Dropdown,
    BotCardGraph,
    BotExchange
  },
  props: {
    bot: Object,
    isActive: Boolean,
    isNew: {
      type: Boolean,
      default: false
    },
    isTopPerformer: {
      type: Boolean,
      default: false
    },
    graphId: String
  },
  data() {
    return {
      editMode: false,
      botModal: false,
      botGraphData: [],
      botGraphLabels: [],
      isPositive: true,
    }
  },
  watch: {
    bot() {
      this.bot.total_pnl_pct < 0 ? this.isPositive = false : this.isPositive = true
      this.populateGraphData()
    }
  },
  created() {
    this.bot.total_pnl_pct < 0 ? this.isPositive = false : this.isPositive = true
  },
  mounted() {
    this.populateGraphData()
  },
  methods: {
    populateGraphData() {
      this.botGraphData = []
      this.botGraphLabels = []

      let counter = 0
      for (const key in this.bot.historical_performance) {
        if (!this.botGraphLabels.includes(this.bot.historical_performance[key].datetime.split(' ')[0])) {
          this.botGraphLabels.push(this.bot.historical_performance[key].datetime.split(' ')[0])
          this.botGraphData.push(this.bot.historical_performance[key].pnl_pct)
          counter++
        } else {
          this.botGraphData.splice(counter - 1, 1)
          this.botGraphLabels.splice(counter - 1, 1)

          this.botGraphLabels.push(this.bot.historical_performance[key].datetime.split(' ')[0])
          this.botGraphData.push(this.bot.historical_performance[key].pnl_pct)
        }
      }
    },
    goToBot() {
      this.$router.push({
        name: 'Bot',
        params: { name: this.bot.bot_name.replace(/ /g, ""), title: this.bot.bot_name, bot_id: this.bot.bot_id },
      })
    },
    goBack() {
      this.editMode = false
    },
  }
}
</script>

<style scoped>
.bot-card {
  position: relative;
  cursor: pointer;
  padding: 10px;
  height: 370px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--card-background);
  box-sizing: border-box;
  transition: transform 0.2s;
  border-radius: 15px !important;
}

.bot-card:hover {
  transform: scale(1.025);
}

.top-area {
  height: auto;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bot-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bot-data {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.name-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.bot-name-icon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.bot-icon-src {
  height: 60px;
  border-radius: 50%;
}

.bot-name {
  font-family: 'Roboto Condensed', sans-serif;
  text-align: left;
  font-size: 21px;
}

.longs-shorts {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.longs-shorts-text {
  height: 20px;
  padding: 0 10px;
  background-color: var(--secondary-blue);
  color: var(--text-secondary);
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.check-mark {
  color: var(--green);
  font-weight: bolder;
}

.cross-mark {
  font-weight: bolder;
  color: var(--red);
}

.bot-badges {
  display: flex;
  justify-content: center;
  text-align: left;
}

.bottom-area {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 180px;
  background: linear-gradient(
      112.03deg,
      var(--card-background) 35.78%,
      var(--primary-blue) 273.82%
    ),
    #ffffff;
  border: 1px solid var(--border);
  box-sizing: border-box;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
}

.notActiveBottom {
  background: linear-gradient(112.03deg, #ffffff 35.78%, #7d7d7d 273.82%),
    #ffffff;
}

.bot-graph {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 2px;
  height: 60%;
  width: 100%;
  border-radius: 10px;
}

.bot-graph-data {
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bot-stats-graph {
  height: 100%;
}

.bot-graph-data-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: var(--primary-blue);
}

.sub-button {
  padding: 10px 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--button-text);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: var(--secondary-blue);
}

.no-data-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.active-bot-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.active-bot-info-item {
  font-family: 'Roboto Condensed', sans-serif;
  padding: 2px;
  margin: 2px 2px;
  width: 100px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
}

.bot-badge-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.gold {
  background: linear-gradient(45deg, var(--card-background) 50%, #ffe6a0 100%);
}

.mobile-exchanges {
  display: none;
}

.risk-level {
  margin-top: 5px;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  /*font-style: italic;*/
  gap: 5px;
}

.risk-text {
  font-size: 14px;
}

.risk1 {
  border: 2px solid var(--green);
  color: var(--green);
}

.risk2 {
  border: 2px solid #17a12c;
  color: #17a12c;
}

.risk3 {
  border: 2px solid var(--yellow);
  color: var(--yellow);
}

.risk4 {
  border: 2px solid #fd9537;
  color: #fd9537;
}

.risk5 {
  border: 2px solid var(--red);
  color: var(--red);
}

.top-performer-return-text {
  font-size: 16px;
}
.top-performing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 20px;
}
.top-performer-value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
}
.positive {
  color: var(--green);
}
.negative {
  color: var(--red);
}
@media (max-width: 1250px) {
  .bot-card {
    height: auto;
    width: 300px;
  }

  .bot-name {
    font-size: 17px;
  }

  .bot-graph-data-text {
    font-size: 24px;
  }

  .bottom-area {
    padding: 5px;
    height: auto;
  }

  .sub-button {
    padding: 4px 10px;
  }
}
</style>
