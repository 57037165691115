<template>
  <div class="okx-tutorial">
    <div class="back-button">
      <router-link class="button-p" :to="{name: 'ExchangeAccounts'}"> Go back</router-link>
    </div>
    <div v-for="item, index in tutorialText" :key="item.header">
      <div v-if="index == tutorialType">
        <h3 class="tutorial-header">{{ item.header }}</h3>
        <div v-for="step in item.steps" :key="step.text" class="tutorial-step">
          <p v-html="step.text"></p>
          <br/>
          <expandable-image class="step-image" :src="loadImage(step.image)" :close-on-background-click="true"/>
          <!-- <div class="step-image">
            <img class="step-image-src" :src="loadImage(step.image)" alt="tutorial-image" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import okxTutorial from '@/json/okxTutorial'
import bybitTutorial from '@/json/bybitTutorial'
import binanceTutorial from '@/json/binanceTutorial'
import bitvavoTutorial from '@/json/bitvavoTutorial'
import bitgetTutorial from '@/json/bitgetTutorial'
import krakenTutorial from '@/json/krakenTutorial'
import kucoinTutorial from '@/json/kucoinTutorial'

export default {
  data() {
    return {
      okxTutorialText: okxTutorial,
      bybitTutorialText: bybitTutorial,
      binanceTutorialText: binanceTutorial,
      bitvavoTutorialText: bitvavoTutorial,
      bitgetTutorial: bitgetTutorial,
      krakenTutorial: krakenTutorial,
      kucoinTutorial: kucoinTutorial,
      tutorialText: {},
      tutorialType: 0
    }
  },
  created() {
    this.tutorialType = this.$route.query.tutorial
    this.setExchangeTutorialText(this.$route.query.exchange)
  },
  methods: {
    loadImage(imageSrc) {
      try {
        return require('@/assets/' + imageSrc + '.png')
      } catch (err) {
        console.log(err)
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    setExchangeTutorialText(exchangeName) {
      switch (exchangeName) {
        case 'okx':
          this.tutorialText = this.okxTutorialText
          break;
        case 'bybit':
          this.tutorialText = this.bybitTutorialText
          break;
        case 'binance':
          this.tutorialText = this.binanceTutorialText
          break;
        case 'bitvavo':
          this.tutorialText = this.bitvavoTutorialText
          break;
        case 'bitget':
          this.tutorialText = this.bitgetTutorial
          break;
        case 'kraken':
          this.tutorialText = this.krakenTutorial
          break;
        case 'kucoin':
          this.tutorialText = this.kucoinTutorial
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
.okx-tutorial {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.tutorial-header {
  font-size: 42px;
  font-weight: normal;
}

.tutorial-step {
  max-width: 900px;
  text-align: justify;
  font-size: 24px;
  padding: 20px 0;
}

.back-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.step-image {
  cursor: zoom-in;
  max-width: 100%;
  transition: transform 0.25s, visibility 0.25s ease-in;
}

.step-image-src {
  max-width: 100%;
}

.step-image:focus img {
  cursor: zoom-out;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: scale(2);
}
</style>
