<template>
  <div class="billing information">
    <div class="name">
      <label class="name-input" for="">
        First Name
        <input class="form-input" type="text" v-model="billingDetails.firstName" placeholder="First Name">
      </label>
      <label class="name-input last-name" for="">
        Last Name
        <input class="form-input" type="text" v-model="billingDetails.lastName" placeholder="Last Name">
      </label>
    </div>
    <div class="country">
      <label for="">
        Country
        <country-select class="form-input" v-model="billingDetails.country" :country="billingDetails.country"/>
      </label>
    </div>
    <div class="address">
      <label class="street" for="">
        Street
        <input class="form-input" type="text" v-model="billingDetails.street" placeholder="Street">
      </label>
      <label class="postcode" for="">
        Postcode
        <input class="form-input" type="text" v-model="billingDetails.postCode" placeholder="PostCode">
      </label>
      <label class="region" for="">
        Region
        <region-select class="form-input" v-model="billingDetails.region" :country="billingDetails.country" :region="billingDetails.region"/>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingDetails',
  props: {
    userData: Object
  },
  data() {
    return {
      billingDetails: {
        firstName: '',
        lastName: '',
        country: '',
        street: '',
        postCode: '',
        region: ''
      }
    }
  },
  watch: {
    userData() {
      this.loadBillingData()
    }
  },
  mounted() {
    this.loadBillingData()
  },
  methods: {
    loadBillingData() {
      if (this.userData && this.userData.first_name) {
        this.billingDetails.firstName = this.userData.first_name
        this.billingDetails.lastName = this.userData.last_name
        this.billingDetails.country = this.userData.country
        this.billingDetails.street = this.userData.address.split(', ')[0]
        this.billingDetails.postCode = this.userData.address.split(', ')[1]
        this.billingDetails.region = this.userData.address.split(', ')[2]
      }
    },
    setBillingData() {
      this.$emit('setBillingDetails', this.billingDetails)
    }
  }
}
</script>

<style scoped>
.billing-information {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section-title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 400;
}

.name {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name-input {
  width: 50%;
}

.last-name {
  margin-left: 5px;
}

.address {
  display: flex;
  flex-direction: row;
}

.street {
  flex: 3;
}

.postcode {
  margin-left: 5px;
  flex: 1;
}

.region {
  margin-left: 5px;
  flex: 2;
}

@media (max-width: 850px) {
  .address {
    flex-direction: column;
  }

  .street {
    flex: 1;
  }

  .postcode {
    margin-left: 0;
    flex: 1;
  }

  .region {
    margin-left: 0;
    flex: 1;
  }
}
</style>