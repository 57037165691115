<template>
  <div class="historical-positions card">
    <v-data-table class="table" :headers="headers" :items="activeItems" no-data-text="No data yet available" :hide-default-footer="true">
      <template v-slot:item.datetime="{ item }">
        <span v-if="item" class="cell">
          <span>{{ item.datetime.split(' ')[0] + ' ' + item.datetime.split(' ')[1].split(':')[0] + ':' + item.datetime.split(' ')[1].split(':')[1] }}</span>
        </span>
      </template>
      <template v-slot:item.direction='{ item }'>
        <span v-if="item" class="cell">
          <span>{{ item.direction.split('_')[0][0].toUpperCase() + item.direction.split('_')[0].substr(1) }}</span>
          <!--          <img class="tooltip" src="@/assets/tooltip.png" @mouseover="toggleTooltip" @mouseleave="toggleTooltip"/>-->
          <!--          <span v-if="directionTooltip" class="direction-tooltip card">asdasd</span>-->
        </span>
      </template>
      <template v-slot:item.bot_name="{ item }">
        <span v-if="item" class="cell">
          <img class="icon" src="@/assets/bots/bot_template.png" alt="">
          <span>{{ item.bot_name }}</span>
        </span>
      </template>
      <template v-slot:item.pnl_pct="{ item }">
        <span v-if="item" class="cell">
          <span v-if="item.pnl_pct > 0" class="pnl positive">{{ item.pnl_pct.toFixed(2) }}%</span>
          <span v-else class="pnl negative">{{ item.pnl_pct.toFixed(2) }}%</span>
        </span>
      </template>
    </v-data-table>
    <div v-if="activeItems.length > 0" class="pagination">
      <span v-if="pages !== 0" class="button-p button-shadow footer-button" @click="previousPage()">&lt;</span>
      <span v-show="showNextPage" class="button-p button-shadow footer-button" @click="nextPage()">&gt;</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HistoricalPositions',
  computed: mapGetters(['widgetData']),
  props: {
    bot: Object
  },
  data() {
    return {
      lastDate: '',
      items: [],
      activeItems: [],
      pages: 0,
      firstItem: 0,
      lastItem: 0,
      headers: [
        { text: 'Date', align: 'start', sortable: false, value: 'datetime' },
        { text: 'Bot', value: 'bot_name', sortable: false },
        { text: 'Symbol', value: 'symbol', sortable: false },
        { text: 'Direction', value: 'direction', sortable: false },
        { text: 'P/L', value: 'pnl_pct', sortable: false }
      ],
      extraPoint: null,
      showNextPage: true,
      directionTooltip: false
    }
  },
  watch: {
    bot() {
      this.pages = 0
      this.items = []
      this.activeItems = []
      this.lastDate = ''
      this.getHistoricalPositions()
    },
  },
  mounted() {
    if (this.bot && this.bot.bot_id) this.getHistoricalPositions()
  },
  methods: {
    toggleTooltip() {
      this.directionTooltip = !this.directionTooltip
    },
    getMoreRows() {
      this.lastDate = this.items[this.items.length - 1].datetime
      this.getHistoricalPositions()
    },
    previousPage() {
      this.showNextPage = true
      this.pages--
      let lastValue = this.items.indexOf(this.activeItems[0])
      let firstValue = lastValue - 10
      this.activeItems = []
      for (let i = firstValue; i < lastValue; i++) {
        this.activeItems.push(this.items[i])
      }
    },
    nextPage() {
      this.pages++
      let firstValue = this.items.indexOf(this.activeItems[this.activeItems.length - 1]) + 1
      let lastValue = firstValue + 10

      if (this.items[this.items.indexOf(this.activeItems[this.activeItems.length - 1]) + 1]) {
        this.activeItems = []
        for (let i = firstValue; i < lastValue; i++) {
          if (!this.items[i]) {
            this.showNextPage = false
            return
          }
          this.activeItems.push(this.items[i])
        }
        if (this.activeItems.length < 10) this.showNextPage = false
        return
      }
      this.getMoreRows()
    },
    getHistoricalPositions() {
      let temp = {
        user_id: this.$session.get('uid'),
        timezone: this.$session.get('timezone'),
        bot_id: this.bot.bot_id
      }
      if (this.lastDate !== '') temp.last_date = this.lastDate
      this.$api
        .get('/bots/get_historical_positions', {
          params: temp,
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.length === 0) {
              if (this.items.length > 0) {
                this.pages--
              }
              this.showNextPage = false
              return
            }
            if (this.items.length > 0) {
              this.showNextPage = true
              this.activeItems = []
              this.activeItems = res.data.data
              this.items = this.items.concat(res.data.data)
              if (this.activeItems.length < 10) {
                this.showNextPage = false
              }
              return
            }
            this.showNextPage = true
            this.items = res.data.data
            this.activeItems = this.items
            if (this.activeItems.length < 10) this.showNextPage = false
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    }
  }
}
</script>

<style scoped>
.historical-positions {
  background-color: var(--card-background) !important;
  width: 100%;
  height: 100%;
}

.table {
  background-color: var(--card-background);
  box-shadow: none;
}

.cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.pnl {
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
}

.positive {
  color: var(--green);
}

.negative {
  color: var(--red);
}

.pagination {
  padding: 0 10px;
  height: 60px;
  background-color: var(--card-background);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: var(--text-primary);
}

.footer-button {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 50%;
  padding: 15px;
  font-weight: bold;
}

.tooltip {
  height: 15px;
  width: 15px;
}

.direction-tooltip {
  position: absolute;
  width: 300px;
  height: auto;
}
</style>