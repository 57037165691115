<template>
  <div class="bot-card-graph">
    <canvas :id="name" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'BotCardGraph',
  props: {
    name: String,
    data: Array,
    labels: Array,
    positive: Boolean,
    landing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      backgroundColor: '#1b81c0',
      chart: null
    }
  },
  watch: {
    data() {
      this.buildLineGraph()
    }
  },
  methods: {
    buildLineGraph() {
      if (this.chart) this.chart.destroy()

      let finalChartData = []
      let finalChartLabels = []
      let fill = false

      const dataJump = 150
      const steps = this.data.length / dataJump

      let j = 0
      if (steps.toFixed(0) > 1) {
        for (let i = 0; i < dataJump; i++) {
          if (typeof this.data[j] !== 'undefined' && this.data[j] !== this.data[this.data.length - 1]) {
            finalChartData.push(this.data[j])
            finalChartLabels.push(this.labels[j])
            j = j + Math.floor(steps.toFixed(0))
          }
        }
        finalChartData.push(this.data[this.data.length - 1])
        finalChartLabels.push(this.labels[this.labels.length - 1])
      } else {
        finalChartData = this.data
        finalChartLabels = this.labels
      }

      if (finalChartData.length === 1) {
        finalChartData.unshift(0)
        finalChartLabels.unshift(' ')
      } else if (finalChartData.length < 1) {
        finalChartData.unshift(0, 0)
        finalChartLabels.unshift(' ', ' ')
      }

      if (this.landing) {
        fill = true
        this.backgroundColor = '#60d248'
      }

      const ctx = document.getElementById(this.name).getContext('2d')

      const gradient = ctx.createLinearGradient(0, 0, 0, 450)
      gradient.addColorStop(0, 'rgb(96, 210, 72)')
      gradient.addColorStop(0.15, 'rgb(204,255,198)')
      gradient.addColorStop(1, 'rgb(255,255,255)')

      // eslint-disable-next-line no-unused-vars
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: finalChartLabels,
          datasets: [
            {
              borderWidth: 4,
              lineTension: 0.2,
              borderJoinStyle: 'round',
              pointBackgroundColor: '#ffff',
              pointRadius: 0,
              stepped: false,
              fill: fill,
              backgroundColor: gradient,
              label: 'Profit',
              borderColor: this.backgroundColor,
              // segment: {
              //   borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
              // },
              data: finalChartData
            }
          ]
        },
        options: {
          events: [],
          plugins: {
            legend: { display: false }
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: { enabled: false },
          scales: {
            y: {
              display: false,
              ticks: {
                min: Math.min.apply(null, this.data) - 10,
                max: Math.max.apply(null, this.data) + 10,
                display: false
              },
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0)'
              },
            },
            x: {
              display: false,
              ticks: { display: false },
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0)',
              }
            }

          }
        }
      })
    }
  }
}
</script>

<style scoped>
.bot-card-graph {
  height: 100%;
}
</style>
