<template>
  <div class="section-settings">
    <span class="title">Payment Methods</span>
    <div class="payments-settings">
      <loading v-if="loading" class="loading" :active="loading" />
      <div v-if="!userCards || userCards.length < 1 && !loading">
        <span class="no-cards-placeholder">There are no saved payment methods. Payment methods will be added upon payments.</span>
      </div>
      <br>
      <div class="user-payment-method card" v-for="item in userCards" :key="item.id">
        <div class="user-info">
          <span class="card-user-name">{{ item.billing_details.name }}</span>
          <span class="card-user-email">{{ email }}</span>
          <span class="card-user-address">
            <!-- <span>
              {{ item.billing_details.address.line1 }},
              {{ item.billing_details.address.postal_code }},
              {{ item.billing_details.address.city }},
              {{ item.billing_details.address.country }}
            </span> -->
          </span>
        </div>
        <div class="card-info">
          <span class="card-img">
            <img class="class-img-src" v-if="item.card.brand === 'visa'" src="@/assets/visa.png" alt="" />
          </span>
          <span class="card-number">**** **** **** {{ item.card.last4 }}</span>
          <span class="card-date">
            {{ item.card.exp_month }}/{{ item.card.exp_year }}
          </span>
        </div>
        <!-- <div class="card-actions">
          <img class="actions-img" src="@/assets/delete.png" alt="" @click="modifyPaymentMethod(item)" />`
        </div> -->
      </div>
    </div>
    <modal v-if="modifyMethod" :item="modifyCard" :card="selectedCard" @click="modifyPaymentMethod()" @deleteMethod="removePaymentMethod()" />
  </div>
</template>

<script>
import Modal from '@/components/shared/modals/Modal.vue'
import Loading from '@/components/shared/Loading.vue'

export default {
  name: 'PaymentMethods',
  components: {
    Modal,
    Loading
  },
  data() {
    return {
      userCards: [],
      modifyCard: {
        title: 'Remove this payment method',
        content: 'Are you sure you want to remove this payment method'
      },
      modifyMethod: false,
      selectedCard: {},
      loading: true,
      email: this.$session.get('email')
    }
  },
  mounted() {
    this.getUserStripeCards()
  },
  methods: {
    getUserStripeCards() {
      this.$api
        .get('/subscriptions/get_saved_payment_details', {
          params: {
            user_id: this.$session.get('uid'),
            payment_method: 'card',
            payment_platform: 'stripe'
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.userCards = res.data.data
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    modifyPaymentMethod(e) {
      this.selectedCard = e
      this.modifyMethod = !this.modifyMethod
    },
  }
}
</script>

<style scoped>
.section-settings {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.payments-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

.user-payment-method {
  margin-bottom: 10px;
  padding: 5px;
  background: linear-gradient(
      112.03deg,
      #f7f7f7 35.78%,
      var(--blue-primary) 273.82%
    ),
    #ffffff;
  border: 2px solid #8caec5;
  min-height: 75px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.card-info {
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.class-img-src {
  height: 40px;
  margin-right: 20px;
}

.card-number {
  margin-right: 20px;
}

.card-actions {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.actions-img {
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.user-info {
  width: 33%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
.no-cards-placeholder {
  font-size: 22px;
}
@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }

  .notifications-settings-item-text {
    font-size: 16px;
  }
}
</style>
