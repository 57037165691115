<template>
  <div v-click-outside="outside" class="filter">
    <div class="closed-filter" :class="{selected: Object.keys(selectedItem).length > 0}" @click="toggleOpenFilter()">
      <span v-if="!selectedItem.id" class="close-filter-title">Order by</span>
      <span v-else-if="selectedItem.id && selectedItem.type === 1" class="close-filter-title"> {{ selectedItem.title }}</span>
      <img v-if="isDarkTheme" class="filter-img" src="@/assets/arrow-up-down-white.png" alt="">
      <img v-else class="filter-img" src="@/assets/arrow-up-down.png" alt="">
    </div>
    <div v-show="isFilterOpen" class="open-filter card">
      <div class="filter-section">
        <div class="filter-options">
          <span v-for="item in botFilters.pnl_pct" :key="item.id" class="filter-item" :class="{selected: selectedItem.id === item.id}" @click="addItemToFilter(item, 1)">
            <span v-if="item.id === selectedItem.id" class="checkmark">✓</span>
            {{ item.title }}
          </span>
        </div>
      </div>
      <span class="clear-button" @click="clearFilter()">Clear</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'BotFilters',
  computed: mapGetters(['darkTheme']),
  data() {
    return {
      isFilterOpen: false,
      botFilters: {
        pnl_pct: [
          {
            type: 1,
            id: 3,
            title: 'ROI: Low to high',
            value: 0
          },
          {
            type: 1,
            id: 4,
            title: 'ROI: High to low',
            value: 1
          },
        ],
      },
      selectedItem: {},
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  methods: {
    outside() {
      this.isFilterOpen = false
    },
    toggleOpenFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },
    addItemToFilter(item, type) {
      this.isFilterOpen = false
      let temp = {
        value: item,
        type: type
      }
      if (this.selectedItem.id !== item.id) this.$emit('updateFilter', temp)
      this.selectedItem = item
    },
    clearFilter() {
      this.selectedItem = {}
      this.$emit('clearFilter')
      this.isFilterOpen = false
    }
  }
}
</script>

<style scoped>
.filter {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.closed-filter {
  min-width: 200px;
  height: 30px;
  display: flex;
  padding: 5px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 5px;
}

.open-filter {
  top: 35px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  background-color: var(--card-background);
  z-index: 9;
  border: 1px solid var(--border);
  border-radius: 5px;
}

.filter-section {
  padding: 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter-options {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.filter-title {
  display: flex;
  justify-content: flex-start;
}

.filter-item {
  font-weight: 400;
  width: 100%;
  padding: 10px 10px;
  cursor: pointer;
  color: var(--text-primary);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
}

.filter-item:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
}

.filter-img {
  height: 15px;
  width: 15px;
}

.selected {
  background-color: var(--primary-blue);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  border: 1px solid var(--primary-blue);
}

.selected:hover {
  background-color: var(--primary-blue);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  border: 1px solid var(--primary-blue);
}

.clear-button {
  padding: 5px 0;
  width: 100%;
  color: var(--text-link);
  text-decoration: underline;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
</style>