<template>
  <div class="connect-exchange">
    <span class="step-title">2. Select your Exchange Account</span>
    <loading :active="loading" class="loading"/>
    <div v-if="!loading && !addNewExchange" class="connect-account">
      <div class="exchange-accounts" v-for="item in connectedExchanges" :key="item.connection_id" v-if="item.exchange === exchange" :class="{selected: selectedExchange.connection_id === item.connection_id}" @click="selectExchange(item)">
        <div class="exchange-accounts-data">
          <img class="logo" :src="loadExchangeIcon(item)" alt="">
          <span class="account-name" :title="item.connection_name">{{ item.connection_name.length > 30 ? item.connection_name.substr(0, 30) + '...' : item.connection_name }}</span>
        </div>
        <span v-if="selectedExchange.connection_id === item.connection_id" class="button-p button-shadow selected-item">&#10003;</span>
      </div>
    </div>
    <new-exchange v-if="addNewExchange" @closeNewExchange='toggleNewExchangeModal()'/>
    <div v-if="!loading && !addNewExchange" class="exchange-accounts new-account card" @click="toggleNewExchangeModal()">
      <span class="account-name">Add new account</span>
      <span class="button-p button-shadow button-exchange">+</span>
    </div>
    <span v-if="errorMsg" class="error">{{ errorMsg }}</span>
    <div class="next-step">
      <span class="form-button button-shadow previous-button" @click="previousStep()">Previous Step</span>
      <span v-show="selectedExchange.connection_id && !errorMsg && !loading && !addNewExchange" class="form-button button-shadow next-button" @click="nextStep()">Next Step</span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import dropdown from '@/components/trading/Dropdown.vue'
import Loading from '@/components/shared/Loading.vue'
import NewExchange from '@/components/shared/exchanges/NewExchange.vue'

export default {
  name: 'Modal',
  computed: mapGetters(['connectedExchanges']),
  components: {
    dropdown,
    Loading,
    NewExchange
  },
  props: {
    bot: Object,
    exchange: String
  },
  data() {
    return {
      errorMsg: '',
      successMsg: '',
      selectedExchange: {},
      minInvestment: 0,
      maxInvestment: 0,
      loading: false,
      addNewExchange: false,
      maxCapitalLimit: 0,
      maxCapitalLimitPct: 1
    }
  },
  mounted() {
    this.minInvestment = Number(this.bot.minimum_investment)
    this.setMaxCapital()
  },
  methods: {
    ...mapActions(['loadExchangeConnections']),
    previousStep() {
      this.$emit('previousStep')
    },
    // select new exchange and toggle new exchange form
    selectExchange(exchange) {
      this.selectedExchange = exchange
      this.errorMsg = ''
      this.successMsg = ''
      this.getConnectionBalance()
    },
    setMaxCapital() {
      switch (this.$session.get('subscription_plan').split('_')[0]) {
        case 'gold':
          this.maxCapitalLimit = 30000
          break;
        case 'silver':
          this.maxCapitalLimit = 10000
          break;
        case 'bronze':
          this.maxCapitalLimit = 2000
          break;
        default:
          break;
      }
    },
    loadExchangeIcon(item) {
      try {
        return require('@/assets/' + item.exchange.toLowerCase() + '-logo.png')
      } catch (e) {
      }
    },
    getConnectionBalance() {
      this.loading = true
      this.$api.get("/exchanges/get_balance", {
        params: {
          user_id: this.$session.get('uid'),
          bot_id: this.bot.bot_id,
          connection_id: this.selectedExchange.connection_id,
          action: 'connect'
        },
        headers: {
          'X-Authorization': this.$session.get('token')
        }
      })
          .then((res) => {
            if (res.status === 200 || res.status === 'success') {
              this.loading = false

              if(this.bot.exchanges[this.selectedExchange.exchange].minimum_investment > Number(res.data.data.available_balance)){
                this.errorMsg = 'This connection does not have enough balance'
                return
              }

              this.maxCapitalLimitPct = this.maxCapitalLimitPct - res.data.data.allocated_balance_pct
              this.minInvestment = this.bot.exchanges[this.selectedExchange.exchange].minimum_investment
              this.maxInvestment = Number(res.data.data.available_balance)
              this.market = res.data.data.asset
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response.data.error.type === 'INSUFFICIENT_BALANCE') {
              this.errorMsg = 'This connection does not have enough balance'
            }
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
            if (err.response.data.error.type === "EXCHANGE_ERROR") {
              this.errorMsg = err.response.data.error.body + ' Please try again later'
            }
          })
    },
    toggleNewExchangeModal() {
      this.errorMsg = ''
      this.addNewExchange = !this.addNewExchange
    },
    nextStep() {
      let temp = {
        connection: this.selectedExchange,
        min: this.minInvestment,
        market: this.market,
        max: this.maxInvestment,
        maxPct: this.maxCapitalLimitPct * 100
      }
      this.$emit('nextStep', temp)
    }
  }
}
</script>

<style scoped>
.connect-exchange {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}

.connect-account {
  width: 500px;
  padding: 0 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 230px;
  overflow-y: scroll;
}

.dropdown {
  margin: 10px;
  width: 300px;
}

.button-exchange {
  height: 30px;
  width: 30px;
  display: flex;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  background-color: var(--card-background);
  color: var(--text-primary);
  font-weight: bold;
}

.selected-item {
  float: right;
  height: 30px;
  width: 30px;
  display: flex;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  font-weight: bold;
  color: var(--text-secondary);
}

.next-button {
  background-color: var(--secondary-blue);
  padding: 10px 20px;
  width: auto;
  display: flex;
  justify-content: center;
}

.previous-button {
  background-color: var(--secondary-blue);
  width: auto;
  display: flex;
  justify-content: center;
}

.next-step {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.exchange-accounts {
  padding: 5px;
  width: 450px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 2px 5px 2px var(--shadow);
}

.exchange-accounts:hover {
  transform: scale(1.05);
}

.exchange-accounts-data {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-name {
  font-size: 20px;
  font-weight: 400;
}

.selected {
  border: 3px solid var(--green);
}

.new-account {
  color: var(--white);
  justify-content: space-between;
  background-color: var(--primary-blue);
  border: none;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 850px) {
  .connect-account {
    justify-content: flex-start;
    align-items: center;
  }

  .next-step {
    margin-top: 50px;
    justify-content: center;
  }

  .next-button {
    width: 100%;
  }

  .account-name {
    font-size: 16px;
  }

  .exchange-accounts {
    width: 95%;
    margin: 10px 0;
  }

  .connect-account {
    width: 99%;
  }
}
</style>
