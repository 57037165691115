<template>
  <div class="specific-graph">
    <canvas v-if="data.type === 0" id="return-graph" class="specific-graph-item" />
    <canvas v-if="data.type === 1" id="risk-graph" class="specific-graph-item" />
    <canvas v-if="data.type === 2" id="frequency-graph" class="specific-graph-item" />
    <span class="graph-title">{{ data.label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      Chart: null
    }
  },
  mounted() {
    this.Chart = require('chart.js')
    this.loadTextPlugin()
  },
  methods: {
    generateReturnGraph() {
      const ctx = document.getElementById('return-graph').getContext('2d')

      const gradient = ctx.createLinearGradient(0, 0, 0, 175)
      gradient.addColorStop(0.0, '#60D248')
      gradient.addColorStop(1.0, '#8ee579')

      const data = [
        {
          data: this.data.data,
          backgroundColor: [gradient, '#f2f2f2'],
          borderColor: '#f2f2f2'
        }
      ]

      const options = {
        tooltips: { enabled: false },
        hover: { mode: null },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom'
        },
        elements: {
          center: {
            text: `${this.data.data[0]}%`,
            color: '#60D248',
            fontStyle: 'Roboto Condensed',
            sidePadding: 20,
            minFontSize: 20,
            lineHeight: 25
          },
          arc: {
            borderWidth: 0
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      const myChart = new this.Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: data
        },
        options
      })
    },
    generateRiskGraph() {
      const ctx = document.getElementById('risk-graph').getContext('2d')

      const gradient = ctx.createLinearGradient(0, 0, 0, 175)
      gradient.addColorStop(0.0, '#F88E9B')
      gradient.addColorStop(1.0, '#fed5da')

      const data = [
        {
          data: this.data.data,
          backgroundColor: [gradient, '#f2f2f2'],
          borderColor: '#f2f2f2'
        }
      ]

      const options = {
        tooltips: { enabled: false },
        hover: { mode: null },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom'
        },
        elements: {
          center: {
            text: this.data.text,
            color: '#F88E9B',
            fontStyle: 'Roboto Condensed',
            sidePadding: 20,
            minFontSize: 20,
            lineHeight: 25
          },
          arc: {
            borderWidth: 0
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      const myChart = new this.Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: data
        },
        options
      })
    },
    generateFrequencyGraph() {
      const ctx = document.getElementById('frequency-graph').getContext('2d')

      const gradient = ctx.createLinearGradient(0, 0, 0, 175)
      gradient.addColorStop(0.0, '#1A81C0')
      gradient.addColorStop(1.0, '#76beeb')

      const data = [
        {
          data: this.data.data,
          backgroundColor: [gradient, '#f2f2f2'],
          borderColor: '#f2f2f2'
        }
      ]

      const options = {
        tooltips: { enabled: false },
        hover: { mode: null },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom'
        },
        elements: {
          center: {
            text: this.data.text,
            color: '#1A81C0',
            fontStyle: 'Roboto Condensed',
            sidePadding: 20,
            minFontSize: 20,
            lineHeight: 25,
            fontWeight: '700'
          },
          arc: {
            borderWidth: 0
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      const myChart = new this.Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: data
        },
        options
      })
    },
    loadTextPlugin() {
      this.Chart.pluginService.register({
        beforeDraw(chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx

            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center
            const fontStyle = centerConfig.fontStyle || 'Arial'
            const txt = centerConfig.text
            const color = centerConfig.color || '#000'
            const maxFontSize = centerConfig.maxFontSize || 75
            const sidePadding = centerConfig.sidePadding || 20
            const sidePaddingCalculated =
              (sidePadding / 100) * (chart.innerRadius * 2)
            // Start with a base font of 30px
            ctx.font = `30px ${fontStyle}`

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width
            const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

            // Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth
            const newFontSize = Math.floor(30 * widthRatio)
            const elementHeight = chart.innerRadius * 2

            // Pick a new font size so it will not be larger than the height of label.
            let fontSizeToUse = Math.min(
              newFontSize,
              elementHeight,
              maxFontSize
            )
            let minFontSize = 12
            const lineHeight = centerConfig.lineHeight || 25
            let wrapText = false

            if (minFontSize === undefined) {
              minFontSize = 20
            }

            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize
              wrapText = true
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
            let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
            ctx.font = `${fontSizeToUse}px ${fontStyle}`
            ctx.fillStyle = color

            if (!wrapText) {
              ctx.fillText(txt, centerX, centerY)
              return
            }

            const words = txt.split(' ')
            let line = ''
            const lines = []

            // Break words up into multiple lines if necessary
            for (let n = 0; n < words.length; n++) {
              const testLine = `${line + words[n]} `
              const metrics = ctx.measureText(testLine)
              const testWidth = metrics.width
              if (testWidth > elementWidth && n > 0) {
                lines.push(line)
                line = `${words[n]} `
              } else {
                line = testLine
              }
            }

            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight

            for (let m = 0; m < lines.length; n++) {
              ctx.fillText(lines[n], centerX, centerY)
              centerY += lineHeight
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.specific-graph {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.graph-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  color: var(--header-color);
  font-family: 'Roboto Condensed', sans-serif;
}
.specific-graph-item {
  height: 100% !important;
  width: 100% !important;
}
</style>
