P
<template>
  <div class="section-settings">
    <span class="title">Change Email</span>
    <span v-if="emailUpdated" class="success">Email succesfully updated</span>
    <span v-if="emailUpdatedError" class="error">{{ emailUpdatedError }}</span>
    <label class="contact-input">
      Current Email
      <input class="form-input" type="text" v-model="email" readonly />
    </label>
    <label class="contact-input">
      New Email
      <input class="form-input" :class="{ errorInput: wrongEmailFormat }" type="text" v-model="newEmail" />
    </label>
    <span v-if="wrongEmailFormat" class="error">Incorrect format</span>
    <label class="contact-input">
      Confirm New Email
      <input class="form-input" :class="{ errorInput: notMatchEmail }" type="text" v-model="confirmNewEmail" />
    </label>
    <span v-if="notMatchEmail" class="error">Emails do not match</span>
    <div class="confirmation-buttons">
      <span class="form-button button-shadow" @click="changeEmail()">SAVE</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeEmail',
  props: {
    email: String
  },
  data() {
    return {
      newEmail: '',
      confirmNewEmail: '',
      wrongEmailFormat: false,
      notMatchEmail: false,
      emailUpdated: false,
      emailUpdatedError: ''
    }
  },
  methods: {
    changeEmail() {
      this.notMatchEmail = false
      this.wrongEmailFormat = false
      if (this.checkEmail()) {
        if (this.newEmail === this.confirmNewEmail) {
          const body = {
            user_id: this.$session.get('uid'),
            email: this.newEmail
          }
          this.$api
            .post('/users/change_email', body, {
              headers: {
                'X-Authorization': this.$session.get('token')
              }
            })
            .then((res) => {
              if (res.status === 200) {
                this.email = this.newEmail
                this.emailUpdatedError = ''
                this.emailUpdated = true

                this.newEmail = ''
                this.confirmNewEmail = ''
                this.getUser()
              }
            })
            .catch((err) => {
              if (err.response.data.error.type === 'DUPLICATE_VALUE') {
                this.newEmail = ''
                this.confirmNewEmail = ''
                this.emailUpdatedError = 'This email already exists'
              }
            })
        } else {
          this.notMatchEmail = true
          this.wrongEmailFormat = false
        }
      } else {
        this.wrongEmailFormat = true
      }
    },
    getUser() {
      this.$api
        .get('/users/get_user', {
          params: {
            user_id: this.$session.get('uid')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.$session.set('username', res.data.data.username)
            this.$session.set('email', res.data.data.email)
            this.$session.set('timezone', res.data.data.timezone)
            this.$session.set('trial_period_activated', res.data.data.trial_period_activated)
            this.$session.set('trial_period_used', res.data.data.trial_period_used)
          }
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
    },
    checkEmail() {
      if (this.newEmail.length < 254) {
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return emailRegex.test(this.newEmail)
      }
      return false
    }
  }
}
</script>

<style scoped>
.section-settings {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 850px) {
  .section-settings {
    width: 100%;
  }
}
</style>
