<template>
  <div class="connect-exchange">
    <br />
    <span class="back-button" @click="goBack()">&larr; Go back</span>
    <br />
    <span class="referal-code">
      To start using our trading bots on Binance you need to integrate its API
      connection
    </span>
    <br />
    <span class="referal-code">
      If you dont have an account yet
      <a class="referal-code-link" href="https://accounts.binance.com/en/register?ref=68748484" target="_blank">
        click here!
      </a>
    </span>
    <br />
    <div class="exchange-form">
      <div class="form-item">
        <span class="form-title">Connection Name</span>
        <input class="form-input" type="text" v-model="exchangeName" />
      </div>
      <div class="form-item">
        <span class="form-title">Account API key</span>
        <input class="form-input" type="text" v-model="api" />
      </div>
      <div class="form-item">
        <span class="form-title">Account API secret</span>
        <input class="form-input" type="text" v-model="secret" />
      </div>
    </div>
    <div class="bottom-content">
      <div class="tips">
        <div class="bottom-items">
          <span class="tips-title" @click="toggleTips()">
            How to connect an exchange account?
          </span>
          <div class="confirmation-buttons ">
            <span class="form-button button-shadow exchange-b" @click="connectAccount()">
              Connect
            </span>
          </div>
        </div>
        <div v-if="tooltips" class="exchange-tips">
          <span class="tool-item">1. Open
            <a href="https://accounts.binance.com" target="_blank">https://accounts.binance.com</a></span>
          <span class="tool-item">2. Log in to your account</span>
          <span class="tool-item">3. Hover over your profile and click "API Management</span>
          <span class="tool-item">4. Give the API key a label at the top of the screen</span>
          <span class="tool-item">5. Click create</span>
          <span class="tool-item">6. Follow the authentication instructions</span>
          <span class="tool-item">7. Copy the API keys into the corresponding input fields
            above</span>
          <span class="tool-item">8. Now you are done</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  data() {
    return {
      tooltips: false,
      exchangeName: '',
      api: '',
      secret: ''
    }
  },
  methods: {
    ...mapActions(['addNewExchange']),
    // toggle tips on how to connect api
    toggleTips() {
      this.tooltips = !this.tooltips
    },
    // add new exchange account
    connectAccount() {
      const tempExchange = {
        connection_name: this.exchangeName,
        exchange: 'binance',
        api_key: this.api,
        api_secret: this.secret,
        user_id: this.$session.get('uid')
      }

      this.addNewExchange(tempExchange)
      this.$emit('closeForm')
    },
    // closenew exchange account form
    goBack() {
      this.$emit('closeForm')
    }
  }
}
</script>

<style scoped>
.connect-exchange {
  display: flex;
  flex-direction: column;
}

.exchange-form {
  display: flex;
  flex-direction: column;
}

.form-item {
  margin: 10px 0px;
  width: 100%;
}

.form-title {
  font-size: 20px;
}

.form-input {
  width: 95%;
}

.bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tips {
  display: flex;
  flex-direction: column;
  width: 95%;
}

.exchange-b {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchange-tips {
  display: flex;
  flex-direction: column;
}

.tips-title {
  width: 50%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: 18px;
  color: var(--blue-primary);
  text-decoration: underline;
}

.tool-item {
  margin: 10px 0;
}

.bottom-items {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.back-button {
  text-decoration: underline;
  cursor: pointer;
}

.referal-code {
  font-size: 18px;
  text-decoration: none;
}

.referal-code-link {
  color: var(--blue-primary);
  font-size: 18px;
  text-decoration: underline;
}

@media (max-width: 850px) {
  .bottom-items {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .exchange-b {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
