<template>
  <div class="subscription-selector card" :class="{selected: item === selectedItem}" @click="updateSelector(item)">
    <div class="top-side">
      <div class="selector-title">
        <span class="title subscription" :class="item.title.toLowerCase()">{{ item.title }}</span>
        <!-- <span v-if="!item.monthly && item.discount_price" class="sale">20% Summer SALE</span> -->
        <span class="price subscription" :class="item.title.toLowerCase()">
          <!-- <span v-if="item.discount_price"><span class="old-price">€{{ item.base_price }}</span> €{{ (item.discount_price / 6).toFixed(0) }}.00/mo</span> -->
          <span>€{{ item.base_price }}/mo</span>
        </span>
        <span v-if='!item.monthly' class="subtext" :class="{selectedSubtext: item === selectedItem}">*Paid every 6 months</span>
      </div>
      <div class=" icon">
        <img class="sub-medal" :src="loadMedal(item.title)" alt />
      </div>
    </div>
    <div class="benefits" v-for="benefit in item.benefits" :key="benefit">
      <span class="item-benefit" :class="{selectedBenefit: item === selectedItem}">
        <span class="checkmark">✓</span>
        {{ benefit }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    selectedItem: Object
  },
  data() {
    return {
      selected: this.isSelected,
    }
  },
  watch: {
    isSelected() {
      this.selected = this.isSelected
    },
  },
  methods: {
    // update selector to display as selected
    loadMedal(path) {
      return require('@/assets/' + path.toLowerCase() + '.png')
    },
    updateSelector(item) {
      this.$emit('updateSelector', item)
    },
  }
}
</script>

<style scoped>
.subscription-selector {
  min-height: 500px;
  width: 330px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: var(--background);
  transition: transform 0.2s;
}

.subscription-selector:hover {
  transform: scale(1.05);
}

.top-side {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selector-title {
  display: flex;
  flex-direction: column;
  color: var(--header-color);
}

.subscription {
  font-weight: bold;
  color: #fff;
}

.selector-information {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.price {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}

.selected {
  background: var(--header-color);
}

.icon {
  display: flex;
  justify-content: flex-end;
}

.subtext {
  color: var(--text-primary);
  margin-bottom: 20px;
}

.selectedSubtext {
  color: white;
}

.benefits {
  display: flex;
  flex-direction: column-reverse;
  font-size: 18px;
}

.item-benefit {
  font-weight: 300;
  margin: 5px 0;
}

.selectedBenefit {
  color: #ffff;
}

.sub-medal {
  height: 100px;
  width: 100px;
}

.bronze {
  background: linear-gradient(90deg, #7d4929 0%, #c7a085 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.silver {
  background: linear-gradient(90deg, #636362 0%, #ababab 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gold {
  background: linear-gradient(90deg, #c4a560 0%, #dec37a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.checkmark {
  color: var(--green);
}

.cross {
  color: var(--red);
}

.not-includes {
  color: gray;
}

.old-price {
  text-decoration: line-through var(--red);
  -webkit-text-decoration: line-through var(--red);
}

.sale {
  padding: 2px 5px;
  border-radius: 5px;
  background-color: var(--red);
  font-weight: 500;
  color: var(--white);
}

@media (max-width: 1250px) {
  .subscription-selector {
    width: 300px;
  }

  .benefits {
    font-size: 14px;
  }
}

@media (max-width: 1036px) {
  .subscription-selector {
    /*width: 300px;*/
  }

  .benefits {
    font-size: 18px;
  }
}
</style>
