import Vue from 'vue'
const state = {
  exchanges: [],
  selectedExchange: {}
}

const getters = {
  connectedExchanges: state => state.exchanges,
  selectedConnection: state => state.selectedExchange
}

const actions = {
  setSelectedConnection({ commit }, connection) {
    commit('setExchange', connection)
  },
  loadExchangeConnections({ commit }) {
    Vue.prototype.$api
      .get('/exchanges/get_connections', {
        params: {
          user_id: Vue.prototype.$session.get('uid')
        },
        headers: {
          'X-Authorization': Vue.prototype.$session.get('token')
        }
      })
      .then(res => {
        if (res.status === 200) {
          if (res.data.data) {
            commit('loadUserConnections', res.data.data)
            if (!Vue.prototype.$session.get('selected_connection')) {
              commit('setExchange', res.data.data[0])
            }
          }
        }
      })
      .catch(err => {
        if (
          err.response &&
          err.response.data.error.type === 'Could not validate credentials'
        ) {
          this.$session.destroy()
          this.$router.push({ name: 'Login' })
        }
      })
  }
}

const mutations = {
  setExchange(state, connection) {
    Vue.prototype.$session.set('selected_connection', connection)
    state.selectedExchange = connection
  },
  loadUserConnections(state, items) {
    Vue.prototype.$session.set('user_connections', items)
    state.exchanges = items
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
