<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header">
                <span class="modal-title">
                  <span>Are you sure you want to resume your subscription?</span>
                </span>
                <loading :active="isLoading" />
                <div v-if="!isLoading" class="subtext">
                  <span>You subscription
                    <span v-if="expiry==='9999-12-30'">is recurring. </span>
                    <span v-else>will end on {{ expiry }}. </span>
                    Do you want to resume your subscription?
                  </span>
                </div>
              </div>
              <div v-if="!isLoading" class="buttons">
                <span class="form-button  button-shadow " @click="resumeSubscription()">Resume subscription</span>
                <span class="to-cart" @click="closeModal()">cancel</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from '../Loading.vue'
export default
  {
    components: { Loading },
    name: 'Modal',
    props: {
      subscriptionId: String,
      expiry: String,
    },
    data() {
      return {
        cancelType: true,
        isLoading: false
      }
    },
    methods: {
      ...mapActions(['addNotification']),
      resumeSubscription() {
        const notification = {
          status: 0,
          title: 'Successfully Resumed',
          text: 'Your subscription was successfully resumed'
        }
        this.addNotification(notification)
        this.$emit('resume')
        this.closeModal()
      },
      closeModal() {
        this.$emit('click')
      },
    }
  }
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 10px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  color: var(--header-color);
  font-size: 34px;
  font-weight: bold;
}

.subtext {
  margin-top: 20px;
  color: var(--header-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}

.modal-container {
  width: 1300px;
  max-height: 95vh;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 20px;
  overflow: auto;
}

.modal-close {
  font-size: 50px;
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -10px;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.to-cart {
  color: var(--blue-primary);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.cancel-bot-button {
  background-color: #eb7c8b;
}

.note {
  color: var(--header-color);
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.cancel-bot-button:hover {
  background-color: #d56f7d;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.res {
  background-color: #64ba62;
}

.res:hover {
  background-color: #64ba62;
}

.cancel-options {
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cancel-radio {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancel-radio-radio {
  margin-right: 5px;
  cursor: pointer;
}

.cancel-radio-text {
  font-size: 18px;
  cursor: pointer;
}
@media (max-width: 1250px) {
  .modal-container {
    padding: 20px 10px;
    width: 95vw;
    height: auto;
  }
}
@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: auto;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }
  .buttons {
    flex-direction: column;
  }
  .to-cart {
    margin-top: 20px;
  }
}
</style>
