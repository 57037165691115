<template>
  <div>
    <div v-if="!isLoading" class="trading">
      <notification v-if="activeNotification" class="notification" />
      <div class="navigation-bar">
        <side-bar></side-bar>
      </div>
      <div class="side-bar"></div>
      <div class="content">
        <top-bar class="top-bar"></top-bar>
        <router-view class="view"></router-view>
      </div>
    </div>
    <div v-else class="loading">
      <loading :active="isLoading" />
      <span>Your payment is being processed, please be patient. This may take some time.</span>
      <span>Please dont close this window</span>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/trading/SideBar.vue'
import TopBar from '@/components/trading/TopBar.vue'
import Loading from '@/components/shared/Loading.vue'
import Notification from "@/components/shared/Notification";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CopyTrading',
  computed: mapGetters(['notification']),
  components: {
    SideBar,
    TopBar,
    Loading,
    Notification
  },
  data() {
    return {
      isLoading: false,
      activeNotification: false,
      interval: null,
      checkingStatus: null,
    }
  },
  watch: {
    notification() {
      if (!this.notification.title) {
        this.activeNotification = false
        return
      }
      this.activeNotification = true
    }
  },
  created() {
    if (this.$route.query.payment === 'success') {
      this.isLoading = true
      const vm = this
      this.interval = setInterval(function () {
        vm.getSubscriptionInfo()
      }, 5000);
      this.checkingStatus = setTimeout(this.paypalDelay, 1200000)
    }
    this.getConnections()
  },
  methods: {
    ...mapActions(['loadExchangeConnections']),
    getConnections() {
      this.loadExchangeConnections()
    },
    paypalDelay() {
      this.isLoading = false
      this.$router.push({ name: 'Dashboard', query: { payment: 'failed' } })
    },
    getSubscriptionInfo() {
      this.$api
        .get('/subscriptions/get_subscription_info', {
          params: {
            user_id: this.$session.get('uid'),
            timezone: this.$session.get('timezone')
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            if (this.$route.query.payment === 'success' && res.data.data.active_subscription === true) {
              clearInterval(this.interval)
              clearTimeout(this.checkingStatus)
              this.isLoading = false
              return
            }
            if (this.$route.query.status === 'cancelled' && res.data.data.active_subscription === false) {
              clearInterval(this.interval)
              clearTimeout(this.checkingStatus)
              this.isLoading = false
              return
            }
          }
        })
        .catch((err) => {
        })
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.important-message {
  padding: 5px;
  top: 0;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: var(--red);
  color: var(--text-primary);
  font-weight: bold;
  width: 100%;
  font-size: 18px;
}

.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trading {
  margin: 0 !important;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.navigation-bar {
  width: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.top-bar {
  width: 100%;
  padding: 5px 20px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  overflow-x: hidden;
}

.view {
  padding: 20px;
}

.button-help {
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  padding: 5px;
  margin: 5px;
  background-color: var(--primary-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
}

.notification {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (max-width: 850px) {
  .navigation-bar {
    display: none;
  }

  .view {
    padding: 5px 5px;
  }

  .top-bar {
    padding: 5px;
  }

  .notification {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    margin-right: 0;
  }
}
</style>
