<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div v-if="!downgrade" class="modal-container">
        <span class="modal-close" @click="closeModal()">&#10799;</span>
        <div class="modal-title">Modify your subscription</div>
        <br/>
        <loading class="loading" :active="loading"/>
        <div v-if="!payment && !loading" class="modal-content">
          <subscription-plan @selectedSubscription="setSelectedSubscription($event)"/>
          <span v-if="errMsg" class="error">{{ errMsg }}</span>
          <span class="info">* If you upgrade to a higher tier than you current subscription you will have to pay the difference in price based on remaining subscription time as a one time payment</span>
          <div class="subscription-buttons">
            <span class="button-p button-shadow change-b" :class="{disabled:  newSubscription.new_subscription_plan === ''}" @click="updateSubscription()">Update Subscription</span>
          </div>
        </div>
        <modify-subscription-payment-form v-if="payment" :surchargeInfo="surchargeInfo" :newSubscription="newSubscription" :newPrice="newMonthlyPrice" :monthly="monthly" @paymentComplete="completeUpdate()"/>
      </div>
      <div v-if="downgrade" class="modal-container">
        <span class="modal-close" @click="closeModal()">&#10799;</span>
        <div class="modal-title">Downgrade your subscription?</div>
        <loading class="loading" :active="loading"/>
        <br>
        <span v-if="!loading" class="modal-text">Are you sure you want to downgrade to a <span style="font-weight:bold">{{ selectedSubscription.title }}</span> plan? You will lose all the benefits from your original subscription.</span>
        <div class="subscription-buttons">
          <span class="button-p button-shadow change-b" @click="downgradeSubscription()">Downgrade Subscription</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading.vue'
import SubscriptionPlan from '../../trading/subscribeBot/SubscriptionPlan.vue'
import ModifySubscriptionPaymentForm from '../ModifySubscriptionPaymentForm.vue'
import {mapActions} from 'vuex'


export default {
  name: 'UpdateSubscription',
  props: {
    subscriptionInfo: Object,
    subscriptionId: String
  },
  components: {
    Loading,
    SubscriptionPlan,
    ModifySubscriptionPaymentForm
  },
  data() {
    return {
      loading: false,
      errMsg: '',
      newSubscription: {
        user_id: this.$session.get('uid'),
        subscription_id: '',
        new_subscription_plan: ''
      },
      selectedSubscription: {},
      payment: false,
      downgrade: false,
      monthlySub: 0,
      newMonthlyPrice: 0,
      monthly: false,
      surchargeInfo: {}
    }
  },
  mounted() {
    this.$session.get('subscription_plan').split('_')[1] ? this.monthlySub = 2 : this.monthlySub = 1
    this.newSubscription.subscription_id = this.subscriptionId
  },
  methods: {
    ...mapActions(['addNotification']),
    closeModal() {
      this.$emit('click')
    },
    setSelectedSubscription(subscription) {
      this.monthly = subscription.monthly
      subscription.discount_price ? this.newMonthlyPrice = subscription.discount_price.toFixed(2) : this.newMonthlyPrice = subscription.per_month + ".00"
      this.selectedSubscription = subscription
      this.newSubscription.new_subscription_plan = subscription.value
    },
    completeUpdate() {
      this.$emit('paymentComplete', 1)
      this.closeModal()
    },
    updateSubscription() {
      this.loading = true
      let apiUrl = '/subscriptions/stripe/get_upgrade_price'

      if (this.subscriptionInfo.payment_platform === 'paypal') apiUrl = '/subscriptions/paypal/get_upgrade_price'
      this.$api
          .get(apiUrl, {
            params: {
              user_id: this.$session.get('uid'),
              subscription_id: this.subscriptionId,
              new_subscription_plan: this.newSubscription.new_subscription_plan
            },
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.loading = false
              this.surchargeInfo = res.data.data
              if (this.surchargeInfo.new_price === 0) {
                this.downgrade = true
                return
              }
              this.payment = true
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
              this.errorMsg = 'You cannot allocate more than 2000 USDT accross all bots'
            }
          })
    },
    downgradeSubscription() {
      this.loading = true
      this.errorMsg = ''
      let apiUrl = '/subscriptions/stripe/modify_subscription'

      if (this.subscriptionInfo.payment_platform === 'paypal') apiUrl = '/subscriptions/paypal/modify_subscription'
      this.$api
          .post(apiUrl, this.newSubscription, {
            headers: {
              'X-Authorization': this.$session.get('token')
            }
          })
          .then(async (res) => {
            if (res.status === 200) {
              this.loading = false

              const notification = {
                status: 0,
                title: 'Successful downgrade',
                text: 'Your subscription was successfully downgraded'
              }
              this.addNotification(notification)

              this.$emit('paymentComplete')
              this.closeModal()
            }
          })
          .catch((err) => {
            this.loadingPayment = false
            if (err.response && err.response.data.error.type === 'EXCHANGE_ERROR') {
              this.errorMsg = 'You cannot allocate more than 2000 USDT accross all bots'
            }
          })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 10px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
}

.modal-container {
  width: 1200px;
  max-height: 95vh;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--card-background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-close {
  font-size: 40px;
  margin-top: -30px;
  float: right;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
  font-size: 18px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.subscription-buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.subscription-buttons-downgrade {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.change-b {
  margin: 0px 10px;
}

.info {
  font-size: 14px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-text {
  margin-top: 20px;
  font-size: 18px;
}

.disabled {
  background-color: gray;
  cursor: not-allowed;
  pointer-events: none;
}

@media (max-width: 1250px) {
  .modal-container {
    padding: 20px 10px;
    width: 95vw;
    height: auto;
  }
}

@media (max-width: 850px) {
  .modal-container {
    padding: 20px 15px;
    width: 95%;
    height: 600px;
  }

  .modal-content {
    width: 100%;
  }

  .content {
    padding: 0;
  }
}
</style>
