<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <span class="modal-close" @click="closeModal()">&#10799;</span>
        <div class="modal-title">Modify your '{{ subscribedBot.bot_name }}' connection</div>
        <br/>
        <loading class="loading" :active="loading"/>
        <div v-if="!loading" class="modal-content">
          <div>
            <div v-if="!showBotOverview && subscribedBot.exchange === 'ftx' && this.$session.get('subscription_plan') !== 'bronze'">
              <div class="enable-shorts">
                <span>Shorts:</span>
                <shorts-toggle-switch :switch-status-value="updatedBot.enable_shorts" @toggle="toggleShorts($event)"/>
              </div>
              <span v-if="errMsg" class="error">{{ errMsg }}</span>
            </div>
            <div v-if="this.subscribedBot.enable_shorts !== null && !showBotOverview " class="update-allocation">
              <input class="change-keys-checkbox" id="updateAllocation" type="checkbox" v-model="updateAllocation">
              <label class="change-keys-label" for="updateAllocation">Update allocated amount</label>
            </div>
            <div v-if="this.subscribedBot.enable_shorts !== null && !showBotOverview && !updateAllocation" class="next-step only-shorts">
              <span class="form-button button-shadow next-button" @click="nextStep()">Next Step</span>
            </div>
            <div v-if="!showBotOverview && updateAllocation">
              <span v-if="this.subscribedBot.enable_shorts === null" class="allocate-amount">Update allocated amount:</span>
              <allocate-amount
                  v-if="!errMsg"
                  :isUpdate="true"
                  :type="subscribedBot.balance_allocation_method"
                  :exchange="subscribedBot.exchange"
                  :amount="Number(subscribedBot.allocated_balance)"
                  :market="subscribedBot.market"
                  :min="minInvestment"
                  :max="maxInvestment"
                  :maxPct="maxCapitalLimitPct"
                  @nextStep="updateAmountAllocated($event)"/>
              <span v-if="errMsg" class="error">{{ errMsg }}</span>
            </div>
            <div v-if="showBotOverview" class="proceed-buttons">
              <bot-overview :isUpdate="true" :bot="subscribedBot" :connectionInfo="updatedBot" :connectionName="selectedConnection" :exchange="subscribedBot.exchange"/>
              <div class="add-to-cart">
                <span v-if="!errMsg" class="form-button button-shadow" @click="updateBot()">Save</span>
                <span class="form-button button-shadow red-bg" @click="closeModal()">Cancel</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AllocateAmount from '@/components/trading/subscribeBot/AllocateAmount.vue'
import Loading from '@/components/shared/Loading.vue'
import BotOverview from '@/components/trading/BotOverview.vue'
import ShortsToggleSwitch from "@/components/shared/ShortsToggleSwitch";

export default {
  name: 'UpdateSubscription',
  components: {
    AllocateAmount,
    Loading,
    BotOverview,
    ShortsToggleSwitch
  },
  props: {
    subscribedBot: Object,
    selectedConnection: String,
  },
  data() {
    return {
      showBotOverview: false,
      payment: false,
      loading: false,
      errMsg: '',
      minInvestment: Number(this.subscribedBot.minimum_investment),
      maxInvestment: 0,
      allocated: 0,
      updatedBot: {
        user_id: this.$session.get('uid'),
        connection_id: this.subscribedBot.connection_id,
        bot_id: this.subscribedBot.bot_id,
        enable_shorts: this.subscribedBot.enable_shorts
      },
      maxCapitalLimit: 0,
      maxCapitalLimitPct: 1,
      updateAllocation: false
    }
  },
  mounted() {
    if (this.subscribedBot.enable_shorts === null) this.updateAllocation = true
    this.allocated = Number(this.subscribedBot.allocated_balance)
    this.setMaxCapital()
    this.getConnectionBalance()
  },
  methods: {
    closeModal() {
      this.$emit('click')
      this.payment = false
    },
    toggleShorts(type) {
      switch (type) {
        case 0:
          this.updatedBot.enable_shorts = false
          break
        case 1:
          this.updatedBot.enable_shorts = true
          break
      }
    },
    setMaxCapital() {
      switch (this.$session.get('subscription_plan').split('_')[0]) {
        case 'gold':
          this.maxCapitalLimit = 30000
          break;
        case 'silver':
          this.maxCapitalLimit = 10000
          break;
        case 'bronze':
          this.maxCapitalLimit = 2000
          break;
        default:
          break;
      }
    },
    updateAmountAllocated(allocation) {
      this.updatedBot.balance_allocation_method = allocation.allocation_type
      switch (allocation.allocation_type) {
        case 'pct':
          if (this.updatedBot.allocated_balance_qty) delete this.updatedBot.allocated_balance_qty
          this.updatedBot.allocated_balance_pct = allocation.amount
          break;
        case 'qty':
          if (this.updatedBot.allocated_balance_pct) delete this.updatedBot.allocated_balance_pct
          this.updatedBot.allocated_balance_qty = allocation.amount
          break;
      }
      this.showBotOverview = true
    },
    nextStep() {
      this.showBotOverview = true
    },
    getConnectionBalance() {
      this.errMsg = ''
      this.loading = true
      this.$api.get("/exchanges/get_balance", {
        params: {
          user_id: this.$session.get('uid'),
          asset: this.subscribedBot.exchange,
          bot_id: this.subscribedBot.bot_id,
          connection_id: this.selectedConnection,
          action: 'modify'
        },
        headers: {
          'X-Authorization': this.$session.get('token')
        }
      })
          .then((res) => {
            if (res.status === 200) {
              this.loading = false
              // this.maxInvestment = Number(res.data.data.available_balance).toFixed()
              this.maxInvestment = 100000
              this.market = res.data.data.asset

              if (this.maxInvestment < this.minInvestment) {
                this.errorMsg = 'This connection does not have enough balance for the minimum amount required'
                return
              }
            }
          })
          .catch((err) => {
            this.loading = false
            if (err.response.data.error.type === 'INSUFFICIENT_BALANCE') {
              this.errMsg = 'This connection does not have enough balance'
            }
            if (err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    },
    updateBot() {
      this.$emit('updateBot', this.updatedBot)
      this.closeModal()
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 10px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
}

.modal-container {
  width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--card-background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-close {
  font-size: 40px;
  margin-top: -22px;
  margin-right: -10px;
  float: right;
  cursor: pointer;
}

.modal-content {
  margin-top: 10px;
  font-size: 18px;
}

.modal-content-text {
  line-height: 200%;
  display: block; /* inline-block; */
  text-align: justify;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.allocate-amount {
  display: flex;
  flex-direction: column;
}

.proceed-button {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.add-to-cart {
  margin-top: 30px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.total-price {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}

::v-deep .v-application--wrap {
  min-height: 1vh !important;
}

.modal-item {
  display: flex;
}

.red-bg {
  background-color: #d56f7d;
}

.country-selector {
  width: 100%;
  cursor: pointer;
}

.payment-b {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  text-align: center;
  font-weight: bold;
}

.payment-b-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  background: #ccd0d2;
  cursor: default;
}

.card-element {
  height: 50%;
}

.payment-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-separator {
  margin: 10px 0;
}

.paypal-checkout {
  z-index: 0;
}

.street {
  width: 50%;
}

.post-code {
  width: 20%;
  margin: 0 5px;
}

.region {
  width: 30%;
}

.user-form-input {
  width: 50%;
}

.first-name {
  margin-right: 5px;
}

.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #ccd0d2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  color: #fa755a;
}

.payment-methods {
  display: flex;
  flex-direction: row;
}

.payment-types {
  height: 100px;
  padding: 10px 0;
  margin-right: 20px;
}

.payment-types-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
  background-color: #fff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
}

.payment-types-item-image {
  height: 80%;
}

.selectedMethod {
  border: 4px solid #63ba62;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.05);
}

.disabled {
  background-color: var(--inactive-header);
  pointer-events: none;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.extend-duration-content {
  display: flex;
  flex-direction: column;
}

.extend-duration-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.extend-duration-checkbox-item {
  cursor: pointer;
}

.extend-duration-checkbox-label {
  margin-left: 10px;
  cursor: pointer;
}

.duration-item {
  padding: 10px 25px;
  margin: 0 10px;
  font-weight: bold;
  background-color: var(--header-color);
  color: var(--white);
}

.extend-duration-options {
  margin-top: 30px;
}

.duration-item {
  cursor: pointer;
}

.selectedDuration {
  background-color: var(--green);
  color: var(--white);
}

.custom-duration {
  margin: 0 5px;
  width: 100px;
}

.custom-input {
  margin-top: 20px;
}

.enable-shorts {
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.enable-shorts-label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.update-allocation {
  display: flex;
  align-items: center;
  gap: 10px;
}

.only-shorts {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1250px) {
  .modal-container {
    padding: 20px 10px;
    width: 95vw;
    height: auto;
  }
}

@media (max-width: 850px) {
  .modal-container {
    padding: 20px 15px;
    width: 95%;
    height: 600px;
  }

  .modal-content {
    width: 100%;
  }

  .content {
    padding: 0;
  }

  .proceed-button {
    justify-content: center;
    margin-bottom: 40px;
  }

  .total-price {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
