<template>
  <div class="bot-item card">
    <div class="item-info">
      <div class="bot-info">
        <img class="bot-icon-src" src="@/assets/bots/bot_template.png" alt="">
        <span class="item-title"> {{ bot.bot_name }} </span>
      </div>
      <div class="item-subscription-detail">
        <div v-if="!isUpdate" class="item-detail">
          <img class="exchange-logo" :src="getConnectionIcon(connectionInfo.exchange)" alt="" />
          <span class="item-data-text item-data-text-data">{{ connectionName }}</span>
        </div>
        <div class="item-detail">
          <span v-if="!isUpdate && allocatedInfo" class="item-data-text">
            <span class="item-data-text-title">Allocated Amount: </span>
            <span class="item-data-text-data">{{ allocatedInfo }}</span>
          </span>
          <span v-if="isUpdate && allocatedInfo" class="item-data-text">
            <span class="item-data-text-title">New Allocated Amount: </span>
            <span class="item-data-text-data">{{ allocatedInfo }}</span>
          </span>
          <span v-if="connectionInfo.enable_shorts && bot.shorts_enabled">
            <span class="item-data-text-title">Shorts: </span>
            <span class="item-data-text-data">Enabled</span>
          </span>
          <span v-if="connectionInfo.enable_shorts !== null && !connectionInfo.enable_shorts && connectionInfo.exchange ==='ftx'  && bot.shorts_enabled">
            <span class="item-data-text-title">Shorts: </span>
            <span class="item-data-text-data">Disabled</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bot: Object,
    connectionInfo: Object,
    connectionName: String,
    isUpdate: {
      type: Boolean,
      default: false
    },
    exchange: String
  },
  data() {
    return {
      allocatedInfo: '',
      asset: ''
    }
  },
  mounted() {
    this.getConnectionAsset()
    if (this.connectionInfo.allocated_balance_pct || this.connectionInfo.allocated_balance_qty) {
      switch (this.connectionInfo.balance_allocation_method) {
        case 'pct':
          this.allocatedInfo = (this.connectionInfo.allocated_balance_pct * 100).toFixed(0) + '% of wallet'
          break;
        case 'qty':
          this.allocatedInfo = this.connectionInfo.allocated_balance_qty + this.asset
          break;
      }
    }

  },
  methods: {
    getConnectionIcon(exchange) {
      try {
        return require('@/assets/' + exchange.toLowerCase() + '-logo.png')
      } catch (e) {
      }
    },
    getConnectionAsset() {
      switch (this.exchange) {
        case 'binance':
          if (this.bot.market === 'BTC') {
            this.asset = ' BTC'
          }
          else {
            this.asset = ' USDT'
          }
          break;
        case 'bitvavo':
          this.asset = ' EUR'
          break;
        case 'ftx':
          this.asset = ' USD'
          break;
      }
    },
    loadBadge(item) {
      const path = `assets/${item}.png`
      return require(`@/${path}`)
    },
  }
}
</script>

<style scoped>
.bot-item {
  width: 100%;
  padding: 20px;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark);
  color: var(--white);
  border-radius: 5px;
  cursor: default;
}

.item-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bot-info {
  display: flex;
  align-items: center;
}

.bot-icon-src {
  margin-right: 10px;
  width: 50px;
  border-radius: 50%;
}

.item-title {
  font-size: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-subscription-detail {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.exchange-logo {
  height: 30px;
  width: 30px;
}

.item-detail {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  gap: 10px;
}

.item-data-text-data {
  font-style: italic;
}

.item-duration {
  font-size: 16px;
}

.item-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}

.item-data-text-title {
  font-weight: bold;
}

@media (max-width: 850px) {
  .cart-item {
    min-height: 70px;
    padding: 10px;
    margin: 10px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .item-title {
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .item-duration {
    font-size: 16px;
  }

  .item-data-text {
    font-size: 14px;
  }

  .item-img {
    height: 25px;
  }

  .item-subscription-detail {
    justify-content: center;
    align-items: center;
  }
}
</style>
