<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <span class="modal-title">Are you sure you want to clear this error message?</span>
              <div class="modal-header-date">
                <div v-if="!loading" class="modal-header">
                  <br>
                  <span class="subtext">By clearing this message, it is understood that you are aware of the current error.</span>
                </div>
                <loading class="loading" :active="loading"/>
                <div v-if="!loading" class="buttons">
                  <span class="button-p button-shadow" @click="acknowledgeError()">Clear</span>
                  <span class="to-cart" @click="closeModal()">Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/shared/Loading'

export default {
  name: 'Modal',
  components: {
    Loading
  },
  props: {
    error: Object,
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    acknowledgeError() {
      this.loading = true
      let temp = {
        user_id: this.$session.get('uid'),
        connection_id: this.error.connection_id,
        bot_id: this.error.bot_id,
        error_name: this.error.error_message
      }

      this.$api.post('/bots/acknowledge_error', temp, {
        headers: {
          'X-Authorization': this.$session.get('token')
        }
      })
          .then((res) => {
            if (res.status === 200) {
              this.loading = true
              this.$emit('click', 1)
            }
          })
          .catch((err) => {
            error.log(err)
          })
    },
    closeModal() {
      this.$emit('click')
    },

  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 20px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  font-size: 28px;
  font-weight: bold;
}

.subtext {
  font-size: 24px;
}

.modal-container {
  width: 50%;
  margin: 0 auto;
  padding: 10px;
  background-color: var(--background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.modal-close {
  font-size: 30px;
  float: right;
  cursor: pointer;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  align-items: center;
}

.to-cart {
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: 300px;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }
}
</style>
