<template>
  <div v-click-outside="outside" class="filter" :class="{ exchangeFilter: type === TYPE_EXCHANGE }">
    <div id="filter" class="closed-filter" @click="toggleFilter()" :class="{ selected: selected }">
      <span v-if="!this.$session.get('selected_connection')" class="filter-name">{{ name }}</span>
      <div v-else class="filter-name" :title="this.$session.get('selected_connection').connection_name">
        <img v-if="this.$session.get('selected_connection')" class="exchange-icon" src="@/assets/binance-logo.png" alt />
        <span v-if="this.$session.get('selected_connection')">{{  this.$session.get('selected_connection').connection_name.length > 20 ? this.$session.get('selected_connection').connection_name.substr(0,20) + '...' : this.$session.get('selected_connection').connection_name}} </span>
      </div>
      <arrow-head-down v-if="this.$session.get('user_connections') && this.$session.get('user_connections').length > 1" />
    </div>
    <div id="openFilter" v-show="(isOpen && this.$session.get('user_connections').length > 1)" class="open-filter card" data-aos="fade-bottom">
      <div v-for="item in this.$session.get('user_connections')" :key="item.title">
        <div v-show="item.connection_id !== $session.get('selected_connection').connection_id" class="filter-item single-select" @click="selectItem(item)">
          <div class="exchange-dropdown-options" v-if="type === TYPE_EXCHANGE">
            <img class="exchange-icon" src="@/assets/binance-logo.png" alt />
            <span class="filter-item-title">{{ item.connection_name }}</span>
          </div>
          <span v-else class="filter-item-title">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ArrowHeadDown from '../shared/ArrowHeadDown.vue'

export default {
  components: { ArrowHeadDown },
  computed: mapGetters(['connectedExchanges', 'selectedConnection']),
  props: {
    name: String,
    // type -> 0 = filters, 1 = Datetime, 2 = exchange, 3 = new bot exchange
    type: Number,
    selectedExchange: Object,
    selected: Boolean,
    isNewBot: Boolean,
  },
  data() {
    return {
      TYPE_EXCHANGE: 2,
      TYPE_EXCHANGE_NEW_BOT: 3,
      isOpen: false,
      selectedItem: {},
      isNew: this.isNewBot
    }
  },
  watch: {
    connectedExchanges() {
      if (this.connectedExchanges.length > 0) {
        this.selectedItem = this.connectedExchanges
      }
    }
  },
  methods: {
    ...mapActions(['setSelectedConnection']),
    outside() {
      this.isOpen = false
    },
    toggleFilter() {
      this.isOpen = !this.isOpen
    },
    // select item from dropdown
    selectItem(item) {
      this.setSelectedConnection(item)
      this.isOpen = !this.isOpen
      this.selectedItem = item
      this.isOpen = false
      this.$emit('selectConnection')
    },
    selectExchangeForNewBot(item) {
      this.selectedItem = item
      this.isOpen = false
      this.$emit('selectExchange', item)
      this.addNewExchangeToProcess(item)
    }
  }
}
</script>

<style scoped>
.filter {
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--header-color);
  z-index: 1;
}

.exchangeFilter {
  min-width: 220px;
}

.closed-filter {
  height: 30px;
  padding: 2px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  border: 1px solid var(--header-color);
  border-radius: 5px;
}

.selected {
  border: 2px solid #63ba62;
}

.closed-filter:hover {
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

.filter-name {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.open-filter {
  min-width: 100%;
  max-height: 150px;
  left: 0;
  display: block;
  position: absolute;
  margin-top: 5px;
  background-color: #fff;
  padding: 2px 2px;
  z-index: 9999;
  border: 1px solid var(--header-color);
  border-radius: 5px;
  overflow: auto;
}

.filter-item {
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.single-select {
  border-radius: 5px;
  cursor: pointer;
}

.single-select:hover {
  background-color: #e8f6ff;
}

.filter-item-title {
  margin-left: 5px;
  text-align: left;
  cursor: pointer;
}

.filter-clear {
  font-size: 14px;
  text-decoration: underline;
  margin: 10px 0;
  color: var(--blue-primary);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.exchange-icon {
  height: 25px;
  margin-right: 5px;
  margin-top: 1px;
}

.exchange-dropdown-options {
  display: flex;
  align-items: center;
}

@media (max-width: 850px) {
  .filter {
    margin: 5px;
  }
}
</style>
