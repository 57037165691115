<template>
  <div class="statistics">
    <h1 class="title">Insights</h1>
    <div class="general-overview">
      <div v-for="item in userStats" :key="item.title" class="general-overview-item">
        <stats-overview :activeBots="true" :overviewItem="item"/>
      </div>
    </div>

    <div class="statistics-content">
      <stats-card
          class="medium"
          :title="'Total Returns'"
          :chartData="lineChartData"
          :chartType="3"
      />
      <stats-card class="small" :title="'Investments'" :bots="userSubscribedBots"/>
      <stats-card
          class="medium"
          :title="'Monthly Returns'"
          :chartData="barChartData"
          :chartType="2"
      />
      <stats-card
          class="small"
          :title="'Asset Composition'"
          :chartData="pieChartData"
          :chartType="1"
      />
      <v-data-table
          :headers="headers"
          :items="items"
          item-key="name"
          disable-pagination
          :hide-default-footer="true"
          show-select
          class="table"
      />
    </div>
  </div>
</template>

<script>
import UserStats from '@/json/userStats.json'
import StatsCard from '@/components/trading/statistics/StatsCard.vue'
import StatsOverview from '@/components/trading/statistics/StatsOverview.vue'
import {mapGetters} from 'vuex'

export default {
  computed: mapGetters(['userStats', 'userSubscribedBots']),
  components: {
    StatsCard,
    StatsOverview
  },
  data() {
    return {
      userStatistics: UserStats,
      pieChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      barChartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: 'return',
            backgroundColor: '#73c159',
            data: [40, -20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 21]
          }
        ]
      },
      lineChartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            fill: false,
            label: 'Total Profit',
            borderColor: '#73c159',
            backgroundColor: '#73c159',
            data: [40, 39, 10, 40, 39, 80, 40, 82, 23, 24, 66, 90]
          },
          {
            fill: false,
            label: 'Tops and Bottoms',
            borderDash: [1, 5],
            borderColor: '#f87979',
            backgroundColor: '#f87979',
            data: [12, 45, 2, 78, 12, 43, 40, 65, 23, 87, 32, 79]
          },
          {
            fill: false,
            label: 'Hirundo.bot',
            borderDash: [1, 5],
            borderColor: '#15bcd2',
            backgroundColor: '#15bcd2',
            data: [40, 30, -12, 43, 93, 23, 14, 56, 34, 75, 98, 64]
          }
        ]
      },
      headers: [
        {text: 'Date', align: 'date', sortable: true, value: 'date'},
        {text: 'Bot name', value: 'botName', sortable: true},
        {text: 'Pair', value: 'pair', sortable: true},
        {text: 'Side', value: 'side', sortable: true},
        {text: 'Price', value: 'price', sortable: true},
        {text: 'Status', value: 'status', sortable: true},
        {text: 'Invested', value: 'invested', sortable: true}
      ],
      items: []
    }
  },
  created() {
    this.getUserFundsAllocation()
  },
  methods: {
    // set pie chart values for user funds allocation
    getUserFundsAllocation() {
      Object.values(UserStats.fundsAllocation).forEach((item) => {
        this.pieChartData.labels.push(item.label)
        this.pieChartData.datasets[0].data.push(item.value)
        this.pieChartData.datasets[0].backgroundColor.push(item.color)
      })
    },
    // load icon images
    loadImage(path) {
      return require(`@/${path}`)
    }
  }
}
</script>

<style scoped>
.statistics {
  display: flex;
  flex-direction: column;
}

.statistics-content {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.general-overview {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.table {
  width: 100%;
}

.small {
  width: 33%;
  height: 600px;
}

.medium {
  width: 65%;
  height: 600px;
}


@media (max-width: 850px) {
  .small {
    width: 100%;
  }

  .medium {
    width: 100%;
  }

  .general-overview-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .statistics-content {
    width: 100%;
  }

  .general-overview {
    flex-direction: column;
  }

  .general-overview {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
}
</style>
