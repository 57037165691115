<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="closeModal()">
      <div class="modal-container" @click.stop>
        <div class="modal-close" @click="closeModal()">&#10799;</div>
        <div class="content">
          <div class="top-information">
            <div class="top-information-text">
              <div class="modal-header">
                <span class="modal-title">
                  <span>Are you sure you want to cancel your subscription?</span>
                </span>
                <loading :active="isLoading"/>
                <span v-if="!isLoading && this.subscriptionInfo.payment_platform === 'paypal'" class="subtext note">NOTE: This action cannot be undone.</span>
                <div v-if="!isLoading && this.subscriptionInfo.payment_platform !== 'paypal'" class="subtext">
                  <span>You subscription will not renew after
                    <span v-if="expiry" style="font-weight: bold"> {{ expiry.split(' ')[0] }}. </span>
                    Do you want to stop your bots now or wait until your subscription runs out?</span>
                  <div class="cancel-options">
                    <div class="cancel-radio">
                      <input name="cancel-type" class="cancel-radio-radio" type="radio" id="now" checked @click="toggleCancelType(0)">
                      <label class="cancel-radio-text" for="now">Stop all bots now</label>
                    </div>
                    <div class="cancel-radio">
                      <input name="cancel-type" class="cancel-radio-radio" type="radio" id="subscription" @click="toggleCancelType(1)">
                      <label class="cancel-radio-text" for="subscription">Stop bots when subscription runs out</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isLoading" class="buttons">
                <span class="form-button  button-shadow cancel-bot-button" @click="cancelSubscription()">Cancel subscription</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import Loading from '../Loading.vue'

export default {
  components: {Loading},
  name: 'Modal',
  props: {
    subscriptionInfo: Object,
    subscriptionId: String,
    expiry: String,
  },
  data() {
    return {
      cancelType: true,
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['addNotification']),
    cancelSubscription() {
      this.isLoading = true
      const body = {
        user_id: this.$session.get('uid'),
        subscription_id: this.subscriptionId,
        stop_all_bots: this.cancelType,
        cancel_now: false
      }
      let apiUrl = '/subscriptions/stripe/cancel_subscription'

      if (this.subscriptionInfo.payment_platform === 'paypal') apiUrl = '/subscriptions/paypal/cancel_subscription'
      this.$api
          .post(apiUrl, body, {
                headers: {
                  'X-Authorization': this.$session.get('token')
                }
              }
          )
          .then((res) => {
            if (res.status === 200) {
              const notification = {
                status: 0,
                title: 'Successful cancellation',
                text: 'Your subscription was successfully cancelled'
              }
              this.addNotification(notification)
              this.$session.remove('subscription_plan')
              if (this.subscriptionInfo.payment_platform === 'paypal') {
                this.$router.push({name: 'Dashboard', query: {status: 'cancelled'}})
              }
            }
          })
          .then(() => {
            this.$emit('cancelSubscription', 1)
          })
          .catch((err) => {
            if (err.response && err.response.data.error.type === 'Could not validate credentials') {
              this.$session.destroy()
              this.$router.push({name: 'Login'})
            }
          })
    },
    closeModal() {
      this.$emit('click')
    },
    toggleCancelType(e) {
      switch (e) {
        case 0:
          this.cancelType = true
          break;
        case 1:
          this.cancelType = false
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.content {
  padding: 10px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-title {
  color: var(--text-primary);
  font-size: 34px;
  font-weight: bold;
}

.subtext {
  margin-top: 20px;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}

.modal-container {
  width: 1300px;
  max-height: 95vh;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: var(--background);
  box-shadow: 0 2px 8px var(--shadow);
  transition: all 0.3s ease;
  border-radius: 20px;
  overflow: auto;
}

.modal-close {
  font-size: 50px;
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -10px;
}

.modal-header {
  vertical-align: middle;
  width: 100%;
  font-size: 28px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.top-information-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.to-cart {
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.cancel-bot-button {
  background-color: var(--red);
}

.note {
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.cancel-bot-button:hover {
  background-color: var(--red);
}

.updated-values {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.updated-value {
  font-weight: bold;
}

.note {
  font-style: italic;
}

.res {
  background-color: var(--green);
}

.res:hover {
  background-color: var(--green);
}

.cancel-options {
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cancel-radio {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cancel-radio-radio {
  margin-right: 5px;
  cursor: pointer;
}

.cancel-radio-text {
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 1250px) {
  .modal-container {
    padding: 20px 10px;
    width: 95vw;
    height: auto;
  }
}

@media (max-width: 850px) {
  .modal-container {
    width: 95vw;
    height: auto;
    padding: 5px;
  }

  .buttons {
    flex-direction: row;
    align-items: center;
  }

  .subtext {
    text-align: center;
  }

  .buttons {
    flex-direction: column;
  }

  .to-cart {
    margin-top: 20px;
  }
}
</style>
