<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="top-content">
          <span class="title">Purchase Subscription</span>
          <div class="modal-close" @click="closeModal()">&#10799;</div>
        </div>
        <div class="content">
          <payment-form :subscriptionPlan="subscription" @paymentComplete="completePayment($event)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentForm from '@/components/shared/PaymentForm'

export default {
  name: 'SubscriptionModal',
  props: {
    subscriptionInfo: Object,
    subscription: Object
  },
  components: {
    PaymentForm
  },
  methods: {
    closeModal() {
      this.$emit('click')
    },
    completePayment() {
      this.$emit('paymentComplete', 0)
    },
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 105vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  height: auto;
  width: 700px;
  margin: 0 auto;
  background-color: var(--background);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 10px;
}

.modal-content {
  height: 100%;
  font-size: 16px;
}

.top-content {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-close {
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 30px;
  cursor: pointer;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  padding: 10px 20px;
  display: flex;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 1200px) {
  .modal-container {
    width: 95vw;
    height: auto;
    overflow-y: auto;
    padding: 15px;
  }

  .content {
    padding: 10px 0;
  }
}
</style>
