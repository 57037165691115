import Vuex from 'vuex'
import Vue from 'vue'
import cartModule from './modules/cart'
import botsModule from './modules/bots'
import exchangeModules from './modules/exchanges'
import userModules from './modules/user'
import newBotModules from './modules/newBot'
import notificationModule from './modules/notification'
import marketPlaceModule from './modules/marketplace'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        bots: botsModule,
        exchanges: exchangeModules,
        user: userModules,
        newBot: newBotModules,
        notification: notificationModule,
        marketplace: marketPlaceModule
    }
});
