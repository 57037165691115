const state = {
	notification: {
		status: 0,
		title: 'Subscription created',
		text: 'Your subscription has succesfully been updated'
	}
};

const getters = {
	notification: (state) => state.notification
};

const actions = {
	async addNotification({commit}, item) {
		commit('newNotification', item)
	},
	async clearNotification({commit}) {
		commit('removeNotification');
	},

};

const mutations = {
	newNotification(state, item) {
		state.notification = item
	},
	removeNotification(state) {
		state.notification = {}
	},
};

export default {
	state,
	getters,
	actions,
	mutations
}
