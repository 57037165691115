<template>
  <div class="activity">
    <h1 class="title">Activity</h1>
    <loading class="loading" :active="loading" />
    <div v-if="!loading" class="filters">
      <div class="selected-filters">
        <div v-if="isFiltered" v-for="filter in selectedFilters.bot_names" :key="filter.bot_id" class="filter-item">
          <span>{{ filter.bot_name }}</span>
          <span class="filter-remove" @click="removeFilter(filter, 0)"><span class="cross">&#10799;</span></span>
        </div>
        <div v-if="isFiltered" v-for="filter in selectedFilters.pairs" :key="filter" class="filter-item">
          <span>{{ filter }}</span>
          <span class="filter-remove" @click="removeFilter(filter, 1)"><span class="cross">&#10799;</span></span>
        </div>
        <div v-if="isFiltered && selectedFilters.sides !== ''" class="filter-item">
          <span>{{ selectedFilters.sides[0].toUpperCase() + selectedFilters.sides.substr(1) }}</span>
          <span class="filter-remove" @click="removeFilter(filter, 2)"><span class="cross">&#10799;</span></span>
        </div>
        <span v-if="isFiltered" class="filter-item-clear" @click="clearFilter()">Clear all filters</span>
      </div>
      <div class="filter-options">
        <activity-filters ref="activityFilter" :items="filters" @updateFilter="updateFilter($event)" />
      </div>
    </div>
    <div v-if="!loading" class="activity-content card">
      <v-data-table :headers="headers" :items="currentItems" :search="search" item-key="name" :footer-props="{'items-per-page-options': [5, 10, 20, 30, 40, 50]}" :items-per-page='20' no-data-text="No data yet available" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :hide-default-footer="true">
        <template v-slot:item.bot_name="{ item }">
          <span v-if="item" small class="exchange">
            <img class="exchange-icon" src="@/assets/bots/bot_template.png" alt="">
            <span>{{ item.bot_name }}</span>
          </span>
        </template>
        <template v-slot:item.date="{ item }">
          <span v-if="item" small class="exchange">
            <span>{{ item.date.split(' ')[0] + ' ' + item.date.split(' ')[1].split(':')[0] + ':' + item.date.split(' ')[1].split(':')[1] }}</span>
          </span>
        </template>
        <template v-slot:item.symbol="{ item }">
          <span v-if="item" small class="exchange">
            <img class="exchange-icon" :src="loadIcon(item.base_asset)" alt="">
            <span>{{ item.symbol }}</span>
          </span>
        </template>
        <template v-slot:item.amount="{ item }">
          <span v-if="item" small class="exchange">
            <span>{{ item.amount.toString() + ' ' + item.base_asset }}</span>
          </span>
        </template>
        <template v-slot:item.side="{ item }">
          <span v-if="item" small class="exchange">
            <span class="buy" :class="{sell :item.side === 'sell'}">{{ item.side[0].toUpperCase() + item.side.substr(1) }}</span>
          </span>
        </template>
        <template v-slot:item.price="{ item }">
          <span v-if="item" small class="exchange">
            <span v-if="item.price">{{ item.price.toString() }}</span>
          </span>
        </template>
      </v-data-table>
      <div v-if="currentItems && currentItems.length > 0" class="pagination">
        <span v-if="currentPage !== 0" class="button-p button-shadow footer-button" @click="previousPage()">&lt;</span>
        <span class="pagination-number">{{ currentPage + 1 }}</span>
        <span v-if="showNextPage" class="button-p button-shadow footer-button" @click="nextPage()">&gt;</span>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityFilters from '@/components/trading/ActivityFilters'
import loading from "@/components/shared/Loading";

export default {
  components: {
    ActivityFilters,
    loading
  },
  data() {
    return {
      headers: [
        { text: 'Date', align: 'date', sortable: false, value: 'date' },
        { text: 'Bot name', value: 'bot_name', sortable: false },
        { text: 'Symbol', value: 'symbol', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Side', value: 'side', sortable: false },
        { text: 'Price', value: 'price', sortable: false }
      ],
      items: [],
      filteredItems: [],
      currentItems: [],
      currentPage: 0,
      showNextPage: true,
      lastDate: '',
      lastDateIndex: '',
      sortBy: 'date',
      sortDesc: true,
      search: '',
      loadedFilters: false,
      filters: {},
      selectedFilters: {
        bot_names: [],
        pairs: [],
        sides: ''
      },
      isFiltered: false,
      botNameFilter: false,
      pairFilter: false,
      sideFilter: false,
      resultsToGet: 20,
      loading: false
    }
  },
  mounted() {
    this.getUserActivity()
  },
  methods: {
    loadIcon(coin) {
      try {
        return require('/node_modules/cryptocurrency-icons/32/color/' + coin.toLowerCase() + '.png')
      } catch (e) {
        return require('/node_modules/cryptocurrency-icons/32/color/generic.png')
      }
    },
    toggleFilter(filter) {
      this.search = filter
    },
    removeFilter(filter, type) {
      this.items = []
      this.currentPage = 0

      switch (type) {
        case 0:
          this.selectedFilters.bot_names.splice(this.selectedFilters.bot_names.indexOf(filter), 1)
          if (this.selectedFilters.bot_names.length < 1) {
            this.botNameFilter = false
          }
          break;
        case 1:
          this.selectedFilters.pairs.splice(this.selectedFilters.pairs.indexOf(filter), 1)
          if (this.selectedFilters.pairs.length < 1) {
            this.pairFilter = false
          }
          break;
        case 2:
          this.selectedFilters.sides = ''
          this.sideFilter = false
          break;
      }

      this.getUserActivity()

      if (!this.botNameFilter && !this.pairFilter && !this.sideFilter) {
        this.isFiltered = false
        this.selectedFilters = {
          bot_names: [],
          pairs: [],
          sides: ''
        }

        this.lastDate = ''
        this.currentPage = 0
        this.resultsToGet = 20
        this.$refs.activityFilter.clearFilters()
        return
      }
    },
    clearFilter() {
      this.items = []

      this.isFiltered = false
      this.botNameFilter = false
      this.pairFilter = false
      this.sideFilter = false

      this.selectedFilters = {
        bot_names: [],
        pairs: [],
        sides: ''
      }

      this.lastDate = ''
      this.currentPage = 0
      this.resultsToGet = 20
      this.$refs.activityFilter.clearFilters()

      this.getUserActivity()
    },
    updateFilter(e) {
      this.isFiltered = true
      this.items = []
      this.currentPage = 0

      switch (e.type) {
        case 0:
          if (this.selectedFilters.bot_names.indexOf(e.item) === -1) {
            this.selectedFilters.bot_names.push(e.item)
            this.botNameFilter = true
          }
          break;
        case 1:
          if (this.selectedFilters.pairs.indexOf(e.item) === -1) {
            this.selectedFilters.pairs.push(e.item)
            this.pairFilter = true
          }
          break;
        case 2:
          if (this.selectedFilters.sides.indexOf(e.item) === -1) {
            this.selectedFilters.sides = e.item.slice(0, -1)
            this.sideFilter = true
          }
          break;
      }

      this.getUserActivity()
    },
    getUserActivity() {
      this.loading = true

      let options = {
        user_id: this.$session.get('uid'),
        timezone: this.$session.get('timezone'),
        results_to_get: this.resultsToGet
      }

      if (this.lastDate !== '') {
        options.last_date = this.lastDate
        options.last_date_index = this.lastDateIndex
      }

      if (this.botNameFilter) {
        let tempBotIds = []
        this.selectedFilters.bot_names.forEach((elem) => {
          tempBotIds.push(elem.bot_id)
        })
        options.bot_ids = tempBotIds
      }

      if (this.pairFilter) {
        options.symbols = this.selectedFilters.pairs
      }

      if (this.sideFilter) {
        options.buy_sells = this.selectedFilters.sides
      }
      const qs = require('qs');
      this.$api
        .get('/bots/get_activity', {
          params: options,
          paramsSerializer: function paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          },
          headers: {
            'X-Authorization': this.$session.get('token')
          }
        })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false
            this.showNextPage = true

            if (this.items.length > 0) {
              this.showNextPage = true
              this.currentItems = []
              this.currentItems = res.data.data.activity_data
              this.items = this.items.concat(res.data.data.activity_data)
              if (this.currentItems.length < 20) {
                this.showNextPage = false
              }
              return
            }
            if (!this.loadedFilters) {
              this.loadedFilters = true
              this.filters = res.data.data.filters
            }
            res.data.data.activity_data ? this.items = res.data.data.activity_data : this.items = []
            this.currentItems = this.items

            if (this.currentItems.length < 20) this.showNextPage = false

          }
        })
        .catch((err) => {
          if (err.response && err.response.data.error.type === 'Could not validate credentials') {
            this.$session.destroy()
            this.$router.push({ name: 'Login' })
          }
        })
    },
    getMoreRows() {
      this.lastDate = this.items[this.items.length - 1].date
      this.lastDateIndex = this.items[this.items.length - 1].date_index

      this.getUserActivity()
    },
    previousPage() {
      this.showNextPage = true
      this.currentPage--

      let lastValue = this.items.indexOf(this.currentItems[0])
      let firstValue = lastValue - 20

      this.currentItems = []

      for (let i = firstValue; i < lastValue; i++) {
        this.currentItems.push(this.items[i])
      }

    },
    nextPage() {
      this.currentPage++
      let firstValue = this.items.indexOf(this.currentItems[this.currentItems.length - 1]) + 1
      let lastValue = firstValue + 20

      if (this.items[firstValue]) {
        this.currentItems = []
        for (let i = firstValue; i < lastValue; i++) {
          if (!this.items[i]) {
            this.showNextPage = false
            return
          }
          this.currentItems.push(this.items[i])
        }
        if (this.currentItems.length < 10) this.showNextPage = false
        return
      }

      this.getMoreRows()
    },
    loadBotIcon(botName) {
      const bot_name = botName.split(" ").join("")
      return require('@/assets/bots/' + bot_name + '.png')
    }
  }
}
</script>

<style scoped>
.activity {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}

.activity-content {
  margin-top: 20px;
}

.exchange {
  display: flex;
  align-items: center;
}

.exchange-icon {
  height: 25px;
  margin-right: 10px;
  border-radius: 50%;
}

.buy {
  width: 65px;
  display: flex;
  justify-content: center;
  padding: 2px 5px;
  color: var(--green);
  border-radius: 5px;
  font-weight: bold;
}

.sell {
  color: var(--red);
}

.search-bar {
  position: relative;
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-input {
  height: 40px;
  width: 100%;
  border: 2px solid var(--header-color);
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  background: var(--card-background);
  color: var(--text-primary);
  border-radius: 5px;
  background-color: var(--white);
}

.search-input:focus {
  outline: none;
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  height: 25px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.filter-item {
  margin: 0 10px;
  padding: 5px 5px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--border);
  font-weight: bold;
}

.filter-remove {
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  color: var(--text-primary);
  font-size: 20px;
  cursor: pointer;
}

.cross {
  margin-left: 2.5px;
}

.filter-item-clear {
  color: var(--text-link);
  text-decoration: underline;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
}

.selected {
  background-color: var(--secondary-blue);
  color: var(--text-secondary);
}

.selected-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.filter-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 999;
}

.filter-item-title {
  font-weight: bold;
  color: var(--disabled);
}

.pagination {
  padding: 0 10px;
  height: 60px;
  background-color: var(--card-background);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: var(--text-link);
}

.pagination-number {
  color: var(--text-primary);
  text-decoration: none;
}

.footer-button {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 50%;
  padding: 15px;
  font-weight: bold;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
