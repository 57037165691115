<template>
  <div v-click-outside="outside" class="filter">
    <div class="closed-filter" @click="toggleOpenFilter()">
      <span class="close-filter-title">Filters</span>
      <img v-if="isDarkTheme" class="filter-img" src="@/assets/filters-white.png" alt="">
      <img v-else class="filter-img" src="@/assets/filters.png" alt="">
    </div>
    <div v-show="isFilterOpen" class="open-filter card">
      <div class="filter-section" @click="toggleExchangeFilter()">
        <span class="filter-title">
          <span>Exchange</span>
          <img v-if="!exchangeFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="exchangeFilterOpen" class="filter-options">
          <span v-for="item in items.exchanges" :key="item.id" class="filter-item" @click="addItemToFilter(item)">{{ item.title }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleCoinFilter()">
        <span class="filter-title">
          <span>Coin</span>
          <img v-if="!coinFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="coinFilterOpen" class="filter-options">
          <span v-for="item in items.coin" :key="item.id" class="filter-item" @click="addItemToFilter(item)">{{ item.title }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleBaseCoinFilter()">
        <span class="filter-title">
          <span>Base Coin</span>
          <img v-if="!baseCoinFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="baseCoinFilterOpen" class="filter-options">
          <span v-for="item in items.base_coin" :key="item.id" class="filter-item" @click="addItemToFilter(item)">{{ item.title }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleRiskLevelFilter()">
        <span class="filter-title">
          <span>Risk Level</span>
          <img v-if="!riskLevelFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="riskLevelFilterOpen" class="filter-options">
          <span v-for="item in items.risk" :key="item.id" class="filter-item" @click="addItemToFilter(item)">{{ item.title }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleExtraFilter()">
        <span class="filter-title">
          <span>Extra</span>
          <img v-if="!extraFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="extraFilterOpen" class="filter-options">
          <span v-for="item in items.extra" :key="item.id" class="filter-item" @click="addItemToFilter(item)">{{ item.title }}</span>
        </div>
      </div>
      <div class="filter-section" @click="toggleExclusivityFilter()">
        <span class="filter-title">
          <span>Exclusivity</span>
          <img v-if="!exclusivityFilterOpen" class="filter-arrow-close" src="@/assets/filterArrowClose.png" alt=""/>
          <img v-else class="filter-arrow-close" src="@/assets/filterArrowOpen.png" alt=""/>
        </span>
        <div v-if="exclusivityFilterOpen" class="filter-options">
          <span v-for="item in items.exclusive" :key="item.id" class="filter-item" @click="addItemToFilter(item)">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketPlaceFilters from '@/json/MarketPlaceFilters.json'
import {mapGetters} from 'vuex'

export default {
  name: 'MarketPlaceFilters',
  data() {
    return {
      isFilterOpen: false,
      items: MarketPlaceFilters,
      coinFilterOpen: false,
      baseCoinFilterOpen: false,
      riskLevelFilterOpen: false,
      exclusivityFilterOpen: false,
      extraFilterOpen: false,
      exchangeFilterOpen: false,
      isDarkTheme: localStorage.boostingAlphaDarkTheme
    }
  },
  computed: mapGetters(['darkTheme']),
  watch: {
    darkTheme() {
      this.isDarkTheme = this.darkTheme
    }
  },
  methods: {
    toggleCoinFilter() {
      this.coinFilterOpen = !this.coinFilterOpen
    },
    toggleBaseCoinFilter() {
      this.baseCoinFilterOpen = !this.baseCoinFilterOpen
    },
    toggleRiskLevelFilter() {
      this.riskLevelFilterOpen = !this.riskLevelFilterOpen
    },
    toggleExtraFilter() {
      this.extraFilterOpen = !this.extraFilterOpen
    },
    toggleExclusivityFilter() {
      this.exclusivityFilterOpen = !this.exclusivityFilterOpen
    },
    toggleExchangeFilter() {
      this.exchangeFilterOpen = !this.exchangeFilterOpen
    },
    outside() {
      this.isFilterOpen = false

      this.coinFilterOpen = false
      this.baseCoinFilterOpen = false
      this.riskLevelFilterOpen = false
      this.exclusivityFilterOpen = false
      this.exchangeFilterOpen = false
    },
    toggleOpenFilter() {
      this.isFilterOpen = !this.isFilterOpen

      this.coinFilterOpen = false
      this.baseCoinFilterOpen = false
      this.riskLevelFilterOpen = false
      this.exclusivityFilterOpen = false
      this.exchangeFilterOpen = false
    },
    clearFilters() {
      this.isFilterOpen = false
      this.$emit('clearFilter')
    },
    addItemToFilter(item) {
      this.isFilterOpen = false
      this.coinFilterOpen = false
      this.baseCoinFilterOpen = false
      this.riskLevelFilterOpen = false
      this.exclusivityFilterOpen = false

      if (item.type === 5) {
        const temp = {
          item: item,
          exchange: true
        }
        this.$emit('updateFilter', temp)
        return
      }

      this.$emit('updateFilter', item)
    },
  }
}
</script>

<style scoped>
.filter {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
}

.closed-filter {
  min-width: 200px;
  height: 30px;
  display: flex;
  padding: 5px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--card-background);
  border: 1px solid var(--border);
  border-radius: 5px;
  z-index: 1;
}

.open-filter {
  top: 35px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  background-color: var(--card-background);
  z-index: 9;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 5px;
}

.filter-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.filter-options {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}

.filter-title {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-title:hover {
  background-color: var(--selected);
  color: var(--secondary-blue);
}

.filter-item {
  font-weight: 400;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  color: var(--text-primary);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
}

.filter-item:hover {
  color: var(--white);
  background-color: var(--secondary-blue);
}

.filter-img {
  height: 15px;
  width: 15px;
}

.clear-filter {
  padding: 10px 0;
  text-decoration: underline;
  color: var(--text-link);
  cursor: pointer;
}

.filter-arrow-close {
  height: 10px;
  width: 10px;
}
</style>